import { useHistory, NavLink, useParams } from "react-router-dom";
import SubHeader from "../Thema/sub-header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faMoneyBillWave, faHotel } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useEffect, useState } from 'react';
import Chart from "react-apexcharts";
// import { Hidden } from "@material-ui/core";
// import AuthorityControl from "../Ekmekk/Auth/AuthorityControl";
import Ekmekk from "../Ekmekk/Helper/ekmekk";
import DataCenter from "../Ekmekk/DataCenter/datacenter";
import NullPage from "../Ekmekk/Views/NullPage";
import AuthorityControl from "../Ekmekk/Auth/AuthorityControl";

export default function HomePage() {

    const [homeData, setHomeData] = useState({});
    const [chartSeries, setChartSeries] = useState([])

    const [mostSelledProducts, setMostSelledProducts] = useState([]);
    const [leastStocks, setLeastStocks] = useState([]);

    const [upcomingDateEducation, setUpcomingDateEducation] = useState([]);
    const [upcomingVaccine, setUpcomingVaccine] = useState([]);
    const [upcomingHotel, setUpcomingHotel] = useState([]);


    var history = useHistory();

    useEffect(() => {

        DataCenter().Home.ListHome().then(r => {

            if (r.status) {
                console.log(r.data);
                setHomeData(r.data);
                setUpcomingDateEducation(r.data.upcomingDateEducation);
                setMostSelledProducts(r.data.mostSellingProduct);
                setLeastStocks(r.data.stock);

                setUpcomingVaccine(r.data.vaccine);
                setUpcomingHotel(r.data.housingLastProcess);

                var dizim = [];
                dizim.push({
                    name: "series-1",
                    data: []
                })

                for (var i = 0; i < 8; i++) {
                    if (r.data.weekly_gain_with_day != undefined || r.data.weekly_gain_with_day != null)
                        dizim[0].data.push(r.data.weekly_gain_with_day[i])
                    else
                        dizim[0].data.push(0)

                }
                setChartSeries(dizim);
            }
        });

    }, [])


    function ChartMy() {

        const [chartOptions, setChartOptions] = useState({
            series: [{
                name: 'Net Profit',
                data: [30, 45, 32, 70, 40]
            }],
            chart: {
                type: 'area',
                height: 100,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'solid',
                opacity: 1
            },
            stroke: {
                curve: 'smooth',
                show: true,
                width: 3,
                colors: ["#1BC5BD"]
            },
            xaxis: {
                categories: ['Pzt', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    style: {
                        colors: "#ffffff",
                        fontSize: '12px',
                    }
                },
                crosshairs: {
                    show: false,
                    position: 'front',
                    stroke: {
                        color: "#ffffff",
                        width: 1,
                        dashArray: 3
                    }
                },
                tooltip: {
                    enabled: true,
                    formatter: undefined,
                    offsetY: 0,
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    style: {
                        colors: "#ffffff",
                        fontSize: '12px',
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val) {
                        return "$" + val + " thousands"
                    }
                }
            },
            colors: ["#c9f7f5"],
            markers: {
                colors: ["#ffffff"],
                strokeColor: ["#ffffff"],
                strokeWidth: 3
            }
        })




        return (
            <Chart options={chartOptions} series={chartSeries} type="area" width="97%" height="80%" />
        );

    }

    function MostSelledProductItemList(item) {

        return <>
            <tr>
                {/*begin::Icon*/}
                <td className="align-middle w-50px pl-0 pr-2 pb-6">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-50 symbol-light-success">
                        <img src={item.ek_product_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + item.ek_product_image} alt="" className="h-50" />

                    </div>
                    {/*end::Symbol*/}
                </td>
                {/*end::Icon mostSelledProducts */}
                {/*begin::Content*/}
                <td className="align-middle pb-6">
                    <div className="font-size-lg font-weight-bolder text-dark-75 mb-1">{item.ek_product_name}</div>
                    <div className="font-weight-bold text-muted">{item.ek_product_brand_name}</div>
                </td>
                <td className="font-weight-bold text-muted align-middle text-right pb-6">

                    <div className="font-weight-bold text-muted mb-1">Adet</div>
                    <span className="text-dark-75 font-size-h5 font-weight-bolder ml-1">{item.selling_piece}</span>
                </td>
                <AuthorityControl pageCode={84}>

                    <td className="text-right align-middle pb-6">
                        <div className="font-weight-bold text-muted mb-1">Kâr</div>
                        <div className="font-size-lg font-weight-bolder text-dark-75">{item.gain}</div>
                    </td>
                </AuthorityControl>
                {/*end::Content*/}
            </tr>

        </>
    }

    function LeastStockItemList(item) {
        return <>
            <tr>
                {/*begin::Icon*/}
                <td className="align-middle w-50px pl-0 pr-2 pb-6">
                    {/*begin::Symbol*/}
                    <div className="symbol symbol-50 symbol-light-success">
                        <img src={item.ek_product_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + item.ek_product_image} alt="" className="h-50" />

                    </div>
                    {/*end::Symbol*/}
                </td>
                {/*end::Icon mostSelledProducts */}
                {/*begin::Content*/}
                <td className="align-middle pb-6">
                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                        <a href="#"
                            className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mb-2">{item.ek_product_name}</a>
                        <span className="text-muted font-weight-bold font-size-lg">Marka:
                            <span className="font-weight-bolder text-muted"> {item.ek_product_brand_name}</span></span>
                    </div>
                </td>
                <td className="align-middle pb-6">
                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3 align-items-center">
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mb-2">Kalan</a>
                        <span className="font-weight-bolder text-danger"> {item.ek_product_stok}</span>
                    </div>
                </td>
                {/*end::Content*/}
            </tr>

        </>
    }

    function UpcomingEducationItemList(item) {

        return <>
            <tr>
                <td className="pl-0">
                    <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.ek_customer_dog_name}</a>
                </td>
                <td className="pl-0">
                    <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.ek_customer_fullname}</a>
                </td>
                <td className="pl-0">
                    <span className="text-muted font-weight-bold">{item.ek_dog_lesson_start_date}</span>
                </td>
                <td className="pl-0">
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.ek_education_name}</span>
                </td>
                <td className="pl-0">
                    <span className="text-dark-75 font-weight-bolder font-size-lg">{item.ek_education_lesson_name}</span>
                </td>
            </tr>

        </>
    }

    function UpcomingVaccineItemList(item) {

        return <>
            <tr>
                <td className="pl-0">
                    <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.ek_customer_fullname}</a>
                </td>
                <td className="pl-0">
                    <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.ek_customer_dog_name}</a>
                </td>
                <td className="pl-0">
                    <span className="text-muted font-weight-bold">{item.ek_will_vaccine_date.split(" ")[0]}</span>
                </td>
                <td className="pl-0">
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.ek_vaccine_name}</span>
                </td>
                
            </tr>
        </>

    }

    function UpcomingHotelItemList(item) {

        return <>
            <tr>
                <td className="pl-0">
                    <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.ek_customer_fullname}</a>
                </td>
                <td className="pl-0">
                    <a href="#" className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.ek_customer_dog_name}</a>
                </td>
                <td className="pl-0">
                    <span className="text-muted font-weight-bold">{item.ek_exit_date ? item.ek_exit_date.split(" ")[0] : "Belirsiz"}</span>
                </td>
                <td className="pl-0">
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.ek_cage_name}</span>
                </td>
                <td className="pl-0">
                    <span className="text-dark-75 font-weight-bolder font-size-lg">{item.price}</span>
                </td>
            </tr>
        </>

    }

    return <>


        <div className="container">

            <div className="row">

                <div className="col-md-4 col-lg-12 col-xxl-4 p-4">
                    {/*begin::Featured Product*/}
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body d-flex flex-column rounded justify-content-between">
                            <div className="rounded text-center mb-7 pt-7">
                                <img src="/my-assets/dog.svg" className="mw-100 w-200px" style={{
                                    transform: 'scale(1.4)'
                                }} />
                            </div>
                            <div className="text-center">
                                <h3 className="font-size-h1">
                                    <a href="#" className="text-dark font-weight-bolder">{homeData.most_selling_education != undefined || homeData.most_selling_education != null ? homeData.most_selling_education : ""}</a>
                                </h3>
                                <div className="font-size-h4 text-muted">Çok satan eğitim</div>
                            </div>
                        </div>
                    </div>
                    {/*end::Featured Product*/}
                </div>
                
                <div className="col-md-8 col-lg-12 col-xxl-8 col-xs-12">

                    <div className="card-stretch card-stretch-half gutter-b overflow-hidden p-0">
                        <AuthorityControl pageCode={1}>

                            <div className="row">

                                {/* TOPLAM KAZANÇ */}
                                <div className="col-md-6 p-4 col-xs-12">
                                    {/* <AuthorityControl pageCode={0}> */}
                                    {/*begin::Stats Widget 27*/}

                                    <div className="card card-custom bg-light-success card-stretch ">
                                        {/*begin::Body*/}
                                        <div className="card-body">
                                            <span className="svg-icon svg-icon-2x svg-icon-success">
                                                {/*begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg*/}
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                    viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16}
                                                            rx="1.5" />
                                                        <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                                                        <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                                                        <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                                                    </g>
                                                </svg>
                                                {/*end::Svg Icon*/}
                                            </span>

                                            <span
                                                className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block">₺{homeData.total_gain}</span>
                                            <span className="font-weight-bold text-muted font-size-sm">Toplam Kazanç</span>

                                        </div>
                                        {/*end::Body*/}
                                    </div>
                                    {/*end::Stats Widget 27*/}

                                    {/* </AuthorityControl > */}
                                </div>


                                <div className="col-xl-6 col-md-6 p-4">

                                    <div className="card card-stretch card-custom h-100 p-4"     >
                                        {/*begin::Body*/}
                                        <div className="card-body p-0" >
                                            <div className="card-rounded-bottom" style={{ height: "70%", width: "100%", position: "absolute", marginTop: "13%" }}>
                                                <ChartMy />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1" style={{ height: "60%", width: "100%", position: "absolute" }}>
                                                <span className="symbol symbol-50 symbol-light-success mr-2">
                                                    <span className="symbol-label">
                                                        <span className="svg-icon svg-icon-xl svg-icon-success">
                                                            {/*begin::Svg Icon | path:/metronic/theme/html/demo10/dist/assets/media/svg/icons/Layout/Layout-4-blocks.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <rect fill="#000000" x={4} y={4} width={7} height={7} rx="1.5" />
                                                                    <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>
                                                    </span>
                                                </span>
                                                <div className="d-flex flex-column text-right">
                                                    <span className="text-dark-75 font-weight-bolder font-size-h3">{homeData.weekly_gain}₺</span>
                                                    <span className="text-muted font-weight-bold mt-2">Haftalık</span>
                                                </div>
                                            </div>


                                        </div>

                                    </div>


                                </div>


                            </div>
                        </AuthorityControl>

                    </div>

                    <div className="card-stretch card-stretch-half gutter-b" style={{ backgroundColor: "transparent" }}>

                        <div className="row">

                            <div className="col-md-6 col-lg-6 col-xs-12">
                                {/*begin::Engage Widget 8*/}
                                <div className=" card-custom card-stretch h-100 p-4">
                                    <div className="card-body p-0 d-flex">
                                        <div
                                            className="d-flex align-items-start justify-content-start flex-grow-1 bg-light-warning p-8 card-rounded flex-grow-1 position-relative">
                                            <div className="d-flex flex-column align-items-start flex-grow-1 h-100">
                                                <div className="p-1 flex-grow-1">
                                                    <h4 className="text-warning font-weight-bolder">Kafesleri Yönet</h4>
                                                    <p className="text-dark-50 font-weight-bold mt-3">Köpek Eklemek İçin Devam Edin</p>
                                                </div>
                                                <NavLink to={"/dashboard/cages/cageList"}>
                                                    <a href="#" className="btn btn-link btn-link-warning font-weight-bold">Create Report
                                                        <span className="svg-icon svg-icon-lg svg-icon-warning">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                                viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                    <rect fill="#000000" opacity="0.3"
                                                                        transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                                                                        x={11} y={5} width={2} height={14} rx={1} />
                                                                    <path
                                                                        d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                                        fill="#000000" fillRule="nonzero"
                                                                        transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span></a>
                                                </NavLink>
                                            </div>
                                            <div className="position-absolute right-0 bottom-0 mr-5 overflow-hidden">
                                                <img src="../assets/media/svg/humans/custom-13.svg"
                                                    className="max-h-200px max-h-xl-275px mb-n20" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*end::Engage Widget 8*/}
                            </div>

                            {/* KAFES DOLULUK ORANI */}
                            <div className="col-md-6 p-4">
                                {/*begin::Stats Widget 21*/}
                                <div className="card card-custom bg-light-info card-stretch">
                                    {/*begin::ody*/}
                                    <div className="card-body">
                                        <span className="svg-icon svg-icon-2x svg-icon-success">
                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg*/}
                                            <FontAwesomeIcon icon={faHotel}></FontAwesomeIcon>
                                        </span>

                                        <span
                                            className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block">{homeData.fullCage}/{homeData.cageCount}</span>
                                        <div className="progress progress-xs bg-info-o-60">
                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: '' + (homeData.fullCage * 100 / homeData.cageCount) + '%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                        </div>

                                        <span className="font-weight-bold text-muted font-size-sm">Dolu Kafes Oranı</span>
                                    </div>
                                    {/*end::Body*/}
                                </div>
                                {/*end::Stats Widget 21*/}
                            </div>


                        </div>

                    </div>
                </div>

            </div>

            <div className="row">

                {/* EN ÇOK SATILAN 3 ÜRÜN */}
                <div className="col-xl-6">
                    {/*begin::List Widget 20*/}
                    <div className="card card-custom card-stretch gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-6 mb-2">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark mb-1 font-size-h3 pt-3">Top 3 Ürün</span>
                            </h3>
                            <div className="card-toolbar">
                                <div className="dropdown dropdown-inline">
                                    <a href="#" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i className="ki ki-bold-more-hor" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                        {/*begin::Navigation*/}
                                        <ul className="navi navi-hover">
                                            <li className="navi-header font-weight-bold py-4">
                                                <span className="font-size-lg">Choose Label:</span>
                                                <i className="flaticon2-information icon-md text-muted"
                                                    data-toggle="tooltip" data-placement="right"
                                                    title="Click to learn more..." />
                                            </li>
                                            <li className="navi-separator mb-3 opacity-70" />
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-success">Customer</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-danger">Partner</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-warning">Suplier</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-primary">Member</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-dark">Staff</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-separator mt-3 opacity-70" />
                                            <li className="navi-footer py-4">
                                                <a className="btn btn-clean font-weight-bold btn-sm" href="#">
                                                    <i className="ki ki-plus icon-sm" />Add new</a>
                                            </li>
                                        </ul>
                                        {/*end::Navigation*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body pt-2">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        {
                                            mostSelledProducts == undefined ? "" : mostSelledProducts.length == 0 ? <NullPage /> : mostSelledProducts.map(item => {
                                                return MostSelledProductItemList(item)
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::List Widget 20*/}
                </div>

                {/* STOKTA AZALAN 3 ÜRÜN */}
                <div className="col-xl-6">
                    {/*begin::List Widget 21*/}
                    <div className="card card-custom card-stretch gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column mb-5">
                                <span className="card-label font-weight-bolder text-dark mb-1 font-size-h3 pt-3">Azalan 3
                                    Ürün</span>
                            </h3>
                            <div className="card-toolbar">
                                <div className="dropdown dropdown-inline">
                                    <a href="#" className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i className="ki ki-bold-more-hor" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                        {/*begin::Navigation*/}
                                        <ul className="navi navi-hover">
                                            <li className="navi-header font-weight-bold py-4">
                                                <span className="font-size-lg">Choose Label:</span>
                                                <i className="flaticon2-information icon-md text-muted"
                                                    data-toggle="tooltip" data-placement="right"
                                                    title="Click to learn more..." />
                                            </li>
                                            <li className="navi-separator mb-3 opacity-70" />
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-success">Customer</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-danger">Partner</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-warning">Suplier</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-primary">Member</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-item">
                                                <a href="#" className="navi-link">
                                                    <span className="navi-text">
                                                        <span
                                                            className="label label-xl label-inline label-light-dark">Staff</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="navi-separator mt-3 opacity-70" />
                                            <li className="navi-footer py-4">
                                                <a className="btn btn-clean font-weight-bold btn-sm" href="#">
                                                    <i className="ki ki-plus icon-sm" />Add new</a>
                                            </li>
                                        </ul>
                                        {/*end::Navigation*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body pt-2">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        {
                                            leastStocks == undefined ? "" : leastStocks.length == 0 ? <NullPage /> : leastStocks.map(item => {
                                                return LeastStockItemList(item)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/* <div className="card-body pt-2">
                            {
                                leastStocks == undefined ? "" : leastStocks.length == 0 ? <NullPage /> : leastStocks.map(item => {
                                    return LeastStockItemList(item)
                                })
                            }

                        </div> */}
                        {/*end::Body*/}
                    </div>
                    {/*end::List Widget 21*/}
                </div>
                {/* ÜRÜN HAVUZU YÖNLENDİRME */}

            </div>


            <div className="row">
                
                <div className="col-xl-8 col-lg-12 col-md-12 col-xs-12">
                    <div className="card card-custom gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark font-size-h3 pt-3">Yaklaşan
                                    Eğitimler</span>
                            </h3>

                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body py-0">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                                    {
                                        upcomingDateEducation == undefined ? "" : upcomingDateEducation.length == 0 ? NullPage() : <thead>
                                            <tr className="text-uppercase">
                                                <th className="pl-0" style={{ minWidth: '100px' }}>Köpek</th>
                                                <th style={{ minWidth: '120px' }}>Müşteri</th>
                                                <th style={{ minWidth: '150px' }}>
                                                    <span className="text-primary">Başlangıç</span>
                                                    <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                        {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                            viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                                <rect fill="#000000" opacity="0.3" x={11} y={4} width={2}
                                                                    height={10} rx={1} />
                                                                <path
                                                                    d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z"
                                                                    fill="#000000" fillRule="nonzero"
                                                                    transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999)" />
                                                            </g>
                                                        </svg>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '150px' }}>Eğitim</th>
                                                <th style={{ minWidth: '130px' }}>Ders</th>
                                            </tr>
                                        </thead>
                                    }

                                    <tbody>
                                        {
                                            upcomingDateEducation == undefined ? "" : upcomingDateEducation.length == 0 ? "" : upcomingDateEducation.map(item => {
                                                return UpcomingEducationItemList(item)
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                </div>
                
                <div className="col-xl-4">
                    {/*begin::Engage Widget 6*/}
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body d-flex p-0">
                            <div className="flex-grow-1 bg-danger p-12 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
                                style={{
                                    backgroundPosition: 'calc(100% + 0.5rem) bottom', backgroundSize: '35% auto',
                                    backgroundImage: 'url(../assets/media/svg/humans/custom-7.svg)'
                                }}>
                                <p
                                    className="text-inverse-danger pt-10 pb-5 font-size-h3 font-weight-bolder line-height-lg">
                                    Hızlı ve Kolay bir şekilde
                                    <br />Ders Planı Yapın
                                    <br />
                                </p>
                                <NavLink to={"/dashboard/plain/organizer"}>
                                    <a className="btn btn-warning font-weight-bold py-2 px-6">DERS PLANLAMA</a>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {/*end::Engage Widget 6*/}
                </div>

            </div>


            <div className="row">

                <div className="col-xl-6 col-lg-12">
                    <div className="card card-custom gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark font-size-h3 pt-3">Yaklaşan
                                    Aşılar</span>
                            </h3>

                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body py-0">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                                    {
                                        upcomingVaccine == undefined ? "" : upcomingVaccine.length == 0 ? NullPage() :
                                            <thead>
                                                <tr className="text-uppercase">
                                                    <th style={{ minWidth: '120px' }}>Müşteri</th>
                                                    <th className="pl-0" style={{ minWidth: '100px' }}>Köpek</th>
                                                    <th style={{ minWidth: '150px' }}>
                                                        <span className="text-primary">Tarih</span>
                                                        <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                                viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                    <rect fill="#000000" opacity="0.3" x={11} y={4} width={2}
                                                                        height={10} rx={1} />
                                                                    <path
                                                                        d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z"
                                                                        fill="#000000" fillRule="nonzero"
                                                                        transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999)" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>
                                                    </th>
                                                    <th style={{ minWidth: '150px' }}>Aşı</th>
                                                    {/* <th style={{ minWidth: '130px' }}>Ders</th> */}
                                                </tr>
                                            </thead>
                                    }

                                    <tbody>
                                        {
                                            upcomingVaccine == undefined ? "" : upcomingVaccine.length == 0 ? "" : upcomingVaccine.map(item => {
                                                return UpcomingVaccineItemList(item)
                                            })
                                        }
                                        

                                    </tbody>
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                </div>

                <div className="col-xl-6 col-lg-12">
                    <div className="card card-custom gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 py-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark font-size-h3 pt-3">Yaklaşan
                                    Konaklamalar</span>
                            </h3>

                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body py-0">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                                    {
                                        upcomingHotel == undefined ? "" : upcomingHotel.length == 0 ? NullPage() : 
                                        <thead>
                                            <tr className="text-uppercase">
                                                <th style={{ minWidth: '100px' }}>Müşteri</th>
                                                <th className="pl-0" style={{ minWidth: '100px' }}>Köpek</th>
                                                <th style={{ minWidth: '100px' }}>
                                                    <span className="text-primary">Bitiş</span>
                                                    <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                        {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg*/}
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                                            viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                                <rect fill="#000000" opacity="0.3" x={11} y={4} width={2}
                                                                    height={10} rx={1} />
                                                                <path
                                                                    d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z"
                                                                    fill="#000000" fillRule="nonzero"
                                                                    transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999)" />
                                                            </g>
                                                        </svg>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '100px' }}>Kafes</th>
                                                <th style={{ minWidth: '100px' }}>Ücret</th>
                                            </tr>
                                        </thead>
                                    }

                                    <tbody>
                                        {
                                            upcomingHotel == undefined ? "" : upcomingHotel.length == 0 ? "" : upcomingHotel.map(item => {
                                                return UpcomingHotelItemList(item)
                                            })
                                        }
                                        

                                    </tbody>
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                </div>

            </div>


        </div>



    </>
}