
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'

import ModalRoyalDogInsert from "../../Modal/RoyalDog/RoyalDogInsert"
import SubHeader from '../../Thema/sub-header'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnet, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import NullPage from '../../Ekmekk/Views/NullPage'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'


export default function RoyalDogSelled() {

    var inputBreedSearch = useRef(null)

    const [breedList, setBreedList] = useState([])

    const [list, setList] = useState([]);
    const [mainList, setMainList] = useState([]);

    var history = useHistory();
    useEffect(() => {


        DataCenter().RoyalDog.ListRoyalDogSelled().then(r => {
            console.log(r.data);
            setList(r.data);
            setMainList(r.data);
        })

        DataCenter().DogBreed.ListDogBreed().then(r => {
            setBreedList(r.data);
        })
    }, [])

    function RoyalDogInsertClick(caseData, data) {



        if (caseData == "inserted") {

            // alert(JSON.stringify(data));
            list.push(data);
            setList([...list])

        }
        else if (caseData == "updated") {
            for (var i = 0; i < list.length; i++) {
                if (list[i].ek_royal_dog_ID == data.ek_royal_dog_ID) {
                    if (data.ek_royal_dog_image == null) {
                        var temp = list[i].ek_royal_dog_image;
                    }
                    else {
                        var temp = data.ek_royal_dog_image
                    }
                    // var tempDate = list[i].ek_product_creation_date;

                    list[i] = data;

                    list[i].ek_royal_dog_image = temp;
                    // list[i].ek_product_creation_date = tempDate;
                }
            }

            setList([...list]);
        }

    }

    function RoyalDogDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {

                DataCenter().RoyalDog.DeleteRoyalDog(item.ek_royal_dog_ID).then(r => {
                    if (r.status) {
                        var list2 = [...list];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_royal_dog_ID == item.ek_royal_dog_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setList(list2);
                        setMainList(list2);
                    }
                })

            }
        })
    }

    function BreedItem(item) {
        return <option value={item.ek_dog_breed_name}>{item.ek_dog_breed_name}</option>
    }

    function ChangeSearchText(e) {
        var filteredList = mainList.filter(item => item.ek_royal_dog_gender.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setList(filteredList);

    }

    function ChangeBreedText(e) {

        if (inputBreedSearch.current.value == 0) {
            setList(mainList);
        }
        else {
            var filteredList = mainList.filter(item => item.ek_dog_breed_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
            setList(filteredList);
        }

    }


    return <>


        <div className="mb-7">
            <div className="row align-items-center">
                <div className="col-md-4 mb-2">
                    <div className="input-icon">
                        <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Köpek Adı" id="kt_datatable_search_query" />
                        <span>
                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
                <div className="col-md"></div>
                <div className="col-md-4 mb-2">
                    <div className="input-icon">
                        <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Müşteri Adı" id="kt_datatable_search_query" />
                        <span>
                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
                <div className="col-md"></div>
                <div className="col-md-3 mb-2">
                    <div className="d-flex align-items-center">
                        <label className="mr-3 mb-0 d-none d-md-block">Cins:</label>
                        <select ref={inputBreedSearch} onChange={ChangeBreedText} className="form-control" id="kt_datatable_search_type">
                            <option value={0}>All</option>
                            {
                                breedList.map(item => {
                                    return BreedItem(item)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md"></div>
                {/* <div className="col-md-2">
                    <ModalRoyalDogInsert isSale={false} insertClick={RoyalDogInsertClick} />
                </div> */}
            </div>
        </div>
    
        <AuthorityControl pageCode={46}>

            {
                list.length != 0 ? TableList(list) : <NullPage />
            }
        </AuthorityControl>

    </>


    function TableList(data) {


        let history = useHistory();

        const columns = [

            /* {
               title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
             },*/

            {
                title: <span className="text">Foto</span>, render: data => <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                    <img src={data.ek_royal_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + data.ek_royal_dog_image} alt="" className="h-50" />
                </div>

            },
            {
                title: <span className="text">İsim</span>, render: data => <a className="text-dark font-weight-boldest text-hover-primary font-size-lg">{data.ek_royal_dog_name}</a>
            },
            {
                title: <span className="text">Müşteri</span>, render: data => <a className="text-dark-50 font-weight-bolder text-hover-primary font-size-lg">{data.ek_customer_fullname}</a>
            },
            {
                title: <span className="text">Tarih</span>, render: data => <div>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{data.selling_date}</span>
                </div>
            },
            {
                title: <span className="text">Cinsi</span>, render: data => <div>
                    <span className="text-dark-50 font-weight-bolder d-block font-size-lg">{data.ek_dog_breed_name}</span>
                </div>
            },
            {
                title: <span className="text">Alt Cinsi</span>, render: data => <div>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{data.ek_dog_sub_breed_name}</span>
                </div>
            },
            {
                title: <span className="text">Alış Fiyatı</span>, render: data => <div>
                    <span className="text-dark-50 font-weight-bolder d-block font-size-lg">{data.ek_royal_dog_purchase_price}</span>
                </div>
            },
            {
                title: <span className="text">Satış Fiyatı</span>, render: data => <div>
                    <span className="text-info font-weight-bolder d-block font-size-lg">{data.ek_royal_dog_sale_price}</span>
                </div>
            },



        ]

        const handleRowClick = (event, rowData) => {
            // alert(JSON.stringify(rowData))
            const index = data.indexOf(rowData)
            history.push('/dashboard/customer/dog-profile/' + rowData.ek_customer_dog_ID)

            //localStorage.setItem("selectedItem", JSON.stringify(data[index]))


            // history.push("/projects/insert");


            /* if (Ekmekk().Profile.userType == UserType.MASTER) {
               history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
             }
             else {
               history.push("/business/orders/order-detail/" + data[index].user_orderID);
             }*/


        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                    }

                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }
}

