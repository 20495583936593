
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function CustomerController() {


    // CUSTOMER

    function ListCustomer() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/customer', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })

                    }
                });

        });
    }

    function InsertCustomer(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()
        return new Promise((ok, fail) => {
            let data = new FormData();
            if (dataBlock.ek_customer_image != null)
                data.append('ek_customer_image', dataBlock.ek_customer_image, dataBlock.ek_customer_image.name);

            if (dataBlock.ek_customer_fullname != null)
                data.append('ek_customer_fullname', dataBlock.ek_customer_fullname);
            else
                data.append('ek_customer_fullname', "");

            console.log(dataBlock.id_no)
            if (dataBlock.id_no != null)
                data.append('id_no', dataBlock.id_no);
            else
                data.append('id_no', "");

            if (dataBlock.ek_customer_phone != null)
                data.append('ek_customer_phone', dataBlock.ek_customer_phone);
            else
                data.append('ek_customer_phone', "");

            if (dataBlock.ek_customer_mail != null)
                data.append('ek_customer_mail', dataBlock.ek_customer_mail);

            if (dataBlock.ek_customer_address != null)
                data.append('ek_customer_address', dataBlock.ek_customer_address);

            if (dataBlock.ek_customer_address_district != null)
                data.append('ek_customer_address_district', dataBlock.ek_customer_address_district);

            if (dataBlock.ek_customer_birthdate != null)
                data.append('ek_customer_birthdate', dataBlock.ek_customer_birthdate);

            if (dataBlock.ek_job_ID != null)
                data.append('ek_job_ID', dataBlock.ek_job_ID);


            axios.post(Ekmekk().URL + '/admin/customer', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateCustomer(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            if (dataBlock.ek_customer_image != null)
                data.append('ek_customer_image', dataBlock.ek_customer_image, dataBlock.ek_customer_image.name);

            if (dataBlock.ek_customer_fullname != null && dataBlock.ek_customer_fullname != undefined)
                data.append('ek_customer_fullname', dataBlock.ek_customer_fullname);

            if (dataBlock.id_no != null && dataBlock.id_no != undefined)
                data.append('ek_customer_id_no', dataBlock.id_no);

            if (dataBlock.ek_customer_phone != null && dataBlock.ek_customer_phone != undefined)
                data.append('ek_customer_phone', dataBlock.ek_customer_phone);

            if (dataBlock.ek_customer_mail != null && dataBlock.ek_customer_mail != undefined)
                data.append('ek_customer_mail', dataBlock.ek_customer_mail);

            if (dataBlock.ek_customer_address != null && dataBlock.ek_customer_address != undefined)
                data.append('ek_customer_address', dataBlock.ek_customer_address);

            if (dataBlock.ek_customer_address_district != null && dataBlock.ek_customer_address_district != undefined)
                data.append('ek_customer_address_district', dataBlock.ek_customer_address_district);

            if (dataBlock.ek_customer_birthdate != null && dataBlock.ek_customer_birthdate != undefined)
                data.append('ek_customer_birthdate', dataBlock.ek_customer_birthdate);

            if (dataBlock.ek_job_ID != null && dataBlock.ek_job_ID != undefined)
                data.append('ek_job_ID', dataBlock.ek_job_ID);

            if (dataBlock.ek_customer_ID != null && dataBlock.ek_customer_ID != undefined)
                data.append('ek_customer_ID', dataBlock.ek_customer_ID);

            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            axios.put(Ekmekk().URL + '/admin/customer', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    ok(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function DeleteCustomer(customerID) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/customer/' + customerID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }



    // CUSTOMER PROFİLE

    function ShowCustomerProfile(customerID) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/customer/profile/' + customerID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })

                    }
                });

        });

    }

    function ListCustomerDog(customerID) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/customer/dog/' + customerID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                });

        });
    }

    function InsertCustomerDog(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            if (dataBlock.ek_customer_dog_image != null)
                data.append('ek_customer_dog_image', dataBlock.ek_customer_dog_image, dataBlock.ek_customer_dog_image.name);
            data.append('ek_customer_dog_name', dataBlock.ek_customer_dog_name);
            data.append('ek_customer_dog_gender', dataBlock.ek_customer_dog_gender);
            data.append('ek_customer_dog_birthday', dataBlock.ek_customer_dog_birthday);
            data.append('ek_customer_ID', dataBlock.ek_customer_ID);
            data.append('ek_dog_sub_breed_ID', dataBlock.ek_dog_sub_breed_ID);


            axios.post(Ekmekk().URL + '/admin/customer/dog', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListCustomerDogEducation(customerDogID) {
        return new Promise((ok, fail) => {
            const api = Ekmekk().Profile.GetProfile()

            axios.post(Ekmekk().URL + '/admin/customer/dog/profile/education', {
                ek_customer_dog_ID: customerDogID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })

                    }
                });
        })
    }

    function DeleteCustomerDog(dogID) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/customer/dog/' + dogID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function PayDebt(dataBlock) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.post(Ekmekk().URL + '/admin/customer/profile/pay_debt', {

                ek_selling_ID: dataBlock.ek_selling_ID,
                paid_price: dataBlock.paid_price,

            }, {
                headers: {
                    'Authorization': `Basic ${api}`,

                },
            })
                .then(function (response) {
                    console.log(response.data)
                    ok(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }


    // CUSTOMER DOG PROFİLE

    function ShowCustomerDogProfile(dogID) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/customer/dog/profile/' + dogID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })

                    }
                });

        });

    }

    function InsertCustomerDogImage(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.put(Ekmekk().URL + '/admin/customer/dog', dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${dataBlock._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateCustomerDog(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();

            if (dataBlock.ek_customer_dog_image != null) {
                data.append('ek_customer_dog_image', dataBlock.ek_customer_dog_image, dataBlock.ek_customer_dog_image.name);
            }

            data.append('ek_customer_dog_name', dataBlock.ek_customer_dog_name);
            data.append('ek_customer_dog_gender', dataBlock.ek_customer_dog_gender);
            data.append('ek_customer_dog_birthday', dataBlock.ek_customer_dog_birthday);
            data.append('ek_dog_sub_breed_ID', dataBlock.ek_dog_sub_breed_ID);
            data.append('ek_customer_dog_ID', dataBlock.ek_customer_dog_ID);


            axios.put(Ekmekk().URL + '/admin/customer/dog', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function InsertDogImages(block) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.post(Ekmekk().URL + '/admin/customer/dog/images', block, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${block._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function DeleteDogImages(imageID) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.delete(Ekmekk().URL + '/admin/customer/dog/image/' + imageID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },

            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });

    }

    // CUSTOMER DOG VACCİNE

    function InsertCustomerDogVaccine(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {


            axios.post(Ekmekk().URL + '/admin/dog/vaccine', dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateCustomerDogVaccine(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {


            axios.put(Ekmekk().URL + '/admin/dog/vaccine', dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function DeleteCustomerDogVaccine(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.delete(Ekmekk().URL + '/admin/dog/vaccine/' + dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`
                },

            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }



    // ROYAL DOG PROFİLE

    function ShowRoyalDogProfile(dogID) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/royal_dog/profile/' + dogID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })

                    }
                });

        });

    }

    return {
        InsertCustomer, ListCustomer, UpdateCustomerDogVaccine, DeleteCustomerDogVaccine, InsertCustomerDogVaccine, ShowCustomerProfile, InsertCustomerDogImage, DeleteCustomer, ShowRoyalDogProfile, InsertCustomerDog, ListCustomerDog, ListCustomerDogEducation, ShowCustomerDogProfile, DeleteCustomerDog, InsertDogImages, UpdateCustomer, UpdateCustomerDog, PayDebt, DeleteDogImages
    }
}