import React, { useEffect, useState } from "react"
import './App.css';
import Ekmekk from './Ekmekk/Helper/ekmekk';
import DataCenter from './Ekmekk/DataCenter/datacenter';
import Main from './Thema/main';
import MainNew from './Thema/mainNew';
import Login from './Pages/Login/login'
import Referance from './Pages/Referance'
import axios from 'axios'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
} from "react-router-dom";


function App() {
    

    useEffect(() => {

        axios.interceptors.request.use(function (config) {
            if (global.requestCounter != undefined) {
                global.requestCounter++;

            } else {
                global.requestCounter = 1;
                global.lastRequestTime = new Date();
            }

            Ekmekk().Alert.Block();

            return config;
        }, function (error) {
            return Promise.reject(error);
        });


        axios.interceptors.response.use(function (response) {
            if (global.responseCounter != undefined) {
                global.responseCounter++;
            } else {
                global.responseCounter = 1;
            }
            if (global.requestCounter == global.responseCounter) {
                global.requestCounter = undefined;
                global.responseCounter = undefined;
                const diffTime = Math.abs(new Date() - global.lastRequestTime);

                var waitTime = 500 - diffTime;
                if (waitTime < 0) {


                    Ekmekk().Alert.UnblockUI();
                } else {
                    setTimeout(function () {

                        Ekmekk().Alert.UnblockUI();
                    }, waitTime);


                }

            }

            return response;
        }, function (error) {
            return Promise.reject(error);
        });


        // console.log(axios.interceptors.response);


    }, [])

    function Body() {

        if (Ekmekk().Profile.GetProfile() == null) {
            return <Redirect to='/login' />
        } else {
            if (localStorage.getItem("isAdmin") == "1")
                return <Redirect to='/dashboard/home' />
            else
                return <Redirect to='/dashboard/user/home' />

        }
    }

    function Index() {
        return < >

            asfasf </>
    }



    return <Router >
        <Switch >


            {
                /* <Route exact path="/">
                        <Index />
                      </Route> */
            } <Route path="/dashboard" >
                <MainNew />
            </Route> <Route path="/login" >
                <Login />
            </Route> <Route path="/referance" >
                <Referance />
            </Route> {
                Body()
            }

        </Switch>

    </Router>
}


export default App;