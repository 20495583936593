import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import { ViewState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Scheduler,
    WeekView,
    MonthView,
    Appointments,
    DateNavigator,
    Toolbar,
    TodayButton,
    AppointmentTooltip,
    ViewSwitcher,
    AppointmentForm,
    AllDayPanel,
    EditRecurrenceMenu,
    DayView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useEffect } from 'react';
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import { useState } from 'react';
import { useRef } from 'react';
import ModalCustomerSelect from '../../Modal/Customer/CustomerSelect';
import ModalEducatorSelect from '../../Modal/Education/EducatorSelect';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { useParams } from 'react-router-dom';
import SubHeader from '../../Thema/sub-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalEducatorInsert from '../../Modal/Education/EducatorInsert';
import NullPage from '../../Ekmekk/Views/NullPage';
import ModalSaleComplete from '../../Modal/Sale/SaleComplete';
import ModalHotelSale from '../../Modal/Cage/HotelSaleInsert'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';
import HotelOldSaleInsert from '../../Modal/Cage/HotelOldSaleInsert'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


export default function KonaklamaSatis() {


    var customerID = useParams();
    var dogID = useParams();
    var cageID = useParams();

    var selectionData = useRef(null);
    var dogRef = useRef(null);
    var salePrice = useRef(null);
    const [mySalePrice, setMySalePrice] = useState(-1);

    const [saleList, setSaleList] = useState([]);

    const [selectionCustomer, setSelectedCustomer] = useState({});

    const [dogList, setDogList] = useState([]);
    const [selectedDog, setSelectedDog] = useState(-1);

    // const [educationList, setEducationList] = useState([]);
    // const [mainEducationList, setMainEducationList] = useState([]);
    // const [selectedEducation, setSelectedEducation] = useState(-1);

    const [cageList, setCageList] = useState([]);
    const [mainCageList, setMainCageList] = useState([]);
    const [selectedCage, setSelectedCage] = useState([]);
    const [cagePrice, setCagePrice] = useState(-1);

    const [isModal, setModal] = useState(false);
    const [isSaleModal, setSaleModal] = useState(false);
    const [isOldSaleModal, setOldSaleModal] = useState(false);
    const [isEducatorModal, setEducatorModal] = useState(false);

    const [updateCage, setUpdateCage] = useState(-1);
    const [isInCage, setİsInCage] = useState(-1);

    useEffect(() => {

        console.log(cageID.cageID);

        DataCenter().Cage.ListCage().then(r => {
            console.log(r);
            setCageList(r)
            setMainCageList(r);

            var myCageList = r;

            for (let i = 0; i < myCageList.length; i++) {

                if (myCageList[i].ek_cage_ID == cageID.cageID) {
                    setSelectedCage(myCageList[i]);
                }

            }
        })

    }, [])

    function CompleteSale() {

        // TÜM BİLGİLER DOLUYSA SATIŞ YAPTIR
        if (salePrice.current.value == null || selectedCage.length == 0 || selectedDog == -1) {
            Ekmekk().Alert.FillAll();
        }
        else {

            console.log(dogList);
            console.log(selectedDog);
            console.log(selectedCage.ek_cage_ID);


            setMySalePrice(salePrice.current.value);
            // setSaleModal(true);



            // KÖPEK ZATEN BİR KAFESTE Mİ KONTROL ET - KÖPEK KONAKLAMADA MI KONTROL ET
            var isInCage = 0;
            var isInHousing = 0;
            setİsInCage(0);

            for (let i = 0; i < dogList.length; i++) {

                if (dogList[i].ek_customer_dog_ID == selectedDog) {

                    // KAFESTE Mİ??
                    if (dogList[i].isActiveCage != "0") {
                        isInCage = 1;
                        setİsInCage(1);
                    }

                    // KONAKLAMADA MI??
                    if (dogList[i].isDogCageHousing == "1") {
                        isInHousing = 1;
                    }
                }
            }

            // KÖPEK KONAKLAMADA MI KONTROL ET
            if (isInHousing == 1) {

                Ekmekk().Alert.FailKonaklama();

            }
            // KÖPEK KAFESTE Mİ KONTROL ET
            else if (isInCage == 1) {

                Ekmekk().Alert.FailKonaklama();

            }
            else {
                setSaleModal(true);
            }

        }

    }

    function CompleteOldSale() {

        if (salePrice.current.value == null || selectedDog == -1) {
            Ekmekk().Alert.FillAll();
        }
        else {
            setMySalePrice(salePrice.current.value);

            setOldSaleModal(true);

        }


    }

    function FillCage(data) {

        // SATIŞ YAPILDIKTAN SONRA SEÇİLİ KAFESİ DOLDURMA VE EKRANDAN ÇIKARTMA

        for (let i = 0; i < cageList.length; i++) {

            if (cageList[i].ek_cage_ID == selectedCage.ek_cage_ID) {
                cageList[i].isCageFull = 1;

                setCageList([...cageList]);
            }
        }

        setSelectedDog(-1);
        setSelectedCage([]);
        setSaleModal(false);


    }


    function Handler(data) {
        if (data == "close") {
            setModal(false);
            setSaleModal(false);
            setOldSaleModal(false);
            setEducatorModal(false);
        }
    }

    function selectedCustomer(r) {
        setModal(false);
        setSelectedCustomer(JSON.parse(r));
        DataCenter().Customer.ListCustomerDog(JSON.parse(r)["ek_customer_ID"]).then(r => {
            setDogList(r.data)
        });

    }

    function SelectCustomer() {
        return <div>

            <div style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center">
                    <img src="/my-assets/hotel.svg" className="max-h-400px" />

                </div>
                <div className="font-weight-bold font-size-lg mt-2">Herşeyden önce bir müşteri seçmelisin</div>
                <div className="text-muted mt-2">Bir eğitim takvimi güzenlemek için öncelikli olarak bir müşteri seçmelisin</div>
                <div className="btn btn-primary mt-6" onClick={() => { setModal(true); }}>Müşteri seç</div>
            </div>


        </div>
    }

    function SelectAnotherData() {
        return <div>
            <BrowserView>
                <div style={{ textAlign: "center" }}>
                    <div className="d-flex justify-content-center">
                        <img src="/my-assets/hotel.svg" className="max-h-400px" />

                    </div>
                    <div className="font-weight-bold font-size-lg mt-2">Harika! Kalan ufak bilgileri doldur ve organize etmeye başla</div>
                    <div className="text-muted mt-2">Bir eğitim takvimi güzenlemek için öncelikli olarak bir müşteri seçmelisin</div>

                </div>
            </BrowserView>
        </div>
    }

    function CageItem(item) {
        return <>
            {
                item.isCageFull == 0 ? <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    {/*begin::Card*/}
                    <div onClick={() => { setSelectedCage(item); console.log(item); setCagePrice(item.ek_cage_price); }} className="card card-custom gutter-b card-stretch">

                        {/*begin::Body*/}
                        <div className="card-body pt-4">
                            {/*begin::Toolbar*/}
                            {/*end::Toolbar*/}
                            <div className="pt-4 text-center">
                                <div className="symbol symbol-100 symbol-2by3 flex-shrink-0">
                                    <div className="d-flex flex-column">
                                        <div className="symbol-label mb-3" style={{ backgroundImage: 'url("' + Ekmekk().URL + '/images/' + item.ek_cage_image + '")' }} />
                                    </div>
                                </div>
                            </div>
                            {/*begin::User*/}
                            <div className="d-flex align-items-center mb-7">
                                {/*begin::Pic*/}
                                <div className="flex-shrink-0 mr-4">
                                </div>
                                {/*end::Pic*/}
                                {/*begin::Title*/}
                                <div className="d-flex flex-column">
                                    <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.ek_cage_name}</a>
                                    <span className="text-muted font-weight-bold">{item.ek_cage_size}</span>
                                </div>
                                {/*end::Title*/}
                            </div>
                            {/*end::User*/}

                        </div>
                    </div>
                    {/*end:: Card*/}
                </div> : ""
            }


        </>
    }

    function Organizer() {
        return <>

            <div className={cageList.length == 0 ? "row d-flex justify-content-center" : "row"}>

                {
                    cageList == undefined ? "" : cageList.length == 0 ? <NullPage image={"certification.svg"} /> : cageList.map(item => {
                        return CageItem(item)
                    })
                }

            </div>

        </>
    }

    function TimeLine() {
        return <>
            <BrowserView>
                <div className="timeline timeline-5">
                    <div className="timeline-items">
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    1
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Müşteri Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        setModal(true);
                                        // setList([]);
                                        // setSelectedEducation(-1);
                                        setSelectedCage([]);
                                        setSelectedDog(-1);
                                        setCagePrice(-1);
                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});

                                    }}>
                                        {/*begin: Pic*/}
                                        <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                            <div className="symbol shadow symbol-40 symbol-lg-50">
                                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                <img src={(selectionCustomer.ek_customer_image != "" && selectionCustomer.ek_customer_image != undefined) ? Ekmekk().GetImageURL(selectionCustomer.ek_customer_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                            </div>
                                            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                            </div>
                                        </div>
                                        {/*end: Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin: Title*/}
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">




                                                        {
                                                            (selectionCustomer.ek_customer_fullname != "" && selectionCustomer.ek_customer_fullname != undefined) ? selectionCustomer.ek_customer_fullname : "-----"
                                                        }
                                                        <i className="flaticon2-correct text-success icon-md ml-2" /></a>

                                                </div>
                                            </div>
                                            {/*end: Title*/}
                                            {/*begin: Content*/}
                                            <div className="d-flex align-items-center flex-wrap justify-content-between">

                                            </div>
                                            {/*end: Content*/}
                                        </div>
                                        {/*end: Info*/}
                                    </div>
                                    <div className="separator separator-solid my-3" />

                                    <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Mail :</span>  {(selectionCustomer.ek_customer_mail != "" && selectionCustomer.ek_customer_mail != undefined) ? selectionCustomer.ek_customer_mail : "-----"}</div>

                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Telefon : {(selectionCustomer.ek_customer_phone != "" && selectionCustomer.ek_customer_phone != undefined) ? selectionCustomer.ek_customer_phone : "-----"}</div>
                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Adres :  {(selectionCustomer.ek_customer_address != "" && selectionCustomer.ek_customer_address != undefined) ? selectionCustomer.ek_customer_address : "-----"}</div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    2
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Köpek Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <select onChange={(e) => {
                                        setSelectedDog(e.target.value);

                                        setSelectedCage([]);
                                        setCagePrice(-1);

                                    }} value={selectedDog} ref={dogRef} class="form-control form-control-lg form-control-solid" id="select_banner_type">
                                        <option value="-1">Lütfen bir seçim yapınız</option>
                                        {
                                            dogList.map(item => {
                                                return <option selected={dogID.dogID == item.ek_customer_dog_ID} value={item.ek_customer_dog_ID}>{item.ek_customer_dog_name}</option>
                                            })
                                        }


                                    </select>
                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    3
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Kafes Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        // setModal(true);
                                        // setList([]);
                                        // setSelectedEducation(-1);
                                        // setSelectedDog(-1);
                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});

                                    }}>
                                        {/*begin: Pic*/}
                                        <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                            <div className="symbol shadow symbol-40 symbol-lg-50">
                                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                <img src={(selectedCage.ek_cage_image != "" && selectedCage.ek_cage_image != undefined) ? Ekmekk().GetImageURL(selectedCage.ek_cage_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                            </div>
                                            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                            </div>
                                        </div>
                                        {/*end: Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin: Title*/}
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">

                                                        {
                                                            (selectedCage.ek_cage_name != "" && selectedCage.ek_cage_name != undefined) ? selectedCage.ek_cage_name : "-----"
                                                        }

                                                        <i className="flaticon2-correct text-success icon-md ml-2" />
                                                    </a>

                                                    <span className="text-muted font-weight-bold">
                                                        {
                                                            (selectedCage.ek_cage_size != "" && selectedCage.ek_cage_size != undefined) ? selectedCage.ek_cage_size : "-----"
                                                        }
                                                    </span>

                                                </div>
                                            </div>
                                            {/*end: Title*/}
                                            {/*begin: Content*/}
                                            <div className="d-flex align-items-center flex-wrap justify-content-between">

                                            </div>
                                            {/*end: Content*/}
                                        </div>
                                        {/*end: Info*/}
                                    </div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    4
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Satış Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="col-lg-9 col-xl-9">
                                        <input ref={salePrice} className="form-control form-control-lg" id="campain_name2" placeholder={"Günlük Fiyatı"} type="number" defaultValue={cagePrice != -1 ? cagePrice : ""} />
                                    </div>
                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    5
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">

                                <button onClick={() => { CompleteSale() }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg py-4 px-3">Satışı Tamamla</button>

                            </div>
                            {/*end::Info*/}
                        </div>
                        <div className="timeline-item mt-2">
                            {/*begin::Icon*/}
                            {/* <div className="timeline-media bg-light-dark">
                                <span className="svg-icon-dark svg-icon-md">
                                    7
                                </span>
                            </div> */}
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            {/* <div className="timeline-desc timeline-desc-light-success"> */}

                            <button onClick={() => { CompleteOldSale() }} className="btn btn-sm btn-light-dark font-weight-bold font-size-lg ml-14 px-3">Geçmiş Satış</button>

                            {/* </div> */}
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className="timeline timeline-5">
                    <div className="timeline-items">
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    1
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Müşteri Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        setModal(true);
                                        // setList([]);
                                        // setSelectedEducation(-1);
                                        setSelectedCage([]);
                                        setSelectedDog(-1);
                                        setCagePrice(-1);
                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});

                                    }}>
                                        {/*begin: Pic*/}
                                        <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                            <div className="symbol shadow symbol-40 symbol-lg-50">
                                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                <img src={(selectionCustomer.ek_customer_image != "" && selectionCustomer.ek_customer_image != undefined) ? Ekmekk().GetImageURL(selectionCustomer.ek_customer_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                            </div>
                                            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                            </div>
                                        </div>
                                        {/*end: Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin: Title*/}
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">




                                                        {
                                                            (selectionCustomer.ek_customer_fullname != "" && selectionCustomer.ek_customer_fullname != undefined) ? selectionCustomer.ek_customer_fullname : "-----"
                                                        }
                                                        <i className="flaticon2-correct text-success icon-md ml-2" /></a>

                                                </div>
                                            </div>
                                            {/*end: Title*/}
                                            {/*begin: Content*/}
                                            <div className="d-flex align-items-center flex-wrap justify-content-between">

                                            </div>
                                            {/*end: Content*/}
                                        </div>
                                        {/*end: Info*/}
                                    </div>
                                    <div className="separator separator-solid my-3" />

                                    <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Mail :</span>  {(selectionCustomer.ek_customer_mail != "" && selectionCustomer.ek_customer_mail != undefined) ? selectionCustomer.ek_customer_mail : "-----"}</div>

                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Telefon : {(selectionCustomer.ek_customer_phone != "" && selectionCustomer.ek_customer_phone != undefined) ? selectionCustomer.ek_customer_phone : "-----"}</div>
                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Adres :  {(selectionCustomer.ek_customer_address != "" && selectionCustomer.ek_customer_address != undefined) ? selectionCustomer.ek_customer_address : "-----"}</div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    2
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Köpek Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <select onChange={(e) => {
                                        setSelectedDog(e.target.value);

                                        setSelectedCage([]);
                                        setCagePrice(-1);

                                    }} value={selectedDog} ref={dogRef} class="form-control form-control-lg form-control-solid" id="select_banner_type">
                                        <option value="-1">Lütfen bir seçim yapınız</option>
                                        {
                                            dogList.map(item => {
                                                return <option selected={dogID.dogID == item.ek_customer_dog_ID} value={item.ek_customer_dog_ID}>{item.ek_customer_dog_name}</option>
                                            })
                                        }


                                    </select>
                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    3
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Kafes Seçimi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        // setModal(true);
                                        // setList([]);
                                        // setSelectedEducation(-1);
                                        // setSelectedDog(-1);
                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});
                                        scroll.scrollToBottom()

                                    }}>

                                        {
                                            !(selectedDog != -1) ? SelectAnotherData() : Organizer()
                                        }

                                    </div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    4
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Kafes Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        // setModal(true);
                                        // setList([]);
                                        // setSelectedEducation(-1);
                                        // setSelectedDog(-1);
                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});

                                    }}>
                                        {/*begin: Pic*/}
                                        <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                            <div className="symbol shadow symbol-40 symbol-lg-50">
                                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                <img src={(selectedCage.ek_cage_image != "" && selectedCage.ek_cage_image != undefined) ? Ekmekk().GetImageURL(selectedCage.ek_cage_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                            </div>
                                            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                            </div>
                                        </div>
                                        {/*end: Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin: Title*/}
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">

                                                        {
                                                            (selectedCage.ek_cage_name != "" && selectedCage.ek_cage_name != undefined) ? selectedCage.ek_cage_name : "-----"
                                                        }

                                                        <i className="flaticon2-correct text-success icon-md ml-2" />
                                                    </a>

                                                    <span className="text-muted font-weight-bold">
                                                        {
                                                            (selectedCage.ek_cage_size != "" && selectedCage.ek_cage_size != undefined) ? selectedCage.ek_cage_size : "-----"
                                                        }
                                                    </span>

                                                </div>
                                            </div>
                                            {/*end: Title*/}
                                            {/*begin: Content*/}
                                            <div className="d-flex align-items-center flex-wrap justify-content-between">

                                            </div>
                                            {/*end: Content*/}
                                        </div>
                                        {/*end: Info*/}
                                    </div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    5
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Satış Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="col-lg-9 col-xl-9">
                                        <input ref={salePrice} className="form-control form-control-lg" id="campain_name2" placeholder={"Günlük Fiyatı"} type="number" defaultValue={cagePrice != -1 ? cagePrice : ""} />
                                    </div>
                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    6
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">

                                <button onClick={() => { CompleteSale() }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg py-4 px-3">Satışı Tamamla</button>

                            </div>
                            {/*end::Info*/}
                        </div>
                        <div className="timeline-item mt-2">
                            {/*begin::Icon*/}
                            {/* <div className="timeline-media bg-light-dark">
                                <span className="svg-icon-dark svg-icon-md">
                                    7
                                </span>
                            </div> */}
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            {/* <div className="timeline-desc timeline-desc-light-success"> */}

                            <button onClick={() => { CompleteOldSale() }} className="btn btn-sm btn-light-dark font-weight-bold font-size-lg ml-14 px-3">Geçmiş Satış</button>

                            {/* </div> */}
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                    </div>
                </div>
            </MobileView>
        </>
    }

    return <>

        <AuthorityControl pageCode={22}>
            <div>


                <ModalCustomerSelect selectedCustomer={selectedCustomer} handler={Handler} isVisible={false} isShow={isModal}></ModalCustomerSelect>

                <ModalHotelSale insertClick={FillCage} customer={JSON.stringify(selectionCustomer)} salePrice={mySalePrice} cageID={selectedCage.ek_cage_ID} dogID={selectedDog} handler={Handler} isVisible={false} isShow={isSaleModal} />

                <HotelOldSaleInsert insertClick={FillCage} customer={JSON.stringify(selectionCustomer)} salePrice={mySalePrice} cageID={selectedCage.ek_cage_ID} dogID={selectedDog} handler={Handler} isVisible={false} isShow={isOldSaleModal} />
                {/* <ModalSaleComplete insertClick={FillCage} saleList={saleList} customer={JSON.stringify(selectionCustomer)} salePrice={mySalePrice} cageID={selectedCage.ek_cage_ID} dogID={selectedDog} isInCage={isInCage} updateCage={updateCage} handler={Handler} isVisible={false} isShow={isSaleModal} /> */}

                {/* <ModalEducatorSelect selectedEducator={r => {
            setSelectedEducator(JSON.parse(r));
            console.log(JSON.parse(r));
            setEducatorModal(false);

        }} isVisible={false} handler={Handler} isShow={isEducatorModal} /> */}

                {
                    selectionCustomer.ek_customer_fullname == null ? SelectCustomer() : <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-12">
                            <TimeLine />
                        </div>
                        <div className="col-md"></div>
                        <div className="col-xl-8 col-lg-7">
                            <BrowserView>
                                {
                                    !(selectedDog != -1) ? SelectAnotherData() : Organizer()
                                }
                            </BrowserView>

                        </div>

                    </div>

                }

            </div>
        </AuthorityControl>
    </>
}



// function Demo2(props) {

//     const Header = withStyles(style, { name: 'Header' })(({
//         children, appointmentData, classes, ...restProps
//     }) => (
//         <AppointmentTooltip.Header
//             {...restProps}
//             // className={classNames(getClassByLocation(classes, appointmentData.location), classes.header)}
//             appointmentData={appointmentData}
//         >
//             <IconButton
//                 /* eslint-disable-next-line no-alert */
//                 onClick={() => { restProps.onHide(); props.deleteEvent(appointmentData); }}
//                 className={classes.commandButton}
//             >

//                 <FontAwesomeIcon icon={faTrash} />
//             </IconButton>
//         </AppointmentTooltip.Header>
//     ));


//     var today = new Date(), date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

//     function TimeTableCellMy({ onDoubleClick, ...restProps }) {

//         return <DayView.TimeTableCell onClick={() => {
//             props.clickEvent(restProps);
//         }} {...restProps} />;
//     }
//     return <>
//         <Paper>
//             <Scheduler
//                 data={props.data}
//             >
//                 <ViewState
//                     defaultCurrentDate={date}
//                     defaultCurrentViewName="Week"
//                 />
//                 <WeekView
//                     startDayHour={10}
//                     endDayHour={18}
//                     timeTableCellComponent={TimeTableCellMy}
//                 />
//                 <Toolbar />
//                 <DateNavigator />
//                 <TodayButton />
//                 <Appointments />
//                 <AppointmentTooltip
//                     headerComponent={Header}
//                     showCloseButton
//                 />


//             </Scheduler>
//         </Paper>

//     </>
// }