import EkmekkHeader from "./header.jsx"
import EkmekkFooter from "./footer.jsx"
import EkmekkBody from "./body.jsx"
import LeftMenu from "./left-menu.jsx"
import SubHeader from "./sub-header.jsx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDoorOpen, faSignOutAlt, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import React, { useState, useEffect } from "react"
import { NavLink, useHistory } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import Ekmekk from "../Ekmekk/Helper/ekmekk.jsx"
import Sticky from "react-sticky-el"

function dasda() {
    return <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="">
            <LeftMenu />
            <div className="row" style={{ width: "100%" }} >

                <div className="col-md-12">
                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        {/*begin::Header*/}

                        {/*end::Header*/}
                        {/*begin::Content*/}
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            {/*begin::Entry*/}
                            <div className="d-flex flex-column-fluid">
                                {/*begin::Container*/}
                                <div className="container">
                                    <EkmekkBody />

                                </div>
                                {/*end::Container*/}
                            </div>
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        {/*begin::Footer*/}
                        <div className="footer py-2 py-lg-0 my-5 d-flex flex-lg-column" id="kt_footer">
                            {/*begin::Container*/}
                            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                                {/*begin::Copyright*/}
                                <div className="text-dark order-2 order-md-1">
                                    <span className="text-muted font-weight-bold mr-2">2021©</span>
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="text-dark-75 text-hover-primary">Ekmekk.app</a>
                                </div>
                                {/*end::Copyright*/}
                                {/*begin::Nav*/}
                                <div className="nav nav-dark order-1 order-md-2">
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pr-3 pl-0">Gizlilik sözleşmesi</a>
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link px-3">Hakkımızda</a>
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pl-3 pr-0">İletişim</a>
                                </div>
                                {/*end::Nav*/}
                            </div>
                            {/*end::Container*/}
                        </div>
                        {/*end::Footer*/}
                    </div>
                </div>

            </div>


        </div>
        {/*end::Page*/}
    </div>

}



export default function Main() {


    const [isUserPanel, setUserPanel] = useState(false);

    const [router, setRouter] = useState(null)
    const [headers, setHeaders] = useState([])
    var history = useHistory();

    useEffect(() => {
        setHeaders(Ekmekk().GetHeaders())
    }, [])

    function OpenPanel() {
        setUserPanel(true);
    }
    function ClosePanel() {
        setUserPanel(false);
    }

    function SignOut() {
        history.push("/login");
        Ekmekk().Profile.SignOut()

    }

    function HeadersItemList(item) {

        return <>
            {
                // item.itemName == "Çıkış" ? <a className="menu-section">
                //     <i className="menu-icon ki ki-bold-more-hor icon-md" />
                // </a> : ""
            }
            {
                true ? <a onClick={() => {
                    if (router != item.itemName)
                        setRouter(item.itemName)
                    else
                        setRouter("a");

                    if (item.itemName == "Çıkış")
                        SignOut();
                }} className={router == item.itemName ? "menu-item menu-item-submenu  menu-item-open" : "menu-item menu-item-submenu"} aria-haspopup="true" data-menu-toggle="hover">


                    {
                        item.first != true ? <a onClick={() => { history.push(item.router); ClosePanel() }} className="navi-item">
                            <div className="navi-link bg-dark">
                                <div className="symbol symbol-40 mr-3">
                                    {/* <span className="svg-icon menu-icon svg-icon-xl">

                                        <FontAwesomeIcon icon={item.icon}></FontAwesomeIcon>
                                    </span> */}
                                    <span className="symbol-label bg-dark">
                                        <span className="svg-icon menu-icon svg-icon-xl text-dark-25">
                                            <FontAwesomeIcon icon={item.icon} />
                                        </span>
                                    </span>
                                </div>
                                <div className="navi-text">
                                    <div className="font-weight-bold text-dark-25">{item.itemName}</div>
                                    {/* <div className="text-muted">Tüm müşteri projeleri</div> */}
                                </div>
                            </div>
                        </a> : <a className="navi-item">
                            <div className="navi-link bg-dark">
                                <div className="symbol symbol-40 mr-3">
                                    <span className="symbol-label bg-dark">
                                        <span className="svg-icon menu-icon svg-icon-xl text-dark-25">
                                            <FontAwesomeIcon icon={item.icon} />
                                        </span>
                                    </span>
                                </div>
                                <div className="navi-text">
                                    <div className="font-weight-bold text-dark-25">{item.itemName}</div>
                                    {/* <div className="text-muted">Tüm müşteri projeleri</div> */}
                                </div>
                                <div className="pt-1">
                                    {
                                        router == item.itemName ? <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon> : <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                    }

                                </div>
                            </div>
                        </a>
                    }

                    {
                        router == item.itemName ? <div className="menu-submenu">
                            <i className="menu-arrow" />
                            {
                                item.subPage.map(subItem => <a onClick={() => { setRouter(subItem.router); history.push(subItem.router); ClosePanel() }} className="navi-item">
                                    <div className="navi-link bg-dark">
                                        <div className="navi-text ml-16">
                                            <div className="font-weight-bold text-dark-50">{subItem.itemName}</div>
                                            {/* <div className="text-muted">Tüm müşteri projeleri</div> */}
                                        </div>
                                    </div>
                                </a>)
                            }

                        </div> : ""
                    }


                </a> : ""
            }

        </>


    }

    return <div>
        <div>
            {/*begin::Main*/}

            {/*begin::Header Mobile*/}
            <div id="kt_header_mobile" className="header-mobile align-items-center header-mobile-fixed">
                {/*begin::Logo*/}

                {/*end::Logo*/}
                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center">
                    {/*begin::Aside Mobile Toggle*/}
                    {/* <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                        <span />
                    </button> */}
                    {/*end::Aside Mobile Toggle*/}
                    {/*begin::Header Menu Mobile Toggle*/}
                    <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle" onClick={OpenPanel}>
                        <span />
                    </button>
                    {/*end::Header Menu Mobile Toggle*/}
                    {/*begin::Topbar Mobile Toggle*/}
                    {/* <button className="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">
                        <span className="svg-icon svg-icon-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                </g>
                            </svg>
                        </span>
                    </button> */}
                    {/*end::Topbar Mobile Toggle*/}
                </div>
                {/*end::Toolbar*/}

                <div className="btn p-0 symbol symbol-40 " id="kt_quick_user_toggle">
                    <NavLink to="/dashboard/home">
                        <div className="symbol-label">
                            <img alt="Logo" src="https://www.royaldogclub.com/wp-content/uploads/2020/11/RDC-LOGO1.png" className="h-100 align-self-center p-1" />
                        </div>
                    </NavLink>

                </div>

                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center">

                    {/* <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                        <span />
                    </button> */}

                </div>
                {/*end::Toolbar*/}
            </div>



            {/*end::Header Mobile*/}
            <div className="d-flex flex-column flex-root" style={{ position: 'absolute', height: '100%', width: '100%' }}>

                <div className="d-flex flex-row flex-column-fluid page">

                    <LeftMenu />


                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

                        <BrowserView>
                            <SubHeader tittle={"asfasg"} />
                        </BrowserView>
                        <MobileView>
                            {/* <h1> This is rendered only on mobile </h1> */}
                        </MobileView>
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                            <div className="d-flex flex-column-fluid">

                                <div className="container">

                                    <div className="card card-custom">
                                        <div className="card-body">
                                            <EkmekkBody />
                                        </div>
                                    </div>

                                </div>
                                {/*end::Container*/}
                            </div>
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        {/*begin::Footer*/}
                        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
                            {/*begin::Container*/}
                            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                                {/*begin::Copyright*/}
                                <div className="text-dark order-2 order-md-1">
                                    <span className="text-muted font-weight-bold mr-2">2021©</span>
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="text-dark-75 text-hover-primary">Keenthemes</a>
                                </div>
                                {/*end::Copyright*/}
                                {/*begin::Nav*/}
                                <div className="nav nav-dark">
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pl-0 pr-5">About</a>
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pl-0 pr-5">Team</a>
                                    <a href="http://keenthemes.com/metronic" target="_blank" className="nav-link pl-0 pr-0">Contact</a>
                                </div>
                                {/*end::Nav*/}
                            </div>
                            {/*end::Container*/}
                        </div>
                        {/*end::Footer*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {/*end::Main*/}
            {/* begin::User Panel*/}
            <div id="kt_quick_user" className={isUserPanel ? "offcanvas offcanvas-left p-10 offcanvas-on bg-dark " : "offcanvas offcanvas-left p-10 "} data-menu-scroll="1" data-menu-dropdown-timeout="500" style={{ overflow: 'auto', overflowX: 'hidden' }}>
                {/* <div id="kt_aside_menu" className="aside-menu my-4 scroll ps ps--active-y " data-menu-scroll="1" data-menu-dropdown-timeout="500" style={{ height: '635px', overflow: 'auto', overflowX: 'hidden' }}> */}
                {/*begin::Header*/}
                <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                    <div className="btn p-0 symbol symbol-40 " id="kt_quick_user_toggle">
                        <NavLink to="/dashboard/home">
                            <div className="symbol-label bg-dark">
                                <img alt="Logo" src="https://www.royaldogclub.com/wp-content/uploads/2020/11/RDC-LOGO1.png" className="h-100 align-self-center p-1" />
                            </div>
                        </NavLink>

                    </div>
                    <a onClick={ClosePanel} className="btn btn-xs btn-icon btn-light btn-hover-primary bg-dark" id="kt_quick_user_close">
                        <FontAwesomeIcon icon={faTimes} ></FontAwesomeIcon>
                        {/* <i className="ki ki-close icon-xs text-muted" /> */}
                    </a>
                </div>
                {/*end::Header*/}
                {/*begin::Content*/}
                <div className="offcanvas-content pr-5 mr-n5">
                    {/*begin::Separator*/}
                    {/* <div className="separator separator-dashed mt-8 mb-5" /> */}
                    {/*end::Separator*/}
                    {/*begin::Nav*/}
                    <div className="navi navi-spacer-x-0 p-0">
                        {
                            headers.map(item => {
                                return HeadersItemList(item);
                            })
                        }

                    </div>
                    {/*end::Nav*/}
                    {/*begin::Separator*/}
                    {/* <div className="separator separator-dashed my-7" /> */}
                    {/*end::Separator*/}
                </div>
                {/*end::Content*/}
                {/* <div className="ps__rail-y" style={{ top: '0px', height: '935px', right: '4px' }}><div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '0px' }} /></div> */}

            </div>
            {/* end::User Panel*/}

            <div className={isUserPanel ? "offcanvas-overlay" : ""} onClick={ClosePanel}>

            </div>

        </div>


    </div>

}