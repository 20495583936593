
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'

import ModalCustomerInsert from "../Modal/Customer/CustomerInsert"
import Ekmekk from '../Ekmekk/Helper/ekmekk';
import DataCenter from '../Ekmekk/DataCenter/datacenter';
import SubHeader from '../Thema/sub-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnet, faSearch, faPencilRuler, faTractor, faTrash } from '@fortawesome/free-solid-svg-icons';
import NullPage from '../Ekmekk/Views/NullPage';
import AuthorityControl from '../Ekmekk/Auth/AuthorityControl';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function Musteri() {

    var history = useHistory();

    const [userList, setUserList] = useState([]);
    const [mainUserList, setMainUserList] = useState([]);

    useEffect(() => {

        DataCenter().Customer.ListCustomer().then(r => {

            if (r.status) {
                setUserList(r.data);
                setMainUserList(r.data);
                // Ekmekk().Alert.UnblockUI();
            }

        })
    }, [])

    function CustomerInsertClick(caseData, data) {

        if (caseData == "inserted") {
            data["debt"] = "0";
            data["profit"] = "0";
            data["last_process_count"] = "0";
            userList.push(data);
            setUserList([...userList])
        }
        else if (caseData == "updated") {
            for (var i = 0; i < userList.length; i++) {
                if (userList[i].ek_customer_ID == data.ek_customer_ID) {
                    if (data.ek_customer_image == null) {
                        var temp = userList[i].ek_customer_image;
                    }
                    else {
                        var temp = data.ek_customer_image;
                    }

                    var tempDebt = userList[i].debt;
                    var profit = userList[i].profit;
                    var last_process_count = userList[i].last_process_count;

                    userList[i] = data;

                    userList[i].ek_customer_image = temp;
                    userList[i].debt = tempDebt;
                    userList[i].profit = profit;
                    userList[i].last_process_count = last_process_count;
                }
            }

            setUserList([...userList]);
        }


    }

    function CustomerDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Customer.DeleteCustomer(item.ek_customer_ID).then(r => {

                    if (r.status) {
                        var list2 = [...userList];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_customer_ID == item.ek_customer_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setUserList(list2);
                    }
                })

            }
        })
    }

    function ChangeSearchText(e) {
        var filteredList = mainUserList.filter(item => item.ek_customer_fullname.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setUserList(filteredList);
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumberString;
    }

    return <>
        <BrowserView>
            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Müşterinin adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch} ></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <div className="d-flex">
                            <div className="mr-auto"></div>
                            <AuthorityControl pageCode={3}>

                                <div className=""> <ModalCustomerInsert isSale={false} insertClick={CustomerInsertClick} /></div>
                            </AuthorityControl>

                        </div>

                    </div>
                </div>
            </div>
        </BrowserView>
        <MobileView>
            <div className="mb-7">
                <div className="row align-items-center text-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                        <div className="mr-auto"></div>
                        <AuthorityControl pageCode={3}>

                            <ModalCustomerInsert isSale={false} insertClick={CustomerInsertClick} />
                        </AuthorityControl>


                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Müşterinin adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch} ></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </MobileView>

        {/*end::Search Form*/}


        <AuthorityControl pageCode={2}>

            {
                userList.length != 0 ? TableList(userList) : <NullPage />
            }
        </AuthorityControl >


    </>



    function TableList(data) {


        let history = useHistory();

        function OpenUser(index) {

            // <AuthorityControl pageCode={6}>

            history.push("/dashboard/customer/customer-profile/" + index)

            // </AuthorityControl>

        }
        const columns = [

            /* {
                
               title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
             },*/
            {
                title: <span className="text">Foto</span>, render: data => <div onClick={() => OpenUser(data.ek_customer_ID)} className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                    <img src={data.ek_customer_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + data.ek_customer_image} alt="" className="h-50" />
                </div>

            },
            {
                title: <span className="text" >Kullanıcı Adı</span>, render: data => <div onClick={() => OpenUser(data.ek_customer_ID)} className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_customer_fullname}</div>
            },
            {
                title: <span className="text">Telefon</span>, render: data => <div onClick={() => OpenUser(data.ek_customer_ID)}>
                    <span className="text-muted font-weight-bolder d-block font-size-lg">{formatPhoneNumber(data.ek_customer_phone)}</span>
                </div>
            },
            {
                title: <span className="text">Mail</span>, render: data => <div onClick={() => OpenUser(data.ek_customer_ID)}>
                    <span className="text-muted font-weight-bolder d-block font-size-lg">{data.ek_customer_mail}</span>
                </div>
            },
            {
                title: <span className="text">Borç</span>, render: data => <div onClick={() => OpenUser(data.ek_customer_ID)}>
                    <span className="text-danger font-weight-bolder d-block font-size-lg">{data.debt}₺</span>
                </div>
            },
            {
                title: <span className="text">Kazanç</span>, render: data => <div onClick={() => OpenUser(data.ek_customer_ID)}>
                    <span className="text-success font-weight-bolder d-block font-size-lg">{data.profit}₺</span>
                </div>
            },
            {
                title: <span className="text">İşlem Geçmişi</span>, render: data => <div onClick={() => OpenUser(data.ek_customer_ID)}>
                    <span className="text-muted font-weight-bolder d-block font-size-lg">{data.last_process_count}</span>
                </div>
            }, {
                title: <span className="text">Detay</span>, render: data => <div className="pr-0">

                    {/* <a href="#" className="btn btn-icon btn-light btn-hover-primary btn-sm">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000" />
                                    <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </a> */}
                    <div>
                        <div className="btn-group">
                            <AuthorityControl pageCode={4}>
                                <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">


                                        <ModalCustomerInsert isUpdateTable={true} isUpdate={true} selectedData={data} insertClick={CustomerInsertClick} />

                                    </span>
                                </div>
                            </AuthorityControl>
                            <AuthorityControl pageCode={5}>
                                <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">

                                        <FontAwesomeIcon onClick={() => CustomerDeleteClick(data)} icon={faTrash}></FontAwesomeIcon>

                                    </span>
                                </div>
                            </AuthorityControl>
                        </div>


                    </div>
                    {/* <a href="#" className="btn btn-icon btn-light btn-hover-primary btn-sm">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </a>
                    <button onClick={() => CustomerDeleteClick(data)} className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero" />
                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </button> */}
                </div>

            },


        ]

        const handleRowClick = (event, rowData) => {
            const index = data.indexOf(rowData)

            localStorage.setItem("selectedItem", JSON.stringify(data[index]))
            // history.push("/customer/customer-profile");


            /* if (Ekmekk().Profile.userType == UserType.MASTER) {
               history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
             }
             else {
               history.push("/business/orders/order-detail/" + data[index].user_orderID);
             }*/


        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                    }

                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }
}

