
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function ServiceController() {

    function InsertService(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.post(Ekmekk().URL + '/admin/service', {
                ek_service_name: dataBlock.ek_service_name,
                ek_service_purchase_price: dataBlock.ek_service_purchase_price,
                ek_service_sale_price: dataBlock.ek_service_sale_price,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateService(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        console.log(dataBlock);
        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/service', {
                ek_service_name: dataBlock.ek_service_name,
                ek_service_purchase_price: dataBlock.ek_service_purchase_price,
                ek_service_sale_price: dataBlock.ek_service_sale_price,
                visible: dataBlock.visible,
                ek_service_ID: dataBlock.ek_service_ID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListService() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/service', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function DeleteService(serviceID){
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/service/' +  serviceID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        })
    }

    return {
        InsertService, ListService, DeleteService, UpdateService
    }
}