import CustomerController from "./customer"
import AuthController from "./auth"
import ProductController from "./product"
import StockController from "./stock"
import CategoryController from "./category"
import CageController from "./cage"
import EducationController from "./education"
import EducatorController from "./educator"
import ServiceController from "./service"
import RoyalDogController from "./royaldog"
import DogBreedController from "./dogbreed"
import DogSubBreedController from "./dogsubbreed"
import CaseController from "./case"
import SaleController from "./sale"
import BrandController from "./brand"
import PlainController from "./plain"
import HomeController from "./home"
import NotificationController from "./notification"
import JobController from "./job"
import UserController from "./user"

export default function DataCenter()
{


    return {
       Home :  HomeController(),
       Customer :  CustomerController(),
       Product : ProductController(),
       Stock : StockController(),
       Category : CategoryController(),
       Brand : BrandController(),
       Education : EducationController(),
       Educator : EducatorController(),
       Service : ServiceController(),
       RoyalDog : RoyalDogController(),
       DogBreed : DogBreedController(),
       SubBreed : DogSubBreedController(),
       Sale : SaleController(),
       Cage : CageController(),
       Case : CaseController(),
       Auth :  AuthController(),
       Plain :  PlainController(),
       Notification :  NotificationController(),
       Job : JobController(),
       User : UserController(),
    }
}