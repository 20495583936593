
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function BrandController() {
    
    function InsertBrand(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.post(Ekmekk().URL + '/admin/product/brand', {
                ek_product_brand_name: dataBlock.ek_product_brand_name
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateBrand(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/product/brand', {
                ek_product_brand_name: dataBlock.ek_product_brand_name,
                ek_product_brandID: dataBlock.ek_product_brandID
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListBrand() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/product/brand', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    
                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function DeleteBrand(brandID){
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/product/brand/' +  brandID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        })
    }

    return {
        InsertBrand, ListBrand, DeleteBrand, UpdateBrand
    }
}