import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import { ViewState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import DatePicker from 'react-datepicker'
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import {
    Scheduler,
    WeekView,
    MonthView,
    Appointments,
    DateNavigator,
    Toolbar,
    TodayButton,
    AppointmentTooltip,
    ViewSwitcher,
    AppointmentForm,
    AllDayPanel,
    EditRecurrenceMenu,
    DayView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useEffect } from 'react';
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import { useState } from 'react';
import { useRef } from 'react';
import ModalCustomerSelect from '../../Modal/Customer/CustomerSelect';
import ModalEducatorSelect from '../../Modal/Education/EducatorSelect';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { useParams } from 'react-router-dom';
import SubHeader from '../../Thema/sub-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalEducatorInsert from '../../Modal/Education/EducatorInsert';
import MaterialTable from 'material-table'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const style = ({ palette }) => ({
    icon: {
        color: palette.action.active,
    },
    textCenter: {
        textAlign: 'center',
    },
    firstRoom: {
        background: 'url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/Lobby-4.jpg)',
    },
    secondRoom: {
        background: 'url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/MeetingRoom-4.jpg)',
    },
    thirdRoom: {
        background: 'url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/MeetingRoom-0.jpg)',
    },
    header: {
        height: '260px',
        backgroundSize: 'cover',
    },
    commandButton: {
        backgroundColor: 'rgba(255,255,255,0.65)',
    },
});

// const getClassByLocation = (classes, location) => {
//     if (location === 'Room 1') return classes.firstRoom;
//     if (location === 'Room 2') return classes.secondRoom;
//     return classes.thirdRoom;
// };



export default function PlainOrganizer() {


    var customerID = useParams();
    var dogID = useParams();
    var selectionData = useRef(null);
    var dogRef = useRef(null);

    const [list, setList] = useState([]);

    const [selectionCustomer, setSelectedCustomer] = useState({});

    const [educatorList, setEducatorList] = useState({})
    const [selectedEducator, setSelectedEducator] = useState({});

    const [dogList, setDogList] = useState([]);
    const [selectedDog, setSelectedDog] = useState(-1);

    const [educationList, setEducationList] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState(-1);

    const [vaccineList, setVaccineList] = useState([])
    const [selectedVaccineType, setSelectedVaccineType] = useState(0)

    const [lessonList, setLessonList] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState({});

    const [isModal, setModal] = useState(false);
    const [isEducatorModal, setEducatorModal] = useState(false);

    const [startDate, setStartDate] = useState(new Date());

    const columns = [

        {
            title: <span className="text">İlişkili Müşteri</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_dog_breed_name}</div>
        },
        {
            title: <span className="text">Köpek Adı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.dog_count}</div>
        },
        {
            title: <span className="text">Aşı </span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.dog_count}</div>
        },
        {
            title: <span className="text">Aşı Tarihi </span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.dog_count}</div>
        },

    ]


    useEffect(() => {

        DataCenter().RoyalDog.ListVaccine().then(r => {

            setVaccineList(r.data);

        })

        var today = new Date(), date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

        // EĞER KÖPEK PROFİLİNDEN GELİNDİYSE MÜŞTERİ VE KÖPEĞİ SEÇİLİ YAPMA
        if (customerID.customerID != undefined) {
            // Ekmekk().Alert.Block();
            DataCenter().Customer.ShowCustomerProfile(customerID.customerID).then(r => {

                setSelectedCustomer(r.data.customer[0]);        // GELEN MÜŞTERİ ID İLE MÜŞTERİ BİLGİLERİ TOPLANIP SEÇİLİ MÜŞTERİYE BASILIR

                DataCenter().Customer.ListCustomerDog(customerID.customerID).then(r => {
                    // console.log(r.data[0].ek_customer_dog_ID);
                    setDogList(r.data)                          // GELEN MÜŞTERİ ID İLE MÜŞTERİ KÖPEKLERİ TOPLANIP DROPDOWN'DA GÖSTERİLİR
                    setSelectedDog(customerID.dogID);           // GELEN MÜŞTERİ KÖPEK ID İLE SEÇİLİ KÖPEK SEÇİLİR

                    DataCenter().Customer.ListCustomerDogEducation(customerID.dogID).then(r => {

                        setEducationList(r.data);

                    })

                    // Ekmekk().Alert.UnblockUI();
                });
            });
        }
    }, [])

    function SavePlan() {
        //kontrolleri ekle
        // alert(selectedDog)
        if (selectedDog == -1 || selectedEducation == -1 || selectedVaccineType == -1) {
            Ekmekk().Alert.FillAll();

        }
        else {
            Ekmekk().Alert.Sure((e) => {
                if (e) {
                    let dataa = {
                        type: 1,
                        ek_vaccine_ID: selectedEducation,
                        ek_dog_ID: selectedDog
                    }
                    // alert(startDate)
                    var a = moment(startDate).format('L').split('/')
                    var dateee = a[2] + "-" + a[0] + "-" + a[1]
                    selectedVaccineType == 0 ? dataa.ek_will_vaccine_date = dateee : dataa.ek_was_vaccine_date = dateee

                    DataCenter().Customer.InsertCustomerDogVaccine(dataa).then(r => {
                        if (r.status == true) Ekmekk().Alert.Success();

                    })

                    // Ekmekk().Alert.UnblockUI();


                }
                else {
                    Ekmekk().Alert.Fail();
                }
            })
        }


    }

    function FillPlain(data) {

        var theLessonData = data;
        console.log(data);

        var educatorID = -1;
        var bashList = [];

        for (var i = 0; i < theLessonData.length; i++) {

            if (theLessonData[i].ek_dog_lesson_plan_ID != "") {

                theLessonData[i].["isDone"] = 1;
                var theStartDate = new Date(theLessonData[i].ek_dog_lesson_start_date);
                var theEndDate = new Date(theLessonData[i].ek_dog_lesson_end_date);
                var lessonName = theLessonData[i].ek_education_lesson_name;
                var dogName = theLessonData[i].ek_customer_dog_name;
                var lessonID = theLessonData[i].ek_education_lesson_ID;

                bashList.push({
                    startDate: theStartDate,
                    endDate: theEndDate,
                    title: dogName + " - " + lessonName,
                    ek_education_lesson_ID: theLessonData[i].ek_education_lesson_ID,
                    ek_dog_lesson_plan_ID: theLessonData[i].ek_dog_lesson_plan_ID,
                    isComplete: theLessonData[i].isComplete,
                    ek_educator_ID: theLessonData[i].ek_educator_ID,
                });

                if (theLessonData[i].ek_educator_ID != "" || theLessonData[i].ek_educator_ID == undefined || theLessonData[i].ek_educator_ID == null)
                    educatorID = theLessonData[i].ek_educator_ID;
            }

        }

        setList(bashList);

        console.log(educatorID);
        // DERSLER DAHA ÖNCEDEN SEÇİLMİŞ VE BİR EĞİTMEN VARSA EĞİTMEN SEÇİLİ OLARAK GELİR
        if (educatorID != -1) {
            for (let i = 0; i < educatorList.length; i++) {

                if (educatorList[i].ek_educator_ID == educatorID)
                    setSelectedEducator(educatorList[i]);
            }
        }

    }

    function Ekle(data) {

        if (selectionData.current.value != "-1" && dogRef.current.value != "-1") {

            // Ekmekk().Alert.Block();

            Insert(data);
            ChangeLessonActivity(1, selectedLesson.ek_education_lesson_ID);

            // Ekmekk().Alert.UnblockUI();

        }

    }

    function Insert(data) {

        console.log(data);

        for (let i = 0; i < dogList.length; i++) {

            if (dogList[i].ek_customer_dog_ID == selectedDog)
                var dogName = dogList[i].ek_customer_dog_name;

        }
        var lessonName = selectedLesson.ek_education_lesson_name;
        var lessonID = selectedLesson.ek_education_lesson_ID;

        var list2 = [...list];
        for (var i = 0; i < list2.length; i++) {

            if (list2[i].ek_education_lesson_ID == selectedLesson.ek_education_lesson_ID) {
                list2.splice(i, 1)
                // list2[i].visible = 0;
            }
        }

        console.log(selectedEducator.ek_educator_ID);
        list2.push({
            startDate: data.startDate,
            endDate: data.endDate,
            title: dogName + "-" + lessonName,
            ek_education_lesson_ID: selectedLesson.ek_education_lesson_ID,
            ek_dog_lesson_plan_ID: selectedLesson.ek_dog_lesson_plan_ID,
            isComplete: selectedLesson.isComplete,
            ek_educator_ID: JSON.stringify(selectedEducator.ek_educator_ID),
        });

        setList(list2);
        // console.log(data);

    }

    function DeleteLesson(data) {

        // Ekmekk().Alert.Block();

        // console.log(data.ek_education_lesson_ID)
        var list2 = [...list];
        for (var i = 0; i < list2.length; i++) {

            if (list2[i].ek_education_lesson_ID == data.ek_education_lesson_ID) {

                list2.splice(i, 1)
                setList(list2);

                ChangeLessonActivity(0, data.ek_education_lesson_ID);

            }
        }

        // Ekmekk().Alert.UnblockUI();
    }

    function ChangeLessonActivity(activeNum, selectedLessonID) {

        for (let i = 0; i < lessonList.length; i++) {
            if (lessonList[i].ek_education_lesson_ID == selectedLessonID) {
                lessonList[i].["isDone"] = activeNum;


                if (i + 1 != lessonList.length) {
                    // console.log(lessonList[i+1]);
                    setSelectedLesson(lessonList[i + 1]);
                }

                // EKLENMEMİŞ DERS VARSA EKLENMEK İÇİN SEÇİLECEK DERSE O ATILIR YOKSA BİR SONRAKİ DERS SEÇİLİR
                for (let j = 0; j < lessonList.length; j++) {

                    if (lessonList[j].["isDone"] == undefined || lessonList[j].["isDone"] == 0) {
                        setSelectedLesson(lessonList[j]);
                        break;
                    }

                }
            }
        }

    }

    function Handler(data) {
        if (data == "close") {
            setModal(false);
            setEducatorModal(false);
        }
    }

    function selectedCustomer(r) {
        setModal(false);
        setSelectedCustomer(JSON.parse(r));
        DataCenter().Customer.ListCustomerDog(JSON.parse(r)["ek_customer_ID"]).then(r => {
            setDogList(r.data)
        });

    }
    function SelectCustomer() {
        return <div>

            <div style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center">
                    <img src="/my-assets/select.svg" className="max-h-400px" />

                </div>
                <div className="font-weight-bold font-size-lg mt-2">Bir aşı takvimi oluşturmak için müşteri seçmelisin</div>
                <div className="text-muted mt-2">Bir aşı oluşturmak için öncelikli olarak bir müşteri seçmelisin</div>
                <div className="btn btn-primary mt-6" onClick={() => { setModal(true); }}>Müşteri seç</div>
            </div>




        </div>
    }

    function SelectAnotherData() {
        return <div>

            <div style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center">
                    <img src="/my-assets/vaccine.svg" className="max-h-400px" />
                </div>
                <div className="font-weight-bold font-size-lg mt-2">Harika! Kalan ufak bilgileri doldur ve organize etmeye başla</div>
                <div className="text-muted mt-2">Bir aşı takvimi düzenlemek için öncelikli olarak bir müşteri seçmelisin</div>

            </div>

        </div>
    }

    function TimeLine() {
        return <div className="timeline timeline-5">
            <div className="timeline-items">
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            1
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Müşteri Bilgisi</span>
                        <p className="font-weight-normal text-dark-50 pb-2">
                            <div className="d-flex mt-6" onClick={() => {
                                setModal(true);
                                setList([]);
                                setSelectedEducation(-1);
                                setSelectedDog(-1);
                                setLessonList([]);
                                setSelectedLesson([]);
                                // setSelectedEducator({});

                            }}>
                                {/*begin: Pic*/}
                                <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                    <div className="symbol shadow symbol-40 symbol-lg-50">
                                        {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                        <img src={(selectionCustomer.ek_customer_image != "" && selectionCustomer.ek_customer_image != undefined) ? Ekmekk().GetImageURL(selectionCustomer.ek_customer_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                    </div>
                                    <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                        <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                    </div>
                                </div>
                                {/*end: Pic*/}
                                {/*begin: Info*/}
                                <div className="flex-grow-1">
                                    {/*begin: Title*/}
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="mr-3">
                                            {/*begin::Name*/}
                                            <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">




                                                {
                                                    (selectionCustomer.ek_customer_fullname != "" && selectionCustomer.ek_customer_fullname != undefined) ? selectionCustomer.ek_customer_fullname : "-----"
                                                }
                                                <i className="flaticon2-correct text-success icon-md ml-2" /></a>

                                        </div>
                                    </div>
                                    {/*end: Title*/}
                                    {/*begin: Content*/}
                                    <div className="d-flex align-items-center flex-wrap justify-content-between">

                                    </div>
                                    {/*end: Content*/}
                                </div>
                                {/*end: Info*/}
                            </div>
                            <div className="separator separator-solid my-3" />

                            <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Mail :</span>  {(selectionCustomer.ek_customer_mail != "" && selectionCustomer.ek_customer_mail != undefined) ? selectionCustomer.ek_customer_mail : "-----"}</div>

                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Telefon : {(selectionCustomer.ek_customer_phone != "" && selectionCustomer.ek_customer_phone != undefined) ? selectionCustomer.ek_customer_phone : "-----"}</div>
                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Adres :  {(selectionCustomer.ek_customer_address != "" && selectionCustomer.ek_customer_address != undefined) ? selectionCustomer.ek_customer_address : "-----"}</div>

                        </p>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            2
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Köpek Bilgisi</span>
                        <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                            <select onChange={(e) => {
                                setSelectedDog(e.target.value);

                            }} value={selectedDog} ref={dogRef} class="form-control form-control-lg form-control-solid" id="select_banner_type">
                                <option value="-1">Lütfen bir seçim yapınız</option>
                                {
                                    dogList.map(item => {
                                        return <option selected={dogID.dogID == item.ek_customer_dog_ID} value={item.ek_customer_dog_ID}>{item.ek_customer_dog_name}</option>
                                    })
                                }


                            </select>
                        </p>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            3
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Aşı Seçimi</span>
                        <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                            <select onChange={(e) => {

                                setSelectedEducation(e.target.value);
                                setList([]);


                            }} value={selectedEducation} className="form-control form-control-lg form-control-solid" id="select_banner_type" ref={selectionData}>

                                <option value="-1">Lütfen bir seçim yapınız</option>
                                {
                                    vaccineList.map(item => {
                                        // if (item.visible == 1)
                                        return <option value={item.ek_vaccine_ID}> {item.ek_vaccine_name}</option>
                                    })
                                }

                            </select>
                        </p>
                    </div>
                    {/*end::Info*/}
                </div>

                <div className="timeline-item">
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            4
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Aşı Durumu</span>
                        <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                            <select onChange={(e) => {

                                setSelectedVaccineType(e.target.value);

                            }} value={selectedVaccineType} className="form-control form-control-lg form-control-solid" id="select_banner_type" ref={selectionData}>

                                <option value="-1">Lütfen bir seçim yapınız</option>

                                <option value={0}> {"Yapılacak Aşı"}</option>
                                <option value={1}> {"Yapılan Aşı"}</option>


                            </select>
                        </p>
                    </div>

                </div>


                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            5
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Tarih Seçimi</span>

                        <div className="form-group row">
                            <div className="col-lg-9 col-xl-9">
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control form-control-lg form-control-solid w-100" />
                            </div>
                        </div>

                    </div>
                    {/*end::Info*/}
                </div>

                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            6
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">


                        <button onClick={() => { SavePlan() }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg py-4 px-3">Aşı Tarihi Oluştur</button>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
            </div>
        </div>

    }

    return <div>


        <AuthorityControl pageCode={34}>

            <ModalCustomerSelect selectedCustomer={selectedCustomer} handler={Handler} isVisible={false} isShow={isModal}></ModalCustomerSelect>
            <ModalEducatorSelect selectedEducator={r => {
                setSelectedEducator(JSON.parse(r));
                console.log(JSON.parse(r));
                setEducatorModal(false);

            }} isVisible={false} handler={Handler} isShow={isEducatorModal} />

            {
                selectionCustomer.ek_customer_fullname == null ? SelectCustomer() : <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-12">
                        {/* <div className="col-md-3"> */}
                        <TimeLine />
                    </div>
                    <div className="col-md"></div>
                    <div className="col-xl-8 col-lg-7">
                        <BrowserView>
                            {
                                SelectAnotherData()
                            }
                        </BrowserView>

                    </div>
                    
                </div>

            }

        </AuthorityControl>

    </div>

}

