
import EkmekkAlert from "../Views/alert"
import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import Profile from "../DataCenter/profile.jsx"
import { faCoffee, faMoneyBillWave, faHotel, faShoppingBasket, faLifeRing, faSyringe, faTh, faTachometerAlt, faCashRegister, faUsers, faBuilding, faCubes, faBone, faDoorOpen, faCalendarAlt, faUserCog, faPiggyBank, faUniversity } from '@fortawesome/free-solid-svg-icons'
export default function Ekmekk() {

    var yetkiDizi = [];
    var filterDizi = [];
    var altYetkili = 0;

    function AdjustLeftMenu() {

        yetkiDizi = Ekmekk().Profile.GetPermission().split(',');

        // console.log(yetkiDizi);
        // console.log(filterDizi);


        // ANA SOL MENÜYÜ DOLAŞ
        for (let i = 1; i < filterDizi.length - 1; i++) {

            // ALT MENÜ KONTROL
            // console.log(filterDizi[i].subPage);
            if (filterDizi[i].subPage != undefined) {// && filterDizi[i].subPage[0] != undefined && filterDizi[i].subPage[0] != null) {

                for (let k = 0; k < filterDizi[i].subPage.length; k++) {

                    altYetkili = 0;

                    // KULLANICI YETKİLERİNDE DOLAŞ YOKSA ÇIKRAT
                    for (let z = 0; z < yetkiDizi.length; z++) {

                        // ALT SAYFA YETKİLERİNİ KONTROL ET
                        if (filterDizi[i].subPage[k].auth == yetkiDizi[z]) {
                            altYetkili = 1;
                            filterDizi[i].delete = 0;
                            filterDizi[i].subPage[k].delete = 0;
                        }

                    }


                }

            }


        }

    }

    var firstTime = 0;

    function DeleteLeftItems() {

        if (firstTime == 0) {
            // console.log("firstTime = " + firstTime)
            firstTime = 1;
            for (let i = 1; i < filterDizi.length; i++) {

                for (let k = 0; k < filterDizi[i].subPage.length; k++) {

                    if (filterDizi[i].subPage[k].delete == 1) {
                        // console.log("silinen alt menü " + filterDizi[i].subPage[k].itemName)
                        filterDizi[i].subPage.splice(k, 1);
                        k -= 1;
                    }

                }




            }
        }

    }


    function DeleteUstLeftItems() {

        var doLoop = 0;
        for (let i = 1; i < filterDizi.length; i++) {
            // console.log(filterDizi[i].itemName + " / " + filterDizi[i].delete)
            if (filterDizi[i].delete == 1) {
                // console.log("silinen üst menü " + filterDizi[i].itemName)
                filterDizi.splice(i, 1);
                doLoop = 1;
            }

        }

        if (doLoop == 1) {
            DeleteUstLeftItems();
        }

    }


    function GetHeaders() {

        // if (localStorage.getItem("userType") == 1) {
        //     // ADMİN TARAFI
        // }
        // else {
        //     // MÜŞTERİ TARAFI
        // }
        console.log(localStorage.getItem("isAdmin"));
        if (parseInt(localStorage.getItem("isAdmin")) == 0) {
            // console.log(1);
            // MÜŞTERİ TARAFI

            var userDizi = [
                {
                    itemName: "Anasayfa",
                    router: "/dashboard/user/home",
                    first: false,
                    // auth: [0, 1, 94],
                    subPage: [],
                    icon: faTachometerAlt,
                }, {
                    itemName: "Ders",
                    router: "/dashboard/user/calendar/lesson-list",
                    first: false,
                    // auth: [0, 1, 94],
                    subPage: [],
                    icon: faCalendarAlt,
                },
                {
                    itemName: "Alış Veriş",
                    router: "/dashboard/user/history/sale",
                    first: false,
                    // auth: [27],
                    // delete: 1,
                    subPage: [],
                    icon: faShoppingBasket
                },
                {
                    itemName: "Konaklama",
                    router: "/dashboard/user/history/hotel",
                    first: false,
                    // auth: [27],
                    // delete: 1,
                    subPage: [],
                    icon: faHotel
                },
                {
                    itemName: "Çıkış",
                    router: "/login",
                    icon: faDoorOpen,
                    first: true,
                    subPage: [],
                },
            ]

            return userDizi;
        }
        else {
            // console.log(2); 
            // ADMİN TARAFI
            filterDizi = [
                {
                    itemName: "Anasayfa",
                    router: "/dashboard/home",
                    first: false,
                    // auth: [0, 1, 94],
                    subPage: [],
                    icon: faTachometerAlt,
                },
                {
                    itemName: "Müşteri",
                    router: "/dashboard/customer/customer-list",
                    first: true,
                    icon: faUsers,
                    auth: [2],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Müşteriler",
                            router: "/dashboard/customer/customer-list",
                            auth: 2,
                            delete: 1,
                        },
                    ]
                },
                {
                    itemName: "Satış",
                    router: "/dashboard/sale/",
                    first: true,
                    auth: [18, 19, 20, 21, 22],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Ürün Satışı",
                            router: "/dashboard/sale/product",
                            auth: 18,
                            delete: 1,
                        },
                        {
                            itemName: "Hizmet Satışı",
                            router: "/dashboard/sale/service",
                            auth: 19,
                            delete: 1,
                        },
                        {
                            itemName: "Köpek Satışı",
                            router: "/dashboard/sale/dog",
                            auth: 20,
                            delete: 1,
                        },
                        {
                            itemName: "Eğitim Satış",
                            router: "/dashboard/sale/education-selling",
                            auth: 21,
                            delete: 1,
                        },
                        {
                            itemName: "Konaklama Satış",
                            router: "/dashboard/sale/hotel",
                            auth: 22,
                            delete: 1,
                        },

                    ],
                    icon: faCashRegister
                },
                {
                    itemName: "Eğitim",
                    router: "/dashboard/plain/",
                    first: true,
                    auth: [23, 94, 95, 86],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Eğitim Listesi",
                            router: "/dashboard/plain/educations",
                            auth: 23,
                            delete: 1,
                        },
                        {
                            itemName: "Ders Planı",
                            router: "/dashboard/plain/list",
                            auth: 94,
                            delete: 1,
                        },
                        {
                            itemName: "Eğitim düzenle",
                            router: "/dashboard/plain/organizer",
                            auth: 95,
                            delete: 1,
                        },
                        {
                            itemName: "Eğitmenler",
                            router: "/dashboard/plain/educators",
                            auth: 86,
                            delete: 1,
                        },

                    ],
                    icon: faCalendarAlt
                },
                {
                    itemName: "Kafes",
                    router: "/dashboard/cages/cageList",
                    first: true,
                    auth: [27],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Kafes Listesi",
                            router: "/dashboard/cages/cageList",
                            auth: 27,
                            delete: 1,
                        },

                    ],
                    icon: faTh
                },
                {
                    itemName: "Aşı",
                    router: "/dashboard/vaccine",
                    first: true,
                    auth: [34],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Aşı Planla",
                            router: "/dashboard/vaccine/organizer",
                            auth: 34,
                            delete: 1,
                        },

                    ],
                    icon: faSyringe
                },
                {
                    itemName: "Kasa",
                    router: "/dashboard/company",
                    first: true,
                    auth: [35],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Kasa",
                            router: "/dashboard/company/case",
                            auth: 35,
                            delete: 1,
                        },
                    ],
                    icon: faLifeRing
                },
                {
                    itemName: "Royal Dog",
                    router: "/dashboard/dog/",
                    icon: faBone,
                    first: true,
                    auth: [38, 46, 43],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "RoyalDog",
                            router: "/dashboard/dog/royal-dog",
                            auth: 38,
                            delete: 1,
                        },
                        {
                            itemName: "Satılanlar",
                            router: "/dashboard/dog/royal-dog-selled",
                            auth: 46,
                            delete: 1,
                        },
                        {
                            itemName: "Mama Ekle",
                            router: "/dashboard/dog/royal-dog-food",
                            auth: 43,
                            delete: 1,
                        },

                    ]

                },
                {
                    itemName: "Tanımlamalar",
                    router: "/dashboard/definition/",
                    icon: faCubes,
                    first: true,
                    auth: [47, 96, 54, 58, 62, 66, 74],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Ürünler",
                            router: "/dashboard/definition/products",
                            auth: 47,
                            delete: 1,
                        },
                        {
                            itemName: "Hizmetler",
                            router: "/dashboard/definition/services",
                            auth: 96,
                            delete: 1,
                        },
                        {
                            itemName: "Meslekler",
                            router: "/dashboard/definition/jobs",
                            auth: 54,
                            delete: 1,
                        },
                        {
                            itemName: "Markalar",
                            router: "/dashboard/definition/brands",
                            auth: 58,
                            delete: 1,
                        },
                        {
                            itemName: "Kategoriler",
                            router: "/dashboard/definition/categories",
                            auth: 62,
                            delete: 1,
                        },
                        {
                            itemName: "Cins",
                            router: "/dashboard/definition/dog-breed",
                            auth: 66,
                            delete: 1,
                        },
                        {
                            itemName: "Aşılar",
                            router: "/dashboard/definition/vaccineList",
                            auth: 74,
                            delete: 1,
                        },


                    ]

                },
                {
                    itemName: "Yetki",
                    router: "/dashboard/user-management",
                    first: true,
                    auth: [78, 79, 83],
                    delete: 1,
                    subPage: [
                        {
                            itemName: "Yetki Grupları",
                            router: "/dashboard/user-management/category",
                            auth: 78,
                            delete: 1,
                        },
                        {
                            itemName: "Kullanıcılar",
                            router: "/dashboard/user-management/users",
                            auth: 79,
                            delete: 1,
                        },
                        {
                            itemName: "Ayarlar",
                            router: "/dashboard/user-management/settings",
                            auth: 83,
                            delete: 1,
                        },
                    ],
                    icon: faUserCog
                },
                {
                    itemName: "Çıkış",
                    router: "/login",
                    icon: faDoorOpen,
                    first: false,
                    subPage: [],
                },
                
            ];

            AdjustLeftMenu();
            DeleteLeftItems();
            DeleteUstLeftItems();

            return filterDizi;
        }



        // console.log(localStorage.getItem("userType"));

        // if (Ekmekk().Profile.userType == "0") {

        // }
        // else if (Ekmekk().Profile.userType == "1") {

        // }
        // else if (Ekmekk().Profile.userType == "2") {

        // }

    }

    function GetPermissionList() {
        return [
            {
                name: "Ana sayfa toplam kazanç",
                key: 0
            },
            {
                name: "Ana sayfa haftalık kazanç",
                key: 1
            },
            {
                name: "Anasayfa Top 3 Ürün",
                key: 84
            },
            {
                name: "Müşteri Listeleme",
                key: 2
            },
            {
                name: "Müşteri Ekleme",
                key: 3
            },
            {
                name: "Müşteri Güncelleme",
                key: 4
            },
            {
                name: "Müşteri Silme",
                key: 5
            },
            {
                name: "Müşteri Profil Görüntüleme",
                key: 6
            },
            {
                name: "Müşteri Profil İşlem Geçmişi",
                key: 7
            },
            {
                name: "Müşteri Profil Borç Geçmişi",
                key: 8
            },
            // {
            //     name: "Müşteri Top Alışveriş",
            //     key: 100
            // },
            // {
            //     name: "Müşteri Kazanç",
            //     key: 101
            // },
            {
                name: "Müşteri Köpek Ekle",
                key: 85
            },
            {
                name: "Müşteri Köpek Profil Görüntüleme",
                key: 9
            },
            {
                name: "Müşteri Köpek Profil Güncelleme",
                key: 10
            },
            {
                name: "Müşteri Köpek Profil Silme",
                key: 11
            },
            {
                name: "Müşteri Köpek Profil Eğitim Doldurma",
                key: 12
            },
            {
                name: "Müşteri Köpek Profil Eğitim Satma",
                key: 13
            },
            {
                name: "Müşteri Köpek Profil Aşı Oluşturma",
                key: 14
            },
            {
                name: "Müşteri Köpek Profil Foto Ekleme",
                key: 15
            },
            {
                name: "Müşteri Köpek Profil İşlem Geçmişi",
                key: 16
            },
            {
                name: "Müşteri Köpek Profil Aşı Listesi",
                key: 17
            },
            {
                name: "Satış Ürün",
                key: 18
            },
            {
                name: "Satış Hizmet",
                key: 19
            },
            {
                name: "Satış Köpek",
                key: 20
            },
            {
                name: "Satış Eğitim",
                key: 21
            },
            {
                name: "Satış Konaklama",
                key: 22
            },
            {
                name: "Eğitim Listesi",
                key: 23
            },
            {
                name: "Eğitim Ekleme",
                key: 24
            },
            {
                name: "Eğitim Güncelleme",
                key: 25
            },
            {
                name: "Eğitim Silme",
                key: 26
            },
            {
                name: "Kafes Görüntüleme",
                key: 27
            },
            {
                name: "Kafes Ekleme",
                key: 28
            },
            {
                name: "Kafes Detay Görüntüleme",
                key: 29
            },
            {
                name: "Kafes Güncelleme",
                key: 30
            },
            {
                name: "Kafes Silme",
                key: 31
            },
            {
                name: "Kafes Doldurma/Boşaltma",
                key: 32
            },
            {
                name: "Aşı Planlama",
                key: 34
            },
            {
                name: "Kasa Görüntüleme",
                key: 35
            },
            {
                name: "Kasa Gelir/Gİder Ekleme",
                key: 36
            },
            {
                name: "Kasa Detay Görüntüleme",
                key: 37
            },
            {
                name: "RoyalDog Görüntüleme",
                key: 38
            },
            {
                name: "RoyalDog Ekleme",
                key: 39
            },
            {
                name: "RoyalDog Profil Görüntüleme",
                key: 40
            },
            {
                name: "RoyalDog Güncelleme",
                key: 41
            },
            {
                name: "RoyalDog Silme",
                key: 42
            },
            {
                name: "RoyalDog Mama",
                key: 43
            },
            {
                name: "RoyalDog Aşı Oluşturma",
                key: 44
            },
            {
                name: "RoyalDog Foto Ekleme",
                key: 45
            },
            {
                name: "RoyalDog Satılanlar Görüntüleme",
                key: 46
            },
            // TANIMLAMALAR
            {
                name: "Ürün Listeleme",
                key: 47
            },
            {
                name: "Ürün Ekleme",
                key: 48
            },
            {
                name: "Ürün Güncelleme",
                key: 49
            },
            {
                name: "Ürün Silme",
                key: 50
            },
            {
                name: "Ürün Stok Listeleme",
                key: 51
            },
            {
                name: "Ürün Stok Oluşturma",
                key: 52
            },
            {
                name: "Ürün Stok Güncelleme",
                key: 53
            },
            {
                name: "Meslek Listeleme",
                key: 54
            },
            {
                name: "Meslek Oluşturma",
                key: 55
            },
            {
                name: "Meslek Güncelleme",
                key: 56
            },
            {
                name: "Meslek Silme",
                key: 57
            },
            {
                name: "Marka Listeleme",
                key: 58
            },
            {
                name: "Marka Oluşturma",
                key: 59
            },
            {
                name: "Marka Güncelleme",
                key: 60
            },
            {
                name: "Marka Silme",
                key: 61
            },
            {
                name: "Kategoriler  Listeleme",
                key: 62
            },
            {
                name: "Kategoriler  Oluşturma",
                key: 63
            },
            {
                name: "Kategoriler  Güncelleme",
                key: 64
            },
            {
                name: "Kategoriler  Silme",
                key: 65
            },
            {
                name: "Cins Listeleme",
                key: 66
            },
            {
                name: "Cins Oluşturma",
                key: 67
            },
            {
                name: "Cins Güncelleme",
                key: 68
            },
            {
                name: "Cins Silme",
                key: 69
            },
            {
                name: "Alt Cins Listeleme",
                key: 70
            },
            {
                name: "Alt Cins Oluşturma",
                key: 71
            },
            {
                name: "Alt Cins Güncelleme",
                key: 72
            },
            {
                name: "Alt Cins Silme",
                key: 73
            },
            {
                name: "Aşı Listeleme",
                key: 74
            },
            {
                name: "Aşı Oluşturma",
                key: 75
            },
            {
                name: "Aşı Güncelleme",
                key: 76
            },
            {
                name: "Aşı Silme",
                key: 77
            },
            {
                name: "Yetki Grubu Görüntüleme",
                key: 78
            },
            {
                name: "Kullanıcı Listeleme",
                key: 79
            },
            {
                name: "Kullanıcı Oluşturma",
                key: 80
            },
            {
                name: "Kullanıcı Güncelleme",
                key: 81
            },
            {
                name: "Kullanıcı Silme",
                key: 82
            },
            {
                name: "Ayarlar",
                key: 83
            },
            {
                name: "Eğitmen Listeleme",
                key: 86
            },
            {
                name: "Eğitmen Oluşturma",
                key: 87
            },
            {
                name: "Eğitmen Güncelleme",
                key: 88
            },
            {
                name: "Eğitmen Silme",
                key: 89
            },
            {
                name: "Eğitim Dersi Görüntüleme",
                key: 90
            },
            {
                name: "Eğitime Ders Ekleme",
                key: 91
            },
            {
                name: "Eğitim Dersi Güncelleme",
                key: 92
            },
            {
                name: "Eğitim Dersini Silme",
                key: 93
            },
            {
                name: "Ders Planı Görüntüleme",
                key: 94
            },
            {
                name: "Eğitim Ders Düzenleme",
                key: 95
            },
            {
                name: "Hizmet Listeleme",
                key: 96
            },
            {
                name: "Hizmet Oluşturma",
                key: 97
            },
            {
                name: "Hizmet Güncelleme",
                key: 98
            },
            {
                name: "Hizmet Silme",
                key: 99
            },
            // EN SON 101 olmadı geri sildim konuşalım onu
        ]
    }



    function GetImageURL(url) {
        return "https://31.210.91.239:3639/images/" + url
    }
    return {
        Alert: EkmekkAlert(),
        Profile: Profile(),
        URL: "https://31.210.91.239:3639",
        GetHeaders,
        GetPermissionList,
        GetImageURL,

    }
}

Ekmekk.PERMISSION = { BAR: "1" };