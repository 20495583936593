
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function ProductController() {

    function InsertProduct(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            if (dataBlock.ek_product_image != null)
                data.append('ek_product_image', dataBlock.ek_product_image, dataBlock.ek_product_image.name);
            data.append('ek_product_name', dataBlock.ek_product_name);
            data.append('ek_product_stok', dataBlock.ek_product_stok);
            data.append('ek_product_brandID', dataBlock.ek_product_brandID);
            data.append('ek_product_category_ID', dataBlock.ek_product_category_ID);
            data.append('ek_product_purchase_price', dataBlock.ek_product_purchase_price);
            data.append('ek_product_sale_price', dataBlock.ek_product_sale_price);


            axios.post(Ekmekk().URL + '/admin/product', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateProduct(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();

            if (dataBlock.ek_product_image != null)
                data.append('ek_product_image', dataBlock.ek_product_image, dataBlock.ek_product_image.name);
            data.append('ek_product_name', dataBlock.ek_product_name);
            data.append('ek_product_stok', dataBlock.ek_product_stok);
            data.append('ek_product_brandID', dataBlock.ek_product_brandID);
            data.append('ek_product_category_ID', dataBlock.ek_product_category_ID);
            data.append('ek_product_purchase_price', dataBlock.ek_product_purchase_price);
            data.append('ek_product_sale_price', dataBlock.ek_product_sale_price);
            data.append('ek_product_ID', dataBlock.ek_product_ID);


            axios.put(Ekmekk().URL + '/admin/product', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            }).then(function (response) {
                console.log(response.data);
                ok(response.data)
            })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListProduct() {

        const api = Ekmekk().Profile.GetProfile()
        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/product', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })

                    }
                });

        });
    }

    function DeleteProduct(productID) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/product/' + productID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function AddFoodToRoyalDog(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()
        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID

        return new Promise((ok, fail) => {
            let data = new FormData();

            axios.post(Ekmekk().URL + '/admin/royal_dog/food', {
                ek_royal_dog_ID: dataBlock.ek_royal_dog_ID,
                ek_product_ID: dataBlock.ek_product_ID,
                ek_product_stock_ID: dataBlock.ek_product_stock_ID,
                ek_food_piece: dataBlock.ek_food_piece,
                ek_food_sale_price: dataBlock.ek_food_sale_price,
                ek_food_purchase_price: dataBlock.ek_food_purchase_price,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    return {
        InsertProduct, ListProduct, DeleteProduct, UpdateProduct, AddFoodToRoyalDog
    }
}