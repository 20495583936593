
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'

import ModalRoyalDogInsert from "../../Modal/RoyalDog/RoyalDogInsert"
import SubHeader from '../../Thema/sub-header'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnet, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import NullPage from '../../Ekmekk/Views/NullPage'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'
import { BrowserView, MobileView } from 'react-device-detect'


export default function RoyalDog() {

    var inputBreedSearch = useRef(null)

    const [breedList, setBreedList] = useState([])

    const [list, setList] = useState([]);
    const [mainList, setMainList] = useState([]);

    var history = useHistory();
    useEffect(() => {
        DataCenter().RoyalDog.ListRoyalDog().then(r => {
            console.log(r.data);
            setList(r.data);
            setMainList(r.data);
        })

        DataCenter().DogBreed.ListDogBreed().then(r => {
            setBreedList(r.data);
        })
    }, [])

    function RoyalDogInsertClick(caseData, data) {



        if (caseData == "inserted") {

            // alert(JSON.stringify(data));
            list.push(data);
            setList([...list])

        }
        else if (caseData == "updated") {
            for (var i = 0; i < list.length; i++) {
                if (list[i].ek_royal_dog_ID == data.ek_royal_dog_ID) {
                    if (data.ek_royal_dog_image == null) {
                        var temp = list[i].ek_royal_dog_image;
                    }
                    else {
                        var temp = data.ek_royal_dog_image
                    }
                    // var tempDate = list[i].ek_product_creation_date;

                    list[i] = data;

                    list[i].ek_royal_dog_image = temp;
                    // list[i].ek_product_creation_date = tempDate;
                }
            }

            setList([...list]);
        }

    }

    function RoyalDogDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {

                DataCenter().RoyalDog.DeleteRoyalDog(item.ek_royal_dog_ID).then(r => {
                    if (r.status) {
                        var list2 = [...list];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_royal_dog_ID == item.ek_royal_dog_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setList(list2);
                        setMainList(list2);
                    }
                })

            }
        })
    }

    function BreedItem(item) {
        return <option value={item.ek_dog_breed_name}>{item.ek_dog_breed_name}</option>
    }

    function ChangeSearchText(e) {
        var filteredList = mainList.filter(item => item.ek_royal_dog_gender.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setList(filteredList);

    }

    function ChangeBreedText(e) {

        if (inputBreedSearch.current.value == 0) {
            setList(mainList);
        }
        else {
            var filteredList = mainList.filter(item => item.ek_dog_breed_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
            setList(filteredList);
        }

    }


    return <>

        <BrowserView>
            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">Cins:</label>
                            <select ref={inputBreedSearch} onChange={ChangeBreedText} className="form-control" id="kt_datatable_search_type">
                                <option value={0}>All</option>
                                {
                                    breedList.map(item => {
                                        return BreedItem(item)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-2">
                        <AuthorityControl pageCode={39}>

                            <ModalRoyalDogInsert isSale={false} insertClick={RoyalDogInsertClick} />
                        </AuthorityControl >
                    </div>
                </div>
            </div>

        </BrowserView>

        <MobileView>
            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <AuthorityControl pageCode={39}>

                            <ModalRoyalDogInsert isSale={false} insertClick={RoyalDogInsertClick} />
                        </AuthorityControl >

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 mt-2">
                        <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">Cins:</label>
                            <select ref={inputBreedSearch} onChange={ChangeBreedText} className="form-control" id="kt_datatable_search_type">
                                <option value={0}>All</option>
                                {
                                    breedList.map(item => {
                                        return BreedItem(item)
                                    })
                                }
                            </select>
                        </div>
                    </div>

                </div>

            </div>

           

        </MobileView>




        <AuthorityControl pageCode={38}>

            {
                list.length != 0 ? TableList(list) : <NullPage />
            }
        </AuthorityControl >

    </>


    function TableList(data) {


        let history = useHistory();

        const columns = [

            /* {
               title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
             },*/

            {
                title: <span className="text">ID</span>, render: data => <a className="text-dark font-weight-boldest text-hover-primary font-size-lg">{data.ek_royal_dog_ID}</a>
            },
            {
                title: <span className="text">Foto</span>, render: data => <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                    <img src={data.ek_royal_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + data.ek_royal_dog_image} alt="" className="h-50" />
                </div>

            },
            {
                title: <span className="text">İsim</span>, render: data => <a className="text-dark font-weight-boldest text-hover-primary font-size-lg">{data.ek_royal_dog_name}</a>
            },
            {
                title: <span className="text">Cinsiyet</span>, render: data => <a className="text-dark-50 font-weight-bolder text-hover-primary font-size-lg">{data.ek_royal_dog_gender}</a>
            },
            {
                title: <span className="text">Yaşı</span>, render: data => <div>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{data.dog_age}</span>
                </div>
            },
            {
                title: <span className="text">Cinsi</span>, render: data => <div>
                    <span className="text-dark-50 font-weight-bolder d-block font-size-lg">{data.ek_dog_breed_name}</span>
                </div>
            },
            {
                title: <span className="text">Alt Cinsi</span>, render: data => <div>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{data.ek_dog_sub_breed_name}</span>
                </div>
            },
            {
                title: <span className="text">Alış Fiyatı</span>, render: data => <div>
                    <span className="text-dark-50 font-weight-bolder d-block font-size-lg">{data.ek_royal_dog_purchase_price}</span>
                </div>
            },
            {
                title: <span className="text">Satış Fiyatı</span>, render: data => <div>
                    <span className="text-info font-weight-bolder d-block font-size-lg">{data.ek_royal_dog_sale_price}</span>
                </div>
            },
            {
                title: <span className="text">Detay</span>, render: data => <div className="pr-0">
                    {/* <a  className="btn btn-icon btn-light btn-hover-primary btn-sm">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000" />
                                    <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </a> */}
                    <div>
                        <div className="btn-group">
                            <AuthorityControl pageCode={41}>

                                <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">

                                        <ModalRoyalDogInsert isUpdateTable={true} isUpdate={true} selectedData={data} insertClick={RoyalDogInsertClick} />

                                    </span>
                                </div>
                            </AuthorityControl>
                            <AuthorityControl pageCode={42}>
                                <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">

                                        <FontAwesomeIcon onClick={() => RoyalDogDeleteClick(data)} icon={faTrash}></FontAwesomeIcon>

                                    </span>
                                </div>
                            </AuthorityControl>
                        </div>


                    </div>
                    {/* <a  className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </a>
                    <button onClick={() => RoyalDogDeleteClick(data)} className="btn btn-icon btn-light btn-hover-primary btn-sm">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero" />
                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </button> */}
                </div>

            },


        ]

        const handleRowClick = (event, rowData) => {
            // alert(JSON.stringify(rowData))
            const index = data.indexOf(rowData)
            history.push('/dashboard/dog/royal-dog/profil/' + rowData.ek_royal_dog_ID)

            //localStorage.setItem("selectedItem", JSON.stringify(data[index]))


            // history.push("/projects/insert");


            /* if (Ekmekk().Profile.userType == UserType.MASTER) {
               history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
             }
             else {
               history.push("/business/orders/order-detail/" + data[index].user_orderID);
             }*/


        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                    }

                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }
}

