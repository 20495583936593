import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';


export default function ModalPayDebt(props) {

    var odenecekTutar = useRef(null);
    var odenenTutar = useRef(null);

    const [toplamTutar, setToplamTutar] = useState(0);
    const [kalanTutar, setKalanTutar] = useState(0)


    function PayDebtClick() {

        var dataBlock = {
            // ek_selling_cost_price: toplamTutar,
            paid_price: odenenTutar.current.value,
            ek_selling_ID : props.selectedData.ek_selling_ID,
            ek_customer_ID: props.customerID,
            // data: JSON.stringify(InsertedSaleList),
            // customerID: JSON.parse(props.customer).ek_customer_ID,
        }

        console.log(dataBlock);

        DataCenter().Customer.PayDebt(dataBlock).then(r => {

            console.log(r);
            
            if (r.status) {
                // Ekmekk().Alert.UnblockUI();
                Ekmekk().Alert.Success();
                setShow(false);

                props.insertClick(dataBlock);
            }
            else {
                // Ekmekk().Alert.UnblockUI();
                Ekmekk().Alert.Fail();
            }
        })


    }

    function SumItems() {
        // setToplamTutar(props.sumItems(toplamTutar));

        // var toplam = 0;
        // for (var i = 0; i < props.saleList.length; i++) {
        //     toplam = (toplam + (props.saleList[i].sale_product_price * props.saleList[i].piece))
        // }
        console.log(props);
        setToplamTutar(props.selectedData.debt);
        //alert(totalPrice);

    }


    const [show, setShow] = useState(false);

    return <>

        <button type="button" onClick={() => {
            SumItems()
            setShow(true)
        }} className="btn btn-sm btn-light-success font-weight-bolder text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
            Öde
        </button>


        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Ödemeyi Tamamla
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Borç: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input className="form-control form-control-lg" id="campain_name2" disabled="disabled" placeholder={toplamTutar} type="number" defaultValue />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Ödenen Borç: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={odenenTutar} onChange={(e) => { setKalanTutar(toplamTutar - e.target.value) }} className="form-control form-control-lg" id="campain_name2" placeholder="0" type="number" defaultValue />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Kalan Borç: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input value={kalanTutar} className="form-control form-control-lg" disabled="disabled" id="campain_name2" placeholder={kalanTutar} type="number" defaultValue />
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal" onClick={() => {
                        setShow(false)
                    }}>
                        Kapat</button>
                    <button type="button" className="btn btn-success font-weight-bold" onClick={PayDebtClick}>Tamamla</button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}