import React, { useState, useEffect } from 'react'
import Sticky from 'react-sticky-el';

import ModalCustomerSelect from "../../Modal/Customer/CustomerSelect"
import ModalCustomerInsert from "../../Modal/Customer/CustomerInsert"

import ModalSaleInsert from "../../Modal/Sale/SaleInsert"
import ModalSaleComplete from "../../Modal/Sale/SaleComplete"
import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import SubHeader from '../../Thema/sub-header'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { faCubes, faBone, faGraduationCap, faCut, faThList } from '@fortawesome/free-solid-svg-icons'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';


export default function Satis() {

    const [countID, setCountID] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const [modalInsertIsShow, setModalInsertIsShow] = useState(false);
    const [modalSelectIsShow, setModalSelectIsShow] = useState(false);

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customerDogs, setCustomerDogs] = useState(null);

    const [isChange, setisChange] = useState(0);

    function InsertModalHandler(type) {
        switch (type) {
            case "customerClick":
                setModalSelectIsShow(false);
                setModalInsertIsShow(true);
                break;
            case "close":
                setModalSelectIsShow(false);
                break;
            case "click":
                setModalSelectIsShow(true);
                break;
        }
    }

    const [saleList, setSaleList] = useState([])

    const [mainProductList, setMainProductList] = useState([])
    // const [mainEducationList, setMainEducationList] = useState([])
    const [mainServicetList, setMainServiceList] = useState([])
    const [mainDogList, setMainDogList] = useState([])

    const [productList, setProductList] = useState([])
    // const [educationList, setEducationList] = useState([])
    const [servicetList, setServiceList] = useState([])
    const [dogList, setDogList] = useState([])

    useEffect(() => {
        DataCenter().Sale.ListSale().then(r => {
            // console.log(r);
            setMainProductList(r.product);
            // setMainEducationList(r.education);
            setMainServiceList(r.service);
            setMainDogList(r.dog);

            setProductList(r.product);
            // setEducationList(r.education);
            setServiceList(r.service);
            setDogList(r.dog);

            var myList = [];
            console.log(r.product);
            console.log(JSON.parse(r.product[0].stok_info));
            // console.log(r.education);
            console.log(r.service);
            console.log(r.dog);
        })
    }, [])

    function ProductItemList(item) {
        return <>
            <div id="item_advertisementID" className="col-xl-4 col-lg-6 col-md-6 col-sm-6">

                <div className="card card-custom gutter-b card-stretch">

                    <div className="card-body pt-4">

                        <div className="pt-4 text-center">
                            <div className="symbol symbol-150 symbol-2by3 flex-shrink-0">
                                <img src={item.ek_product_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + item.ek_product_image} alt="" className="h-50" />

                            </div>
                        </div>
                        {/*begin::User*/}
                        <div className="d-flex align-items-center mb-7">
                            {/*begin::Pic*/}
                            <div className="flex-shrink-0 mr-4">
                            </div>
                            {/*end::Pic*/}
                            {/*begin::Title*/}
                            <div className="d-flex flex-column">
                                <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.ek_product_name}</a>
                                <span className="text-muted font-weight-bold">{item.ek_product_brand_name}</span>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Info*/}
                        <div className="mb-7">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Fiyat:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">₺{JSON.parse(item.stok_info).length > 0 ? JSON.parse(item.stok_info)[0].ek_product_sale_price : "---"}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-cente my-1">
                                <span className="text-dark-75 font-weight-bolder mr-2">Stok:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{JSON.parse(item.stok_info).length > 0 ? JSON.parse(item.stok_info)[0].ek_product_stock : "---"}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-cente my-1">
                                <span className="text-dark-75 font-weight-bolder mr-2">Kategori:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{item.ek_product_category_name}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Türü:</span>
                                <span className="text-dark-50 font-weight-bold">{item.type == 2 ? "Ürün" : ""}</span>
                            </div>
                        </div>
                        {/*end::Info*/}
                    </div>
                    {/*end::Body*/}

                    <div className="card-footer">
                        <ModalSaleInsert insertClick={SaleInsertClick} item={item} productName={item.ek_product_name} purchasePrice={item.ek_product_purchase_price} productPrice={item.ek_product_sale_price} ID={countID} customerDogs={customerDogs} />

                    </div>
                </div>
                {/*end:: Card*/}
            </div>

        </>
    }



    function ServiceItemList(item) {
        // alert(JSON.stringify(item))
        return <>
            <div id="item_advertisementID" className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                {/*begin::Card*/}
                <div className="card card-custom gutter-b card-stretch">
                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                        {/*begin::Toolbar*/}
                        {/*end::Toolbar*/}
                        <div className="pt-4 text-center">
                            <div className="symbol symbol-100 symbol-2by3 flex-shrink-0">
                                <div className="d-flex flex-column">
                                    <div className="symbol-label mb-3" style={{ backgroundImage: 'url("http://www.pethair.com.tr/images/blog/kopek-trasi-nasil-yapilir.jpg")' }} />
                                </div>
                            </div>
                        </div>
                        {/*begin::User*/}
                        <div className="d-flex align-items-center mb-7">
                            {/*begin::Pic*/}
                            <div className="flex-shrink-0 mr-4">
                            </div>
                            {/*end::Pic*/}
                            {/*begin::Title*/}
                            <div className="d-flex flex-column">
                                <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.ek_service_name}</a>
                                <span className="text-muted font-weight-bold">En İyi Berber</span>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Info*/}
                        <div className="mb-7">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Fiyat:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">₺{item.ek_service_sale_price}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-cente my-1">
                                <span className="text-dark-75 font-weight-bolder mr-2">Stok:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">Sınırsız</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Türü:</span>
                                <span className="text-dark-50 font-weight-bold">{item.type == 1 ? "Hizmet" : ""}</span>
                            </div>
                        </div>
                        {/*end::Info*/}
                    </div>
                    {/*end::Body*/}

                    <div className="card-footer">
                        <ModalSaleInsert insertClick={SaleInsertClick} item={item} productName={item.ek_service_name} purchasePrice={item.ek_service_purchase_price} productPrice={item.ek_service_sale_price} ID={countID} customerDogs={customerDogs} />

                    </div>
                </div>
                {/*end:: Card*/}
            </div>

        </>
    }

    function DogItemList(item) {
        // alert(JSON.stringify(item))
        return <>
            <div id="item_advertisementID" className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                {/*begin::Card*/}
                <div className="card card-custom gutter-b card-stretch">
                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                        {/*begin::Toolbar*/}
                        {/*end::Toolbar*/}
                        {/* https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg */}
                        <div className="pt-4 text-center">
                            <div className="symbol symbol-150 symbol-2by3 flex-shrink-0">
                                <img src={item.ek_royal_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + item.ek_royal_dog_image} alt="" className="h-50" />

                            </div>
                        </div>
                        {/*begin::User*/}
                        <div className="d-flex align-items-center mb-7">
                            {/*begin::Pic*/}
                            <div className="flex-shrink-0 mr-4">
                            </div>
                            {/*end::Pic*/}
                            {/*begin::Title*/}
                            <div className="d-flex flex-column">
                                <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.ek_dog_breed_name == null ? "Köpek" : item.ek_dog_breed_name}</a>
                                <span className="text-muted font-weight-bold">{item.ek_dog_sub_breed_name}</span>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Info*/}
                        <div className="mb-7">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Fiyat:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">₺{item.ek_royal_dog_sale_price}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-cente my-1">
                                <span className="text-dark-75 font-weight-bolder mr-2">Cinsiyet:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{item.ek_royal_dog_gender}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-cente my-1">
                                <span className="text-dark-75 font-weight-bolder mr-2">Yaş:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{item.dog_age}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Türü:</span>
                                <span className="text-dark-50 font-weight-bold">{item.type == 3 ? "Köpek" : ""}</span>
                            </div>
                        </div>
                        {/*end::Info*/}
                    </div>
                    {/*end::Body*/}

                    <div className="card-footer">
                        <ModalSaleInsert insertClick={SaleInsertClick} item={item} productName={item.ek_dog_breed_name} purchasePrice={item.ek_royal_dog_purchase_price} productPrice={item.ek_royal_dog_sale_price} ID={countID} customerDogs={customerDogs} />

                    </div>
                </div>
                {/*end:: Card*/}
            </div>

        </>
    }


    function SaleInsertClick(data) {

        // console.log(JSON.stringify(data));
        saleList.push(data);
        setSaleList([...saleList])

        setCountID(countID + 1);

    }

    function SaleDeleteClick(item) {

        var list2 = [...saleList];
        for (var i = 0; i < list2.length; i++) {
            if (list2[i].sale_product_ID == item.sale_product_ID) {
                list2.splice(i, 1)
            }
        }
        setSaleList(list2);
    }


    function FilterItems(categoryNumber) {
        switch (categoryNumber) {
            case 0:
                setProductList(mainProductList);
                // setEducationList(mainEducationList);
                setServiceList(mainServicetList);
                setDogList(mainDogList);
                break;
            case 1:
                setProductList(mainProductList);
                // setEducationList([]);
                setServiceList([]);
                setDogList([]);
                break;
            case 2:
                setProductList([]);
                // setEducationList(mainEducationList);
                setServiceList([]);
                setDogList([]);
                break;
            case 3:
                setProductList([]);
                // setEducationList([]);
                setServiceList(mainServicetList);
                setDogList([]);
                break;
            case 4:
                setProductList([]);
                // setEducationList([]);
                setServiceList([]);
                setDogList(mainDogList);
                break;

            default:
                break;
        }
    }

    function ChangeSearchText(e) {

        var filteredList = mainProductList.filter(item => item.ek_product_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setProductList(filteredList);
        // var filteredList = mainEducationList.filter(item => item.ek_education_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        // setEducationList(filteredList);
        var filteredList = mainServicetList.filter(item => item.ek_service_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setServiceList(filteredList);
        var filteredList = mainDogList.filter(item => item.ek_dog_breed_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setDogList(filteredList);

    }

    function GetCustomerDogs(id) {

        DataCenter().Customer.ListCustomerDog(id).then(r => {
            setCustomerDogs(r.data);
        })
    }

    function SaleItemList(item) {
        return <>
            <tr>
                {/*begin::Content*/}
                <td className="align-left pb-6">
                    <div className="font-size-sm font-weight-bolder text-dark-75 mb-1">{item.sale_product_name}</div>
                </td>
                <td className="font-weight-bold text-muted align-middle text-left pb-6">
                    <span className=" font-size-lg font-weight-bolder ml-1">{item.piece}</span>
                </td>
                <td className="text-left  align-middle pb-6">
                    <div className="font-size-h5 font-weight-bolder text-success">₺{item.sale_product_price}</div>
                </td>
                <td className="pr-0 text-left">
                    <button onClick={() => SaleDeleteClick(item)} className="btn btn-icon btn-light-danger btn-hover-danger btn-sm">
                        <span className="svg-icon svg-icon-danger svg-icon-md">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Close.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect x={0} y={7} width={16} height={2} rx={1} />
                                    <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x={0} y={7} width={16} height={2} rx={1} />
                                </g>
                            </g>
                        </svg>{/*end::Svg Icon*/}</span>
                    </button>
                </td>
                {/*end::Content*/}
            </tr>
        </>
    }

    return <>

        <AuthorityControl pageCode={18}>
            <div className="d-flex flex-row">
                {/*begin::Aside*/}

                <div className="flex-row-auto offcanvas-mobile w-200px w-xxl-275px" id="kt_todo_aside">
                    {/*begin::Card*/}
                    <Sticky topOffset={10}>
                        <div className="col-md-12">
                            
                            <div className="mt-6 card card-custom gutter-b">
                                <div className="card-header h-auto py-3 border-0">
                                    <div className="card-title">
                                        <h3 className="card-label text-primary text-boldest">Toplam Tutar</h3>
                                    </div>
                                </div>
                                <div className="px-4 pt-2">
                                    <table className="table table-borderless mb-0">
                                        <tbody>

                                            {
                                                saleList.map(item => {
                                                    return SaleItemList(item)
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <div className="text-center">
                                        <a href="#" className="btn btn-clean btn-sm font-weight-bold text-left">İptal</a>

                                        <ModalSaleComplete saleList={saleList} customer={selectedCustomer} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>
                    {/*end::Card*/}

                </div>


                <div className="flex-row-fluid ml-lg-8">
                    <div className="d-flex flex-column flex-grow-1">
                        {/* BOŞ MÜŞTERİ EKLE */}
                        {
                            selectedCustomer != null ? "" : <div className="card card-custom gutter-b">
                                <div className="card-body">
                                    <div className="d-flex">
                                        {/*begin::Pic*/}
                                        {/*end::Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin::Title*/}
                                            <div className="text-center align-items-center justify-content-between flex-wrap">
                                                {/*begin::User*/}


                                                <ModalCustomerSelect selectedCustomer={r => {
                                                    setSelectedCustomer(r);
                                                    GetCustomerDogs(JSON.parse(r).ek_customer_ID);
                                                    setModalSelectIsShow(false);

                                                }} isShow={modalSelectIsShow} handler={InsertModalHandler} isChange={0} />

                                                <ModalCustomerInsert isShow={modalInsertIsShow} HandlerClose={() => { setModalInsertIsShow(false); setModalSelectIsShow(true) }} isSale={true} />

                                                {/*end::Actions*/}
                                            </div>
                                            {/*end::Title*/}
                                            {/*begin::Content*/}
                                            {/*end::Content*/}
                                        </div>
                                        {/*end::Info*/}
                                    </div>
                                </div>
                            </div>

                        }



                        {/* Eklenmiş Müşteri  */}
                        {
                            selectedCustomer == null ? "" : <div className="card card-custom gutter-b">
                                <div className="card-body">
                                    <div className="d-flex">
                                        {/*begin::Pic*/}
                                        <div className="flex-shrink-0 mr-7">
                                            <div className="symbol symbol-50 symbol-lg-120">
                                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                <img src={JSON.parse(selectedCustomer).ek_customer_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" />

                                            </div>
                                        </div>
                                        {/*end::Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin::Title*/}
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                {/*begin::User*/}
                                                <div className="mr-3">
                                                    <div className="d-flex align-items-center mr-3">
                                                        {/*begin::Name*/}
                                                        <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{JSON.parse(selectedCustomer).ek_customer_fullname}</a>
                                                        {/*end::Name*/}
                                                    </div>
                                                    {/*begin::Contacts*/}
                                                    <div className="d-flex flex-wrap my-2">
                                                        <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                            <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                        <rect x={0} y={0} width={24} height={24} />
                                                                        <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                        <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                    </g>
                                                                </svg>
                                                                {/*end::Svg Icon*/}
                                                            </span>{JSON.parse(selectedCustomer).ek_customer_mail}</a>
                                                        <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                            <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                        <mask fill="white">
                                                                            <use xlinkHref="#path-1" />
                                                                        </mask>
                                                                        <g />
                                                                        <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                                    </g>
                                                                </svg>
                                                                {/*end::Svg Icon*/}
                                                            </span>{JSON.parse(selectedCustomer).ek_customer_phone}</a>
                                                        <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                            <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                        <rect x={0} y={0} width={24} height={24} />
                                                                        <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                    </g>
                                                                </svg>
                                                                {/*end::Svg Icon*/}
                                                            </span>{JSON.parse(selectedCustomer).ek_customer_address_district}</a>
                                                    </div>
                                                    {/*end::Contacts*/}
                                                </div>
                                                {/*begin::User*/}
                                                {/*begin::Actions*/}
                                                <div className="mb-10">

                                                    <ModalCustomerSelect selectedCustomer={r => {
                                                        setSelectedCustomer(r);
                                                        GetCustomerDogs(JSON.parse(r).ek_customer_ID);
                                                        setModalSelectIsShow(false);

                                                    }} isShow={modalSelectIsShow} handler={InsertModalHandler} isChange={1} />

                                                    <ModalCustomerInsert isShow={modalInsertIsShow} HandlerClose={() => { setModalInsertIsShow(false); setModalSelectIsShow(true) }} isSale={true} />


                                                    <button onClick={() => { setSelectedCustomer(null); setCustomerDogs(null); }} className="btn btn-sm btn-danger font-weight-bolder text-uppercase mr-2">Kaldır</button>
                                                </div>
                                                {/*end::Actions*/}
                                            </div>
                                            {/*end::Title*/}
                                            {/*begin::Content*/}
                                            <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                {/*begin::Description*/}
                                                <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">{JSON.parse(selectedCustomer).ek_customer_address}</div>
                                                {/*end::Description*/}
                                                {/*begin::Progress*/}
                                                {/*end::Progress*/}
                                            </div>
                                            {/*end::Content*/}
                                        </div>
                                        {/*end::Info*/}
                                    </div>
                                </div>
                            </div>

                        }

                        <div className="card card-custom gutter-b">
                            <div className="card-body">
                                {/*begin: Search Form*/}
                                <form>
                                    <div className="row align-items-center">
                                        <div className="col-md-10 my-2 my-md-0">
                                            <div className="input-icon">
                                                <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün adı" id="kt_datatable_search_query" />
                                                <span>
                                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="d-flex">
                                                <div className="mr-auto"></div>
                                                <NavLink to="/dashboard/definition/products">
                                                    <button className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">Ürün Oluştur</button>
                                                </NavLink>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-3 mb-lg-0 align-items-center">
                                        <label>Ürün Adı:</label>
                                    </div>
                                    <div className="input-icon">
                                        <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün adı" id="kt_datatable_search_query" />
                                        <span>
                                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                        </span>
                                    </div> */}
                                        {/* <div className="col-lg-9 mb-lg-0 input-icon">
                                        <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün adı" id="kt_datatable_search_query" />
                                        <span>
                                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                        </span>
                                        <input type="text" className="form-control datatable-input" placeholder="Ürün Adı" data-col-index={4} />
                                    </div> */}
                                        {/* <div className="col-lg-5 mb-lg-0 mt-2">
                                        <label>Ürün Kodu:</label>
                                    </div> */}
                                    </div>
                                </form>
                                {/*begin: Datatable*/}
                            </div>
                        </div>
                        {/* ÜRÜN LİSTESİ */}
                        <div className="row" id="">

                            {
                                productList.map(item => {
                                    return ProductItemList(item)
                                })

                            }


                        </div>

                    </div>
                </div>
                {/*end::List*/}
            </div>
        </AuthorityControl>
    </>
}