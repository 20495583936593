import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import DatePicker from "react-datepicker"
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';


export default function ModalDogInsert(props) {


    const [selectedImage, setSelectedImage] = useState(null)

    var inputCustomerDogName = useRef(null);
    var inputCustomerDogGender = useRef(null);
    var inputCustomerDogSubBreed = useRef(null);
    var inputCustomerDogBreed = useRef(null);


    function CustomerDogInsertClick() {

        var birthDate = calendarDate, date = birthDate.getFullYear() + '/' + (birthDate.getMonth() + 1) + '/' + birthDate.getDate();

        var dataBlock = {

            ek_customer_ID: props.ek_customer_ID,
            ek_customer_dog_name: inputCustomerDogName.current.value,
            ek_customer_dog_gender: inputCustomerDogGender.current.value,
            ek_customer_dog_birthday: date,
            ek_dog_sub_breed_ID: inputCustomerDogSubBreed.current.value,

            ek_customer_dog_image: selectedImage,

            ek_dog_breed_name: null,
            ek_dog_sub_breed_name: null,
        }

        var validator = new ValidationController();

        if (validator.Control() == 0) {

            if (props.selectedData == undefined) {
                DataCenter().Customer.InsertCustomerDog(dataBlock).then(r => {

                    for (let i = 0; i < breedList.length; i++) {

                        if (breedList[i].ek_dog_breed_ID == inputCustomerDogBreed.current.value) {
                            dataBlock.ek_dog_breed_name = breedList[i].ek_dog_breed_name;
                        }
                    }

                    for (let i = 0; i < subBreedList.length; i++) {

                        if (subBreedList[i].ek_dog_sub_breed_ID == dataBlock.ek_dog_sub_breed_ID) {
                            dataBlock.ek_dog_sub_breed_name = subBreedList[i].ek_dog_sub_breed_name;
                        }
                    }

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_customer_dog_image"] = r.data.imagePath;
                        dataBlock["ek_customer_dog_ID"] = r.data.lastID;
                        dataBlock["dog_age"] = r.data.age;

                        props.insertClick(dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }

                })
            }
            else {

                dataBlock["ek_customer_dog_ID"] = props.selectedData.ek_customer_dog_ID;

                DataCenter().Customer.UpdateCustomerDog(dataBlock).then(r => {

                    for (let i = 0; i < breedList.length; i++) {

                        if (breedList[i].ek_dog_breed_ID == inputCustomerDogBreed.current.value) {
                            dataBlock.ek_dog_breed_name = breedList[i].ek_dog_breed_name;
                        }
                    }

                    for (let i = 0; i < subBreedList.length; i++) {

                        if (subBreedList[i].ek_dog_sub_breed_ID == dataBlock.ek_dog_sub_breed_ID) {
                            dataBlock.ek_dog_sub_breed_name = subBreedList[i].ek_dog_sub_breed_name;
                        }
                    }

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);

                        dataBlock["ek_customer_dog_image"] = r.data.ek_customer_dog_image;

                        // DOĞUM TARİHİ GÜZELLEŞTİRİLİP GERİ YOLLANIYOR EKRANA BASILMASI İÇİN
                        var birthDate = calendarDate, date = birthDate.getDate() + '.' + (birthDate.getMonth() + 1) + '.' + birthDate.getFullYear();
                        dataBlock["ek_customer_dog_birthday"] = date;

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }


    }

    // ----------- CİNSİYET ------------

    const genders = ["Erkek", "Dişi"];

    function GenderItem(item) {
        return <option value={item} >{item}</option>
    }

    // ------------- CİNS --------------

    const [breedList, setBreedList] = useState([])
    useEffect(() => {
        DataCenter().DogBreed.ListDogBreed().then(r => {
            setBreedList(r.data)
        })

    }, [])

    function BreedItem(item) {
        return <option value={item.ek_dog_breed_ID} selected={props.selectedData != undefined && props.selectedData.ek_dog_breed_name == item.ek_dog_breed_name}>{item.ek_dog_breed_name}</option>
    }


    // ------------- ALT CİNS --------------

    const [subBreedList, setSubBreedList] = useState([])

    function ChangeSubBreedList(subBreedID) {


        DataCenter().SubBreed.ListDogSubBreed(subBreedID).then(r => {
            setSubBreedList(r.data)
        })
    }

    function SubBreedItem(item) {
        return <option value={item.ek_dog_sub_breed_ID} selected={props.selectedData != undefined && props.selectedData.ek_dog_sub_breed_name == item.ek_dog_sub_breed_name} >{item.ek_dog_sub_breed_name}</option>
    }


    // ------------- TARİH --------------

    const [calendarDate, setCalendarDate] = useState(new Date());
    // const [birthDate, setBirthDate] = useState(null);

    function SetDate() {

        return calendarDate;
    }



    // ------------------------------------

    const [show, setShow] = useState(false);

    function ClickEvent() {

        // GÜNCELLEMEYSE DOĞUM TARİHİNİ TAKVİMDE SEÇİLİ GETİRİYOR
        if (props.selectedData != null) {

            // GÜNCELLEMEDE SUBBREED ID'YE GÖRE SUBBREED LİSTESİ GÖSTERME
            if (props.selectedData == undefined) {
                DataCenter().SubBreed.ListDogSubBreed().then(r => {
                    setSubBreedList(r.data)
                })
            }
            else {
                var subBreedID = props.selectedData.ek_dog_breed_ID;
                DataCenter().SubBreed.ListDogSubBreed(subBreedID).then(r => {
                    setSubBreedList(r.data)
                })
            }

            let strDay = props.selectedData.ek_customer_dog_birthday;
            var res = strDay.split(".");

            var temp = res[0];
            res[0] = res[1];
            res[1] = temp;

            var date = new Date(res);

            setCalendarDate(date);
        }


        setShow(true);

    }

    return <>



            {
                props.isUpdate == false ? <button onClick={() => setShow(true)} className="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-4">Köpek Ekle</button> : <button onClick={ClickEvent} className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Güncelle</button>
            }


        <Modal
            show={show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">

                    <div>
                        <h5 className="font-boldest">{props.isUpdate == true ? "Bilgileri Güncelle" : "Köpek Ekle"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {/*
                        ImagePicker(function (r) {
                            setSelectedImage(r);
                        }, true,false, props.selectedData.ek_customer_dog_image == undefined ? null : Ekmekk().URL + '/images/' + props.selectedData.ek_customer_dog_image)
                    */}

                    <Validator>
                        {/* KÖPEK İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Köpek İsmi: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputCustomerDogName} className="form-control form-control-lg form-control-solid" name="dogName" defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_dog_name : ""} placeholder="Köpek İsmi" type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Köpek Cinsiyeti: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputCustomerDogGender} className="form-control form-control-lg form-control-solid" id="select_banner_type" defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_dog_gender : ""} >
                                    <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                    {
                                        genders.map(item => {
                                            return GenderItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* KÖPEK CİNSİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Doğum Tarihi: </label>

                            <div className="col-lg-9 col-xl-9">

                                <DatePicker
                                    selected={calendarDate}
                                    // @ts-ignore
                                    onChange={date => setCalendarDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control form-control-lg form-control-solid w-100"
                                />

                            </div>

                        </div>
                        {/* SÜRE */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Cinsi: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputCustomerDogBreed} onChange={(e) => ChangeSubBreedList(e.target.value)} className="form-control form-control-lg form-control-solid" id="select_banner_type" >
                                    <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                    {
                                        breedList.map(item => {
                                            return BreedItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* SÜRE */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Alt Cinsi: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputCustomerDogSubBreed} className="form-control form-control-lg form-control-solid" id="select_banner_type" >
                                    {
                                        subBreedList.map(item => {
                                            return SubBreedItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false) }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal" >Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={CustomerDogInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Ekle"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}