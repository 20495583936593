
import axios from 'axios'
import Ekmekk from '../Helper/ekmekk';

//const axios = require("axios");

export default function AuthController() {

  function Login(username, password) {
    return new Promise((ok, fail) => {
      axios.post(Ekmekk().URL + '/login', {
        username: username,
        password: password
      })
        .then(function (response) {
          console.log(response.data);
          ok(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });

    });
  }
  function UpdateUser(id,mails, names, surnames, password, permissionID) {
    return new Promise((ok, fail) => {
      const api = Ekmekk().Profile.GetProfile()

      var data = {

        ek_admin_name: names,
        ek_admin_lastname: surnames,
        ek_admin_mail: mails,
        ek_admin_authority_ID: permissionID,
        ek_admin_ID: id,
      }
      if(password != "")
      {
        data["ek_admin_password"] = password;
      }
      axios.put(Ekmekk().URL + '/admin/authority', data, {
        headers: {
          'Authorization': `Basic ${api}`
        },
      })
        .then(function (response) {
       
          ok({ status: response.data.status, id: response.data.data.lastInsert })
        })
        .catch(function (error) {
          ok(false);
        });

    })
  }
  function RemoveUser(id) {
    return new Promise((ok, fail) => {
      const api = Ekmekk().Profile.GetProfile()

      axios.delete(Ekmekk().URL + '/admin/authority/'+id, {
        headers: {
          'Authorization': `Basic ${api}`
        },
      })
        .then(function (response) {
        
          ok({ status: response.data.status })
        })
        .catch(function (error) {
          ok(false);
        });

    })
  }
  function InsertUser(mails, names, surnames, password, permissionID) {
    return new Promise((ok, fail) => {
      const api = Ekmekk().Profile.GetProfile()

      axios.post(Ekmekk().URL + '/admin/authority', {
        ek_admin_name: names,
        ek_admin_lastname: surnames,
        ek_admin_mail: mails,
        ek_admin_password: password,
        ek_admin_authority_ID: permissionID
      }, {
        headers: {
          'Authorization': `Basic ${api}`
        },
      })
        .then(function (response) {
       
          ok({ status: response.data.status, id: response.data.data.lastInsert })
        })
        .catch(function (error) {
          ok(false);
        });

    })


  }

  function ListUser() {
    const api = Ekmekk().Profile.GetProfile()
    return new Promise((ok, fail) => {

      axios.get(Ekmekk().URL + '/admin/authority', {
        headers: {
          'Authorization': `Basic ${api}`
        },
      }).then(function (response) {

        ok(response.data.data);
      }).catch(function (error){

        console.log(error);

        if (error.response.status == 401) {
          Ekmekk().Alert.GoToLogin((e) => {
              if (e) {
                  window.location.href = '/login';
              }
              else {
                  window.location.href = '/login';
              }
          })

      }

      })
    });
  }

  function ListGroup() {
    const api = Ekmekk().Profile.GetProfile()
    return new Promise((ok, fail) => {
      axios.get(Ekmekk().URL + '/admin/authority/process', {
        headers: {
          'Authorization': `Basic ${api}`
        },
      }).then(function (response) {

        ok(response.data.data);
      }).catch(function (error){

        console.log(error);

        if (error.response.status == 401) {
          Ekmekk().Alert.GoToLogin((e) => {
              if (e) {
                  window.location.href = '/login';
              }
              else {
                  window.location.href = '/login';
              }
          })

      }

      })
    });
  }

  function RemoveGroup(id) {
    const api = Ekmekk().Profile.GetProfile()
    axios.delete(Ekmekk().URL + '/admin/authority/process/' + id, {
      headers: {
        'Authorization': `Basic ${api}`
      },
    })
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function InsertGroup(names, dataSet) {
    return new Promise((ok, fail) => {
      const api = Ekmekk().Profile.GetProfile()

      axios.post(Ekmekk().URL + '/admin/authority/process', {
        ek_admin_authority: names,
        ek_authority_process: dataSet,
      }, {
        headers: {
          'Authorization': `Basic ${api}`
        },
      })
        .then(function (response) {
          ok(response.data.data.lastInsert);
         
        })
        .catch(function (error) {
          console.log(error);
        });
    })


  }
  function UpdateGroup(id, names, dataSet) {
    return new Promise((ok, fail) => {
      const api = Ekmekk().Profile.GetProfile()

      axios.put(Ekmekk().URL + '/admin/authority/process', {
        ek_admin_authority: names,
        ek_authority_process: dataSet,
        ek_admin_authority_ID: id,
      }, {
        headers: {
          'Authorization': `Basic ${api}`
        },
      })
        .then(function (response) {

          ok(response.data);

        })
        .catch(function (error) {
          console.log(error);
        });
    })


  }
  return {
    Login, ListUser, InsertUser, ListGroup, InsertGroup, RemoveGroup, UpdateGroup, RemoveUser,UpdateUser
  }
}