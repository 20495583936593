
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function SaleController() {

    function InsertSale(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            console.log(dataBlock);
            if (dataBlock.customerID != null || dataBlock.customerID != undefined)
                data.append('customerID', dataBlock.customerID);
            data.append('ek_selling_cost_price', dataBlock.ek_selling_cost_price);
            data.append('ek_selling_paid_price', dataBlock.ek_selling_paid_price);
            data.append('data', dataBlock.data);

            axios.post(Ekmekk().URL + '/admin/selling', dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function InsertOldSale(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            console.log(dataBlock);
            if (dataBlock.customerID != null || dataBlock.customerID != undefined)
                data.append('customerID', dataBlock.customerID);
            data.append('ek_selling_cost_price', dataBlock.ek_selling_cost_price);
            data.append('ek_selling_paid_price', dataBlock.ek_selling_paid_price);
            data.append('data', dataBlock.data);
            data.append('creationDate ', dataBlock.creationDate);

            axios.post(Ekmekk().URL + '/admin/selling/past', dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function CalculatePrice(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()
        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID

        return new Promise((ok, fail) => {
            let data = new FormData();

            axios.post(Ekmekk().URL + '/admin/selling/calc_selling', {
                //ek_entry_date, ek_cage_daily_price
                ek_entry_date: dataBlock.ek_entry_date,
                ek_cage_daily_price: dataBlock.ek_cage_daily_price,
                ek_customer_ID: dataBlock.ek_customer_ID,
                // ek_selling_paid_price: dataBlock.ek_selling_paid_price,
                // customerID: dataBlock.customerID,
                // ek_customer_dog_ID: dataBlock.ek_customer_dog_ID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function CalculateOldPrice(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()
        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID

        return new Promise((ok, fail) => {
            let data = new FormData();

            axios.post(Ekmekk().URL + '/admin/selling/calc_past_selling', {
                //ek_entry_date, ek_cage_daily_price
                ek_entry_date: dataBlock.ek_entry_date,
                ek_exit_date: dataBlock.ek_exit_date,
                ek_cage_daily_price: dataBlock.ek_cage_daily_price,
                ek_customer_ID: dataBlock.ek_customer_ID,
                // ek_selling_paid_price: dataBlock.ek_selling_paid_price,
                // customerID: dataBlock.customerID,
                // ek_customer_dog_ID: dataBlock.ek_customer_dog_ID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function HousingSale(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()
        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID

        return new Promise((ok, fail) => {

            let data = new FormData();
            if (dataBlock.ek_selling_paid_price != null)
                data.append('ek_selling_paid_price', dataBlock.ek_selling_paid_price);

            if (dataBlock.customerID != null)
                data.append('customerID', dataBlock.customerID);

            if (dataBlock.ek_customer_dog_ID != null)
                data.append('ek_customer_dog_ID', dataBlock.ek_customer_dog_ID);

            if (dataBlock.creationDate != null)
                data.append('creationDate', dataBlock.creationDate);

                console.log(dataBlock);
                console.log(data);
                for (let [key, value] of data) {
                    console.log(`${key}: ${value}`)
                  }
            
            axios.post(Ekmekk().URL + '/admin/selling/housingSelling', {
                customerID : dataBlock.customerID,
                creationDate : dataBlock.creationDate,
                ek_customer_dog_ID : dataBlock.ek_customer_dog_ID,
                ek_selling_paid_price : dataBlock.ek_selling_paid_price,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function PayAllDebt(customerID, cost, dogID) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.post(Ekmekk().URL + '/admin/selling/pay_all_debt', {
                customerID: customerID,
                housingPrice: cost,
                dogID: dogID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListSale() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/selling', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })

                    }
                });

        });
    }

    return {
        InsertSale, ListSale, HousingSale, CalculatePrice, CalculateOldPrice, PayAllDebt, InsertOldSale
    }
}