

import React, { useState, useEffect } from 'react'

import { useHistory, NavLink, useParams } from "react-router-dom";
import SubHeader from "../../Thema/sub-header";
import DataCenter from "../../Ekmekk/DataCenter/datacenter";
import ModalCustomerSelect from "../../Modal/Customer/CustomerSelect";
import ModalCustomerInsert from "../../Modal/Customer/CustomerInsert";
import Ekmekk from "../../Ekmekk/Helper/ekmekk";
import ModalCageInsert from '../../Modal/Cage/CageInsert';
import ModalDogInsertToCage from '../../Modal/Cage/DogInsertToCage';
import ModalRoyalDogSelect from '../../Modal/RoyalDog/RoyalDogSelect';
import ModalRoyalDogInsertToCage from '../../Modal/Cage/RoyalDogInsertToCage'
import NullPage from '../../Ekmekk/Views/NullPage';
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';
import Sticky from "react-sticky-el";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function KafesDetay() {

    var history = useHistory();


    const [dogType, setDogType] = useState(-1);

    const [modalInsertIsShow, setModalInsertIsShow] = useState(false);
    const [modalSelectIsShow, setModalSelectIsShow] = useState(false);

    const [modalRoyalDogSelect, setModalRoyalDogSelect] = useState(false);

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedCustomerDog, setSelectedCustomerDog] = useState(null);
    const [selectedRoyalDog, setSelectedRoyalDog] = useState(null);

    const [customerDogs, setCustomerDogs] = useState(null);

    const [cageData, setCageData] = useState([]);
    const [lastProcess, setLastProcess] = useState([]);

    // ROYALDOG KAFES İŞLEMLERİ
    const [isRoyalDogCage, setİsRoyalDogCage] = useState(-1);
    const [royalDogListInCage, setRoyalDogListInCage] = useState([]);

    var history = useHistory();
    // @ts-ignore
    const { id } = useParams();

    useEffect(() => {

        DataCenter().Cage.CageDetail(id).then(r => {
            console.log(r.data);
            setCageData(r.data.cage);
            setLastProcess(r.data.lastprocess);
            CheckFilledData(r.data.cage, r.data.lastprocess);


            // KAFESTE ROYALDOG VARSA ÇIKIŞ TARİHİ OLMAYAN KÖPEKLERİ LİSTEYE ATAR BÖYLECE KÖPEKLERİ MODALA YOLLAYAİBLİRİZ
            // AYRICA KAFESTE ROYALDOG OLDUĞUNU isRoyalDogCage DEĞİŞKENİYLE BELLİ EDERİZ BÖYLECE GÖSTERİLECEK MODALLAR BELLİ OLUR 
            if (r.data.lastprocess != undefined && r.data.lastprocess.length != 0) {
                if (r.data.lastprocess[0].type == "Royal Dog") {

                    var myData = [];
                    for (let i = 0; i < r.data.lastprocess.length; i++) {

                        if (r.data.lastprocess[i].ek_exit_date == null) {
                            myData.push(r.data.lastprocess[i]);
                        }
                    }

                    setRoyalDogListInCage(myData);
                    console.log(myData);

                    // EĞER ÇIKIŞ TARİHİ OLMAYAN Bİ ROYALDOG VARSA KAFESTE ROYALDOG VARDIR DEMEKTİR
                    if (myData.length > 0)
                        setİsRoyalDogCage(1);
                    else
                        setİsRoyalDogCage(0);
                }
                else
                    setİsRoyalDogCage(0);
            }
            else {
                setİsRoyalDogCage(0);
            }

        });


    }, [])

    function CheckFilledData(cageData, lastProgressData) {

        // console.log(lastProgressData);

        // MÜŞTERİ KÖPEĞİ VARSA BİLGİLERİ GETİR
        console.log(JSON.stringify(cageData));
        console.log(cageData.isCageFull);
        console.log(JSON.stringify(lastProgressData));
        console.log(lastProgressData[0]);
        if (cageData.isCageFull && (lastProgressData[0].process_type_ID == 1 || lastProgressData[0].process_type_ID == 0)) {


            DataCenter().Customer.ShowCustomerDogProfile(lastProgressData[0].ek_customer_dog_ID).then(r => {
                // console.log(JSON.stringify(r.data.customer[0]));
                // console.log(r.data.dog);
                setSelectedCustomerDog(JSON.stringify(r.data.dog));
                // setSelectedCustomer(JSON.stringify(r.data.customer[0]))
            })

        }
        // ROYALDOG KÖPEĞİ VARSA BİLGİLERİ GETİR
        // else if (cageData.isCageFull && lastProgressData[0].process_type_ID == 2) {

        //     DataCenter().RoyalDog.ListRoyalDog().then(r => {

        //         for (let i = 0; i < r.data.length; i++) {

        //             if (r.data[i].ek_royal_dog_ID == lastProgressData[0].ek_customer_dog_ID) {
        //                 // console.log(r.data[i]);
        //                 setSelectedRoyalDog(JSON.stringify(r.data[i]));

        //             }
        //         }
        //         // console.log(r.data[0]);
        //     })

        // }
    }

    function InsertModalHandler(type) {
        switch (type) {
            case "customerClick":
                setModalSelectIsShow(false);
                setModalInsertIsShow(true);
                break;
            case "close":
                setModalSelectIsShow(false);
                break;
            case "click":
                setModalSelectIsShow(true);
                break;
        }
    }

    function InsertModalHandler2(type) {
        switch (type) {
            case "close":
                setModalRoyalDogSelect(false);
                break;
            case "click":
                setModalRoyalDogSelect(true);
                break;
        }
    }

    function GetCustomerDogs(id) {

        DataCenter().Customer.ListCustomerDog(id).then(r => {
            setCustomerDogs(r.data);
        })
    }

    function CageInsertClick(caseData, data) {

        if (caseData == "updated") {

            if (data.ek_cage_image == null) {
                var tempPhoto = cageData.ek_cage_image;
            }
            else {
                var tempPhoto = data.ek_cage_image;
            }

            data["ek_cage_image"] = tempPhoto;

            var tempEntryDate = cageData.ek_entry_date;
            data["ek_entry_date"] = tempEntryDate;
            var tempExitDate = cageData.ek_exit_date;
            data["ek_exit_date"] = tempExitDate;
            var tempProces = cageData.cageLastProcessCount;
            data["cageLastProcessCount"] = tempProces;
            var tempFull = cageData.isCageFull;
            data["isCageFull"] = tempFull;

            setCageData(data);

        }


    }

    function CageFillClick(caseData, data) {

        if (caseData == "inserted") {

            // KAFES BİLGİLERİ
            data["ek_cage_image"] = cageData.ek_cage_image;
            data["ek_cage_name"] = cageData.ek_cage_name;
            data["ek_cage_size"] = cageData.ek_cage_size;
            data["ek_entry_date"] = data.ek_entry_date;
            data["ek_exit_date"] = "-";

            var tempProces = cageData.cageLastProcessCount;
            data["cageLastProcessCount"] = tempProces + 1;

            data["isCageFull"] = 1;

            setCageData(data);


            // GEÇMİŞ İŞLEM BİLGİLERİ
            var lastProccess2 = [];

            var today = new Date(), date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

            lastProccess2["ek_cage_dog_creation_date"] = date;
            lastProccess2["ek_cage_dog_ID"] = data.ek_cage_dog_ID;
            lastProccess2["ek_entry_date"] = data.ek_entry_date;
            lastProccess2["ek_exit_date"] = null;
            lastProccess2["ek_customer_fullname"] = data.ek_customer_fullname; // response'da at 
            lastProccess2["ek_customer_dog_name"] = data.dog_name; // response'da at 
            lastProccess2["type"] = data.type; // response'da at 


            // alert(JSON.stringify(data));
            lastProcess.push(lastProccess2);
            setLastProcess([...lastProcess]);

            // setSelectedCustomer(null);
            // CheckFilledData(cageData, lastProcess);

        }
        else if (caseData == "updated") {

            // KAFES BİLGİLERİ
            data["ek_cage_image"] = cageData.ek_cage_image;
            data["ek_cage_name"] = cageData.ek_cage_name;
            data["ek_cage_size"] = cageData.ek_cage_size;
            data["ek_entry_date"] = cageData.ek_entry_date;
            data["ek_exit_date"] = data.ek_exit_date;
            data["cageLastProcessCount"] = cageData.cageLastProcessCount;

            if (royalDogListInCage.length > 1)
                data["isCageFull"] = 1;
            else {
                data["isCageFull"] = 0;
                setİsRoyalDogCage(0);
            }

            setCageData(data);

            // GEÇMİŞ İŞLEM BİLGİLERİ

            for (let i = 0; i < lastProcess.length; i++) {

                if (lastProcess[i].ek_cage_dog_ID == data.ek_cage_dog_ID)
                    lastProcess[i].ek_exit_date = data.ek_exit_date;

            }

            for (let k = 0; k < royalDogListInCage.length; k++) {

                if (royalDogListInCage[k].ek_cage_dog_ID == data.ek_cage_dog_ID)
                    royalDogListInCage.splice(k, 1)
            }

            setRoyalDogListInCage([...royalDogListInCage]);

            setLastProcess([...lastProcess]);

            setSelectedRoyalDog(null);
            setSelectedCustomerDog(null);


        }




    }


    function CageDeleteClick(ID) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Cage.DeleteCage(ID).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        // FURKAN KAFESLERİM SAYFASINA YÖNLENDİRELİM
                        history.push("/dashboard/cages/cageList");
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })

            }
        })
    }

    function LastProcessItemList(item) {
        return <>
            <tr>
                <td className="pl-0">
                    <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{item.ek_entry_date}</a>
                </td>
                <td className="pl-0">
                    <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{item.ek_exit_date != null ? item.ek_exit_date : "-"}</a>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{item.ek_customer_fullname == null ? "" : item.ek_customer_fullname != null || item.ek_customer_fullname.length > 0 ? item.ek_customer_fullname : "-"}</span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{item.ek_customer_dog_name}</span>
                </td>
                <td>
                    <span className={item.type == "Eğitim" ? "label label-success label-dot mr-2" : item.type == "Royal Dog" ? "label label-info label-dot mr-2" : item.type == "Konaklama" ? "label label-danger label-dot mr-2" : "label label-warning label-dot mr-2"} />
                    <span className={item.type == "Eğitim" ? "font-weight-bold text-success" : item.type == "Royal Dog" ? "font-weight-bold text-info" : item.type == "Konaklama" ? "font-weight-bold text-danger" : "font-weight-bold text-warning"}>{item.type}</span>
                </td>
            </tr>

        </>
    }

    return <>


        <AuthorityControl pageCode={29}>

            <div className="row">

                {/*begin::Aside*/}
                <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12" id="kt_profile_aside">
                    {/* <div className="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside"> */}
                    {/*begin::Profile Card*/}
                    <BrowserView>
                        <Sticky>

                            <div className="sticky card card-custom mr-4">
                                {/*begin::Body*/}
                                <div className="card-body pt-4">
                                    {/*begin::Toolbar*/}
                                    <div className="d-flex justify-content-end">
                                        <div className="dropdown dropdown-inline">
                                            <a href="#" className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="svg-icon svg-icon-light-dark svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Other2.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <circle fill="#000000" cx={5} cy={12} r={2} />
                                                        <circle fill="#000000" cx={12} cy={12} r={2} />
                                                        <circle fill="#000000" cx={19} cy={12} r={2} />
                                                    </g>
                                                </svg>{/*end::Svg Icon*/}</span>

                                            </a>
                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                {/*begin::Navigation*/}
                                                <ul className="navi navi-hover py-5">
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="navi-icon">
                                                                <i className="flaticon2-drop" />
                                                            </span>
                                                            <span className="navi-text">New Group</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="navi-icon">
                                                                <i className="flaticon2-list-3" />
                                                            </span>
                                                            <span className="navi-text">Contacts</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="navi-icon">
                                                                <i className="flaticon2-rocket-1" />
                                                            </span>
                                                            <span className="navi-text">Groups</span>
                                                            <span className="navi-link-badge">
                                                                <span className="label label-light-primary label-inline font-weight-bold">new</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="navi-icon">
                                                                <i className="flaticon2-bell-2" />
                                                            </span>
                                                            <span className="navi-text">Calls</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="navi-icon">
                                                                <i className="flaticon2-gear" />
                                                            </span>
                                                            <span className="navi-text">Settings</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-separator my-3" />
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="navi-icon">
                                                                <i className="flaticon2-magnifier-tool" />
                                                            </span>
                                                            <span className="navi-text">Help</span>
                                                        </a>
                                                    </li>
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="navi-icon">
                                                                <i className="flaticon2-bell-2" />
                                                            </span>
                                                            <span className="navi-text">Privacy</span>
                                                            <span className="navi-link-badge">
                                                                <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                {/*end::Navigation*/}
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::Toolbar*/}
                                    {/*begin::User*/}
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                            <img src={cageData.ek_cage_image == undefined || cageData.ek_cage_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + cageData.ek_cage_image} alt="" className="h-50" />
                                        </div>
                                        <div>
                                            <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{cageData.ek_cage_name}</a>
                                            <div className="text-muted">{cageData.ek_cage_size}</div>
                                            <div className="mt-2">
                                                <AuthorityControl pageCode={30}>

                                                    <ModalCageInsert isSale={false} isUpdate={true} selectedData={cageData} insertClick={CageInsertClick} />
                                                </AuthorityControl >

                                                <AuthorityControl pageCode={31}>

                                                    <button onClick={() => CageDeleteClick(cageData.ek_cage_ID)} className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1">Kaldır</button>
                                                </AuthorityControl>
                                            </div>
                                        </div>
                                    </div>
                                    {/*end::User*/}
                                    {/*begin::Contact*/}
                                    <div className="py-9">

                                        <div className="d-flex align-items-center justify-content-between  mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Giriş Tarihi:</span>
                                            <a href="#" className="text-muted text-hover-primary">{cageData.ek_entry_date}</a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Çıkış Tarihi:</span>
                                            <span className="text-muted">{cageData.ek_exit_date}</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">İşlem Sayısı:</span>
                                            <span className="text-muted">{cageData.cageLastProcessCount}</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Günlük Ücret:</span>
                                            <span className="text-muted">{cageData.ek_cage_price}</span>
                                        </div>

                                        {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder font-size-h5 mr-2">Müşteri:</span>
                                <span className="text-muted">Ahmet Eğitmen</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder font-size-h5 mr-2">Köpek:</span>
                                <span className="text-muted">Ahmet Eğitmen</span>
                            </div> */}

                                        {/* <div className="separator separator-solid my-7" /> */}

                                        {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder mr-2">Alış Fiyatı:</span>
                                <span className="text-muted">$150</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder mr-2">Satış Fiyatı:</span>
                                <span className="text-muted">$1150</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder mr-2">Toplam Eğitim:</span>

                                <span className="font-weight-bolder text-dark text-right">80%</span>
                                <div className="w-xl-150px mt-sm-0">

                                    <div className="progress progress-xs mt-2 mb-2">
                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '80%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                    </div>
                                </div>
                            </div> */}

                                    </div>
                                    {/*end::Contact*/}
                                    {/*begin::Nav*/}
                                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                        <div className="navi-item mb-2">

                                            {/* EĞER KAFESTE ROYALDOG VARSA GÖSTERİLECEK MODAL DEĞİŞİKLİĞİ - MODALA ROYALDOG LİSTESİ YOLLANIR */}
                                            <AuthorityControl pageCode={32}>

                                                {
                                                    isRoyalDogCage != 1 ? <ModalDogInsertToCage customer={JSON.parse(selectedCustomer)} customerDogs={customerDogs} royalDog={selectedRoyalDog} cageData={cageData} lastProcess={lastProcess} isCageFull={cageData.isCageFull} cageID={id} dogType={dogType} insertClick={CageFillClick} />
                                                        : <ModalRoyalDogInsertToCage royalDogs={royalDogListInCage} royalDog={selectedRoyalDog} cageData={cageData} lastProcess={lastProcess} isCageFull={cageData.isCageFull} cageID={id} dogType={dogType} insertClick={CageFillClick} />

                                                }
                                            </AuthorityControl>


                                            {/* <a href="#" className="btn btn-sm btn-block btn-light-primary font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1">Sahip</a> */}
                                            <AuthorityControl pageCode={21}>

                                                {
                                                    cageData.isCageFull == 0 ? <NavLink to={'/dashboard/sale/education-selling/cage/' + id}>
                                                        <a className="btn btn-sm btn-block btn-light-primary font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1">Eğitim Sat</a>

                                                    </NavLink> : ""
                                                }
                                            </AuthorityControl>

                                        </div>
                                    </div>
                                    {/*end::Nav*/}
                                </div>
                                {/*end::Body*/}
                            </div>

                        </Sticky>
                    </BrowserView>

                    <MobileView>

                        <div className="sticky card card-custom mb-4">
                            {/*begin::Body*/}
                            <div className="card-body pt-4">
                                {/*begin::Toolbar*/}
                                <div className="d-flex justify-content-end">
                                    <div className="dropdown dropdown-inline">
                                        <a href="#" className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="svg-icon svg-icon-light-dark svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Other2.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <rect x={0} y={0} width={24} height={24} />
                                                    <circle fill="#000000" cx={5} cy={12} r={2} />
                                                    <circle fill="#000000" cx={12} cy={12} r={2} />
                                                    <circle fill="#000000" cx={19} cy={12} r={2} />
                                                </g>
                                            </svg>{/*end::Svg Icon*/}</span>

                                        </a>
                                        
                                    </div>
                                </div>
                                {/*end::Toolbar*/}
                                {/*begin::User*/}
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                        <img src={cageData.ek_cage_image == undefined || cageData.ek_cage_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + cageData.ek_cage_image} alt="" className="h-50" />
                                    </div>
                                    <div>
                                        <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{cageData.ek_cage_name}</a>
                                        <div className="text-muted">{cageData.ek_cage_size}</div>
                                        <div className="mt-2">
                                            <AuthorityControl pageCode={30}>

                                                <ModalCageInsert isSale={false} isUpdate={true} selectedData={cageData} insertClick={CageInsertClick} />
                                            </AuthorityControl >

                                            <AuthorityControl pageCode={31}>

                                                <button onClick={() => CageDeleteClick(cageData.ek_cage_ID)} className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1">Kaldır</button>
                                            </AuthorityControl>
                                        </div>
                                    </div>
                                </div>
                                {/*end::User*/}
                                {/*begin::Contact*/}
                                <div className="py-9">

                                    <div className="d-flex align-items-center justify-content-between  mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Giriş Tarihi:</span>
                                        <a href="#" className="text-muted text-hover-primary">{cageData.ek_entry_date}</a>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Çıkış Tarihi:</span>
                                        <span className="text-muted">{cageData.ek_exit_date}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">İşlem Sayısı:</span>
                                        <span className="text-muted">{cageData.cageLastProcessCount}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Günlük Ücret:</span>
                                        <span className="text-muted">{cageData.ek_cage_price}</span>
                                    </div>

                                    {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder font-size-h5 mr-2">Müşteri:</span>
                                <span className="text-muted">Ahmet Eğitmen</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder font-size-h5 mr-2">Köpek:</span>
                                <span className="text-muted">Ahmet Eğitmen</span>
                            </div> */}

                                    {/* <div className="separator separator-solid my-7" /> */}

                                    {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder mr-2">Alış Fiyatı:</span>
                                <span className="text-muted">$150</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder mr-2">Satış Fiyatı:</span>
                                <span className="text-muted">$1150</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bolder mr-2">Toplam Eğitim:</span>

                                <span className="font-weight-bolder text-dark text-right">80%</span>
                                <div className="w-xl-150px mt-sm-0">

                                    <div className="progress progress-xs mt-2 mb-2">
                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '80%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                    </div>
                                </div>
                            </div> */}

                                </div>
                                {/*end::Contact*/}
                                {/*begin::Nav*/}
                                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                    <div className="navi-item mb-2">

                                        {/* EĞER KAFESTE ROYALDOG VARSA GÖSTERİLECEK MODAL DEĞİŞİKLİĞİ - MODALA ROYALDOG LİSTESİ YOLLANIR */}
                                        <AuthorityControl pageCode={32}>

                                            {
                                                isRoyalDogCage != 1 ? <ModalDogInsertToCage customer={JSON.parse(selectedCustomer)} customerDogs={customerDogs} royalDog={selectedRoyalDog} cageData={cageData} lastProcess={lastProcess} isCageFull={cageData.isCageFull} cageID={id} dogType={dogType} insertClick={CageFillClick} />
                                                    : <ModalRoyalDogInsertToCage royalDogs={royalDogListInCage} royalDog={selectedRoyalDog} cageData={cageData} lastProcess={lastProcess} isCageFull={cageData.isCageFull} cageID={id} dogType={dogType} insertClick={CageFillClick} />

                                            }
                                        </AuthorityControl>


                                        {/* <a href="#" className="btn btn-sm btn-block btn-light-primary font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1">Sahip</a> */}
                                        <AuthorityControl pageCode={21}>

                                            {
                                                cageData.isCageFull == 0 ? <NavLink to={'/dashboard/sale/education-selling/cage/' + id}>
                                                    <a className="btn btn-sm btn-block btn-light-primary font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1">Eğitim Sat</a>

                                                </NavLink> : ""
                                            }
                                        </AuthorityControl>

                                    </div>
                                </div>
                                {/*end::Nav*/}
                            </div>
                            {/*end::Body*/}
                        </div>

                    </MobileView>
                    {/*end::Profile Card*/}
                </div>
                {/*end::Aside*/}
                {/*begin::Content*/}
                <div className="flex-row-fluid col-xl-8 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    {/* <div className="flex-row-fluid ml-lg-8"> */}

                    {/* BOŞ MÜŞTERİ SEÇME */}
                    {
                        selectedCustomerDog != null || selectedCustomer != null || selectedRoyalDog != null ? "" : <div className="card card-custom gutter-b">
                            <div className="card-body">
                                <div className="d-flex">
                                    {/*begin::Pic*/}
                                    {/*end::Pic*/}
                                    {/*begin: Info*/}
                                    <div className="flex-grow-1">
                                        {/*begin::Title*/}
                                        <div className="text-center align-items-center justify-content-between flex-wrap">
                                            {/*begin::User*/}

                                            {/* KAFESTE ROYALDOG VARSA MÜŞTERİ SEÇTİRMİYOR */}
                                            {
                                                isRoyalDogCage != 1 ? <ModalCustomerSelect selectedCustomer={r => {
                                                    setSelectedCustomer(r);
                                                    console.log(r);
                                                    // console.log(JSON.parse(r));
                                                    GetCustomerDogs(JSON.parse(r).ek_customer_ID);
                                                    setModalSelectIsShow(false);
                                                    setDogType(1);

                                                }} isShow={modalSelectIsShow} handler={InsertModalHandler} isChange={0} isCageFill={true} /> : ""
                                            }


                                            {/* KAFESTE ROYALDOG VARSA MÜŞTERİ SEÇTİRMİYOR */}
                                            {
                                                isRoyalDogCage != 1 ? <ModalCustomerInsert isShow={modalInsertIsShow} HandlerClose={() => { setModalInsertIsShow(false); setModalSelectIsShow(true) }} isSale={true} />
                                                    : ""
                                            }

                                            <ModalRoyalDogSelect selectedRoyalDog={r => {
                                                setSelectedRoyalDog(r);
                                                console.log(r);
                                                setModalRoyalDogSelect(false);
                                                setDogType(0);

                                            }} isShow={modalRoyalDogSelect} handler={InsertModalHandler2} isChange={0} />

                                            {/*end::Actions*/}
                                        </div>
                                        {/*end::Title*/}
                                        {/*begin::Content*/}
                                        {/*end::Content*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                            </div>
                        </div>

                    }

                    {/* DOLU MÜŞTERİ BİLGİLERİ */}
                    {
                        selectedCustomer == null ? "" : <div className="card card-custom gutter-b">
                            <div className="card-body">
                                <div className="d-flex">
                                    {/*begin::Pic*/}
                                    <div className="flex-shrink-0 mr-7">
                                        <div className="symbol symbol-50 symbol-lg-120">
                                            {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                            <img src={JSON.parse(selectedCustomer).ek_customer_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" />

                                        </div>
                                    </div>
                                    {/*end::Pic*/}
                                    {/*begin: Info*/}
                                    <div className="flex-grow-1">
                                        {/*begin::Title*/}
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            {/*begin::User*/}
                                            <div className="mr-3">
                                                <div className="d-flex align-items-center mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{JSON.parse(selectedCustomer).ek_customer_fullname}</a>
                                                    {/*end::Name*/}
                                                </div>
                                                {/*begin::Contacts*/}
                                                <div className="d-flex flex-wrap my-2">
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedCustomer).ek_customer_mail}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <mask fill="white">
                                                                        <use xlinkHref="#path-1" />
                                                                    </mask>
                                                                    <g />
                                                                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedCustomer).ek_customer_phone}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedCustomer).ek_customer_address_district}</a>
                                                </div>
                                                {/*end::Contacts*/}
                                            </div>
                                            {/*begin::User*/}
                                            {/*begin::Actions*/}
                                            <div className="mb-10">

                                                <ModalCustomerSelect selectedCustomer={r => {
                                                    setSelectedCustomer(r);
                                                    GetCustomerDogs(JSON.parse(r).ek_customer_ID);
                                                    setModalSelectIsShow(false);


                                                }} isShow={modalSelectIsShow} handler={InsertModalHandler} isChange={1} />

                                                <ModalCustomerInsert isShow={modalInsertIsShow} HandlerClose={() => { setModalInsertIsShow(false); setModalSelectIsShow(true) }} isSale={true} />


                                                <button onClick={() => { setSelectedCustomer(null); setCustomerDogs(null); setDogType(-1); }} className="btn btn-sm btn-danger font-weight-bolder text-uppercase mr-2">Kaldır</button>
                                            </div>
                                            {/*end::Actions*/}
                                        </div>
                                        {/*end::Title*/}
                                        {/*begin::Content*/}
                                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                                            {/*begin::Description*/}
                                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">{JSON.parse(selectedCustomer).ek_customer_address}</div>
                                            {/*end::Description*/}
                                            {/*begin::Progress*/}
                                            {/*end::Progress*/}
                                        </div>
                                        {/*end::Content*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                            </div>
                        </div>

                    }

                    {/* DOLU ROYALDOG BİLGİLERİ */}
                    {
                        selectedRoyalDog == null ? "" : <div className="card card-custom gutter-b">
                            <div className="card-body">
                                <div className="d-flex">
                                    {/*begin::Pic*/}
                                    <div className="flex-shrink-0 mr-7">
                                        <div className="symbol symbol-50 symbol-lg-120">
                                            {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                            <img src={JSON.parse(selectedRoyalDog).ek_royal_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + JSON.parse(selectedRoyalDog).ek_royal_dog_image} alt="" className="h-50" />

                                        </div>
                                    </div>
                                    {/*end::Pic*/}
                                    {/*begin: Info*/}
                                    <div className="flex-grow-1">
                                        {/*begin::Title*/}
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            {/*begin::User*/}
                                            <div className="mr-3">
                                                <div className="d-flex align-items-center mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{JSON.parse(selectedRoyalDog).ek_royal_dog_name}</a>
                                                    {/*end::Name*/}
                                                </div>
                                                {/*begin::Contacts*/}
                                                <div className="d-flex flex-wrap my-2">
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedRoyalDog).ek_royal_dog_gender}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedRoyalDog).dog_age}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <mask fill="white">
                                                                        <use xlinkHref="#path-1" />
                                                                    </mask>
                                                                    <g />
                                                                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedRoyalDog).ek_dog_breed_name}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedRoyalDog).ek_dog_sub_breed_name}</a>
                                                </div>
                                                {/*end::Contacts*/}
                                            </div>
                                            {/*begin::User*/}
                                            {/*begin::Actions*/}
                                            <div className="mb-10">

                                                <ModalRoyalDogSelect selectedRoyalDog={r => {
                                                    setSelectedRoyalDog(r);
                                                    setModalRoyalDogSelect(false);

                                                }} isShow={modalRoyalDogSelect} handler={InsertModalHandler2} isChange={1} />

                                                <button onClick={() => { setSelectedRoyalDog(null); setDogType(-1); }} className="btn btn-sm btn-danger font-weight-bolder text-uppercase mr-2">Kaldır</button>
                                            </div>
                                            {/*end::Actions*/}
                                        </div>
                                        {/*end::Title*/}
                                        {/*begin::Content*/}
                                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                                            {/*begin::Description*/}
                                            <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">Alış Fiyatı:</div>
                                            <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">{JSON.parse(selectedRoyalDog).ek_royal_dog_purchase_price}</div>
                                            {/*end::Description*/}
                                            {/*begin::Progress*/}
                                            {/*end::Progress*/}
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                                            {/*begin::Description*/}
                                            <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">Satış Fiyatı:</div>
                                            <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">{JSON.parse(selectedRoyalDog).ek_royal_dog_sale_price}</div>
                                            {/*end::Description*/}
                                            {/*begin::Progress*/}
                                            {/*end::Progress*/}
                                        </div>
                                        {/*end::Content*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                            </div>
                        </div>

                    }

                    {/* DOLU MÜŞTERİ KÖPEĞİ BİLGİLERİ */}
                    {
                        selectedCustomerDog == null ? "" : <div className="card card-custom gutter-b">
                            <div className="card-body">
                                <div className="d-flex">
                                    {/*begin::Pic*/}
                                    <div className="flex-shrink-0 mr-7">
                                        <div className="symbol symbol-50 symbol-lg-120">
                                            {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                            <img src={JSON.parse(selectedCustomerDog).ek_customer_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + JSON.parse(selectedCustomerDog).ek_customer_dog_image} alt="" className="h-50" />

                                        </div>
                                    </div>
                                    {/*end::Pic*/}
                                    {/*begin: Info*/}
                                    <div className="flex-grow-1">
                                        {/*begin::Title*/}
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            {/*begin::User*/}
                                            <div className="mr-3">
                                                <div className="d-flex align-items-center mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{JSON.parse(selectedCustomerDog).ek_customer_dog_name}</a>
                                                    {/*end::Name*/}
                                                </div>
                                                {/*begin::Contacts*/}
                                                <div className="d-flex flex-wrap my-2">
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedCustomerDog).ek_customer_dog_gender}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedCustomerDog).ek_customer_dog_birthday}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <mask fill="white">
                                                                        <use xlinkHref="#path-1" />
                                                                    </mask>
                                                                    <g />
                                                                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedCustomerDog).ek_dog_breed_name}</a>
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{JSON.parse(selectedCustomerDog).ek_dog_sub_breed_name}</a>
                                                </div>
                                                {/*end::Contacts*/}
                                            </div>
                                            {/*begin::User*/}
                                            {/*begin::Actions*/}
                                            <div className="mb-10">

                                                {/* <ModalRoyalDogSelect selectedRoyalDog={r => {
                                                setSelectedRoyalDog(r);
                                                setModalRoyalDogSelect(false);

                                            }} isShow={modalRoyalDogSelect} handler={InsertModalHandler2} isChange={1} />

                                            <button onClick={() => { setSelectedRoyalDog(null); setDogType(-1); }} className="btn btn-sm btn-danger font-weight-bolder text-uppercase mr-2">Kaldır</button> */}
                                            </div>
                                            {/*end::Actions*/}
                                        </div>
                                        {/*end::Title*/}
                                        {/*begin::Content*/}
                                        {/* <div className="d-flex align-items-left justify-content-between mb-2">
                                        <span className="font-weight-bolder">Toplam Eğitim:</span>

                                        <span className="font-weight-bolder text-dark text-right">{JSON.parse(selectedCustomerDog).total_education_percentage}%</span>
                                        <div className="w-xl-150px mt-sm-0">

                                            <div className="progress progress-xs mt-2 mb-2">
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '' + JSON.parse(selectedCustomerDog).total_education_percentage + '%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                                            {/*begin::Description*/}
                                            {/* <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">Alış Fiyatı:</div>
                                        <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">{JSON.parse(selectedRoyalDog).ek_royal_dog_purchase_price}</div> */}
                                            {/*end::Description*/}
                                            {/*begin::Progress*/}
                                            {/*end::Progress*/}
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                                            {/*begin::Description*/}
                                            {/* <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">Satış Fiyatı:</div>
                                        <div className="flex-grow-1 font-weight-bold text-dark py-2 py-lg-2 mr-5">{JSON.parse(selectedRoyalDog).ek_royal_dog_sale_price}</div> */}
                                            {/*end::Description*/}
                                            {/*begin::Progress*/}
                                            {/*end::Progress*/}
                                        </div>
                                        {/*end::Content*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                            </div>
                        </div>

                    }

                    {/*begin::Advance Table: Widget 7*/}
                    <div className="card card-custom gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-5 align-items-center ">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">Geçmiş İşlemler</span>
                            </h3>
                            {/* <div className="card-toolbar">
                            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                                <li className="nav-item">
                                    <a className="nav-link py-2 px-4" data-toggle="tab" href="#kt_tab_pane_12_1">Month</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link py-2 px-4" data-toggle="tab" href="#kt_tab_pane_12_2">Week</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link py-2 px-4 active" data-toggle="tab" href="#kt_tab_pane_12_3">Day</a>
                                </li>
                            </ul>
                        </div> */}
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body pt-2 pb-0 mt-n3">
                            <div className="tab-content mt-5" id="myTabTables12">
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade show active" id="kt_tab_pane_12_3" role="tabpanel" aria-labelledby="kt_tab_pane_12_3">
                                    {/*begin::Table*/}
                                    <div className="table-responsive">
                                        <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                                            {
                                                lastProcess == undefined ? "" : lastProcess.length == 0 ? NullPage() : <thead>
                                                    <tr className="text-uppercase">
                                                        <th style={{ minWidth: '150px' }}>
                                                            <span className="text-primary">Giriş</span>
                                                            <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                                {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg*/}
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                        <rect fill="#000000" opacity="0.3" x={11} y={4} width={2} height={10} rx={1} />
                                                                        <path d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999)" />
                                                                    </g>
                                                                </svg>
                                                                {/*end::Svg Icon*/}
                                                            </span>
                                                        </th>
                                                        <th style={{ minWidth: '150px' }}>
                                                            <span className="text-primary">Çıkış</span>
                                                            <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                                {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg*/}
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                        <rect fill="#000000" opacity="0.3" x={11} y={4} width={2} height={10} rx={1} />
                                                                        <path d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999)" />
                                                                    </g>
                                                                </svg>
                                                                {/*end::Svg Icon*/}
                                                            </span>
                                                        </th>
                                                        <th style={{ minWidth: '130px' }}>Müşteri</th>
                                                        <th style={{ minWidth: '130px' }}>Köpek</th>
                                                        <th style={{ minWidth: '130px' }}>Sebep</th>
                                                    </tr>
                                                </thead>
                                            }

                                            <tbody>
                                                {
                                                    lastProcess == undefined ? "" : lastProcess.length == 0 ? "" : lastProcess.map(item => {
                                                        return LastProcessItemList(item)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/*end::Table*/}
                                </div>
                                {/*end::Tap pane*/}
                            </div>
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::Advance Table Widget 7*/}
                    {/*begin::Row*/}
                    {/*end::Row*/}
                </div>
                {/*end::Content*/}
            </div>
        </AuthorityControl>

    </>
}