import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import Ekmekk from '../../Ekmekk/Helper/ekmekk'


export default function ModalSaleInsert(props) {


    var inputAmount = useRef(null);
    var salePrice = useRef(null);
    var inputDogID = useRef(null);
    var inputStockID = useRef(null);

    const [stockList, setStockList] = useState([]);
    const [selectedStock, setSelectedStock] = useState([]);

    function SaleInsertClick() {


        // props.item.type != 2 ? props.purchasePrice : props.item.stok_info.length > 0 && selectedStock != undefined ? selectedStock.ek_product_purchase_price : ""

        var purchasePrice = 0;

        if (props.item.type != 2) {
            console.log("1-" + props.purchasePrice + " / " + salePrice.current.value);
            purchasePrice = props.purchasePrice;
        }
        else if (props.item.stok_info.length > 0 && selectedStock != undefined) {
            console.log("2-" + selectedStock.ek_product_purchase_price + " / " + salePrice.current.value);
            purchasePrice = selectedStock.ek_product_purchase_price;
        }
        else {
            console.log("3" + " / " + salePrice.current.value);
            purchasePrice = 0;
        }

        var dataBlock = {
            sale_product_ID: props.ID, // FİŞ İÇİN ID, POSTTA YOK

            piece: inputAmount.current.value, // KAÇ TANE ALDIĞI, FİŞ  İÇİN, POST VAR
            sale_product_name: props.productName, // SATIN ALINAN ŞEYİN ADI, FİŞ İÇİN, POST YOK
            sale_product_price: salePrice.current.value, // SATIN ALINAN ŞEYİN FİYATI, FİŞ İÇİN POST YOK

            // POST İÇİN TANIMLAMALAR (SaleComplete Modalına data olarak yollanıyor)
            ek_customer_dog_ID: inputDogID.current != null ? inputDogID.current.value : "",
            type: props.item.type,

            ek_education_ID: props.item.ek_education_ID,
            ek_education_price: salePrice.current.value,

            ek_service_ID: props.item.ek_service_ID,
            ek_service_sale_price: salePrice.current.value,
            ek_service_purchase_price: props.item.ek_service_purchase_price,

            ek_product_ID: props.item.ek_product_ID,
            ek_product_sale_price: salePrice.current.value,
            // ek_product_purchase_price: selectedStock.ek_product_purchase_price,
            // ek_product_stock_ID: inputStockID.current.value,

            ek_royal_dog_ID: props.item.ek_royal_dog_ID,
            ek_royal_dog_sale_price: salePrice.current.value,
            ek_royal_dog_purchase_price: props.item.ek_royal_dog_purchase_price,
        }


        if (selectedStock == null) {
            dataBlock["ek_product_purchase_price"] = 0;
        }
        else {
            dataBlock["ek_product_purchase_price"] = selectedStock.ek_product_purchase_price;
        }

        if (inputStockID == null || inputStockID.current == null) {
            dataBlock["ek_product_stock_ID"] = -1;
        }
        else {
            dataBlock["ek_product_stock_ID"] = inputStockID.current.value;
        }


        var validator = new ValidationController();

        if (validator.Control() == 0) {

            // SATIŞ FİYATI ALIŞ FİYATINDAN DÜŞÜK MÜ ?
            if (salePrice.current.value < purchasePrice) {
                Ekmekk().Alert.FailSalePrice();
            }
            else {
                setShow(false);
                props.insertClick(dataBlock);
            }


        }





    }

    function CustomerDogItemList(item) {
        return <option value={item.ek_customer_dog_ID}>{item.ek_customer_dog_name}</option>
    }

    function StockItemList(item) {
        return <option value={item.ek_product_stock_ID}>Alış - {item.ek_product_purchase_price} | Satış - {item.ek_product_sale_price} | Stok - {item.ek_product_stock}</option>
    }


    const [show, setShow] = useState(false);

    return <>

        <button type="button" onClick={() => {
            // console.log(JSON.parse(props.item.stok_info));

            if (props.item.stok_info != undefined) {
                setStockList(JSON.parse(props.item.stok_info));

                if (props.item.stok_info.length > 0)
                    setSelectedStock(JSON.parse(props.item.stok_info)[0]);
                else
                    setSelectedStock(null);

            }
            setShow(true)
        }} className="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4" data-toggle="modal" data-target="#exampleModalCenter">
            Ekle
        </button>

        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Seçimi Tamamla
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Adet: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputAmount} className="form-control form-control-lg" id="campain_name2" placeholder="1" type="number" defaultValue="1" />
                            </div>
                        </div>
                        {
                            props.item.type == 2 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Stok Seçimi: </label>

                                <div className="col-lg-9 col-xl-9 col-md-9 col-sm-12">

                                    <select ref={inputStockID} onChange={(e) => {

                                        for (let i = 0; i < stockList.length; i++) {

                                            if (stockList[i].ek_product_stock_ID == e.target.value) {
                                                setSelectedStock(stockList[i]);
                                                console.log(stockList[i]);
                                            }
                                        }
                                    }} className="form-control selectpicker">
                                        <option value="-1">Lütfen Bir Seçim Yapınız</option>

                                        {
                                            JSON.parse(props.item.stok_info) != null ? JSON.parse(props.item.stok_info).map(item => {
                                                return StockItemList(item)
                                            }) : ""
                                        }
                                    </select>

                                    {/* <button type="button" className="mt-2 btn btn-primary font-weight-bold">Köpek Ekle</button> */}
                                </div>
                            </div> : "" //console.log(JSON.parse(props.item.stok_info));
                        }
                        {
                            props.item.type != 0 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Alış Fiyatı: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <input className="form-control form-control-lg" id="campain_name2" disabled="disabled" placeholder={props.item.type != 2 ? props.purchasePrice : props.item.stok_info.length > 0 && selectedStock != undefined ? selectedStock.ek_product_purchase_price : ""} type="number" />
                                </div>
                            </div> : ""
                        }
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyatı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={salePrice} className="form-control form-control-lg" id="campain_name2" placeholder={props.item.type != 2 ? props.productPrice : props.item.stok_info.length > 0 && selectedStock != undefined ? selectedStock.ek_product_sale_price : ""} type="number" defaultValue={props.item.type != 2 ? props.productPrice : props.item.stok_info.length > 0 && selectedStock != undefined ? selectedStock.ek_product_sale_price : ""} />
                            </div>
                        </div>
                        {
                            props.item.type == 0 || props.item.type == 1 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Köpek Seçimi: </label>

                                <div className="col-lg-9 col-xl-9 col-md-9 col-sm-12">
                                    <select ref={inputDogID} className="form-control selectpicker">
                                        <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                        {
                                            props.customerDogs != null ? props.customerDogs.map(item => {
                                                return CustomerDogItemList(item)
                                            }) : ""
                                        }
                                    </select>
                                    {/* <button type="button" className="mt-2 btn btn-primary font-weight-bold">Köpek Ekle</button> */}
                                </div>
                            </div> : ""
                        }
                    </Validator>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal" onClick={() => {
                        setShow(false)
                    }}>
                        Close</button>
                    <button type="button" className="btn btn-primary font-weight-bold" onClick={SaleInsertClick}>Ekle</button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}