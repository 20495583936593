import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import DatePicker from "react-datepicker"


export default function ModalDogInsertToCage(props) {


    const [processType, setProcessType] = useState(-1);

    const [cost, setCost] = useState(-1);
    const [preDebt, setPreDebt] = useState(-1);
    const [totalCost, setTotalCost] = useState(-1);
    


    const [kalanTutar, setKalanTutar] = useState(0)

    var inputAmount = useRef(null);
    var inputProcessType = useRef(null);
    var inputDogID = useRef(null);
    var inputSalePrice = useRef(null);
    var odenenTutar = useRef(null);

    function DogInsertToCageClick() {
        var today = calendarDate, date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

        console.log(date);

        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID
        var validator = new ValidationController();

        if (validator.Control() == 0) {

            if (props.isCageFull == 0) {

                var dataBlock = {
                    ek_cage_ID: props.cageID,
                    // ek_dog_ID: inputDogID.current,
                    ek_entry_date: date,
                    // ek_exit_date: 2,
                    ek_dog_type: props.dogType,
                    ek_process_ID: 1,                                   // NE ATTIĞIN FARK ETMEZ AMA AT
                    // ek_process_type: inputProcessType.current.value,

                }

                // EĞER HERHANGİ BİR KÖPEK SEÇİLMEMİŞSE
                if (dataBlock.ek_dog_type == -1) {
                    Ekmekk().Alert.Fail();
                    console.log(dataBlock);
                }
                else {

                    // MÜŞTERİ KÖPEĞİYSE
                    if (dataBlock.ek_dog_type == 1) {
                        dataBlock["ek_dog_ID"] = inputDogID.current.value;
                        dataBlock["ek_process_type"] = inputProcessType.current.value;

                    }
                    // ŞİRKET KÖPEĞİYSE
                    else {
                        dataBlock["ek_dog_ID"] = JSON.parse(props.royalDog).ek_royal_dog_ID;
                        dataBlock["ek_process_ID"] = -1
                        dataBlock["ek_process_type"] = -1

                    }


                    // KONAKLAMA SATIŞI YAPILDIYSA
                    if (dataBlock.ek_process_type == 0) {
                        dataBlock["ek_cage_daily_price"] = inputSalePrice.current.value;
                    }
                    else {
                        dataBlock["ek_cage_daily_price"] = 0;
                    }

                    console.log(dataBlock);

                    // KAFESİ DOLDUR
                    DataCenter().Cage.InsertDogToCage(dataBlock).then(r => {

                        if (r.status) {
                            Ekmekk().Alert.Success();
                            setShow(false);
                            dataBlock["ek_cage_dog_ID"] = r.data[0].ek_cage_dog_ID;
                            dataBlock["dog_name"] = r.data[0].dog_name;
                            dataBlock["ek_customer_fullname"] = r.data[0].ek_customer_fullname;
                            dataBlock["type"] = r.data[0].type;

                            props.insertClick("inserted", dataBlock);
                        }
                        else {
                            Ekmekk().Alert.Fail();
                        }
                    })


                    console.log(dataBlock);


                }
            }
            else {

                // KAFES DOLU BOŞALT

                var dataBlock2 = {
                    ek_cage_dog_ID: props.lastProcess[0].ek_cage_dog_ID,
                    ek_exit_date: date,

                }

                // EĞER KONAKLAMAYI BOŞALTIYORSAK
                if (props.lastProcess[0].process_type_ID == 0) {

                    dataBlock2["customerID"] = props.lastProcess[0].ek_customer_ID;
                    dataBlock2["ek_customer_dog_ID"] = props.lastProcess[0].ek_customer_dog_ID;
                    dataBlock2["ek_selling_paid_price"] = totalCost;

                    Ekmekk().Alert.SureKonaklama((e) => {

                        if (e) {

                            // EĞER ÖNCEDEN BORÇ VARSA
                            if (preDebt > 0) {

                                DataCenter().Sale.PayAllDebt(props.lastProcess[0].ek_customer_ID, cost, props.lastProcess[0].ek_customer_dog_ID).then(r => {

                                    if (r.status) {

                                        console.log("Tüm Borçlar Ödendi");

                                        // KAFES GÜNCELLEME (BOŞALTMA)
                                        DataCenter().Cage.UpdateDogToCage(dataBlock2).then(r => {

                                            if (r.status) {
                                                console.log("Kafes Boşaltıldı");

                                                Ekmekk().Alert.Success();
                                                setShow(false);

                                                props.insertClick("updated", dataBlock2);
                                            }
                                            else {
                                                Ekmekk().Alert.Fail();
                                            }
                                        })

                                    }
                                    else {
                                        Ekmekk().Alert.Fail();
                                    }

                                })

                            }
                            else {

                                dataBlock2["creationDate"] = dataBlock2.ek_exit_date;
                                // KONAKLAMA SATIŞI
                                DataCenter().Sale.HousingSale(dataBlock2).then(r => {

                                    if (r.status) {

                                        console.log(dataBlock2);
                                        console.log("Konaklama Satışı Yapıldı");

                                        // KAFES GÜNCELLEME (BOŞALTMA)
                                        DataCenter().Cage.UpdateDogToCage(dataBlock2).then(r => {

                                            if (r.status) {
                                                console.log("Kafes Boşaltıldı");

                                                Ekmekk().Alert.Success();
                                                setShow(false);

                                                props.insertClick("updated", dataBlock2);
                                            }
                                            else {
                                                Ekmekk().Alert.Fail();
                                            }
                                        })

                                    }
                                    else {
                                        Ekmekk().Alert.Fail();
                                    }

                                })

                            }


                        }
                        else {
                            Ekmekk().Alert.Fail();
                        }

                    }, totalCost)

                }
                else {
                    console.log(dataBlock2);

                    // KAFES GÜNCELLEME (BOŞALTMA)
                    DataCenter().Cage.UpdateDogToCage(dataBlock2).then(r => {

                        if (r.status) {
                            Ekmekk().Alert.Success();
                            setShow(false);

                            props.insertClick("updated", dataBlock2);
                        }
                        else {
                            Ekmekk().Alert.Fail();
                        }
                    })
                }


            }

        }

    }

    // ----------- DOLULUK ------------

    const process = [{
        name: "Eğitim",
        id: 1
    },
    {
        name: "Konaklama",
        id: 0
    }
    ];

    function ProcessItem(item) {
        return <option value={item.id} >{item.name}</option>
    }



    function CustomerDogItemList(item) {
        // console.log(item.activeCage);
        if (item.activeCage == "")
            return <option value={item.ek_customer_dog_ID}>{item.ek_customer_dog_name}</option>
    }


    // ------------- TARİH --------------

    const [calendarDate, setCalendarDate] = useState(new Date());
    // const [birthDate, setBirthDate] = useState(null);



    const [show, setShow] = useState(false);


    function CheckData() {

        if (props.isCageFull == 0 && props.customerDogs == null && props.royalDog == null) {
            Ekmekk().Alert.FillAll();
            // console.log(props.isCageFull);
            // console.log(props.customerDogs);
            // console.log(props.royalDog);
        }
        else {

            // EĞER KONAKLAMAYI BOŞALTIYORSAK
            if (props.isCageFull == 1 && props.lastProcess[0].process_type_ID == 0) {

                var dataBlock2 = {
                    ek_cage_daily_price: props.lastProcess[0].ek_cage_daily_price,
                    ek_entry_date: props.lastProcess[0].ek_entry_date,
                    ek_customer_ID: props.lastProcess[0].ek_customer_ID,

                }


                var firstHalf = dataBlock2.ek_entry_date.split(" ");
                console.log(firstHalf);
                var dates = firstHalf[0].split("/");
                console.log(dates);

                var theDate = dates[2] + "-" + dates[1] + "-" + dates[0] + " " + firstHalf[1];
                console.log(theDate);

                dataBlock2["ek_entry_date"] = theDate;

                DataCenter().Sale.CalculatePrice(dataBlock2).then(r => {
                    if (r.status) {

                        setCost(r.data.cost);
                        setPreDebt(r.data.debt);
                        setTotalCost(r.data.total_cost);

                    }

                })

            }


            setShow(true);


        }

    }

    return <>

        {
            props.isCageFull == 0 ? <button type="button" onClick={() => {
                CheckData();
                console.log(props);
            }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
                Doldur
            </button> : <button type="button" onClick={() => {
                CheckData();
                setShow(true);
                console.log(props);
            }} className="btn btn-sm btn-block btn-light-danger font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
                Boşalt
            </button>
        }

        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Seçimi Tamamla
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    {/* <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Adet: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={inputAmount} className="form-control form-control-lg" id="campain_name2" placeholder="1" type="number" defaultValue="1" />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyatı: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={salePrice} className="form-control form-control-lg" id="campain_name2" placeholder={props.productPrice} type="number" defaultValue={props.productPrice} />
                        </div>
                    </div> */}

                    <Validator>
                        {
                            props.customerDogs != null && props.isCageFull == 0 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Köpek Seçimi: </label>

                                <div className="col-lg-9 col-xl-9 col-md-9 col-sm-12">
                                    <select ref={inputDogID} className="form-control selectpicker">
                                        <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                        {
                                            props.customerDogs != null ? props.customerDogs.map(item => {
                                                return CustomerDogItemList(item)
                                            }) : ""
                                        }
                                    </select>
                                    {/* <button type="button" className="mt-2 btn btn-primary font-weight-bold">Köpek Ekle</button> */}
                                </div>
                            </div> : ""
                        }

                        {
                            props.isCageFull == 0 && props.dogType != 0 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Doluluk Sebebi: </label>

                                <div className="col-lg-9 col-xl-9 col-md-9 col-sm-12">

                                    <select ref={inputProcessType} onChange={(e) => { setProcessType(e.target.value); console.log(e.target.value); }} className="form-control selectpicker">
                                        <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                        {
                                            process.map(item => {
                                                return ProcessItem(item)
                                            })
                                        }
                                    </select>
                                    {/* <button type="button" className="mt-2 btn btn-primary font-weight-bold">Köpek Ekle</button> */}
                                </div>
                            </div> : ""
                        }
                        {
                            processType != null && processType == 0 && props.dogType != 0 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Günlük Ücret: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <input data-validation-rule="notNull|onlyNumber" ref={inputSalePrice} className="form-control form-control-lg form-control-solid" name="phone" placeholder="Fiyat" type="number" defaultValue={props.cageData != undefined ? props.cageData.ek_cage_price : ""} />
                                </div>
                            </div> : ""
                        }

                        {
                            props.isCageFull == 0 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Giriş Tarihi: </label>

                                <div className="col-lg-9 col-xl-9">

                                    <DatePicker
                                        selected={calendarDate}
                                        // @ts-ignore
                                        onChange={date => setCalendarDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput

                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="form-control form-control-lg form-control-solid w-100"
                                    />

                                </div>

                            </div> : <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Çıkış Tarihi: </label>

                                <div className="col-lg-9 col-xl-9">

                                    <DatePicker
                                        selected={calendarDate}
                                        // @ts-ignore
                                        onChange={date => setCalendarDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput

                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="form-control form-control-lg form-control-solid w-100"
                                    />

                                </div>

                            </div>
                        }

                        {
                            cost == undefined || cost == null || cost == -1 ? "" : <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Kafes Tutarı: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <input className="form-control form-control-lg" id="campain_name2" disabled="disabled" placeholder={cost} type="number" defaultValue />
                                </div>
                            </div>
                        }

                        {
                            preDebt == undefined || preDebt == null || preDebt == -1 ? "" : <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Önceki Borç: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <input className="form-control form-control-lg" id="campain_name2" disabled="disabled" placeholder={preDebt} type="number" defaultValue />
                                </div>
                            </div>
                        }

                        {
                            totalCost == undefined || totalCost == null || totalCost == -1 ? "" : <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Ödenecek Tutar: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <input className="form-control form-control-lg" id="campain_name2" disabled="disabled" placeholder={totalCost} type="number" defaultValue />
                                </div>
                            </div>
                        }




                    </Validator>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal" onClick={() => {
                        setShow(false)
                    }}>
                        Close</button>
                    <button type="button" className="btn btn-primary font-weight-bold" onClick={DogInsertToCageClick}>Tamamla</button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}