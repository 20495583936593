import axios from "axios"
import React, { useEffect, useState } from "react"

import "../Thema/css/referance.css"
export default function Referance() {

    const ref = React.createRef();

    function GetProjects(callback) {
        var IDList = [
            1542507802,
            1546702214,
            1549177702,
            1543139977,
            1554788397,
            1536438777,
            1542928662,
            1540720184,
            1532368209,
            1514935048,
            1523552425,
            1510251207,
            1510251892,
            1515420390,
            1537389497,
            1510251776,
            1529744894,
        ]
        var counter = 0;
        var responseList = [];
        for (var i = 0; i < IDList.length; i++) {
            axios.get('https://itunes.apple.com/lookup?id=' + IDList[i] + '&lang=en')
                .then(function (response) {
                    counter++;
                    response.data.results[0].screenshotUrls = response.data.results[0].screenshotUrls.splice(0,response.data.results[0].screenshotUrls.length>6 ? 6 : response.data.results[0].screenshotUrls.length);
                    responseList.push({
                        logo: response.data.results[0].artworkUrl512,
                        projectName: response.data.results[0].trackCensoredName,
                        appStoreURL: response.data.results[0].trackViewUrl,
                        ss: response.data.results[0].screenshotUrls,
                        //bashData : response.data



                    })
                    if (counter == IDList.length) {
                        callback(responseList);

                    }

                });
        }

    }

    const [projectList, setProjectsList] = useState([]);
    useEffect(() => {
        GetProjects((r) => {
            setProjectsList(r);
        })
    }, []);


    function Row(item) {
        return <div className="row mt-10">
            <div className="col-xl-2 text-center p-10" style={{ width: "20%" }}>
                <div style={{ height: "60%", width: "50%", marginLeft: "25%", display: "flex", "alignItems": "center", flexWrap: "wrap", textAlign: "center" }}>
                    <img src={item.logo} className="img-fluid shadow" style={{ borderRadius: 20, width: "100%" }} />
                </div>

                <div style={{ height: "20%" }}>
                    {item.projectName}
                    <a className="btn btn-block denemes" href={item.appStoreURL}>App Store</a>
                </div>
            </div>

            <div className="col-xl-10" style={{ width: "80%" }}>


                {
                    item.ss.map(screenshot => {
                        return <img src={screenshot} className="ml-2 shadow" style={{ borderRadius: 20, maxHeight: 200 }} />
                    })
                }


            </div>
        </div>

    }
    return <div>

        <div>
        {
                    projectList.length != 0 ? projectList.map(item => Row(item)) : ""
                }
        </div>




    </div>


}