
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'


import ModalProductInsert from "../../Modal/Product/ProductInsert"
import SubHeader from '../../Thema/sub-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import NullPage from '../../Ekmekk/Views/NullPage'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'



export default function CaseDetail() {

    var inputCategorySearch = useRef(null)

    const [categoryList, setCategoryList] = useState([])

    const [list, setList] = useState([]);
    const [mainList, setMainList] = useState([]);

    const [tableType, setTableType] = useState(0)
    const { ek_cash_box_ID } = useParams();



    var history = useHistory();
    useEffect(() => {

        DataCenter().Case.ShowIncomeExpenseDetail(ek_cash_box_ID).then(r => {

            setTableType(0)

            if (r.status) {

                var bashList = [];

                if (r.data.product != undefined) {
                    for (let i = 0; i < r.data.product.length; i++) {

                        bashList.push(r.data.product[i]);
                    }
                }
                if (r.data.education != undefined) {
                    for (let i = 0; i < r.data.education.length; i++) {

                        bashList.push(r.data.education[i]);
                    }
                }
                if (r.data.service != undefined) {
                    for (let i = 0; i < r.data.service.length; i++) {


                        bashList.push(r.data.service[i]);
                    }
                }
                if (r.data.dog != undefined) {
                    for (let i = 0; i < r.data.dog.length; i++) {

                        bashList.push(r.data.dog[i]);
                    }
                }
                if (r.data.product_purchase != undefined) {
                    setTableType(1)
                    for (let i = 0; i < r.data.product_purchase.length; i++) {

                        bashList.push(r.data.product_purchase[i]);
                    }
                }

                if (r.data.dog_purchase != undefined) {
                    setTableType(2)
                    for (let i = 0; i < r.data.dog_purchase.length; i++) {

                        bashList.push(r.data.dog_purchase[i]);
                    }

                }

                if (r.data.debt != undefined) {
                    setTableType(3)
                    for (let i = 0; i < r.data.debt.length; i++) {

                        bashList.push(r.data.debt[i]);
                    }

                }



                for (let i = 0; i < bashList.length; i++) {

                    if (bashList[i].ek_product_name != undefined || bashList[i].ek_product_name != null) {
                        bashList[i]["name"] = bashList[i].ek_product_name;
                        bashList[i]["piece"] = bashList[i].ek_product_selling_piece;
                        bashList[i]["purchase"] = bashList[i].ek_product_purchase_price;
                        bashList[i]["sale"] = bashList[i].ek_product_sale_price;
                        bashList[i]["type"] = "Ürün";
                    }


                    else if (bashList[i].ek_education_name != undefined) {
                        bashList[i]["name"] = bashList[i].ek_education_name;
                        bashList[i]["piece"] = bashList[i].ek_education_selling_piece;
                        bashList[i]["purchase"] = "0";
                        bashList[i]["sale"] = bashList[i].ek_education_selling_price;
                        bashList[i]["type"] = "Eğitim";

                    }


                    else if (bashList[i].ek_service_name != undefined || bashList[i].ek_service_name != null) {
                        bashList[i]["name"] = bashList[i].ek_service_name;
                        bashList[i]["piece"] = bashList[i].ek_service_selling_piece;
                        bashList[i]["purchase"] = bashList[i].ek_service_purchase_price;
                        bashList[i]["sale"] = bashList[i].ek_service_selling_cost;
                        bashList[i]["type"] = "Hizmet";

                    }

                    else if (bashList[i].ek_royal_dog_name != undefined || bashList[i].ek_royal_dog_name != null) {
                        bashList[i]["name"] = bashList[i].ek_royal_dog_name;
                        bashList[i]["piece"] = 1;
                        bashList[i]["purchase"] = bashList[i].ek_royal_dog_purchase_price;
                        bashList[i]["sale"] = bashList[i].ek_royal_dog_sale_price;
                        bashList[i]["type"] = "RoyalDog";
                    }


                }

                console.log(bashList);

                setList(bashList);
                setMainList(bashList);

            }
            else {
                Ekmekk().Alert.Fail();
            }

        })

    }, [])


    function ProductInsertClick(caseData, data) {

        if (caseData == "inserted") {
            var today = new Date(),

                date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();


            data["ek_product_creation_date"] = date;

            // alert(JSON.stringify(data));
            list.push(data);
            setList([...list])
        }
        else if (caseData == "updated") {
            for (var i = 0; i < list.length; i++) {
                if (list[i].ek_product_ID == data.ek_product_ID) {
                    if (data.ek_product_image == null) {
                        var temp = list[i].ek_product_image;
                    }
                    else {
                        var temp = data.ek_product_image
                    }
                    var tempDate = list[i].ek_product_creation_date;

                    list[i] = data;

                    list[i].ek_product_image = temp;
                    list[i].ek_product_creation_date = tempDate;
                }
            }

            setList([...list]);
        }
    }


    function ProductDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Product.DeleteProduct(item.ek_product_ID).then(r => {
                    if (r.status) {
                        var list2 = [...list];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_product_ID == item.ek_product_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setList(list2);
                    }
                })

            }
        })


    }

    function CategoryItem(item) {
        return <option value={item.ek_product_category_name}>{item.ek_product_category_name}</option>
    }

    function ChangeSearchText(e) {
        var filteredList = mainList.filter(item => item.name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setList(filteredList);

    }

    function ChangeBrandText(e) {
        var filteredList = mainList.filter(item => item.type.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setList(filteredList);

    }

    function ChangeCategoryText(e) {

        if (inputCategorySearch.current.value == 0) {
            setList(mainList);
        }
        else {
            var filteredList = mainList.filter(item => item.ek_product_category_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
            setList(filteredList);
        }

    }


    return <>


        <div className="mb-7">
            <div className="row align-items-center">
                <div className="col-md-5">
                    <div className="input-icon">
                        <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün Adı" id="kt_datatable_search_query" />
                        <span>
                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
                <div className="col-md"></div>
                <div className="col-md-5">
                    <div className="input-icon">
                        <input type="text" onChange={ChangeBrandText} className="form-control" placeholder="Türü" id="kt_datatable_search_query" />
                        <span>
                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
                <div className="col-md"></div>
                {/* <div className="col-md-2">
                    <div className="d-flex align-items-center">
                        <label className="mr-3 mb-0 d-none d-md-block">Kategori:</label>
                        <select ref={inputCategorySearch} onChange={ChangeCategoryText} className="form-control" id="kt_datatable_search_type">
                            <option value={0}>All</option>
                            {
                                categoryList.map(item => {
                                    return CategoryItem(item)
                                })
                            }
                        </select>
                    </div>
                </div> */}
                {/* <div className="col-md"></div> */}
                {/* <div className="col-md-2">
                    <ModalProductInsert isSale={false} insertClick={ProductInsertClick} />
                </div> */}
            </div>
        </div>
        {/*end::Search Form*/}

        <AuthorityControl pageCode={37}>

            {
                list.length != 0 ? TableList(list) : <NullPage />
            }
        </AuthorityControl >

    </>

    function TableList(data) {
        let history = useHistory();

        function OpenStock(id) {
            history.push("/dashboard/definition/products/" + id);
        }

        let columns = [

            /* {
               title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
             },*/
            // {
            //     title: <span className="text">Foto</span>, render: data => <div onClick={() => OpenStock(data.ek_product_ID)} className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">

            //         <img src={data.ek_product_image == null || data.ek_product_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + data.ek_product_image} alt="" className="h-50" />
            //     </div>


            // },
            {
                title: <span className="text">{data.isStock == 1 ? "Marka" : "Müşteri Adı"}</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_customer_fullname}</div>
            },
            {
                title: <span className="text">Türü</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.type}</div>
            },
            {
                title: <span className="text">Ürün Adı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.name}</div>
            },
            {
                title: <span className="text">Alış Fiyatı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.purchase}</div>
            },
            {
                title: <span className="text">Satış Fiyatı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.sale}</div>
            },
            {
                title: <span className="text">Adet</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.piece}</div>
            },
            {
                title: <span className="text">Maliyet</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_selling_cost_price}</div>
            },
            {
                title: <span className="text">Ödenen</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_selling_paid_price}</div>
            },
            // {
            //     title: <span className="text">Alış Fiyatı</span>, render: data => <div onClick={() => OpenStock(data.ek_product_ID)} className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_purchase_price}₺</div>
            // },
            // {
            //     title: <span className="text">Satış Fiyatı</span>, render: data => <div onClick={() => OpenStock(data.ek_product_ID)} className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_sale_price}₺</div>
            // },
            // {
            //     title: <span className="text">Stok</span>, render: data => <div>
            //         <span className="text-info font-weight-bolder d-block font-size-lg">{data.total_stock}</span>
            //     </div>
            // },
            // {
            //     title: <span className="text">Oluşturulma Tarihi</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_creation_date.split(" ")[0]}</div>
            // },
            // {
            //     title: <span className="text">Kategori</span>, render: data => <div> <span className="label-success"/*className={data.label}*/>{data.ek_product_category_name}</span> </div>
            // },
        ]
        if (tableType === 1) {
            columns = [
                {
                    title: <span className="text">Ürün Adı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_name}</div>
                },
                {
                    title: <span className="text">Marka</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_brand_name}</div>
                },
                {
                    title: <span className="text">Alış Fiyatı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_purchase_price}</div>
                },
                {
                    title: <span className="text">Satış Fiyatı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_sale_price}</div>
                },
                {
                    title: <span className="text">Maliyet</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_cash_box_process_price}</div>
                }

            ]
        }
        if (tableType === 2) {
            columns = [
                {
                    title: <span className="text">Adı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_royal_dog_name}</div>
                },
                {
                    title: <span className="text">Türü</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_dog_breed_name}</div>
                },
                {
                    title: <span className="text">Alt Türü</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_dog_sub_breed_name}</div>
                },
                {
                    title: <span className="text">Maliyet</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_cash_box_process_price}</div>
                }

            ]
        }
        if (tableType === 3) {
            columns = [
                {
                    title: <span className="text">İlişkili Kişi</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_customer_fullname}</div>
                },
                {
                    title: <span className="text">Satış Fiyatı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_selling_cost_price}</div>
                },
                {
                    title: <span className="text">Ödenen</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.debt_paid_price}</div>
                },
                {
                    title: <span className="text">Kalan</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.remaining_debt}</div>
                },
                {
                    title: <span className="text">Satış Türü</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.selling_type}</div>
                }

            ]
        }

        const handleRowClick = (event, rowData) => {
            const index = data.indexOf(rowData)

            localStorage.setItem("selectedItem", JSON.stringify(data[index]))


            // history.push("/customer/customer-profile");


            /* if (Ekmekk().Profile.userType == UserType.MASTER) {
               history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
             }
             else {
               history.push("/business/orders/order-detail/" + data[index].user_orderID);
             }*/


        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                    }

                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }

}



