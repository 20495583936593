import HomePage from "../Pages/homepage"

import Satis from "../Pages/Satis/ürün"
import Kopek from '../Pages/Satis/dog'
import Servis from '../Pages/Satis/servis'
import Konaklama from '../Pages/Satis/konaklama'

import Musteri from "../Pages/musteri"

import ProfilMusteri from "../Pages/Profil/profil-musteri"
import ProfilKopek from "../Pages/Profil/profil-kopek"
import UserManagement from "../Pages/UserManagement/UserManagement"
import UserGroup from "../Pages/UserManagement/UserGroup"

import Kategoriler from "../Pages/Stok/kategoriler"
import Urunler from "../Pages/Stok/urunler"
import Stok from "../Pages/Stok/stok"
import Markalar from "../Pages/Stok/markalar"

import Egitimler from "../Pages/Egitim/Egitimler"
import EgitimDetay from "../Pages/Egitim/EgitimDetay"

import Egitmenlerim from "../Pages/RoyalDogClub/egitmenlerim"
import Hizmetler from "../Pages/RoyalDogClub/hizmetlerim"

import Kafeslerim from "../Pages/RoyalDogClub/kafeslerim"
import KafesDetay from "../Pages/RoyalDogClub/kafes-detay"
import Ayarlar from "../Pages/RoyalDogClub/ayarlar"

import RoyalDog from "../Pages/Köpek/royaldog"
import RoyalDogMama from "../Pages/Satis/royaldogMama"
import RoyalDogSatilan from "../Pages/Köpek/royaldog-selled"
import KöpekCinsi from "../Pages/Köpek/dog-breed"
import DogEducationList from "../Pages/Köpek/DogEducationList"
import KöpekAltCinsi from "../Pages/Köpek/dog-sub-breed"
import RoyalDogProfil from '../Pages/Köpek/royaldog-profil'
import Asi from '../Pages/Köpek/dog-vaccine'

import PlainOrganizer from "../Pages/Calendar/PlainOrganizer"
import PlainList from "../Pages/Calendar/PlainList"
import EgitimSatis from "../Pages/Calendar/EgitimSatis"
import VaccineOrganizer from '../Pages/Calendar/AşıPlanı'
import Meslekler from '../Pages/Stok/meslekler'

// --------------------------------------------------------------

import UserHome from "../Pages/Kullanıcı/HomePage/homePageUser"
import UserHotelHistory from "../Pages/Kullanıcı/History/hotelHistoryUser"
import UserSaleHistory from "../Pages/Kullanıcı/History/saleHistoryUser"
import UserLessonList from "../Pages/Kullanıcı/Calendar/lessonListUser"
import UserDogProfile from "../Pages/Kullanıcı/UserDog/userDogProfile"

import "../Thema/css/style.bundle.css"
import "../Thema/css/prismjs.bundle.css"
import "../Thema/css/dark.css"
import "../Thema/css/brandDark.css"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";
import React, { useEffect } from "react"
import Ekmekk from "../Ekmekk/Helper/ekmekk"
import Example from "../Pages/Example"
import Case from "../Pages/Case/case"
import CaseDetail from "../Pages/Case/case-detail"


export default function Body() {


  return <>
    <Switch>


      <Route exact path="/dashboard/home">
        <HomePage />
      </Route>

      {/* Müşteri */}
      <Route exact path="/dashboard/customer/customer-list">
        <Musteri />
      </Route>
      <Route exact path="/dashboard/customer/customer-profile/:id">
        <ProfilMusteri />
      </Route>
      <Route exact path="/dashboard/customer/dog-profile/:id">
        <ProfilKopek />
      </Route>
      <Route exact path="/dashboard/customer/dog-profile/:id/education/:educationID">
        <DogEducationList />
      </Route>


      {/* Satış */}
      <Route exact path="/dashboard/sale/product">
        <Satis />
      </Route>
      <Route exact path="/dashboard/sale/service">
        <Servis />
      </Route>
      <Route exact path="/dashboard/sale/dog">
        <Kopek />
      </Route>
      <Route exact path="/dashboard/sale/education-selling">
        <EgitimSatis />
      </Route>
      <Route exact path="/dashboard/sale/education-selling/cage/:cageID">
        <EgitimSatis />
      </Route>
      <Route exact path="/dashboard/sale/education-selling/:customerID">
        <EgitimSatis />
      </Route>
      <Route exact path="/dashboard/sale/education-selling/:customerID/:dogID">
        <EgitimSatis />
      </Route>
      <Route exact path="/dashboard/sale/hotel">
        <Konaklama />
      </Route>


      {/* Eğitim */}
      <Route exact path="/dashboard/plain/educations">
        <Egitimler />
      </Route>
      <Route exact path="/dashboard/plain/education-detail/:id">
        <EgitimDetay />
      </Route>
      <Route exact path="/dashboard/plain/list">
        <PlainList />
      </Route>
      <Route exact path="/dashboard/plain/organizer">
        <PlainOrganizer />
      </Route>
      <Route exact path="/dashboard/plain/organizer/:customerID">
        <PlainOrganizer />
      </Route>
      <Route exact path="/dashboard/plain/organizer/:customerID/:dogID">
        <PlainOrganizer />
      </Route>
      <Route exact path="/dashboard/plain/educators">
        <Egitmenlerim />
      </Route>


      {/* Kafesler */}
      <Route exact path="/dashboard/cages/cageList">
        <Kafeslerim />
      </Route>
      <Route exact path="/dashboard/cages/cage-detail/:id">
        <KafesDetay />
      </Route>


      {/* Aşı */}
      <Route exact path="/dashboard/vaccine/organizer">
        <VaccineOrganizer />
      </Route>


      {/* Kasa */}
      <Route exact path="/dashboard/company/case">
        <Case />
      </Route>
      <Route exact path="/dashboard/company/case/:ek_cash_box_ID">
        <CaseDetail />
      </Route>


      {/* RoyalDog */}
      <Route exact path="/dashboard/dog/royal-dog/profil/:id">
        <RoyalDogProfil />
      </Route>
      <Route exact path="/dashboard/dog/royal-dog">
        <RoyalDog />
      </Route>
      <Route exact path="/dashboard/dog/royal-dog-selled">
        <RoyalDogSatilan />
      </Route>
      <Route exact path="/dashboard/dog/royal-dog-food">
        <RoyalDogMama />
      </Route>
      <Route exact path="/dashboard/dog/royal-dog-food/:royalDogID">
        <RoyalDogMama />
      </Route>


      {/* Tanımlamalar */}
      <Route exact path="/dashboard/definition/products">
        <Urunler />
      </Route>
      <Route exact path="/dashboard/definition/products/:productID">
        <Stok />
      </Route>
      <Route exact path="/dashboard/definition/services">
        <Hizmetler />
      </Route>
      <Route exact path="/dashboard/definition/categories">
        <Kategoriler />
      </Route>
      <Route exact path="/dashboard/definition/brands">
        <Markalar />
      </Route>
      <Route exact path="/dashboard/definition/jobs">
        <Meslekler />
      </Route>
      <Route exact path="/dashboard/definition/dog-breed">
        <KöpekCinsi />
      </Route>
      <Route exact path="/dashboard/definition/dog-sub-breed/:id">
        <KöpekAltCinsi />
      </Route>
      <Route exact path="/dashboard/definition/vaccineList">
        <Asi />
      </Route>


      {/* Yetki */}
      <Route exact path="/dashboard/user-management">
        <UserGroup />
      </Route>
      <Route exact path="/dashboard/user-management/category">
        <UserGroup />
      </Route>
      <Route exact path="/dashboard/user-management/users">
        <UserManagement />
      </Route>
      <Route exact path="/dashboard/user-management/settings">
        <Ayarlar />
      </Route>


      {/* -------------------------------------------------------------------------------------------------- */}

      <Route exact path="/dashboard/user/home">
        <UserHome />
      </Route>
      <Route exact path="/dashboard/user/history/hotel">
        <UserHotelHistory />
      </Route>
      <Route exact path="/dashboard/user/history/sale">
        <UserSaleHistory />
      </Route>
      <Route exact path="/dashboard/user/calendar/lesson-list">
        <UserLessonList />
      </Route>
      <Route exact path="/dashboard/user/dog-profile/:id">
        <UserDogProfile />
      </Route>



    </Switch>
  </>
}