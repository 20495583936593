
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'

import ModalAdminInsert from "../../Modal/Admin/ModalAdminInsert"
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import SubHeader from '../../Thema/sub-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnet, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import NullPage from '../../Ekmekk/Views/NullPage';
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';


export default function UserManagement() {

    var history = useHistory();

    const [userList, setUserList] = useState([]);
    const [mainUserList, setMainUserList] = useState([]);

    useEffect(() => {
        DataCenter().Auth.ListUser().then(r => {


            setUserList(r);
            setMainUserList(r);

        })

    }, [])

    function CustomerInsertClick(data) {

        // data["cage_info"] = {
        //     "exit_date" : "-",
        //     "full_reason" : "-",
        //     "entry_date" : "-",
        // }
        // data["isCageFull"] = false;

        data["debt"] = "0";
        data["profit"] = "0";
        data["last_process_count"] = "0";
        userList.push(data);
        setUserList([...userList])

    }

    function CustomerDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Customer.DeleteCustomer(item.ek_customer_ID).then(r => {

                    if (r.status) {
                        var list2 = [...userList];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_customer_ID == item.ek_customer_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setUserList(list2);
                    }
                })

            }
        })
    }
    function OnHandler(caseData, newItem) {
        if (caseData == "inserted") {
            userList.push(newItem);
            setUserList([...userList]);
        }
        else if (caseData == "updated") {

            for (var i = 0; i < userList.length; i++) {

                if (userList[i].ek_admin_ID == newItem.ek_admin_ID) {
                    userList[i] = newItem;
                }
            }

            setUserList([...userList]);
        }
    }
    function Remove(id) {
        Ekmekk().Alert.Delete((e) => {
            if (e) {

                for (var i = 0; i < userList.length; i++) {
                    if (userList[i].ek_admin_ID == id) {
                        userList.splice(i, 1);
                    }
                }
                DataCenter().Auth.RemoveUser(id).then(r => {

                    if (r.status) {

                    }
                });
                setUserList([...userList]);

            }
        })


    }

    function ChangeSearchText(e) {
        var filteredList = mainUserList.filter(item => (item.ek_admin_name + " " + item.ek_admin_lastname).toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setUserList(filteredList);
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return "Invalid Number!!";
    }

    return <>

        <AuthorityControl pageCode={79}>


            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-md-10">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Kullanıcının adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch} ></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex">
                            <div className="mr-auto"></div>
                            <AuthorityControl pageCode={80}>
                                <div className=""> <ModalAdminInsert OnHandler={OnHandler} insertClick={CustomerInsertClick} /></div>
                            </AuthorityControl>

                        </div>



                    </div>
                </div>
            </div>

            {
                userList == undefined ? "" : userList.length == 0 ? NullPage() : userList.map(item => {
                    return <div className="alert alert-custom alert-white alert-shadow fade show " role="alert">
                        <div className="d-flex w-100 align-items-center">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40 symbol-light-primary mr-5">
                                <span className="symbol-label">
                                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                                        {/*begin::Svg Icon | path:/metronic/theme/html/demo10/dist/assets/media/svg/icons/Home/Library.svg*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
                                                <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width={3} height={18} rx={1} />
                                            </g>
                                        </svg>
                                        {/*end::Svg Icon*/}
                                    </span>
                                </span>
                            </div>

                            <div className="d-flex flex-column font-weight-bold" style={{ width: 200 }}>
                                <a href="#" className="text-dark text-hover-primary mb-1 font-size-lg">{item.ek_admin_name + " " + item.ek_admin_lastname}</a>
                                <span className="text-muted">{item.ek_admin_authority}</span>
                            </div>
                            <div className="mr-auto font-weight-bold ml-10">
                                <div>
                                    {item.ek_admin_mail}
                                </div>
                            </div>
                            <div>
                                <div className="btn-group">
                                    <AuthorityControl pageCode={81}>
                                        <div onClick={() => Remove(item.ek_admin_ID)} className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                            <span className="svg-icon svg-icon-sm svg-icon-primary">

                                                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                            </span>
                                        </div>
                                    </AuthorityControl>

                                    <AuthorityControl pageCode={82}>
                                        <ModalAdminInsert OnHandler={OnHandler} selectedItem={item} />
                                    </AuthorityControl>

                                </div>


                            </div>

                            {/*end::Text*/}
                        </div>


                    </div>
                })
            }

        </AuthorityControl>



    </>


}

