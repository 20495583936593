import React from 'react'

import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect } from 'react'

import ModalRoyalDogSelect from "../RoyalDog/RoyalDogSelect"
import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnet, faSearch } from '@fortawesome/free-solid-svg-icons';
import NullPage from '../../Ekmekk/Views/NullPage'


export default function ModalEducatorSelect(props) {


    const [list, setList] = useState([]);
    const [mainList, setMainList] = useState([]);
    var history = useHistory();
    useEffect(() => {

        // Ekmekk().Alert.Block();
        DataCenter().Educator.ListEducator().then(r => {

            if (r.status) {
                setList(r.data);
                setMainList(r.data);
                Ekmekk().Alert.UnblockUI();
            }
            else {
                Ekmekk().Alert.UnblockUI();
                Ekmekk().Alert.Fail();
            }

        })

    }, [])



    function ChangeSearchText(e) {
        var filteredList = mainList.filter(item => item.ek_educator_fullname.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setList(filteredList);
    }


    const [show, setShow] = useState(false);


    function ClickEvent() {
        setShow(true)
        if (props.handler != undefined)
            props.handler("click")
    }

    return <>

        {/* <button onClick={ClickEvent} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg py-4 px-3">Planı Kaydet</button> */}

        {
            props.isVisible || props.isVisible == undefined ? (
                props.isChange == 0 ? <button type="button" onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2 text-center my-10 px-10" data-toggle="modal" data-target="#exampleModalCenter">
                    Müşteri Ekle
            </button> : <button type="button" onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2" data-toggle="modal" data-target="#exampleModalCenter">
                    Değiştir
        </button>) : ""
        }

        <Modal
            show={props.isShow == undefined ? show : props.isShow}
            size="xl"
            onHide={() => { setShow(false); if (props.handler != undefined) props.handler("close") }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Eğitmen Seç
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="mb-7">
                    <div className="row align-items-center">
                        <div className="col-md-10">
                            <div className="input-icon">
                                <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Eğitmen adı" id="kt_datatable_search_query" />
                                <span>
                                    <FontAwesomeIcon icon={faSearch} ></FontAwesomeIcon>
                                </span>

                            </div>
                        </div>
                        {/* <div className="col-md">
                            {
                                props.handler != undefined ? <button type="button" onClick={() => {
                                    setShow(false);
                                    props.handler("customerClick")
                                }} className="btn btn-success font-weight-bold text-right">Müşteri Oluştur</button> : ""
                            }
                        </div> */}

                    </div>
                </div>
                {/*end::Search Form*/}
                {/*end: Search Form*/}
                {/*begin: Datatable*/}
                {
                    list.length != 0 ? TableList(list, (selectedEducator) => {
                        setShow(false);
                        props.selectedEducator(selectedEducator);
                    }) : <NullPage />
                }

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button onClick={() => {
                        setShow(false); if (props.handler != undefined) props.handler("close")
                    }} type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal">Kapat</button>

                    <button type="button" className="btn btn-primary font-weight-bold">Ekle</button>

                </div>

            </Modal.Footer>
        </Modal>
    </>
}


function TableList(data, callback) {

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return "Somethings Wrong!!";
    }

    let history = useHistory();

    const columns = [

        /* {
           title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
         },*/
        {
            title: <span className="text">Eğitmen Adı</span>, render: data => <a className="text-dark-75 font-weight-bolder font-size-lg">{data.ek_educator_fullname}</a>
        },
        {
            title: <span className="text">Telefon</span>, render: data => <div>
                <span className="text-dark font-weight-bolder d-block font-size-lg">{formatPhoneNumber(data.ek_educator_phone)}</span>
            </div>
        },
        {
            title: <span className="text">Yakın Telefon</span>, render: data => <div>
                <span className="text-dark-50 font-weight-bolder d-block font-size-lg">{formatPhoneNumber(data.ek_educator_other_phone)}</span>
            </div>
        },
        {
            title: <span className="text">E-mail</span>, render: data => <div>
                <span className="text-dark-50 font-weight-bolder d-block font-size-lg">{data.ek_educator_mail}</span>
            </div>
        },
        {
            title: <span className="text">Kan Grubu</span>, render: data => <div>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{data.ek_educator_blood_group}</span>
            </div>
        },
        {
            title: <span className="text">Adres</span>, render: data => <div>
                <span className="text-dark-50 font-weight-bolder d-block font-size-lg">{data.ek_educator_address}</span>
            </div>
        },



    ]

    const handleRowClick = (event, rowData) => {
        const index = data.indexOf(rowData)

        localStorage.setItem("selectedItem", JSON.stringify(data[index]))


        callback(JSON.stringify(data[index]))


        /* if (Ekmekk().Profile.userType == UserType.MASTER) {
           history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
         }
         else {
           history.push("/business/orders/order-detail/" + data[index].user_orderID);
         }*/


    }

    const Material = (
        <MaterialTable
            data={data}
            columns={columns}
            onRowClick={handleRowClick}
            options={
                {
                    filtering: false,
                    search: false,
                    toolbar: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10],
                }

            } />
    )


    return (
        <div>
            <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                    {Material}
                </table>
            </div>
        </div>
    )
}