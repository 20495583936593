
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'

import ModalCustomerInsert from "../../../Modal/Customer/CustomerInsert"
import Ekmekk from '../../../Ekmekk/Helper/ekmekk';
import DataCenter from '../../../Ekmekk/DataCenter/datacenter';
import SubHeader from '../../../Thema/sub-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnet, faSearch, faPencilRuler, faTractor, faTrash } from '@fortawesome/free-solid-svg-icons';
import NullPage from '../../../Ekmekk/Views/NullPage';
import AuthorityControl from '../../../Ekmekk/Auth/AuthorityControl';

export default function KullaniciKonaklamaGecmis() {

    var history = useHistory();

    const [userList, setUserList] = useState([]);
    const [mainUserList, setMainUserList] = useState([]);

    useEffect(() => {

        console.log(localStorage.getItem("myUserID"));

        DataCenter().User.ListDogHousing(localStorage.getItem("myUserID")).then(r => {

            if (r.status) {
                setUserList(r.data);
                setMainUserList(r.data);
                // Ekmekk().Alert.UnblockUI();
            }


        })

    }, [])

    function CustomerInsertClick(caseData, data) {

        if (caseData == "inserted") {
            data["debt"] = "0";
            data["profit"] = "0";
            data["last_process_count"] = "0";
            userList.push(data);
            setUserList([...userList])
        }
        else if (caseData == "updated") {
            for (var i = 0; i < userList.length; i++) {
                if (userList[i].ek_customer_ID == data.ek_customer_ID) {
                    if (data.ek_customer_image == null) {
                        var temp = userList[i].ek_customer_image;
                    }
                    else {
                        var temp = data.ek_customer_image;
                    }

                    var tempDebt = userList[i].debt;
                    var profit = userList[i].profit;
                    var last_process_count = userList[i].last_process_count;

                    userList[i] = data;

                    userList[i].ek_customer_image = temp;
                    userList[i].debt = tempDebt;
                    userList[i].profit = profit;
                    userList[i].last_process_count = last_process_count;
                }
            }

            setUserList([...userList]);
        }


    }

    function CustomerDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Customer.DeleteCustomer(item.ek_customer_ID).then(r => {

                    if (r.status) {
                        var list2 = [...userList];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_customer_ID == item.ek_customer_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setUserList(list2);
                    }
                })

            }
        })
    }

    function ChangeSearchText(e) {
        var filteredList = mainUserList.filter(item => item.ek_customer_fullname.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setUserList(filteredList);
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumberString;
    }

    return <>


        <div className="mb-7">
            <div className="row align-items-center">
                <div className="col-md-10">
                    <div className="input-icon">
                        <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Konaklama adı" id="kt_datatable_search_query" />
                        <span>
                            <FontAwesomeIcon icon={faSearch} ></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
                {/* <div className="col">
                    <div className="d-flex">
                        <div className="mr-auto"></div>
                        <AuthorityControl pageCode={3}>

                            <div className=""> <ModalCustomerInsert isSale={false} insertClick={CustomerInsertClick} /></div>
                        </AuthorityControl>

                    </div>

                </div> */}
            </div>
        </div>
        {/*end::Search Form*/}



        {
            userList.length != 0 ? TableList(userList) : <NullPage />
        }


    </>



    function TableList(data) {


        let history = useHistory();

        function OpenUser(index) {

            // <AuthorityControl pageCode={6}>

            history.push("/dashboard/customer/customer-profile/" + index)

            // </AuthorityControl>

        }
        const columns = [

            /* {
                
               title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
             },*/
            {
                title: <span className="text">Kafes</span>, render: data => <div >
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{data.ek_cage_name}</span>
                </div>

            },
            {
                title: <span className="text">Köpek</span>, render: data => <div>
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{data.ek_customer_dog_name}</span>
                </div>

            },
            {
                title: <span className="text">Başlangıç Tarih</span>, render: data => <div >
                    <span className="text-muted font-weight-bolder d-block font-size-lg">{data.ek_entry_date}</span>
                </div>
            },
            {
                title: <span className="text">Bitiş Tarih</span>, render: data => <div >
                    <span className="text-muted font-weight-bolder d-block font-size-lg">{data.ek_exit_date}</span>
                </div>
            },
            {
                title: <span className="text">Gün</span>, render: data => <div >
                    <span className="text-primary font-weight-bolder d-block font-size-lg">{data.day}</span>
                </div>
            },
            {
                title: <span className="text">Ücret</span>, render: data => <div>
                    <span className="text-success font-weight-bolder d-block font-size-lg">{data.price}₺</span>
                </div>
            },
            


        ]

        const handleRowClick = (event, rowData) => {
            const index = data.indexOf(rowData)

            localStorage.setItem("selectedItem", JSON.stringify(data[index]))
            // history.push("/customer/customer-profile");


            /* if (Ekmekk().Profile.userType == UserType.MASTER) {
               history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
             }
             else {
               history.push("/business/orders/order-detail/" + data[index].user_orderID);
             }*/


        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                    }

                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }
}

