import React from 'react'

import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'

import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnet, faSearch } from '@fortawesome/free-solid-svg-icons';

import ModalRoyalDogInsert from "../../Modal/RoyalDog/RoyalDogInsert"

export default function ModalRoyalDogSelect(props) {

    var inputBreedSearch = useRef(null)

    const [breedList, setBreedList] = useState([])

    const [list, setList] = useState([]);
    const [mainList, setMainList] = useState([]);

    var history = useHistory();
    useEffect(() => {

        DataCenter().RoyalDog.ListRoyalDog().then(r => {

            if (r.status) {
                console.log(r.data);
                var emptyRoyalDog = r.data;

                for (var i = 0; i < emptyRoyalDog.length; i++) {
                    if (emptyRoyalDog[i].activeCage != "") {

                        // console.log(emptyRoyalDog[i]);

                        emptyRoyalDog.splice(i, 1)
                        i -= 1;       // aramaya 1 tane atlayarak devam ettiği için i 1 azaltılarak tekrar aratılıyor
                    }
                }

                setList(emptyRoyalDog);
                setMainList(emptyRoyalDog);
            }
        })

        DataCenter().DogBreed.ListDogBreed().then(r => {
            setBreedList(r.data);
        })

    }, [])



    function BreedItem(item) {
        return <option value={item.ek_dog_breed_name}>{item.ek_dog_breed_name}</option>
    }

    function ChangeSearchText(e) {
        var filteredList = mainList.filter(item => item.ek_royal_dog_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setList(filteredList);

    }

    function ChangeBreedText(e) {

        if (inputBreedSearch.current.value == 0) {
            setList(mainList);
        }
        else {
            var filteredList = mainList.filter(item => item.ek_dog_breed_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
            setList(filteredList);
        }

    }


    const [show, setShow] = useState(false);


    function ClickEvent() {
        setShow(true)
        if (props.handler != undefined)
            props.handler("click")
    }

    return <>

        {
            props.isChange == 0 && props.isVisible != false ? <button type="button" onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2 text-center my-10 px-10" data-toggle="modal" data-target="#exampleModalCenter">
                RoyalDog Ekle
            </button> : props.isVisible != false ? <button type="button" onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2" data-toggle="modal" data-target="#exampleModalCenter">
                Değiştir
            </button> : ""
        }


        <Modal
            show={props.isShow == undefined ? show : props.isShow}
            size="xl"
            onHide={() => { setShow(false); if (props.handler != undefined) props.handler("close") }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    RoyalDog Seç
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="mb-7">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="input-icon">
                                <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Köpek Adı" id="kt_datatable_search_query" />
                                <span>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex align-items-center">
                                <label className="mr-3 mb-0 d-none d-md-block">Cins:</label>
                                <select ref={inputBreedSearch} onChange={ChangeBreedText} className="form-control" id="kt_datatable_search_type">
                                    <option value={0}>All</option>
                                    {
                                        breedList.map(item => {
                                            return BreedItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md">
                            {/* <ModalRoyalDogInsert isSale={false} insertClick={RoyalDogInsertClick} /> */}
                        </div>
                    </div>
                </div>

                {/*end::Search Form*/}
                {/*end: Search Form*/}
                {/*begin: Datatable*/}
                {
                    list.length != 0 ? TableList(list, (selectedRoyalDog) => {
                        props.selectedRoyalDog(selectedRoyalDog)
                    }) : ""
                }
                

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button onClick={() => {
                        setShow(false); if (props.handler != undefined) props.handler("close")
                    }} type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal">Kapat</button>

                    <button type="button" className="btn btn-primary font-weight-bold">Ekle</button>

                </div>

            </Modal.Footer>
        </Modal>
    </>
}


function TableList(data, callback) {


    let history = useHistory();

    const columns = [

        /* {
           title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
         },*/

        {
            title: <span className="text">ID</span>, render: data => <a href="#" className="text-dark font-weight-boldest text-hover-primary font-size-lg">{data.ek_royal_dog_ID}</a>
        },
        {
            title: <span className="text">Foto</span>, render: data => <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
                <img src={data.ek_royal_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + data.ek_royal_dog_image} alt="" className="h-50" />
            </div>

        },
        {
            title: <span className="text">İsim</span>, render: data => <a className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_royal_dog_name}</a>
        },
        {
            title: <span className="text">Cinsiyet</span>, render: data => <a className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_royal_dog_gender}</a>
        },
        {
            title: <span className="text">Yaşı</span>, render: data => <div>
                <span className="text-info font-weight-bolder d-block font-size-lg">{data.dog_age}</span>
            </div>
        },
        {
            title: <span className="text">Cinsi</span>, render: data => <div>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{data.ek_dog_breed_name}</span>
            </div>
        },
        {
            title: <span className="text">Alt Cinsi</span>, render: data => <div>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">{data.ek_dog_sub_breed_name}</span>
            </div>
        },
        {
            title: <span className="text">Alış Fiyatı</span>, render: data => <div>
                <span className="text-info font-weight-bolder d-block font-size-lg">{data.ek_royal_dog_purchase_price}</span>
            </div>
        },
        {
            title: <span className="text">Satış Fiyatı</span>, render: data => <div>
                <span className="text-info font-weight-bolder d-block font-size-lg">{data.ek_royal_dog_sale_price}</span>
            </div>
        },


    ]

    const handleRowClick = (event, rowData) => {
        const index = data.indexOf(rowData)

        localStorage.setItem("selectedItem", JSON.stringify(data[index]))

        callback(JSON.stringify(data[index]))

        // history.push("/projects/insert");


        /* if (Ekmekk().Profile.userType == UserType.MASTER) {
           history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
         }
         else {
           history.push("/business/orders/order-detail/" + data[index].user_orderID);
         }*/


    }

    const Material = (
        <MaterialTable
            data={data}
            columns={columns}
            onRowClick={handleRowClick}
            options={
                {
                    filtering: false,
                    search: false,
                    toolbar: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10],
                }

            } />
    )


    return (
        <div>
            <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                    {Material}
                </table>
            </div>
        </div>
    )
}