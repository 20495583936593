import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import { ViewState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Scheduler,
    WeekView,
    MonthView,
    Appointments,
    DateNavigator,
    Toolbar,
    TodayButton,
    AppointmentTooltip,
    ViewSwitcher,
    AppointmentForm,
    AllDayPanel,
    EditRecurrenceMenu,
    DayView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useEffect } from 'react';
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import { useState } from 'react';
import { useRef } from 'react';
import ModalCustomerSelect from '../../Modal/Customer/CustomerSelect';
import ModalEducatorSelect from '../../Modal/Education/EducatorSelect';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { useParams } from 'react-router-dom';
import SubHeader from '../../Thema/sub-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalEducatorInsert from '../../Modal/Education/EducatorInsert';
import AuthorityControl from "../../Ekmekk/Auth/AuthorityControl";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const style = ({ palette }) => ({
    icon: {
        color: palette.action.active,
    },
    textCenter: {
        textAlign: 'center',
    },
    firstRoom: {
        background: 'url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/Lobby-4.jpg)',
    },
    secondRoom: {
        background: 'url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/MeetingRoom-4.jpg)',
    },
    thirdRoom: {
        background: 'url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/MeetingRoom-0.jpg)',
    },
    header: {
        height: '260px',
        backgroundSize: 'cover',
    },
    commandButton: {
        backgroundColor: 'rgba(255,255,255,0.65)',
    },
});

// const getClassByLocation = (classes, location) => {
//     if (location === 'Room 1') return classes.firstRoom;
//     if (location === 'Room 2') return classes.secondRoom;
//     return classes.thirdRoom;
// };



export default function PlainOrganizer() {


    var customerID = useParams();
    var dogID = useParams();
    var selectionData = useRef(null);
    var dogRef = useRef(null);

    const [list, setList] = useState([]);

    const [selectionCustomer, setSelectedCustomer] = useState({});

    const [educatorList, setEducatorList] = useState({})
    const [selectedEducator, setSelectedEducator] = useState({});

    const [dogList, setDogList] = useState([]);
    const [selectedDog, setSelectedDog] = useState(-1);

    const [educationList, setEducationList] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState(-1);

    const [lessonList, setLessonList] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState({});

    const [isModal, setModal] = useState(false);
    const [isEducatorModal, setEducatorModal] = useState(false);

    useEffect(() => {

        DataCenter().Educator.ListEducator().then(r => {
            if (r.status) {
                setEducatorList(r.data);
                setSelectedEducator(r.data[0]);
            }
        })

        var today = new Date(), date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

        // EĞER KÖPEK PROFİLİNDEN GELİNDİYSE MÜŞTERİ VE KÖPEĞİ SEÇİLİ YAPMA
        if (customerID.customerID != undefined) {
            // Ekmekk().Alert.Block();
            DataCenter().Customer.ShowCustomerProfile(customerID.customerID).then(r => {

                setSelectedCustomer(r.data.customer[0]);        // GELEN MÜŞTERİ ID İLE MÜŞTERİ BİLGİLERİ TOPLANIP SEÇİLİ MÜŞTERİYE BASILIR

                DataCenter().Customer.ListCustomerDog(customerID.customerID).then(r => {
                    // console.log(r.data[0].ek_customer_dog_ID);
                    setDogList(r.data)                          // GELEN MÜŞTERİ ID İLE MÜŞTERİ KÖPEKLERİ TOPLANIP DROPDOWN'DA GÖSTERİLİR
                    setSelectedDog(customerID.dogID);           // GELEN MÜŞTERİ KÖPEK ID İLE SEÇİLİ KÖPEK SEÇİLİR

                    DataCenter().Customer.ListCustomerDogEducation(customerID.dogID).then(r => {

                        setEducationList(r.data);

                    })

                    // Ekmekk().Alert.UnblockUI();
                });
            });
        }
    }, [])

    function SavePlan() {

        if (list.length == 0 || selectedEducator.ek_educator_ID == undefined || selectedDog == -1 || selectedEducation == -1) {
            Ekmekk().Alert.FillAll();
            // console.log(list.length);
            // console.log(selectionCustomer.ek_customer_fullname);
            // console.log(selectedEducator.ek_educator_ID);
            // console.log(selectedDog);
            // console.log(selectedEducation);
        }
        else {
            Ekmekk().Alert.Sure((e) => {
                if (e) {

                    // Ekmekk().Alert.Block();

                    for (let i = 0; i < list.length; i++) {
                        // console.log(list[i].ek_dog_lesson_plan_ID);

                        var theStartDate = new Date(list[i].startDate), startTime = theStartDate.getFullYear() + '-' + (theStartDate.getMonth() + 1) + '-' + theStartDate.getDate() + ' ' + theStartDate.getHours() + ':' + theStartDate.getMinutes() + ':' + theStartDate.getSeconds();
                        var theEndDate = new Date(list[i].endDate), endTime = theEndDate.getFullYear() + '-' + (theEndDate.getMonth() + 1) + '-' + theEndDate.getDate() + ' ' + theEndDate.getHours() + ':' + theEndDate.getMinutes() + ':' + theEndDate.getSeconds();

                        var dataBlock = {
                            ek_dog_lesson_plan_ID: list[i].ek_dog_lesson_plan_ID,
                            ek_education_lesson_ID: list[i].ek_education_lesson_ID,
                            ek_educator_ID: list[i].ek_educator_ID,
                            ek_customer_dog_ID: selectedDog,
                            ek_dog_lesson_start_date: startTime,
                            ek_dog_lesson_end_date: endTime,
                            isComplete: list[i].isComplete,
                        }
                        // console.log(dataBlock);

                        if (list[i].ek_dog_lesson_plan_ID == "") {
                            console.log(i);
                            DataCenter().Plain.InsertDogEducationLessonPlan(dataBlock).then(r => {


                            })
                        }
                        else {

                            // EĞER SEÇTİĞİM DERSLERDEN BİRİNİ UPDATE EDİYORSAM

                            for (let j = 0; j < lessonList.length; j++) {

                                if (list[i].ek_dog_lesson_plan_ID == lessonList[j].ek_dog_lesson_plan_ID) {
                                    console.log(list[i].ek_dog_lesson_plan_ID);

                                    DataCenter().Plain.UpdateDogEducationLessonPlan(dataBlock).then(r => {


                                    })

                                }

                            }


                        }
                    }

                    // Ekmekk().Alert.UnblockUI();

                    Ekmekk().Alert.Success();

                }
                else {
                    Ekmekk().Alert.Fail();
                }
            })
        }


    }

    function FillPlain(data) {

        var theLessonData = data;
        console.log(data);

        var educatorID = -1;
        var bashList = [];

        for (var i = 0; i < theLessonData.length; i++) {

            if (theLessonData[i].ek_dog_lesson_plan_ID != "") {

                theLessonData[i].["isDone"] = 1;
                var theStartDate = new Date(theLessonData[i].ek_dog_lesson_start_date);
                var theEndDate = new Date(theLessonData[i].ek_dog_lesson_end_date);
                var lessonName = theLessonData[i].ek_education_lesson_name;
                var dogName = theLessonData[i].ek_customer_dog_name;
                var lessonID = theLessonData[i].ek_education_lesson_ID;

                bashList.push({
                    startDate: theStartDate,
                    endDate: theEndDate,
                    title: dogName + " - " + lessonName,
                    ek_education_lesson_ID: theLessonData[i].ek_education_lesson_ID,
                    ek_dog_lesson_plan_ID: theLessonData[i].ek_dog_lesson_plan_ID,
                    isComplete: theLessonData[i].isComplete,
                    ek_educator_ID: theLessonData[i].ek_educator_ID,
                });

                if (theLessonData[i].ek_educator_ID != "" || theLessonData[i].ek_educator_ID == undefined || theLessonData[i].ek_educator_ID == null)
                    educatorID = theLessonData[i].ek_educator_ID;
            }

        }

        setList(bashList);

        console.log(educatorID);
        // DERSLER DAHA ÖNCEDEN SEÇİLMİŞ VE BİR EĞİTMEN VARSA EĞİTMEN SEÇİLİ OLARAK GELİR
        if (educatorID != -1) {
            for (let i = 0; i < educatorList.length; i++) {

                if (educatorList[i].ek_educator_ID == educatorID)
                    setSelectedEducator(educatorList[i]);
            }
        }

    }

    function Ekle(data) {

        if (selectionData.current.value != "-1" && dogRef.current.value != "-1") {


            if (selectedEducator == undefined) {
                Ekmekk().Alert.FailEğitmen();

            }
            else {
                Insert(data);
                ChangeLessonActivity(1, selectedLesson.ek_education_lesson_ID);
            }




        }

    }

    function Insert(data) {

        console.log(data);

        for (let i = 0; i < dogList.length; i++) {

            if (dogList[i].ek_customer_dog_ID == selectedDog)
                var dogName = dogList[i].ek_customer_dog_name;

        }
        var lessonName = selectedLesson.ek_education_lesson_name;
        var lessonID = selectedLesson.ek_education_lesson_ID;

        var list2 = [...list];
        for (var i = 0; i < list2.length; i++) {

            if (list2[i].ek_education_lesson_ID == selectedLesson.ek_education_lesson_ID) {
                list2.splice(i, 1)
                // list2[i].visible = 0;
            }
        }

        console.log(selectedEducator.ek_educator_ID);
        list2.push({
            startDate: data.startDate,
            endDate: data.endDate,
            title: dogName + "-" + lessonName,
            ek_education_lesson_ID: selectedLesson.ek_education_lesson_ID,
            ek_dog_lesson_plan_ID: selectedLesson.ek_dog_lesson_plan_ID,
            isComplete: selectedLesson.isComplete,
            ek_educator_ID: JSON.stringify(selectedEducator.ek_educator_ID),
        });

        setList(list2);
        // console.log(data);

    }

    function DeleteLesson(data) {

        // Ekmekk().Alert.Block();

        // console.log(data.ek_education_lesson_ID)
        var list2 = [...list];
        for (var i = 0; i < list2.length; i++) {

            if (list2[i].ek_education_lesson_ID == data.ek_education_lesson_ID) {

                list2.splice(i, 1)
                setList(list2);

                ChangeLessonActivity(0, data.ek_education_lesson_ID);

            }
        }

        // Ekmekk().Alert.UnblockUI();
    }

    function ChangeLessonActivity(activeNum, selectedLessonID) {

        for (let i = 0; i < lessonList.length; i++) {
            if (lessonList[i].ek_education_lesson_ID == selectedLessonID) {
                lessonList[i].["isDone"] = activeNum;


                if (i + 1 != lessonList.length) {
                    // console.log(lessonList[i+1]);
                    setSelectedLesson(lessonList[i + 1]);
                }

                // EKLENMEMİŞ DERS VARSA EKLENMEK İÇİN SEÇİLECEK DERSE O ATILIR YOKSA BİR SONRAKİ DERS SEÇİLİR
                for (let j = 0; j < lessonList.length; j++) {

                    if (lessonList[j].["isDone"] == undefined || lessonList[j].["isDone"] == 0) {
                        setSelectedLesson(lessonList[j]);
                        break;
                    }

                }
            }
        }

    }

    function Handler(data) {
        if (data == "close") {
            setModal(false);
            setEducatorModal(false);
        }
    }

    function selectedCustomer(r) {
        setModal(false);
        setSelectedCustomer(JSON.parse(r));
        DataCenter().Customer.ListCustomerDog(JSON.parse(r)["ek_customer_ID"]).then(r => {
            setDogList(r.data)
        });

    }
    function SelectCustomer() {
        return <div>

            <div style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center">
                    <img src="/my-assets/lessonPlan.svg" className="max-h-400px" />

                </div>
                <div className="font-weight-bold font-size-lg mt-2">Herşeyden önce bir müşteri seçmelisin</div>
                <div className="text-muted mt-2">Bir eğitim takvimi güzenlemek için öncelikli olarak bir müşteri seçmelisin</div>
                <div className="btn btn-primary mt-6" onClick={() => { setModal(true); }}>Müşteri seç</div>
            </div>


        </div>
    }

    function SelectAnotherData() {
        return <div>

            <div style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center">
                    <img src="/my-assets/lessonPlan.svg" className="max-h-400px" />

                </div>
                <div className="font-weight-bold font-size-lg mt-2">Harika! Kalan ufak bilgileri doldur ve organize etmeye başla</div>
                <div className="text-muted mt-2">Bir eğitim takvimi güzenlemek için öncelikli olarak bir müşteri seçmelisin</div>

            </div>

        </div>
    }

    function LessonItemList(item) {
        return <>
            {/* <span className="label label-lg label-inline font-weight-bolder label-rounded label-light-dark mr-2 mt-2">{item.ek_education_lesson_name}</span> */}
            <button onClick={() => { setSelectedLesson(item) }} className={item.isDone == undefined || item.isDone == 0 ? "btn btn-sm btn-shadow btn-hover-bg-warning font-weight-bolder btn-light-dark mr-2 mt-2" : "btn btn-sm btn-hover-bg-warning font-weight-bolder btn-light-success mr-2 mt-2"}>{item.ek_education_lesson_name}</button>
        </>
    }

    function Organizer() {
        return <>

            <Demo2 data={list} clickEvent={(data) => { Ekle(data); }} deleteEvent={(e) => { DeleteLesson(e); }} />

        </>
    }

    function TimeLine() {
        return <div className="timeline timeline-5">
            <div className="timeline-items">
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            1
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Müşteri Bilgisi</span>
                        <p className="font-weight-normal text-dark-50 pb-2">
                            <div className="d-flex mt-6" onClick={() => {
                                setModal(true);
                                setList([]);
                                setSelectedEducation(-1);
                                setSelectedDog(-1);
                                setLessonList([]);
                                setSelectedLesson([]);
                                // setSelectedEducator({});

                            }}>
                                {/*begin: Pic*/}
                                <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                    <div className="symbol shadow symbol-40 symbol-lg-50">
                                        {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                        <img src={(selectionCustomer.ek_customer_image != "" && selectionCustomer.ek_customer_image != undefined) ? Ekmekk().GetImageURL(selectionCustomer.ek_customer_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                    </div>
                                    <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                        <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                    </div>
                                </div>
                                {/*end: Pic*/}
                                {/*begin: Info*/}
                                <div className="flex-grow-1">
                                    {/*begin: Title*/}
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="mr-3">
                                            {/*begin::Name*/}
                                            <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">




                                                {
                                                    (selectionCustomer.ek_customer_fullname != "" && selectionCustomer.ek_customer_fullname != undefined) ? selectionCustomer.ek_customer_fullname : "-----"
                                                }
                                                <i className="flaticon2-correct text-success icon-md ml-2" /></a>

                                        </div>
                                    </div>
                                    {/*end: Title*/}
                                    {/*begin: Content*/}
                                    <div className="d-flex align-items-center flex-wrap justify-content-between">

                                    </div>
                                    {/*end: Content*/}
                                </div>
                                {/*end: Info*/}
                            </div>
                            <div className="separator separator-solid my-3" />

                            <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Mail :</span>  {(selectionCustomer.ek_customer_mail != "" && selectionCustomer.ek_customer_mail != undefined) ? selectionCustomer.ek_customer_mail : "-----"}</div>

                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Telefon : {(selectionCustomer.ek_customer_phone != "" && selectionCustomer.ek_customer_phone != undefined) ? selectionCustomer.ek_customer_phone : "-----"}</div>
                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Adres :  {(selectionCustomer.ek_customer_address != "" && selectionCustomer.ek_customer_address != undefined) ? selectionCustomer.ek_customer_address : "-----"}</div>

                        </p>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            2
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Köpek Bilgisi</span>
                        <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                            <select onChange={(e) => {
                                setSelectedDog(e.target.value);

                                DataCenter().Customer.ListCustomerDogEducation(e.target.value).then(r => {

                                    setEducationList(r.data);
                                    setList([]);
                                    setSelectedEducation(-1);
                                    setLessonList([]);
                                    setSelectedLesson([]);
                                    // setSelectedEducator({});

                                })

                            }} value={selectedDog} ref={dogRef} class="form-control form-control-lg form-control-solid" id="select_banner_type">
                                <option value="-1">Lütfen bir seçim yapınız</option>
                                {
                                    dogList.map(item => {
                                        return <option selected={dogID.dogID == item.ek_customer_dog_ID} value={item.ek_customer_dog_ID}>{item.ek_customer_dog_name}</option>
                                    })
                                }


                            </select>
                        </p>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            3
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Eğitim Bilgisi</span>
                        <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                            <select onChange={(e) => {

                                setSelectedEducation(e.target.value);
                                setList([]);
                                // setSelectedEducator({});

                                // EĞİTİM SEÇİLDİĞİNDE 4.ADIMDAKİ DERSLERİ DOLDURUR
                                // Ekmekk().Alert.Block();
                                DataCenter().Plain.DogEducationLessonList(e.target.value, selectedDog).then(r => {

                                    if (r.status) {

                                        // 4. ADIMDAKİ DERSLERİN DAHA ÖNCEDEN PLANI YAPILDIYSA DOLU(YEŞİL) OLARAK GETİRİR
                                        var theLessonData = r.data;

                                        for (var i = 0; i < theLessonData.length; i++) {
                                            if (theLessonData[i].ek_dog_lesson_plan_ID != "") {

                                                theLessonData[i].["isDone"] = 1;
                                            }
                                        }

                                        setLessonList(theLessonData);
                                        setSelectedLesson(theLessonData[0]);

                                        // Ekmekk().Alert.UnblockUI();

                                    }
                                    else {
                                        // Ekmekk().Alert.UnblockUI();
                                        Ekmekk().Alert.Fail();
                                    }

                                })

                                // EĞİTİM SEÇİLDİĞİNDE TÜM PLANLARI EKRANA BASAR
                                DataCenter().Plain.List().then(r => {

                                    if (r.status) {
                                        FillPlain(r.data);
                                    }

                                    // var bashList = [];

                                    // for (var i = 0; i < r.data.length; i++) {

                                    //     bashList.push({
                                    //         title: r.data[i]["ek_customer_fullname"] + "--" + r.data[i]["ek_customer_dog_name"],
                                    //         startDate: new Date(r.data[i]["ek_dog_lesson_start_date"]),
                                    //         endDate: new Date(r.data[i]["ek_dog_lesson_end_date"]),
                                    //     })
                                    // }
                                    // setAppointments(bashList);

                                    // if (r.status) {
                                    //     // Ekmekk().Alert.Success()
                                    // }
                                    // else {
                                    //     Ekmekk().Alert.Fail();
                                    // }
                                })

                            }} value={selectedEducation} className="form-control form-control-lg form-control-solid" id="select_banner_type" ref={selectionData}>

                                <option value="-1">Lütfen bir seçim yapınız</option>
                                {
                                    educationList.map(item => {
                                        // if (item.visible == 1)
                                        return <option value={item.ek_education_selling_ID}> {item.ek_education_name}</option>
                                    })
                                }


                            </select>
                        </p>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            4
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Dersler</span>
                        <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                            <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5">

                                {
                                    lessonList == null || lessonList.length == 0 || lessonList.length == undefined ? "" : lessonList.map(item => {
                                        return LessonItemList(item)
                                    })
                                }
                            </div>

                        </p>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            5
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">
                        <span className="font-weight-bolder text-success">Eğitmen Bilgisi</span>
                        <p onClick={() => { setEducatorModal(true); }} className="font-weight-normal text-dark-50 pb-2">
                            <div className="d-flex mt-6" >
                                {/*begin: Pic*/}
                                {/* <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                    <div className="symbol shadow symbol-40 symbol-lg-50">
                                        <img src={(selectionCustomer.ek_customer_image != "" && selectionCustomer.ek_customer_image != undefined) ? Ekmekk().GetImageURL(selectionCustomer.ek_customer_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                    </div>
                                    <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                        <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                    </div>
                                </div> */}
                                {/*end: Pic*/}
                                {/*begin: Info*/}
                                <div className="flex-grow-1">
                                    {/*begin: Title*/}
                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="mr-3">
                                            {/*begin::Name*/}
                                            <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">

                                                {
                                                    (selectedEducator != null && selectedEducator != undefined && selectedEducator.ek_educator_fullname != "" && selectedEducator.ek_educator_fullname != undefined) ? selectedEducator.ek_educator_fullname : "-----"
                                                }

                                                <i className="flaticon2-correct text-success icon-md ml-2" /></a>

                                        </div>
                                    </div>
                                    {/*end: Title*/}
                                    {/*begin: Content*/}
                                    <div className="d-flex align-items-center flex-wrap justify-content-between">

                                    </div>
                                    {/*end: Content*/}
                                </div>
                                {/*end: Info*/}
                            </div>
                            <div className="separator separator-solid my-3" />

                            <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Mail :</span>  {(selectedEducator != undefined && selectedEducator.ek_educator_mail != "" && selectedEducator.ek_educator_mail != undefined) ? selectedEducator.ek_educator_mail : "-----"}</div>

                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Kan Grubu : {(selectedEducator != undefined && selectedEducator.ek_educator_blood_group != "" && selectedEducator.ek_educator_blood_group != undefined) ? selectedEducator.ek_educator_blood_group : "-----"}</div>
                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Telefon : {(selectedEducator != undefined && selectedEducator.ek_educator_phone != "" && selectedEducator.ek_educator_phone != undefined) ? selectedEducator.ek_educator_phone : "-----"}</div>
                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Yakın Telefon : {(selectedEducator != undefined && selectedEducator.ek_educator_other_phone != "" && selectedEducator.ek_educator_other_phone != undefined) ? selectedEducator.ek_educator_other_phone : "-----"}</div>
                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">Adres :  {(selectedEducator != undefined && selectedEducator.ek_educator_address != "" && selectedEducator.ek_educator_address != undefined) ? selectedEducator.ek_educator_address : "-----"}</div>

                        </p>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
                {/*begin::Item*/}
                <div className="timeline-item">
                    {/*begin::Icon*/}
                    <div className="timeline-media bg-light-success">
                        <span className="svg-icon-success svg-icon-md">
                            6
                        </span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Info*/}
                    <div className="timeline-desc timeline-desc-light-success">


                        <button onClick={() => { SavePlan() }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg py-4 px-3">Planı Kaydet</button>
                    </div>
                    {/*end::Info*/}
                </div>
                {/*end::Item*/}
            </div>
        </div>

    }

    return <div>


        <AuthorityControl pageCode={95}>


            <ModalCustomerSelect selectedCustomer={selectedCustomer} handler={Handler} isVisible={false} isShow={isModal}></ModalCustomerSelect>
            <ModalEducatorSelect selectedEducator={r => {
                setSelectedEducator(JSON.parse(r));
                console.log(JSON.parse(r));
                setEducatorModal(false);

            }} isVisible={false} handler={Handler} isShow={isEducatorModal} />

            {
                selectionCustomer.ek_customer_fullname == null ? SelectCustomer() : <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-12">
                        <TimeLine />
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        {
                            !(selectedDog != -1 && selectedEducation != -1 && lessonList.length != 0 && lessonList.length != undefined) ? SelectAnotherData() : Organizer()
                        }

                    </div>

                </div>

            }
        </AuthorityControl>

    </div>

}



function Demo2(props) {

    const Header = withStyles(style, { name: 'Header' })(({
        children, appointmentData, classes, ...restProps
    }) => (
        <AppointmentTooltip.Header
            {...restProps}
            // className={classNames(getClassByLocation(classes, appointmentData.location), classes.header)}
            appointmentData={appointmentData}
        >
            <IconButton
                /* eslint-disable-next-line no-alert */
                onClick={() => { restProps.onHide(); props.deleteEvent(appointmentData); }}
                className={classes.commandButton}
            >

                <FontAwesomeIcon icon={faTrash} />
            </IconButton>
        </AppointmentTooltip.Header>
    ));


    var today = new Date(), date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    function TimeTableCellMy({ onDoubleClick, ...restProps }) {

        return <DayView.TimeTableCell onClick={() => {
            props.clickEvent(restProps);
        }} {...restProps} />;
    }
    return <>
        <Paper>
            <Scheduler
                data={props.data}
            >
                <ViewState
                    defaultCurrentDate={date}
                    defaultCurrentViewName="Week"
                />
                <WeekView
                    startDayHour={10}
                    endDayHour={18}
                    timeTableCellComponent={TimeTableCellMy}
                />
                <Toolbar />
                <DateNavigator />
                <TodayButton />
                <Appointments />
                <AppointmentTooltip
                    headerComponent={Header}
                    showCloseButton
                />


            </Scheduler>
        </Paper>

    </>
}