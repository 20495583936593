export default function NullPage(props) {
    return <div>

        <div style={{ textAlign: "center" }}>
            <div className="d-flex justify-content-center">
                <img src={props != undefined && props.image != undefined ? "/my-assets/" + props.image : "/my-assets/empty.svg"} className="max-h-400px" />

            </div>
            <div className="font-weight-bold font-size-lg mt-2">Ops! Malesef şuanda senin için gösterebileceğimiz bir veri bulunmamakta </div>
            <div className="text-muted mt-2">Sisteme kayıtlı hiçbir veri olmadığı için senin için birşeyler gösteremiyoruz.</div>
            {
                props == undefined || props.hideLabel == undefined || !props.hideLabel ? <div>

                    <div className="btn btn-primary mt-6">Hemen bir ürün ekle</div>
                </div> : ""
            }

        </div>






    </div>
}