import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function ModalServiceInsert(props) {


    var inputServiceName = useRef(null);
    var inputPurchasePrice = useRef(null);
    var inputSalePrice = useRef(null);
    var inputVisibleType = useRef(null);

    function ServiceInsertClick() {

        var dataBlock = {
            ek_service_name: inputServiceName.current.value,
            ek_service_purchase_price: inputPurchasePrice.current.value,
            ek_service_sale_price: inputSalePrice.current.value,
        }

        var controller = new ValidationController();

        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {

                DataCenter().Service.InsertService(dataBlock).then(r => {
                    // alert(JSON.stringify(r))

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_service_ID"] = r.data.ek_service_ID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }
            else {
                dataBlock["visible"] = inputVisibleType.current.value;
                dataBlock["ek_service_ID"] = props.selectedData.ek_service_ID;

                DataCenter().Service.UpdateService(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }


    }

    // ----------- CİNSİYET ------------

    const visibility = [{
        name: "Aktif",
        id: 1
    },
    {
        name: "Pasif",
        id: 0
    }
    ];

    function VisibleItem(item) {
        return <option value={item.id} selected={props.selectedData != undefined && props.selectedData.visible == item.id} >{item.name}</option>
    }


    const [show, setShow] = useState(false);


    return <>

        <BrowserView>

            {
                props.isSale == false ? <button onClick={() => setShow(true)} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Hizmet Ekle</button>
                    : ""
            }
        </BrowserView>
        <MobileView>

            {
                props.isSale == false ? <button onClick={() => setShow(true)} className="btn btn-primary btn-block font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Hizmet Ekle</button>
                    : ""
            }
        </MobileView>


        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={() => setShow(true)} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={() => setShow(true)} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">

                    <div>
                        <h5 className="font-boldest">{props.isUpdate == true ? "Hizmet Güncelle" : "Hizmet Ekle"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}
                    {/* KÖPEK İSMİ */}
                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Hizmet Adı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|" ref={inputServiceName} className="form-control form-control-lg form-control-solid" name="dog_name" placeholder="Hizmet Adı" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_service_name : ""} />
                            </div>
                        </div>
                        {/* KÖPEK CİNSİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Alış Fiyatı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputPurchasePrice} className="form-control form-control-lg form-control-solid" name="purhcase" placeholder="Alış Fiyatı" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_service_purchase_price : ""} />
                            </div>
                        </div>
                        {/* SÜRE */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyatı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputSalePrice} className="form-control form-control-lg form-control-solid" name="sale" placeholder="Satış Fiyatı" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_service_sale_price : ""} />
                            </div>
                        </div>

                        {

                            props.selectedData != undefined ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Aktiflik: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <select ref={inputVisibleType} className="form-control form-control-lg form-control-solid" id="select_banner_type" >
                                        <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                        {
                                            visibility.map(item => {
                                                return VisibleItem(item)
                                            })
                                        }
                                    </select>
                                </div>
                            </div> : ""
                        }
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false) }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={ServiceInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Ekle"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}