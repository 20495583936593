import NullPage from "../../Ekmekk/Views/NullPage";
import React, { useEffect, useState } from "react"
import SubHeader from "../../Thema/sub-header";
import Paper from '@material-ui/core/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    WeekView,
    MonthView,
    Appointments,
    DateNavigator,
    Toolbar,
    TodayButton,
    AppointmentTooltip,
    ViewSwitcher,
    AppointmentForm,
    AllDayPanel,
    EditRecurrenceMenu,
    DayView,
} from '@devexpress/dx-react-scheduler-material-ui';
import DataCenter from "../../Ekmekk/DataCenter/datacenter";
import Ekmekk from "../../Ekmekk/Helper/ekmekk";
import AuthorityControl from "../../Ekmekk/Auth/AuthorityControl";
export default function PlainList() {


    return <div>

        <Calendar />
    </div>
}


function Calendar() {

    /*const appointments = [
         {
            title: 'Install New Database',
            startDate: new Date(2021, 4, 29, 9, 45),
            endDate: new Date(2021, 4, 30, 11, 15),
        },
    ];*/

    const [date, setDate] = useState(new Date());

    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        DataCenter().Plain.List().then(r => {

            // var today = new Date(), date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            console.log(date);

            var bashList = [];

            for (var i = 0; i < r.data.length; i++) {

                bashList.push({
                    title: r.data[i]["ek_customer_dog_name"] + "--" + r.data[i]["ek_education_lesson_name"] + "--" + r.data[i]["ek_educator_fullname"],
                    startDate: new Date(r.data[i]["ek_dog_lesson_start_date"]),
                    endDate: new Date(r.data[i]["ek_dog_lesson_end_date"]),
                })
            }
            setAppointments(bashList);

            if (r.status) {
                // Ekmekk().Alert.Success()
            }
            else {
                Ekmekk().Alert.Fail();
            }
        })
    }, [])

    return <>


        <AuthorityControl pageCode={94}>

            <Paper>
                <Scheduler data={appointments}>
                    <ViewState
                        defaultCurrentDate={date}
                    />
                    <MonthView
                        startDayHour={10}
                        endDayHour={18}
                    />
                    <Toolbar />
                    <DateNavigator />
                    <TodayButton />
                    <Appointments />
                    <AppointmentTooltip
                        showCloseButton
                    />
                </Scheduler>
            </Paper>
        </AuthorityControl >

    </>
}