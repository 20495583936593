import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'

import DatePicker from "react-datepicker"
import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function ModalCustomerInsert(props) {

    const [selectedImage, setSelectedImage] = useState(null);
    const [phone, setphone] = useState("");
    const [tcNo, setTcNo] = useState("");

    var inputCustomerFullname = useRef(null);
    var inputCustomerIdNo = useRef(null);
    var inputCustomerPhone = useRef(null);
    var inputCustomerMail = useRef(null);
    var inputCustomerAddress = useRef(null);
    var inputCustomerAddressDistrict = useRef(null);
    var inputCustomerJob = useRef(null);


    function CustomerInsertClick() {

        var birthDate = calendarDate, date = birthDate.getFullYear() + '/' + (birthDate.getMonth() + 1) + '/' + birthDate.getDate();

        var dataBlock = {
            // ek_customer_ID: props.selectedData.ek_customer_ID,
            ek_customer_fullname: inputCustomerFullname.current.value,
            id_no: parseInt(inputCustomerIdNo.current.value),
            ek_customer_phone: inputCustomerPhone.current.value,
            ek_customer_mail: inputCustomerMail.current.value,
            ek_customer_address: inputCustomerAddress.current.value,
            ek_customer_address_district: inputCustomerAddressDistrict.current.value,
            ek_job_ID: inputCustomerJob.current.value,
            ek_customer_birthdate: date,
            ek_customer_image: selectedImage,

        }


        var controller = new ValidationController();

        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {
                console.log(dataBlock);
                DataCenter().Customer.InsertCustomer(dataBlock).then(r => {
                    // Ekmekk().Customer

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        console.log(r.data);
                        dataBlock["ek_customer_image"] = r.data.imagePath;
                        dataBlock["ek_customer_ID"] = r.data.lastID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        if (r.data.error != undefined && r.data.error != null) {
                            Ekmekk().Alert.UnblockUI();
                            Ekmekk().Alert.FailMail();
                        }
                        else {
                            Ekmekk().Alert.Fail();

                        }
                    }
                })
            }
            else {
                dataBlock["ek_customer_ID"] = props.selectedData.ek_customer_ID;
                console.log(dataBlock);

                DataCenter().Customer.UpdateCustomer(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_customer_image"] = r.data.data.ek_customer_image;
                        console.log(r.data.data);

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }


        }

    }

    // -------------- İLÇELER ---------------- //


    const ilceler = ["Adalar", "Arnavut", "Bağcılar", "Bahçelievler", "Bakırköy", "Başakşehir", "Bayrampaşa", "Beşiktaş", "Beykoz", "Beylikdüzü", "Beyoğlu", "Büyükçekmece",
        "Çatalca", "Çekmeköy", "Esenler", "Esenyurt", "Eyüp", "Fatih", "Gaziosmanpaşa", "Güngören", "Kadıköy", "Kağıthane", "Kartal", "Küçükçekmece", "Maltepe",
        "Pendik", "Sancaktepe", "Sarıyer", "Silivri", "Sultanbeyli", "Sultangazi", "Şile", "Şişli", "Tuzla", "Ümraniye", "Üsküdar", "Zeytinburnu"
    ];


    function IlceItem(item) {
        return <option value={item} >{item}</option>
    }

    // -------------- MESLEKLER ---------------- //


    const [jobList, setJobList] = useState([])

    useEffect(() => {
        DataCenter().Job.ListJob().then(r => {
            setJobList(r.data);
        })


    }, [])

    function JobItem(item) {
        return <option value={item.ek_job_ID} selected={props.selectedData != undefined && props.selectedData.ek_job == item.ek_job}>{item.ek_job}</option>
    }


    // -------------- TARİH ---------------- //

    const [calendarDate, setCalendarDate] = useState(new Date());


    // -------------------------------------

    const [show, setShow] = useState(false);

    function ClickEvent() {

        if (props.selectedData != undefined) {

            console.log(props.selectedData);

            let strDay = props.selectedData.ek_customer_birthdate;
            console.log(strDay);
            // var res = strDay.split(".");

            // var temp = res[0];
            // res[0] = res[1];
            // res[1] = temp;

            // var date = new Date(res);

            // setCalendarDate(date);

            setphone(props.selectedData.ek_customer_phone);
            setTcNo(props.selectedData.ek_customer_id_no);
        }

        setShow(true)
    }

    function ViewTittle() {
        return <h5 className="font-boldest">{props.isUpdate == true ? "Müşteri Güncelle" : "Müşteri Oluştur"}</h5>
    }

    return <>

        <BrowserView>
            {
                props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    <span className="svg-icon svg-icon-md">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <circle fill="#000000" cx={9} cy={15} r={6} />
                            <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                        </g>
                    </svg> */}
                        {/*end::Svg Icon*/}

                    </span>Müşteri Oluştur</button>
                    : ""
            }
        </BrowserView>
        <MobileView>
            {
                props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary btn-block font-weight-bold text-center" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    <span className="svg-icon svg-icon-md">
                        {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <circle fill="#000000" cx={9} cy={15} r={6} />
                            <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                        </g>
                    </svg> */}
                        {/*end::Svg Icon*/}

                    </span>Müşteri Oluştur</button>
                    : ""
            }
        </MobileView>


        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={ClickEvent} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>

                        <ViewTittle />


                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="customerForm">
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}
                    {/* FOTO SEÇİMİ */}
                    {
                        ImagePicker(function (r) {
                            setSelectedImage(r);

                        }, true, false, props.selectedData == undefined ? null : Ekmekk().URL + '/images/' + props.selectedData.ek_customer_image)
                    }
                    <br />
                    <Validator>
                        {/* KULLANICI İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">İsim Soyisim: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputCustomerFullname} className="form-control form-control-lg form-control-solid" name="customerName" defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_fullname : ""} placeholder="İsim Soyisim" type="text" />
                            </div>
                        </div>
                        {/* TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">TC: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="onlyNumber"
                                    // @ts-ignore
                                    maxLength="11" ref={inputCustomerIdNo} onChange={(e) => {
                                        setTcNo((e.target.value));

                                    }} value={tcNo} className="form-control form-control-lg form-control-solid" name="phone" placeholder="xxx-xxx-xxx-xx" type="tel" defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_id_no : ""} />
                            </div>
                        </div>
                        {/* TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Telefon: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber"
                                    // @ts-ignore
                                    maxLength="10" ref={inputCustomerPhone} onChange={(e) => {
                                        setphone((e.target.value));

                                    }} value={phone} className="form-control form-control-lg form-control-solid" name="phone" placeholder="xxx-xxx-xx-xx" type="tel" defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_phone : ""} />
                            </div>
                        </div>
                        {/* E-MAIL */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Email:</label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyMail" ref={inputCustomerMail} className="form-control form-control-lg form-control-solid" type="email" placeholder="email@mail.com" name="example-email-input" defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_mail : ""} />
                            </div>
                        </div>
                        {/* KÖPEK CİNSİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Doğum Tarihi: </label>

                            <div className="col-lg-9 col-xl-9">

                                <DatePicker
                                    selected={calendarDate}
                                    // @ts-ignore
                                    onChange={date => setCalendarDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control form-control-lg form-control-solid w-100"
                                />

                            </div>

                        </div>
                        {/* E-MAIL */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Meslek: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputCustomerJob} className="form-control form-control-lg form-control-solid" id="select_banner_type" defaultValue={props.selectedData != undefined ? props.selectedData.ek_job_ID : ""}>
                                    <option value="-1">Lütfen Meslek Seçiniz</option>
                                    {
                                        jobList.map(item => {
                                            return JobItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* E-MAIL */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">İlçe: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputCustomerAddressDistrict} className="form-control form-control-lg form-control-solid" id="select_banner_type" defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_address_district : ""}>
                                    <option value="-2">Lütfen İlçe Seçiniz</option>
                                    {
                                        ilceler.map(item => {
                                            return IlceItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* MÜŞTERİ AÇIKLAMASI */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Adres:</label>
                            <div className="col-lg-9 col-xl-9">
                                <textarea ref={inputCustomerAddress} className="form-control" name="textarea" rows={3} placeholder="Lütfen adres giriniz." defaultValue={props.selectedData != undefined ? props.selectedData.ek_customer_address : ""} />
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    {

                    }
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={CustomerInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Oluştur"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}