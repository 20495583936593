
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function ServiceController() {

    function UpdateNotification(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/notification', {
                ek_notification_ID: dataBlock.ek_notification_ID,
                message: dataBlock.message,
                mail: dataBlock.mail,
                sms: dataBlock.sms,
                type: dataBlock.type,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListNotification() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/notification',{
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    
    return {
        UpdateNotification, ListNotification
    }
}