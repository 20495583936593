
import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'


import ModalProductInsert from "../../Modal/Product/ProductInsert"
import SubHeader from '../../Thema/sub-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import NullPage from '../../Ekmekk/Views/NullPage'
import ModalStockInsert from '../../Modal/Stock/StockInsert'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";


export default function Stock() {

    const { productID } = useParams();

    var inputCategorySearch = useRef(null)
    const [categoryList, setCategoryList] = useState([])

    const [stockList, setStockList] = useState([]);
    const [mainList, setMainList] = useState([]);

    const [productData, setProductData] = useState([]);

    var history = useHistory();

    useEffect(() => {

        DataCenter().Stock.ListProductStock(productID).then(r => {

            setStockList(r.data);
            setMainList(r.data);

            var myData = []
            if (r.data.length > 0) {
                myData["photo"] = r.data[0].ek_product_image;
                myData["name"] = r.data[0].ek_product_name;
                myData["brand"] = r.data[0].ek_product_brand_name;
                myData["category"] = r.data[0].ek_product_category_name;
            }


            console.log(myData);

            setProductData(myData);
        })

        DataCenter().Category.ListCategory().then(r => {
            setCategoryList(r.data);
        })
    }, [])


    function StockInsertClick(caseData, data) {

        if (caseData == "inserted") {

            // alert(JSON.stringify(data));
            data["ek_product_image"] = productData.photo;
            data["ek_product_name"] = productData.name;
            data["ek_product_brand_name"] = productData.brand;
            data["ek_product_category_name"] = productData.category;

            stockList.push(data);
            setStockList([...stockList])
        }
        else if (caseData == "updated") {
            for (var i = 0; i < stockList.length; i++) {
                if (stockList[i].ek_product_stock_ID == data.ek_product_stock_ID) {

                    if (data.ek_product_stock == null) {
                        var tempStock = stockList[i].ek_product_stock;
                    }
                    else {
                        var tempStock = data.ek_product_stock
                    }

                    var temp = stockList[i].ek_product_image;
                    var tempName = stockList[i].ek_product_name;
                    var tempBrand = stockList[i].ek_product_brand_name;
                    var tempCategory = stockList[i].ek_product_category_name;

                    stockList[i] = data;

                    stockList[i].ek_product_image = temp;
                    stockList[i].ek_product_name = tempName;
                    stockList[i].ek_product_brand_name = tempBrand;
                    stockList[i].ek_product_category_name = tempCategory;
                    stockList[i].ek_product_stock = tempStock;

                }
            }

            setStockList([...stockList]);
        }
    }


    function StockDeleteClick(item) {

        // Ekmekk().Alert.Delete((e) => {
        //     if (e) {
        //         DataCenter().Product.DeleteProduct(item.ek_product_ID).then(r => {
        //             if (r.status) {
        //                 var list2 = [...list];
        //                 for (var i = 0; i < list2.length; i++) {
        //                     if (list2[i].ek_product_ID == item.ek_product_ID) {
        //                         list2.splice(i, 1)
        //                     }
        //                 }
        //                 setList(list2);
        //             }
        //         })

        //     }
        // })


    }

    function CategoryItem(item) {
        return <option value={item.ek_product_category_name}>{item.ek_product_category_name}</option>
    }

    function ChangeSearchText(e) {
        var filteredList = mainList.filter(item => item.ek_product_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setStockList(filteredList);

    }

    function ChangeBrandText(e) {
        var filteredList = mainList.filter(item => item.ek_product_brand.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setStockList(filteredList);

    }

    function ChangeCategoryText(e) {

        if (inputCategorySearch.current.value == 0) {
            setStockList(mainList);
        }
        else {
            var filteredList = mainList.filter(item => item.ek_product_category_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
            setStockList(filteredList);
        }

    }


    return <>

        <BrowserView>

            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-3">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeBrandText} className="form-control" placeholder="Marka Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-2">
                        <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">Kategori:</label>
                            <select ref={inputCategorySearch} onChange={ChangeCategoryText} className="form-control" id="kt_datatable_search_type">
                                <option value={0}>All</option>
                                {
                                    categoryList.map(item => {
                                        return CategoryItem(item)
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-2">
                        <AuthorityControl pageCode={52}>
                            <ModalStockInsert isSale={false} insertClick={StockInsertClick} productID={productID} />
                        </AuthorityControl >

                    </div>
                </div>

            </div>
        </BrowserView>

        <MobileView>
            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                        <AuthorityControl pageCode={52}>
                            <ModalStockInsert isSale={false} insertClick={StockInsertClick} productID={productID} />
                        </AuthorityControl >

                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Ürün Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeBrandText} className="form-control" placeholder="Marka Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-2">
                        <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">Kategori:</label>
                            <select ref={inputCategorySearch} onChange={ChangeCategoryText} className="form-control" id="kt_datatable_search_type">
                                <option value={0}>All</option>
                                {
                                    categoryList.map(item => {
                                        return CategoryItem(item)
                                    })
                                }
                            </select>
                        </div>
                    </div>

                </div>

            </div>

        </MobileView>

        
        {/*end::Search Form*/}

        <AuthorityControl pageCode={51}>
            {
                stockList.length != 0 ? TableList(stockList) : <NullPage />
            }
        </AuthorityControl>

    </>

    function TableList(data) {


        let history = useHistory();

        const columns = [

            /* {
               title: <span className="text">Sipariş ID</span>, field: 'user_orderID'
             },*/
            {
                title: <span className="text">Foto</span>, render: data => <div className="symbol symbol-50 symbol-light mr-3 flex-shrink-0">

                    <img src={data.ek_product_image == null || data.ek_product_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + data.ek_product_image} alt="" className="h-50" />
                </div>


            },
            {
                title: <span className="text">Ürün Adı</span>, render: data => <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_name}</a>
            },
            {
                title: <span className="text">Ürün Markası</span>, render: data => <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_brand_name}</a>
            },
            {
                title: <span className="text">Alış Fiyatı</span>, render: data => <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_purchase_price}₺</a>
            },
            {
                title: <span className="text">Satış Fiyatı</span>, render: data => <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_sale_price}₺</a>
            },
            {
                title: <span className="text">Stok</span>, render: data => <div>
                    <span className="text-info font-weight-bolder d-block font-size-lg">{data.ek_product_stock}</span>
                </div>
            },
            {
                title: <span className="text">Kategori</span>, render: data => <span className="label-success"/*className={data.label}*/>{data.ek_product_category_name}</span>
            },
            {
                title: <span className="text">Detay</span>, render: data => <div className="pr-0">
                    {/* <a href="#" className="btn btn-icon btn-light btn-hover-primary btn-sm">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000" />
                                    <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </a> */}
                    <div>
                        <div className="btn-group">
                            <AuthorityControl pageCode={53}>
                                <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">

                                        <ModalStockInsert isUpdateTable={true} isUpdate={true} selectedData={data} insertClick={StockInsertClick} />

                                    </span>
                                </div>
                            </AuthorityControl>
                            {/* <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                <span className="svg-icon svg-icon-sm svg-icon-primary">

                                    <FontAwesomeIcon onClick={() => StockDeleteClick(data)} icon={faTrash}></FontAwesomeIcon>

                                </span>
                            </div> */}
                        </div>


                    </div>
                    {/* <a href="#" className="btn btn-icon btn-light btn-hover-primary btn-sm">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </a>
                    <button onClick={() => ProductDeleteClick(data)} className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1">
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero" />
                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </button> */}
                </div>

            },


        ]

        const handleRowClick = (event, rowData) => {
            const index = data.indexOf(rowData)

            localStorage.setItem("selectedItem", JSON.stringify(data[index]))


            // history.push("/customer/customer-profile");


            /* if (Ekmekk().Profile.userType == UserType.MASTER) {
               history.push("/master/qupo/orders/order-detail/" + data[index].companyID + "/" + data[index].user_orderID);
             }
             else {
               history.push("/business/orders/order-detail/" + data[index].user_orderID);
             }*/


        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                    }

                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }

}



