import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import ModalCustomerInsert from "../../../Modal/Customer/CustomerInsert"
// import ModalDogInsert from "../../../Modal/Customer/DogInsert"
// import ModalPayDebt from "../../../Modal/Customer/PayDebt"

import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from "react-router-dom";
import SubHeader from '../../../Thema/sub-header'
import DataCenter from '../../../Ekmekk/DataCenter/datacenter'
import Ekmekk from '../../../Ekmekk/Helper/ekmekk'
import NullPage from '../../../Ekmekk/Views/NullPage'
import AuthorityControl from '../../../Ekmekk/Auth/AuthorityControl'
import Chart from "react-apexcharts";
import Sticky from "react-sticky-el";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faMoneyBillAlt, faSyringe, faSortDown, faTrash } from "@fortawesome/free-solid-svg-icons";


export default function KullaniciAnasayfa() {

    const [index, setindex] = useState(0);

    const [customerData, setCustomerData] = useState([]);
    const [customerDog, setCustomerDog] = useState([]);
    const [vaccineList, setVaccineList] = useState([]);

    var history = useHistory();
    // @ts-ignore


    useEffect(() => {



        console.log(localStorage.getItem("myUserID"));

        DataCenter().User.ListHomePage(localStorage.getItem("myUserID")).then(r => {

            if (r.status) {
                setCustomerData(r.data.customer[0]);
                // console.log(r.data)
                setCustomerDog(r.data.dog);
                setVaccineList(r.data.vaccine);
            }

        })

        // DataCenter().Customer.ShowCustomerProfile(id).then(r => {
        //     console.log(r.data);
        //     setCustomerData(r.data.customer[0]);
        //     setLastProcess(r.data.lasProcess);


        //     // BORÇ OLMAYANLARIN ÇIKARTILMASI
        //     var debtData = [];
        //     debtData = r.data.debt;
        //     for (let i = 0; i < debtData.length; i++) {

        //         if (debtData[i].debt <= 0) {
        //             debtData.splice(i, 1)
        //         }
        //     }

        //     if (debtData.length > 0)
        //         setLastDebt(debtData);
        //     else
        //         setLastDebt([]);

        //     console.log(debtData);
        // });

        // // @ts-ignore
        // DataCenter().Customer.ListCustomerDog(id).then(r => {
        //     console.log(r.data);
        //     setCustomerDog(r.data);
        // })

    }, [])

    function DogInsertClick(data) {

        // data["debt"] = "0";
        // data["profit"] = "0";
        // data["last_process_count"] = "0";
        // data["ek_dog_breed_name"] = 
        data["educationCount"] = 0;
        data["activeEducationPercentage"] = 0;
        data["totalEducationPercentage"] = 0;
        customerDog.push(data);
        setCustomerDog([...customerDog])

    }


    function CustomerDogList(item) {


        function ChartMy(props) {

            const [chartOptions, setChartOptions] = useState({

                options: {
                    colors: ['#32CD32', '#AAAAAA'],
                    series: props.series,
                    labels: ['Biten', 'Kalan'],
                    classNames: ['apexcharts-series-collapsed'],
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    dataLabels: {
                        enabled: true,
                    },
                    // labels: ['Team A', 'Team B'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                // fill: {
                //     colors: ['#32CD32', '#AAAAAA']
                // }


            });





            return (
                <Chart options={chartOptions.options} series={chartOptions.options.series} showSeries={null} type="pie" width="70%" height="70%" />
            );

        }

        return <>

            {/*begin::Item*/}
            <div className="d-flex flex-wrap align-items-center mb-10">
                {/*begin::Symbol*/}
                <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4 align-items-center">
                    {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                    <img src={item.ek_customer_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + item.ek_customer_dog_image} alt="" className="h-50" />
                    {/* <img src={"https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" /> */}
                </div>
                {/*begin::Title*/}
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3 align-items-center">
                    <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{item.ek_customer_dog_name}</a>
                    <span className="text-muted font-weight-bold font-size-sm my-1">{item.ek_customer_dog_gender} - {item.dog_age}</span>
                    <span className="text-muted font-weight-bold font-size-sm">{item.ek_dog_breed_name} -
                        <span className="text-muted font-weight-bold">{item.ek_dog_sub_breed_name}</span></span>
                </div>

                {/*end::Symbol*/}
                {/*begin::Title*/}
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-1 align-items-center">
                    <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">Toplam Eğitim</a>
                    <ChartMy series={[item.totalEducationPercentage, 100 - item.totalEducationPercentage]} />
                </div>
                {/*begin::Title*/}
                {/* <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-1 align-items-center">
                    <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">Aktif Eğitim</a>
                    {
                        item.activeEducationPercentage != null ? <ChartMy series={[item.activeEducationPercentage, 100 - item.activeEducationPercentage]} /> : <ChartMy series={[0, 100]} />
                    }

                </div> */}
                {/*end::Title*/}
                {/*begin::Info*/}
                <div className="d-flex align-items-center py-lg-0 py-2">
                    <div className="my-lg-0 my-1">

                        <button onClick={() => { history.push("/dashboard/user/dog-profile/" + item.ek_customer_dog_ID) }} className="btn btn-sm btn-info font-weight-bolder text-uppercase mr-2">Profil</button>

                        {/* <ModalDogInsert isUpdate={false} isSale={false} ek_customer_ID={id} insertClick={DogInsertClick} /> */}

                        {/* <ModalCustomerInsert isUpdate={true} selectedData={customerData} insertClick={CustomerInsertClick} /> */}

                        {/* <NavLink to={'/dashboard/sale/education-selling/' + customerData.ek_customer_ID}>
                                            <a className="btn btn-sm btn-primary font-weight-bolder text text-uppercase mr-3">Güncelle</a>

                                        </NavLink> */}
                        {/* <ModalPayDebt selectedData={customerData} /> */}
                        {/* <AuthorityControl pageCode={5}> */}

                        {/* <button onClick={() => CustomerDeleteClick(customerData.ek_customer_ID)} className="btn btn-sm btn-danger font-weight-bolder text-uppercase">Kaldır</button> */}
                        {/* </AuthorityControl> */}
                    </div>
                    {/* <div className="d-flex flex-column text-right">
                                            <span className="text-dark-75 font-weight-bolder font-size-h4">24,900</span>
                                            <span className="text-muted font-size-sm font-weight-bolder">votes</span>
                                        </div> */}
                </div>
                {/*end::Info*/}

            </div>
            {/*end::Item*/}

        </>

    }

    function VaccineItemList(item) {

        return <>

            <div className="d-flex align-items-center flex-wrap mb-8">
                {/*begin::Symbol*/}
                {
                    item.ek_was_vaccine_date != null ? <div className="symbol symbol-40 symbol-light-success mr-2">
                        <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-success">
                                <FontAwesomeIcon icon={faSyringe} />
                            </span>
                        </span>
                    </div> : <div className="symbol symbol-40 symbol-light-gray mr-2">
                        <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-gray">
                                <FontAwesomeIcon icon={faSyringe} />
                            </span>
                        </span>
                    </div>
                }

                {/*end::Symbol*/}
                {/*begin::Text*/}
                <div className="d-flex flex-column flex-grow-1 mr-2 align-items-center">
                    <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">{item.ek_vaccine_name}</a>
                    <span className="text-muted font-weight-bold">{item.ek_was_vaccine_date != null ? item.ek_was_vaccine_date : item.ek_will_vaccine_date}</span>
                </div>
                {/*end::Text*/}
                <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{item.ek_was_vaccine_date != null ? "Yapıldı" : "Bekliyor"}</span>
            </div>

        </>

    }

    return <>


        <div>

            <div className="row">

                <div className="col-xl-8">


                    <div className="col-xl-12">

                        <div className="card card-custom gutter-b">
                            <div className="card-body">
                                <div className="d-flex">
                                    {/*begin: Pic*/}
                                    <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                        <div className="symbol symbol-50 symbol-lg-120">
                                            {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                            <img src={customerData.length != 0 ? customerData.ek_customer_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + customerData.ek_customer_image : ""} alt="" className="h-50" />
                                            {/* <img src={"https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" /> */}
                                        </div>
                                        <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                            <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                        </div>
                                    </div>
                                    {/*end: Pic*/}
                                    {/*begin: Info*/}
                                    <div className="flex-grow-1">
                                        {/*begin: Title*/}
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <div className="mr-3">
                                                {/*begin::Name*/}
                                                <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{customerData.length != 0 ? customerData.ek_customer_fullname : ""}
                                                    {/* <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">Bilal Çelik */}
                                                    <i className="flaticon2-correct text-success icon-md ml-2" /></a>
                                                {/*end::Name*/}
                                                {/*begin::Contacts*/}
                                                <div className="d-flex flex-wrap my-2">
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{customerData.length != 0 ? customerData.ek_customer_mail : ""}</a>
                                                    {/* </span>bilalcelik@gmail.com</a> */}
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <mask fill="white">
                                                                        <use xlinkHref="#path-1" />
                                                                    </mask>
                                                                    <g />
                                                                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{customerData.length != 0 ? customerData.ek_customer_phone : ""}</a>
                                                    {/* </span>555 555 55 55</a> */}
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <rect x={0} y={0} width={24} height={24} />
                                                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{customerData.length != 0 ? customerData.ek_customer_address_district : ""}</a>
                                                    {/* </span>TO DO 4</a> */}
                                                    <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 ml-1">
                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                    <mask fill="white">
                                                                        <use xlinkHref="#path-1" />
                                                                    </mask>
                                                                    <g />
                                                                    <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                            {/*end::Svg Icon*/}
                                                        </span>{customerData.length != 0 ? customerData.ek_job : ""}</a>
                                                    {/* </span>TO DO 5</a> */}
                                                </div>
                                                {/*end::Contacts*/}
                                            </div>
                                            <div className="my-lg-0 my-1">

                                                {/* <ModalDogInsert isUpdate={false} isSale={false} ek_customer_ID={id} insertClick={DogInsertClick} /> */}

                                                {/* <ModalCustomerInsert isUpdate={true} selectedData={customerData} insertClick={CustomerInsertClick} /> */}

                                                {/* <NavLink to={'/dashboard/sale/education-selling/' + customerData.ek_customer_ID}>
                                                    <a className="btn btn-sm btn-primary font-weight-bolder text text-uppercase mr-3">Güncelle</a>

                                                </NavLink> */}
                                                {/* <ModalPayDebt selectedData={customerData} /> */}
                                                {/* <AuthorityControl pageCode={5}> */}

                                                {/* <button onClick={() => CustomerDeleteClick(customerData.ek_customer_ID)} className="btn btn-sm btn-danger font-weight-bolder text-uppercase">Kaldır</button> */}
                                                {/* </AuthorityControl> */}
                                            </div>
                                        </div>
                                        {/*end: Title*/}
                                        {/*begin: Content*/}
                                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                                            {/* <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">asdasdasd</div> */}
                                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">{customerData.length != 0 ? customerData.ek_customer_address : ""}</div>
                                            <div className="d-flex flex-wrap align-items-center py-2">

                                                {/* <div className="flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0">
                        <span className="font-weight-bold">Progress</span>
                        <div className="progress progress-xs mt-2 mb-2">
                            <div className="progress-bar bg-success" role="progressbar" style={{ width: '63%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                        <span className="font-weight-bolder text-dark">78%</span>
                    </div> */}
                                            </div>
                                        </div>
                                        {/*end: Content*/}
                                    </div>
                                    {/*end: Info*/}
                                </div>
                                <div className="separator separator-solid my-7" />
                                {/*begin: Items*/}
                                <div className="d-flex align-items-center flex-wrap">
                                    {/*begin: Item*/}
                                    <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                        <span className="mr-4">
                                            <span className="svg-icon svg-icon-light-dark svg-icon-3x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Shopping\Wallet2.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <rect x={0} y={0} width={24} height={24} />
                                                    <rect fill="#000000" opacity="0.3" x={2} y={2} width={10} height={12} rx={2} />
                                                    <path d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z" fill="#000000" />
                                                </g>
                                            </svg>{/*end::Svg Icon*/}</span>
                                        </span>
                                        <div className="d-flex flex-column text-dark-75">
                                            <span className="font-weight-bolder font-size-sm">Toplam Alışveriş</span>
                                            <span className="font-weight-bolder font-size-h5">
                                                <span className="text-dark-50 font-weight-bold">₺</span>{customerData.length != 0 ? customerData.total_order_price : ""}</span>
                                        </div>
                                    </div>
                                    {/*end: Item*/}
                                    {/*begin: Item*/}
                                    <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                        <span className="mr-4">
                                            <span className="svg-icon svg-icon-light-dark svg-icon-3x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Clip.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <rect x={0} y={0} width={24} height={24} />
                                                    <path d="M14,16 L12,16 L12,12.5 C12,11.6715729 11.3284271,11 10.5,11 C9.67157288,11 9,11.6715729 9,12.5 L9,17.5 C9,19.4329966 10.5670034,21 12.5,21 C14.4329966,21 16,19.4329966 16,17.5 L16,7.5 C16,5.56700338 14.4329966,4 12.5,4 L12,4 C10.3431458,4 9,5.34314575 9,7 L7,7 C7,4.23857625 9.23857625,2 12,2 L12.5,2 C15.5375661,2 18,4.46243388 18,7.5 L18,17.5 C18,20.5375661 15.5375661,23 12.5,23 C9.46243388,23 7,20.5375661 7,17.5 L7,12.5 C7,10.5670034 8.56700338,9 10.5,9 C12.4329966,9 14,10.5670034 14,12.5 L14,16 Z" fill="#000000" fillRule="nonzero" transform="translate(12.500000, 12.500000) rotate(-315.000000) translate(-12.500000, -12.500000) " />
                                                </g>
                                            </svg>{/*end::Svg Icon*/}</span>

                                        </span>
                                        <div className="d-flex flex-column text-dark-75">
                                            <span className="font-weight-bolder font-size-sm">Borç</span>
                                            <span className="font-weight-bolder font-size-h5">
                                                <span className="text-dark-50 font-weight-bold">₺</span>{customerData.length != 0 ? customerData.debt : ""}</span>
                                        </div>
                                    </div>
                                    {/*end: Item*/}
                                    {/*begin: Item*/}
                                    <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                        <span className="mr-4">
                                            <span className="svg-icon svg-icon-light-dark svg-icon-3x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Clipboard.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <rect x={0} y={0} width={24} height={24} />
                                                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
                                                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
                                                    <rect fill="#000000" opacity="0.3" x={7} y={10} width={5} height={2} rx={1} />
                                                    <rect fill="#000000" opacity="0.3" x={7} y={14} width={9} height={2} rx={1} />
                                                </g>
                                            </svg>{/*end::Svg Icon*/}</span>

                                        </span>
                                        <div className="d-flex flex-column text-dark-75">
                                            <span className="font-weight-bolder font-size-sm">İşlem Sayısı</span>
                                            <span className="font-weight-bolder font-size-h5">
                                                <span className="text-dark-50 font-weight-bold">₺</span>{customerData.length != 0 ? customerData.process_count : ""}</span>
                                        </div>
                                    </div>
                                </div>
                                {/*begin: Items*/}


                            </div>
                        </div>

                    </div>



                    <div className="col-xl-12">
                        {/*begin::List Widget 14*/}
                        <div className="card card-custom card-stretch gutter-b">
                            {/*begin::Header*/}
                            <div className="card-header border-0">
                                <h3 className="card-title font-weight-bolder text-dark">Köpeklerim</h3>
                                <div className="card-toolbar">
                                    <div className="dropdown dropdown-inline">
                                        <a href="#" className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="ki ki-bold-more-ver" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/*end::Header*/}
                            {/*begin::Body*/}
                            <div className="card-body pt-2">

                                {
                                    customerDog && customerDog.map(item => {
                                        return CustomerDogList(item);
                                    })
                                }

                            </div>
                            {/*end::Body*/}
                        </div>
                        {/*end::List Widget 14*/}
                    </div>


                </div>


                <div className="col-xl-4">

                    <Sticky>

                        <div className="col-xl-12">

                            {/*begin::List Widget 7*/}
                            <div className="card card-custom gutter-b card-stretch">
                                {/*begin::Header*/}
                                <div className="card-header border-0">
                                    <h3 className="card-title font-weight-bolder text-dark">Aşı Listem</h3>
                                    <div className="card-toolbar">
                                        <div className="dropdown dropdown-inline">
                                            <a href="#" className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="ki ki-bold-more-ver" />
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                {/*end::Header*/}
                                {/*begin::Body*/}
                                <div className="card-body pt-0">

                                    {
                                        vaccineList.map(item => {
                                            return VaccineItemList(item);
                                        })
                                    }

                                </div>
                                {/*end::Body*/}
                            </div>
                            {/*end::List Widget 7*/}
                        </div>

                    </Sticky>

                </div>

            </div>





        </div>


    </>


}

<div className="row">


    <div className="col-md"></div>

    <div className="col-xl-4">
        {/*begin::List Widget 9*/}
        <div className="card card-custom card-stretch gutter-b">
            {/*begin::Header*/}
            <div className="card-header align-items-center border-0 mt-4">
                <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">Aşılarım</span>
                    <span className="text-muted mt-3 font-weight-bold font-size-sm">890,344 Sales</span>
                </h3>
                <div className="card-toolbar">
                    <div className="dropdown dropdown-inline">
                        <a href="#" className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="ki ki-bold-more-hor" />
                        </a>

                    </div>
                </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-4">
                {/*begin::Timeline*/}
                <div className="timeline timeline-6 mt-3">
                    {/*begin::Item*/}
                    <div className="timeline-item align-items-start">
                        {/*begin::Label*/}
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">06.07</div>
                        {/*end::Label*/}
                        {/*begin::Badge*/}
                        <div className="timeline-badge">
                            <i className="fa fa-genderless text-warning icon-xl" />
                        </div>
                        {/*end::Badge*/}
                        {/*begin::Text*/}
                        <div className="font-weight-bold font-size-lg timeline-content text-dark-75 pl-3">Biontech - Köpüş</div>
                        {/*end::Text*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="timeline-item align-items-start">
                        {/*begin::Label*/}
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">06.07</div>
                        {/*end::Label*/}
                        {/*begin::Badge*/}
                        <div className="timeline-badge">
                            <i className="fa fa-genderless text-success icon-xl" />
                        </div>
                        {/*end::Badge*/}
                        {/*begin::Content*/}
                        <div className="timeline-content d-flex">
                            <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">Kuduz - Tarçın</span>
                        </div>
                        {/*end::Content*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="timeline-item align-items-start">
                        {/*begin::Label*/}
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">06.07</div>
                        {/*end::Label*/}
                        {/*begin::Badge*/}
                        <div className="timeline-badge">
                            <i className="fa fa-genderless text-warning icon-xl" />
                        </div>
                        {/*end::Badge*/}
                        {/*begin::Text*/}
                        <div className="font-weight-bold font-size-lg timeline-content text-dark-75 pl-3">Biontech - Köpüş</div>
                        {/*end::Text*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="timeline-item align-items-start">
                        {/*begin::Label*/}
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">06.07</div>
                        {/*end::Label*/}
                        {/*begin::Badge*/}
                        <div className="timeline-badge">
                            <i className="fa fa-genderless text-success icon-xl" />
                        </div>
                        {/*end::Badge*/}
                        {/*begin::Content*/}
                        <div className="timeline-content d-flex">
                            <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">Kuduz - Tarçın</span>
                        </div>
                        {/*end::Content*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="timeline-item align-items-start">
                        {/*begin::Label*/}
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">07.07</div>
                        {/*end::Label*/}
                        {/*begin::Badge*/}
                        <div className="timeline-badge">
                            <i className="fa fa-genderless text-warning icon-xl" />
                        </div>
                        {/*end::Badge*/}
                        {/*begin::Text*/}
                        <div className="font-weight-bold font-size-lg timeline-content text-dark-75 pl-3">Biontech - Köpüş</div>
                        {/*end::Text*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="timeline-item align-items-start">
                        {/*begin::Label*/}
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">07.07</div>
                        {/*end::Label*/}
                        {/*begin::Badge*/}
                        <div className="timeline-badge">
                            <i className="fa fa-genderless text-success icon-xl" />
                        </div>
                        {/*end::Badge*/}
                        {/*begin::Content*/}
                        <div className="timeline-content d-flex">
                            <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">Kuduz - Tarçın</span>
                        </div>
                        {/*end::Content*/}
                    </div>
                    {/*end::Item*/}
                </div>
                {/*end::Timeline*/}
            </div>
            {/*end: Card Body*/}
        </div>
        {/*end: List Widget 9*/}
    </div>

    <div className="col-md"></div>

</div>
