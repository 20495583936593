
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function StockController() {

    function InsertStock(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            
            axios.post(Ekmekk().URL + '/admin/product/stock', {
                ek_product_ID: dataBlock.ek_product_ID,
                ek_product_stock: dataBlock.ek_product_stock,
                ek_product_purchase_price: dataBlock.ek_product_purchase_price,
                ek_product_sale_price: dataBlock.ek_product_sale_price,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`,
                }
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateStock(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.put(Ekmekk().URL + '/admin/product/stock', {
                ek_product_stock_ID: dataBlock.ek_product_stock_ID,
                ek_product_purchase_price: dataBlock.ek_product_purchase_price,
                ek_product_stock: dataBlock.ek_product_stock,
                ek_product_sale_price: dataBlock.ek_product_sale_price,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`,
                }
            }).then(function (response) {
                console.log(response.data);
                ok(response.data)
            })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListProductStock(ek_product_ID) {

        const api = Ekmekk().Profile.GetProfile()
        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/product/stock/detail/' + ek_product_ID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function DeleteStock(productID) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/product/' + productID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    return {
        InsertStock, ListProductStock, DeleteStock, UpdateStock
    }
}