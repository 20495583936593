import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function ModalEducationInsert(props) {


    var inputEducationName = useRef(null);
    var inputEducationPrice = useRef(null);
    // var inputEducationLesson = useRef(null);

    function EducationInsertClick() {

        var dataBlock = {
            ek_education_name: inputEducationName.current.value,
            ek_education_price: inputEducationPrice.current.value,
            // lesson: inputEducationLesson.current.value,
        }

        var controller = new ValidationController();
        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {
                DataCenter().Education.InsertEducation(dataBlock).then(r => {

                    // alert(JSON.stringify(r))

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_education_ID"] = r.data.lastID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }
            else {
                dataBlock["ek_education_ID"] = props.selectedData.ek_education_ID;

                DataCenter().Education.UpdateEducation(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }


        }

    }


    const [show, setShow] = useState(false);


    return <>

        <BrowserView>
            {
                props.isSale == false ? <button onClick={() => setShow(true)} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Eğitim Ekle</button>
                    : ""
            }
        </BrowserView>

        <MobileView>
            {
                props.isSale == false ? <button onClick={() => setShow(true)} className="btn btn-primary btn-block font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Eğitim Ekle</button>
                    : ""
            }
        </MobileView>


        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={() => setShow(true)} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={() => setShow(true)} className="btn w-50 btn-light-info text-uppercase font-weight-bolder ">Güncelle</button> : ""
        }

        <Modal
            show={show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">

                    <div>
                        <h5 className="font-boldest">{props.isUpdate == true ? "Eğitimi Güncelle" : "Eğitim Ekle"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}
                    {/* KÖPEK İSMİ */}
                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Eğitim Adı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputEducationName} className="form-control form-control-lg form-control-solid" name="dog_name" placeholder="Eğitim Adı" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_education_name : ""} />
                            </div>
                        </div>
                        {/* KÖPEK CİNSİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Fiyat: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputEducationPrice} className="form-control form-control-lg form-control-solid" name="dog_breed" placeholder="Fiyat" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_education_price : ""} />
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false) }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={EducationInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Ekle"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}