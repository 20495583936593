import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';


export default function DogSubBreedInsert(props) {

    var inputDogSubBreedName = useRef(null);
    // var inputDogBreedID = props.dogBreedID;

    function DogSubBreedInsertClick() {
        var dataBlock = {
            ek_dog_sub_breed_name: inputDogSubBreedName.current.value,
            ek_dog_breed_ID: props.dogBreedID,
        }

        var validator = new ValidationController();

        if (validator.Control() == 0) {

            if (props.selectedData == undefined) {

                DataCenter().SubBreed.InsertDogSubBreed(dataBlock).then(r => {
                    // alert(JSON.stringify(r))

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_dog_sub_breed_ID"] = r.data.ek_dog_sub_breed_ID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }
            else {
                dataBlock["ek_dog_sub_breed_ID"] = props.selectedData.ek_dog_sub_breed_ID;
                dataBlock["ek_dog_breed_ID"] = props.selectedData.ek_dog_breed_ID;

                DataCenter().DogBreed.UpdateDogBreed(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }

    }



    const [show, setShow] = useState(false);


    return <>

        {
            props.isSale == false ? <button onClick={() => setShow(true)} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                <span className="svg-icon svg-icon-md">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <circle fill="#000000" cx={9} cy={15} r={6} />
                            <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                        </g>
                    </svg>
                    {/*end::Svg Icon*/}

                </span>Alt Cinsi Ekle</button>
                : ""
        }

        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={() => setShow(true)} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={() => setShow(true)} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">

                    <div>
                        <h5 className="font-boldest">{props.isUpdate == true ? "Alt Cins Güncelle" : "Alt Cins Ekle"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}

                    {/* KÖPEK İSMİ */}
                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Alt Cins Adı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputDogSubBreedName} className="form-control form-control-lg form-control-solid" name="dog_name" placeholder="Alt Cins Adı" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_dog_sub_breed_name : ""} />
                            </div>
                        </div>
                    </Validator>
                    {/* KÖPEK İSMİ */}
                    {/* <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Eklenecek Cins: </label>
                        <div className="col-lg-9 col-xl-9">
                            <select ref={inputDogBreedID} className="form-control form-control-lg form-control-solid" id="select_banner_type" >
                                {
                                    dogBreedList.map(item => {
                                        return BreedItem(item)
                                    })
                                }
                            </select>
                        </div>
                    </div> */}

                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false) }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={DogSubBreedInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Ekle"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}