import Swal from 'sweetalert2'
export default function AlertEkmekk() {


  function GoToLogin(callBackFunction) {
    let timerInterval
    Swal.fire({
      title: 'Geçersiz Kullanıcı!',
      html: 'Giriş ekranına yönlendiriliyorsunuz.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
        callBackFunction(true);

      }
      else {
        console.log('I was closed by the timer2222')
        callBackFunction(false);

      }
    })
  }

  function Login() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: 'Başarıyla Giriş Yapıldı'
    })
  }

  function Sure(callBackFunction) {
    Swal.fire({
      title: 'Emin Misin?',
      text: "Kabul edersen kaydedilecek!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Evet, Kaydet!',
      cancelButtonText: 'Hayır, İptal!',
      confirmButtonColor: '#26C281',
      cancelButtonColor: '#D91E18',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        Swal.fire(
          'Kaydedildi!',
          'Girdiğiniz Bilgiler Kaydedilmiştir.',
          'success'
        )
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'İptal Edildi',
          'Bilgilerinizi Tekrar Girebilirsiniz',
          'error'
        )
      }

      callBackFunction(result.value);


    })
  }

  function SureKafesGuncelleme(callBackFunction) {
    Swal.fire({
      title: 'Köpek Zaten Bir Kafeste',
      text: "Kabul edersen yeni kafese taşınacak!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Evet, Taşı!',
      cancelButtonText: 'Hayır, Taşıma!',
      confirmButtonColor: '#26C281',
      cancelButtonColor: '#D91E18',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        Swal.fire(
          'Taşınacak!',
          'Köpek Seçilen Kafese Taşınacaktır.',
          'warning'
        )
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Taşınmayacak',
          'Köpek Halen Eski Kafesinde Kalacaktır.',
          'warning'
        )
      }

      callBackFunction(result.value);


    })
  }

  function SureKonaklama(callBackFunction, totalCost) {
    Swal.fire({
      title: 'Emin Misin?',
      text: 'Devam edersen müşterinin ' + totalCost + ' ödediğini kabul edersin!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Evet, Kaydet!',
      cancelButtonText: 'Hayır, İptal!',
      confirmButtonColor: '#26C281',
      cancelButtonColor: '#D91E18',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        Swal.fire(
          'Kaydedildi!',
          'Girdiğiniz Bilgiler Kaydedilmiştir.',
          'success'
        )
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'İptal Edildi',
          'Bilgilerinizi Tekrar Girebilirsiniz',
          'error'
        )
      }

      callBackFunction(result.value);


    })
  }

  function Success() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'İşleminiz Başarıyla Gerçekleşti',
      showConfirmButton: false,
      timer: 1500
    })
  }

  function Fail() {
    Swal.fire({
      title: 'İşleminizi Gerçekleştiremedik',
      icon: 'error',
    })
  }

  function FailMail() {
    Swal.fire({
      title: 'Bu Mail Kullanılmakta',
      icon: 'error',
    })
  }

  function FailSalePrice() {
    Swal.fire({
      title: 'Geçersiz Satış Fiyatı',
      text: 'Alış fiyatından düşük değer giremezsiniz!',
      icon: 'error',
    })
  }

  function FailEgitim() {
    Swal.fire({
      icon: 'error',
      title: 'Bu Köpek Konaklamada!',
      text: 'Kafesten çıkarmadan eğitim satamazsın!',
    })
  }

  function FailDers() {
    Swal.fire({
      icon: 'error',
      title: 'Ders Yok!',
      text: 'Lütfen önce bu eğitime giderek ders tanımlayın!',
    })
  }

  function FailEğitmen() {
    Swal.fire({
      icon: 'error',
      title: 'Eğitmen Yok!',
      text: 'Lütfen önce bir eğitmen oluşturun!',
    })
  }


  function FailKonaklama() {
    Swal.fire({
      icon: 'error',
      title: 'Bu Köpek Zaten Kafeste!',
      text: 'Kafesten çıkarmadan konaklama satamazsın!',
    })
  }
  function FailGecmisKonaklama() {
    Swal.fire({
      icon: 'error',
      title: 'Eksik Fiyat!',
      text: 'Toplam fiyattan düşük ödenen tutar giremezsin!',
    })
  }

  function FillAll() {
    Swal.fire({
      title: 'Lütfen Tüm Seçimleri Yapınız',
      icon: 'warning',
    })
  }

  function Delete(callBackFunction) {
    Swal.fire({
      title: 'Emin Misin?',
      text: 'Bu İşlemi Geri Alamayacaksın!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c4c4c4',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Evet, Sil!',
      cancelButtonText: 'Hayır, İptal Et'
    }).then((result) => {


      if (result.value) {
        Swal.fire(
          'Silindi!',
          'İşleminiz başarıyla gerçekleşti.',
          'success'
        )

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'İptal Edildi',
          'Silim işlemi gerçekleşmedi.',
          'error'
        )
      }
      callBackFunction(result.value);

    }

    )

  }

  function Block() {
    Swal.fire({
      html: '<div className="spinner"> <span className="sr-only">Yükleniyor...</span></div>',
      onBeforeOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      showCancelButton: false,
      focusConfirm: false,

    })

  }

  function UnblockUI() {
    Swal.close()
  }

  return {
    Login, Sure, Success, Fail, FailMail, Delete, Block, UnblockUI, FillAll, SureKonaklama, SureKafesGuncelleme, FailKonaklama, FailEgitim, GoToLogin, FailSalePrice, FailDers, FailEğitmen, FailGecmisKonaklama
  }
}