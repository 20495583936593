import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBullhorn, faCamera, faCheck, faCoffee, faComment, faFileExcel, faFileExport, faFileImport, faFilePdf, faPrint, faStar, faTimesCircle, faUpload, la } from '@fortawesome/free-solid-svg-icons'


export default function ImagePicker(onHandler, preview, multipleImage, defaultValue, id) {
    const [selectedImage, setSelectedImage] = useState("");
    var deneme = useRef(null)
    function EventChangeInputImage(event) {



        var myFile = document.getElementById(id != null || id != undefined ? id : "upload").files;

        if (multipleImage) {

            onHandler(myFile);
        }
        else {
            onHandler(myFile[0]);
        }

        var reader = new FileReader();
        reader.onload = function (e) {

            setSelectedImage(e.target.result)

            const img = new Image();

            img.crossOrigin = 'Anonymous';
            img.src = e.target.result;

        };

        if (preview == null || preview == undefined || preview)
            reader.readAsDataURL(myFile[0])



    }
    return (<div>
        <div id="userphoto" onClick={() => deneme.current.click()} style={{ overflow: 'hidden', textAlign: 'center', borderWidth: '2px', borderStyle: 'dotted', width: '100%', borderRadius: '15px', padding: '10px' }}>



            {
                selectedImage == "" ? defaultValue == null ? <div classname="symbol-label rounded" style={{ borderRadius: 10, height: 100, backgroundSize: "cover" }} >

                    <FontAwesomeIcon className="align-middle" icon={faCamera} style={{ verticalAlign: "middle", color: "#3699FF", width: 50, height: 50, padding: 12 }} />
                    <br></br>
                Fotoğraf seçmek için tıklayın
                {selectedImage}


                </div> : "" : ""
            }
            <div className="gravatar-wrapper-128" style={{ height: '100%', overflow: 'hidden', borderRadius: '17px' }}><img className="img-fluid" id="image" src={selectedImage == "" ? (defaultValue == null ? "" : defaultValue) : selectedImage} alt="" /></div>
            <div className="change-picture-slide" style={{ top: '30px' }}>
                {multipleImage}

                <input accept="image/*" multiple={multipleImage == true ? true : false} type="file" id={id != null || id != undefined ? id : "upload"} ref={deneme} name="upload" onChange={EventChangeInputImage} style={{ visibility: 'hidden', display: 'none' }} />
            </div>



        </div>


    </div>);

}