import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'

import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';

export default function ModalAdminGroup(props) {

    var inputName = useRef(null);


    function CustomerInsertClick() {


        var dataBlock = {
            ek_admin_authority: inputName.current.value,
            ek_authority_process: inputName.current.value,
            ek_admin_authority_ID: inputName.current.value,
            authority: inputName.current.value,

        }

        var controller = new ValidationController();

        if (controller.Control() == 0) {

            var dataSet = "";
            dataBlock["authority"] = [...Ekmekk().GetPermissionList()];
            permissionList.map(item => {
                if (item.active) {
                    dataBlock.authority.map(item2 => {
                        if (item.key == item2.key) {
                            item2.active = true;
                        }
                    })
                    dataSet += item.key + ",";
                }
            })
            dataSet = dataSet.substr(0, dataSet.length - 1);
            dataBlock["ek_authority_process"] = dataSet;

            if (props.selectedItem != undefined) {
                dataBlock["ek_admin_authority_ID"] = props.selectedItem.ek_admin_authority_ID;

                DataCenter().Auth.UpdateGroup(props.selectedItem.ek_admin_authority_ID, inputName.current.value, dataSet).then(id => {

                    setShow(false);
                    props.OnHandler("updated", dataBlock)
                });
            }
            else {
                DataCenter().Auth.InsertGroup(inputName.current.value, dataSet).then(id => {

                    dataBlock["ek_admin_authority_ID"] = id;
                    setShow(false);
                    props.OnHandler("inserted", dataBlock)
                });
            }




        }


    }


    const [show, setShow] = useState(false);
    const [permissionList, setPermissionList] = useState([]);

    const groups = {
        "groupData": [
            { "name": "Ceo", active: false, id: 0 },
            { "name": "Admin", active: false, id: 1 },
            { "name": "Kasiyer", active: false, id: 2 }
        ]
    }

    const adminBanList = [0, 1, 5, 11, 26, 31, 35, 36, 37, 42, 50, 57, 61, 65, 69, 73, 77, 78, 79, 80, 81, 82, 83, 89, 93, 99];
    const cashierBanList = [0, 1, 5, 11, 26, 31, 35, 36, 37, 42, 50, 57, 61, 65, 69, 73, 77, 78, 79, 80, 81, 82, 83, 89, 93, 99, 4, 10, 25, 30, 41, 49, 53, 56, 60, 64, 68, 72, 76, 88, 92, 98];

    const [groupList, setGroupList] = useState([]);

    function GroupClick(myID, status) {

        for (let i = 0; i < groupList.length; i++) {

            if (groupList[i].id != myID) {
                groupList[i].active = false;
            }

        }

        for (let k = 0; k < permissionList.length; k++) {

            if (myID == 0 && status == true) {
                permissionList[k].active = true;
            }
            else {
                permissionList[k].active = false;

            }
        }

        if (myID == 1 && status == true) {

            // ÖNCE HEPSİNİ AKTİF ET
            for (let k = 0; k < permissionList.length; k++) {
                permissionList[k].active = true;
            }

            // LİSTEDEKİLERİ PASİF ET
            for (let z = 0; z < adminBanList.length; z++) {

                for (let l = 0; l < permissionList.length; l++) {

                    if (permissionList[l].key == adminBanList[z]) {
                        permissionList[l].active = false;
                    }
                }

            }
        }

        if (myID == 2 && status == true) {

            // ÖNCE HEPSİNİ AKTİF ET
            for (let k = 0; k < permissionList.length; k++) {
                permissionList[k].active = true;
            }

            // LİSTEDEKİLERİ PASİF ET
            for (let z = 0; z < cashierBanList.length; z++) {

                for (let l = 0; l < permissionList.length; l++) {

                    if (permissionList[l].key == cashierBanList[z]) {
                        permissionList[l].active = false;
                    }
                }

            }
        }


    }

    function ClickEvent() {

        var dizi = [...Ekmekk().GetPermissionList()];

        for (var i = 0; i < dizi.length; i++) {
            if (props.selectedItem != undefined) {
                props.selectedItem.authority.map(item => {

                    if (item.key == dizi[i].key) {
                        dizi[i].active = item.active;
                    }
                })
            }
            else {
                dizi[i]["active"] = false;
            }


        }

        console.log(dizi);
        setPermissionList(dizi);
        setGroupList(groups.groupData);


        setShow(true)
    }

    function ViewTittle() {
        return <h5 className="font-boldest">{props.selectedItem != undefined ? "Grup Güncelle" : "Grup Oluştur"}</h5>
    }

    return <>

        {
            props.selectedItem == undefined ? <button onClick={ClickEvent} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                <span className="svg-icon svg-icon-md">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <circle fill="#000000" cx={9} cy={15} r={6} />
                            <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                        </g>
                    </svg> */}
                    {/*end::Svg Icon*/}

                </span>Yeni Grup Oluştur</button>
                : <FontAwesomeIcon onClick={ClickEvent} style={{ fontSize: 2 }} icon={faPencilRuler}></FontAwesomeIcon>
        }

        {
            props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }


        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>

                        <ViewTittle />


                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="customerForm">
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}

                    <Validator>
                        {/* KULLANICI İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Grup adı : </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputName} className="form-control form-control-lg form-control-solid" name="customerName" defaultValue={props.selectedItem != undefined ? props.selectedItem.ek_admin_authority : ""} placeholder="İsim" type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-12 col-lg-12 col-form-label">Grup: </label>
                            <div className="col-lg-12 col-xl-12">
                                {
                                    groupList.map(item => {
                                        return <span onClick={() => {

                                            groupList.map(item2 => {

                                                if (item.id == item2.id) {
                                                    item2.active = !item2.active;
                                                    GroupClick(item2.id, item2.active);
                                                }
                                            })
                                            setPermissionList([...permissionList]);
                                        }} className={item.active ? "label label-lg label-light-success label-inline ml-2 mt-2" : "label label-lg label-light-secondary text-dark label-inline ml-2 mt-2"}>{item.name}</span>
                                    })
                                }

                            </div>
                        </div>
                        {/* TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-12 col-lg-12 col-form-label">Yetkileri: </label>
                            <div className="col-lg-12 col-xl-12">
                                {
                                    permissionList.map(item => {
                                        return <span onClick={() => {

                                            permissionList.map(item2 => {

                                                if (item.name == item2.name) {
                                                    item2.active = !item2.active;
                                                }
                                            })
                                            setPermissionList([...permissionList]);
                                        }} className={item.active ? "label label-lg label-light-success label-inline ml-2 mt-2" : "label label-lg label-light-secondary text-dark label-inline ml-2 mt-2"}>{item.name}</span>
                                    })
                                }

                            </div>
                        </div>



                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={CustomerInsertClick}>
                        {props.selectedItem != undefined ? "Güncelle" : "Oluştur"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal >
    </>
}