import "../../Thema/css/login-2.css"

import React from 'react'
import { useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DataCenter from "../../Ekmekk/DataCenter/datacenter";
import Ekmekk from "../../Ekmekk/Helper/ekmekk";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function Login() {

    var inputUsername = useRef(null);
    var inputPassword = useRef(null);
    var history = useHistory();

    function LoginClick() {

        if (inputUsername.current.value == "bilo" && inputPassword.current.value == 123) {
            console.log(inputUsername.current.value);
            console.log(inputPassword.current.value);
            // Ekmekk().Profile.SetData(-1,-1);
            // Ekmekk().Profile.SetProfile(null);

            // Ekmekk().Profile.SetPermission(null);
            localStorage.setItem("isAdmin", "0");

            Ekmekk().Alert.Login();
            history.push("/dashboard/user/home");

        }
        else {
            DataCenter().Auth.Login(inputUsername.current.value, inputPassword.current.value).then(r => {

                console.log(r);
                Ekmekk().Profile.SetData(r);
                Ekmekk().Profile.SetProfile(r.token);

                Ekmekk().Profile.SetPermission(r.data.ek_authority_process);


                if (r.status == true) {
                    Ekmekk().Alert.Login();
                    console.log(r.type)
                    if (r.type == 2) {

                        localStorage.setItem("isAdmin", "1");
                        history.push("/dashboard/home");
                    }
                    else {

                        localStorage.setItem("isAdmin", "0");
                        localStorage.setItem("myUserID", r.data.ek_customer_ID);
                        history.push("/dashboard/user/home");
                    }
                }
                else {
                    console.log(r);
                    Ekmekk().Alert.Fail()
                }

            });
        }


    }
    useEffect(() => {
        Ekmekk().Profile.SetProfile(null);

        if (Ekmekk().Profile.GetProfile() != null) {
            // history.push("/dashboard/home");
        }
    }, [])

    return <>

        <div className="d-flex flex-column flex-root" style={{ position: "absolute", right: "0", bottom: "0", left: "0", top: "0" }}>
            {/*begin::Login*/}
            <div className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                {/*begin::Aside*/}
                <div className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
                    {/*begin: Aside Container*/}
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        {/*begin::Aside body*/}
                        <div className="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
                            <a href="#" className="mb-15 text-center">
                                <img src="//www.royaldogclub.com/wp-content/uploads/2020/11/RDC-LOGO.png" className="max-h-175px" alt="" />
                            </a>
                            {/*begin::Signin*/}
                            <div className="login-form login-signin">
                                <div className="text-center mb-10 mb-lg-20">
                                    <h2 className="font-weight-bold">Giriş Yap</h2>
                                    <p className="text-muted font-weight-bold">Yönetici paneline giriş yapmak için kullanıcı adı ve şifre girin</p>
                                </div>
                                {/*begin::Form*/}
                                <div className="form" id="kt_login_signin_form">
                                    <div className="form-group py-3 border-bottom m-0">
                                        <input ref={inputUsername} className="form-control h-auto border-0 px-0 placeholder-dark-75" type="Email" placeholder="Kullanıcı adı" name="username" autoComplete="off" />
                                    </div>
                                    <div className="form-group py-3 border-bottom m-0">
                                        <input ref={inputPassword} className="form-control h-auto border-0 px-0 placeholder-dark-75" type="Password" placeholder="Şifre" name="password" />
                                    </div>

                                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                                        <div className="my-3 mr-2">

                                        </div>
                                        <button onClick={LoginClick} id="kt_login_signin_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3">Giriş Yap</button>
                                    </div>
                                </div>
                                {/*end::Form*/}
                            </div>
                            {/*end::Signin*/}
                            {/*end::Forgot*/}
                        </div>
                        {/*end::Aside body*/}
                        {/*begin: Aside footer for desktop*/}
                        <div className="d-flex flex-column-auto justify-content-between mt-15">

                        </div>
                        {/*end: Aside footer for desktop*/}
                    </div>
                    {/*end: Aside Container*/}
                </div>
                {/*begin::Aside*/}
                {/*begin::Content*/}
                {/* <BrowserView> */}
                    <div className="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7" style={{ backgroundImage: 'url(my-assets/dog.svg)', backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                        {/*begin::Content body*/}
                        <div className="d-flex flex-column-fluid flex-lg-center">

                        </div>
                        {/*end::Content body*/}
                    </div>
                {/* </BrowserView> */}

                {/*end::Content*/}
            </div>
            {/*end::Login*/}
        </div>



    </>
}