
import React, { useState, useEffect, useRef } from 'react'
import Chart from "react-apexcharts";
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import SubHeader from '../../Thema/sub-header';

export default function Ayarlar() {

    const [index, setindex] = useState(0);

    const inputText = useRef(null);

    const [newMail, setNewMail] = useState(0);
    const [newSms, setNewSms] = useState(0);

    const [birthdaySms, setBirthdaySms] = useState(0);
    const [birthdayMail, setBirthdayMail] = useState(0);

    const [educationSms, setEducationSms] = useState(0);
    const [educationMail, setEducationMail] = useState(0);

    const [notificationList, setNotificationList] = useState([]);

    useEffect(() => {

        DataCenter().Notification.ListNotification().then(r => {

            if (r.status) {

                setNotificationList(r.data);

                setNewMail(r.data[0].mail);
                setNewSms(r.data[0].sms);

                setBirthdayMail(r.data[1].mail);
                setBirthdaySms(r.data[1].sms);

                setEducationMail(r.data[2].mail);
                setEducationSms(r.data[2].sms);


            }
            else {
                Ekmekk().Alert.Fail();
            }

        })

    }, [])


    function SaveAll() {



        console.log(notificationList);
        console.log(inputText.current.value);

        // DataCenter().Notification.InsertNotification(dataBlock).then(r => {

        // })

        // Ekmekk().Alert.Block();

        for (let i = 0; i < notificationList.length; i++) {
            // lessonData[i].isComplete = JSON.stringify(lessonData[i].isComplete);
            console.log(notificationList[i]);

            var dataBlock = {
                ek_notification_ID: notificationList[i].ek_notification_ID,
                message: notificationList[i].message,
                mail: JSON.stringify(notificationList[i].mail),
                sms: JSON.stringify(notificationList[i].sms),
                type: notificationList[i].type,
            }


            DataCenter().Notification.UpdateNotification(dataBlock).then(r => {
                console.log(dataBlock);
            })

        }

        // Ekmekk().Alert.UnblockUI();
        Ekmekk().Alert.Success();

    }

    function NotificationItemList(item, i, index) {

        return <>

            {
                i == index ? <div className="tab-pane active" id="kt_apps_contacts_view_tab_4" role="tabpanel">
                    <form className="form">
                        <div class="form-group row">
                            <div className="col-md-3 text-right">
                                <label className="text-right mr-4 col-form-label font-size-h3">Gönderilecek mesaj: </label>

                            </div>
                            <div className="col-md-9">
                                <textarea ref={inputText} onChange={() => { notificationList[i]["message"] = inputText.current.value }} class="form-control form-control-lg form-control-solid" id="exampleTextarea" rows="3" defaultValue={item.message} placeholder="Hoş Geldiniz"></textarea>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label className="col-xl-3 col-lg-3 text-right col-form-label font-size-h4 pr-4">E-Mail</label>
                            <div className="col-lg-9 col-xl-6">
                                <span className="switch">
                                    <label>

                                        <input value={item.mail} onChange={() => {

                                            if (item.mail == 0) {
                                                notificationList[i]["mail"] = 1;
                                                setNewMail(1);
                                            }
                                            else {
                                                notificationList[i]["mail"] = 0;
                                                setNewMail(0);
                                            }

                                        }} defaultChecked={item.mail == 0 ? "" : "checked"} type="checkbox" name="email_notification_1" />


                                        <span />
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label className="col-xl-3 col-lg-3 text-right col-form-label font-size-h4 pr-4">SMS</label>
                            <div className="col-lg-9 col-xl-6">
                                <span className="switch">
                                    <label>

                                        <input value={item.sms} onChange={() => {

                                            if (item.sms == 0) {
                                                notificationList[i]["sms"] = 1;
                                                setNewMail(1);
                                            }
                                            else {
                                                notificationList[i]["sms"] = 0;
                                                setNewMail(0);
                                            }

                                        }} defaultChecked={item.sms == 0 ? "" : "checked"} type="checkbox" name="email_notification_1" />

                                        <span />
                                    </label>
                                </span>
                            </div>
                        </div>
                        {/* <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 text-right col-form-label">Send Copy To Personal Email</label>
                                        <div className="col-lg-9 col-xl-6">
                                            <span className="switch">
                                                <label>
                                                    <input type="checkbox" name="email_notification_2" />
                                                    <span />
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="separator separator-dashed my-10" />

                                    <div className="row">
                                        <div className="col-lg-9 col-xl-6 offset-xl-3">
                                            <h3 className="font-size-h6 mb-5">Activity Related Emails:</h3>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 text-right">When To Email</label>
                                        <div className="col-lg-9 col-xl-6">
                                            <div className="checkbox-list">
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span />You have new notifications.</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span />You're sent a direct message</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" defaultChecked="checked" />
                                                    <span />Someone adds you as a connection</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 text-right">When To Escalate Emails</label>
                                        <div className="col-lg-9 col-xl-6">
                                            <div className="checkbox-list">
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span />Upon new order.</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span />New membership approval</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" defaultChecked="checked" />
                                                    <span />Member registration</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="separator separator-dashed my-10" />


                                    <div className="row">
                                        <div className="col-lg-9 col-xl-6 offset-xl-3">
                                            <h3 className="font-size-h6 mb-5">Updates From Keenthemes:</h3>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-xl-3 col-lg-3 text-right">Email You With</label>
                                        <div className="col-lg-9 col-xl-6">
                                            <div className="checkbox-list">
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span />News about Metronic product and feature updates</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" />
                                                    <span />Tips on getting more out of Keen</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" defaultChecked="checked" />
                                                    <span />Things you missed since you last logged into Keen</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" defaultChecked="checked" />
                                                    <span />News about Metronic on partner products and other services</label>
                                                <label className="checkbox">
                                                    <input type="checkbox" defaultChecked="checked" />
                                                    <span />Tips on Metronic business products</label>
                                            </div>
                                        </div>
                                    </div>
                                 */}
                    </form>
                </div> : ""
            }


        </>
    }


    return <>

        <AuthorityControl pageCode={83}>

            <div>


                {/*begin::Card*/}
                <div className="card card-custom">

                    {/*begin::Header*/}
                    <div className="card-header card-header-tabs-line align-items-center ">
                        {/* <h3 className="card-title align-items-center flex-column">
                        <span className="card-label font-weight-bolder text-dark">İşlem Geçmişi</span>
                    </h3> */}

                        <div className="card-toolbar">
                            <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x" role="tablist">
                                <li className="nav-item mr-3">
                                    <a onClick={() => setindex(0)} className={index == 0 ? "nav-link active" : "nav-link"} data-toggle="tab">
                                        <span className="nav-icon mr-2">
                                            <span className="svg-icon mr-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <path d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                        <path d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z" fill="#000000" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                        <span className="nav-text font-weight-bold">Yeni Kayıt</span>
                                    </a>
                                </li>
                                <li className="nav-item mr-3">
                                    <a onClick={() => setindex(1)} className={index == 1 ? "nav-link active" : "nav-link"} data-toggle="tab">
                                        <span className="nav-icon mr-2">
                                            <span className="svg-icon mr-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fillRule="nonzero" />
                                                        <circle fill="#000000" opacity="0.3" cx={12} cy={10} r={6} />
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                        <span className="nav-text font-weight-bold">Doğum Günü</span>
                                    </a>
                                </li>
                                <li className="nav-item mr-3">
                                    <a onClick={() => setindex(2)} className={index == 2 ? "nav-link active" : "nav-link"} data-toggle="tab">
                                        <span className="nav-icon mr-2">
                                            <span className="svg-icon mr-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fillRule="nonzero" />
                                                        <circle fill="#000000" opacity="0.3" cx={12} cy={10} r={6} />
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                        <span className="nav-text font-weight-bold">Eğitim Bitimi</span>
                                    </a>
                                </li>
                                <li className="nav-item mr-3">
                                    <a onClick={() => setindex(3)} className={index == 3 ? "nav-link active" : "nav-link"} data-toggle="tab">
                                        <span className="nav-icon mr-2">
                                            <span className="svg-icon mr-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fillRule="nonzero" />
                                                        <circle fill="#000000" opacity="0.3" cx={12} cy={10} r={6} />
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                        <span className="nav-text font-weight-bold">Aşı Bildirimi</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>

                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                        <div className="tab-content pt-5">
                            {
                                notificationList == undefined ? "" : notificationList.length == 0 ? "" : notificationList.map((item, i) => {
                                    return NotificationItemList(item, i, index)
                                })
                            }




                        </div>
                    </div>
                    {/*end::Body*/}
                    
                    <button onClick={() => { SaveAll() }} className="btn w-30 btn-light-primary font-weight-bolder text-uppercase text-center mt-0">Kaydet</button>

                </div>
            </div>

        </AuthorityControl>

    </>
}