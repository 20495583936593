import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function ModalEducatorInsert(props) {

    var inputEducatorName = useRef(null);
    var inputEducatorPhone = useRef(null);
    var inputEducatorOtherPhone = useRef(null);
    var inputEducatorMail = useRef(null);
    var inputEducatorBlood = useRef(null);
    var inputEducatorAddress = useRef(null);

    function EducatorInsertClick() {

        var dataBlock = {
            ek_educator_fullname: inputEducatorName.current.value,
            ek_educator_phone: inputEducatorPhone.current.value,
            ek_educator_other_phone: inputEducatorOtherPhone.current.value,
            ek_educator_mail: inputEducatorMail.current.value,
            ek_educator_blood_group: inputEducatorBlood.current.value,
            ek_educator_address: inputEducatorAddress.current.value,
        }

        var controller = new ValidationController();


        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {

                DataCenter().Educator.InsertEducator(dataBlock).then(r => {
                    // // alert(JSON.stringify(r))

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_educator_ID"] = r.data.ek_educator_ID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }

                })
            }
            else {
                console.log(props.selectedData.ek_educator_ID);
                dataBlock["ek_educator_ID"] = props.selectedData.ek_educator_ID;

                DataCenter().Educator.UpdateEducator(dataBlock).then(r => {


                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }

    }

    const [show, setShow] = useState(false);

    function ClickEvent() {
        setShow(true)
    }

    function ViewTittle() {
        return <h5 className="font-boldest">{props.isUpdate == true ? "Eğitmen Güncelle" : "Eğitmen Oluştur"}</h5>
    }


    return <>

        <BrowserView>
            {
                props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Eğitmen Oluştur</button>
                    : ""
            }
        </BrowserView>
        <MobileView>
            {
                props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary btn-block font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Eğitmen Oluştur</button>
                    : ""
            }
        </MobileView>

        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={ClickEvent} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>

                        <ViewTittle />


                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="customerForm">
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}
                    {/* FOTO SEÇİMİ */}
                    {/* {
                        ImagePicker(function (r) {
                            setSelectedImage(r);

                        }, true, false, null)
                    }
                    <br /> */}
                    {/* KULLANICI İSMİ */}
                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">İsim Soyisim: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputEducatorName} className="form-control form-control-lg form-control-solid" name="customerName" defaultValue={props.selectedData != undefined ? props.selectedData.ek_educator_fullname : ""} placeholder="İsim Soyisim" type="text" />
                            </div>
                        </div>
                        {/* KAN GRUBU */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kan Grubu: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input ref={inputEducatorBlood} className="form-control form-control-lg form-control-solid" name="bloodType" defaultValue={props.selectedData != undefined ? props.selectedData.ek_educator_blood_group : ""} placeholder="Kan Grubu" type="text" />
                            </div>
                        </div>
                        {/* TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Telefon: </label>
                            <div className="col-lg-9 col-xl-9">

                                <input data-validation-rule="notNull|onlyNumber|onlyPhone" maxLength="10" ref={inputEducatorPhone} className="form-control form-control-lg form-control-solid" name="phone" placeholder="xxx-xxx-xx-xx" type="tel" defaultValue={props.selectedData != undefined ? props.selectedData.ek_educator_phone : ""} />
                            </div>
                        </div>
                        {/* YAKIN TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Yakın Telefon: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber|onlyPhone" maxLength="10" ref={inputEducatorOtherPhone} className="form-control form-control-lg form-control-solid" name="phone" placeholder="xxx-xxx-xx-xx" type="tel" defaultValue={props.selectedData != undefined ? props.selectedData.ek_educator_other_phone : ""} />
                            </div>
                        </div>
                        {/* E-MAIL */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Email:</label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyMail" ref={inputEducatorMail} className="form-control form-control-lg form-control-solid" type="email" placeholder="email@mail.com" name="example-email-input" defaultValue={props.selectedData != undefined ? props.selectedData.ek_educator_mail : ""} />
                            </div>
                        </div>
                        {/* MÜŞTERİ AÇIKLAMASI */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Adres:</label>
                            <div className="col-lg-9 col-xl-9">
                                <textarea data-validation-rule="notNull" ref={inputEducatorAddress} className="form-control" name="textarea" rows={3} placeholder="Lütfen adres giriniz." defaultValue={props.selectedData != undefined ? props.selectedData.ek_educator_address : ""} />
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={EducatorInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Oluştur"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}