import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import DatePicker from "react-datepicker"


export default function ModalHotelOldSale(props) {

    var odenecekTutar = useRef(null);
    var odenenTutar = useRef(null);

    const [toplamTutar, setToplamTutar] = useState(0);
    const [kalanTutar, setKalanTutar] = useState(0)

    const [cost, setCost] = useState(-1);
    const [preDebt, setPreDebt] = useState(-1);
    const [totalCost, setTotalCost] = useState(-1);
    // const [processType, setProcessType] = useState(-1);


    // var inputAmount = useRef(null);
    // var inputProcessType = useRef(null);
    // var inputDogID = useRef(null);
    // var inputSalePrice = useRef(null);
    // var odenenTutar = useRef(null);

    function DogOldSaleClick() {


        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID

        var birthDate2 = exitDate, secondDate = birthDate2.getFullYear() + '-' + (birthDate2.getMonth() + 1) + '-' + birthDate2.getDate() + ' 12:00:00';

        var validator = new ValidationController();

        if (validator.Control() == 0 && odenenTutar.current.value == toplamTutar) {

            var dataBlock2 = {

                customerID: JSON.parse(props.customer).ek_customer_ID,
                ek_customer_dog_ID: props.dogID,
                ek_selling_paid_price: odenenTutar.current.value,
                creationDate : secondDate,

            }


            dataBlock2["customerID"] = JSON.parse(props.customer).ek_customer_ID;
            dataBlock2["ek_customer_dog_ID"] = props.dogID;
            dataBlock2["ek_selling_paid_price"] = odenenTutar.current.value;

            // KONAKLAMA SATIŞI
            DataCenter().Sale.HousingSale(dataBlock2).then(r => {

                if (r.status) {

                    console.log(dataBlock2);
                    console.log("Konaklama Satışı Yapıldı");

                    Ekmekk().Alert.Success();
                    props.handler("close")
                    // KAFES GÜNCELLEME (BOŞALTMA)
                    // DataCenter().Cage.UpdateDogToCage(dataBlock2).then(r => {

                    //     if (r.status) {
                    //         console.log("Kafes Boşaltıldı");

                    //         Ekmekk().Alert.Success();
                    //         setShow(false);

                    //         props.insertClick("updated", dataBlock2);
                    //     }
                    //     else {
                    //         Ekmekk().Alert.Fail();
                    //     }
                    // })

                }
                else {
                    Ekmekk().Alert.Fail();
                }

            })

            console.log(dataBlock2);

        }
        else{
            Ekmekk().Alert.FailGecmisKonaklama();
        }

    }

    function CalculateCost(first, second) {


        if (first != null) {
            var birthDate = first, firstDate = birthDate.getFullYear() + '-' + (birthDate.getMonth() + 1) + '-' + birthDate.getDate() + ' 12:00:00';
        }
        else {
            var birthDate = calendarDate, firstDate = birthDate.getFullYear() + '-' + (birthDate.getMonth() + 1) + '-' + birthDate.getDate() + ' 12:00:00';
        }

        if (second != null) {
            var birthDate2 = second, secondDate = birthDate2.getFullYear() + '-' + (birthDate2.getMonth() + 1) + '-' + birthDate2.getDate() + ' 12:00:00';
        }
        else {
            var birthDate2 = exitDate, secondDate = birthDate2.getFullYear() + '-' + (birthDate2.getMonth() + 1) + '-' + birthDate2.getDate() + ' 12:00:00';
        }


        var dataBlock2 = {
            ek_cage_daily_price: props.salePrice,
            ek_entry_date: firstDate,
            ek_exit_date: secondDate,
            ek_customer_ID: JSON.parse(props.customer).ek_customer_ID,

        }

        DataCenter().Sale.CalculateOldPrice(dataBlock2).then(r => {
            if (r.status) {

                setToplamTutar(r.data.cost);
                setCost(r.data.cost);
                setPreDebt(r.data.debt);
                setTotalCost(r.data.total_cost);


            }

        })

    }

    // ------------- TARİH --------------

    const [calendarDate, setCalendarDate] = useState(new Date());
    const [exitDate, setExitDate] = useState(new Date());
    // const [birthDate, setBirthDate] = useState(null);



    const [show, setShow] = useState(false);

    useEffect(() => {

        if (props.isShow && props.salePrice != undefined) {
            // console.log(props.salePrice);
            console.log(JSON.parse(props.customer).ek_customer_ID);

        }

        if (props.isCageFull == 1 && props.lastProcess[0].process_type_ID == 0) {

            CalculateCost(null, null)

        }


    }, [props.isShow])


    return <>

        {
            // props.isCageFull == 0 ? <button type="button" onClick={() => {
            //     console.log(props);
            // }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
            //     Doldur
            // </button> : <button type="button" onClick={() => {
            //     setShow(true);
            //     console.log(props);
            // }} className="btn btn-sm btn-block btn-light-danger font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
            //     Boşalt
            // </button>
        }

        <Modal
            show={props.isShow == undefined ? show : props.isShow}
            onHide={() => { setShow(false); if (props.handler != undefined) props.handler("close") }}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Seçimi Tamamla
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    {/* <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Adet: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={inputAmount} className="form-control form-control-lg" id="campain_name2" placeholder="1" type="number" defaultValue="1" />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyatı: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={salePrice} className="form-control form-control-lg" id="campain_name2" placeholder={props.productPrice} type="number" defaultValue={props.productPrice} />
                        </div>
                    </div> */}

                    <Validator>

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Giriş Tarihi: </label>

                            <div className="col-lg-9 col-xl-9">

                                <DatePicker
                                    selected={calendarDate}
                                    // @ts-ignore
                                    onChange={date => { setCalendarDate(date); CalculateCost(date, null); }}
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    showTimeInput

                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control form-control-lg form-control-solid w-100"
                                />

                            </div>

                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Çıkış Tarihi: </label>

                            <div className="col-lg-9 col-xl-9">

                                <DatePicker
                                    selected={exitDate}
                                    // @ts-ignore
                                    onChange={date => { setExitDate(date); CalculateCost(null, date) }}
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    showTimeInput

                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control form-control-lg form-control-solid w-100"
                                />

                            </div>

                        </div>

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Ödenecek Tutar: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input className="form-control form-control-lg" id="campain_name2" disabled="disabled" placeholder={toplamTutar} type="number" defaultValue />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Ödenen Tutar: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={odenenTutar} onChange={(e) => { setKalanTutar(toplamTutar - e.target.value) }} className="form-control form-control-lg" id="campain_name2" placeholder="0" type="number" defaultValue />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kalan Tutar: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input value={kalanTutar} className="form-control form-control-lg" disabled="disabled" id="campain_name2" placeholder={kalanTutar} type="number" defaultValue />
                            </div>
                        </div>

                    </Validator>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal" onClick={() => {
                        setShow(false); if (props.handler != undefined) props.handler("close")
                    }}>
                        Close</button>
                    <button type="button" className="btn btn-primary font-weight-bold" onClick={() => { DogOldSaleClick() }}>Tamamla</button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}