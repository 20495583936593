

export default function Profile() {
    var isAdmin = -1;
    var userType = -1;
    function SetProfile(token) {
        localStorage.setItem("tokenData", token);
    }
    function GetProfile() {
        return localStorage.getItem("tokenData");
    }

    function SignOut() {
        localStorage.setItem("tokenData", null);
    }
    function SetData(response) {
        // alert(response["data"]["ek_admin_authority_ID"]);
        localStorage.setItem("userType", response["data"]["ek_admin_authority_ID"]);
    }
    function SetPermission(response) {
        // alert(response["data"]["ek_admin_authority_ID"]);
        localStorage.setItem("permission", response);
    }
    function GetPermission() {
        return localStorage.getItem("permission");
    }
    function GetUserType() {
        return localStorage.getItem("userType");
    }

    return {
        SetProfile, GetProfile, SignOut, userType: GetUserType, SetData, SetPermission, GetPermission
    }
}