
import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { NavLink, useHistory } from 'react-router-dom'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'

import ModalDogBreedInsert from "../../Modal/RoyalDog/DogBreedInsert"
import SubHeader from '../../Thema/sub-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnet, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import NullPage from '../../Ekmekk/Views/NullPage'
import ModalManager from '../../Ekmekk/Helper/ModalManager'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'


export default function DogBreed() {

    const [list, setList] = useState([]);
    const [mainList, setMainList] = useState([]);

    var inputs = [
        {
            label: "Aşı Adı",
            name: "ek_vaccine_name",
        }
    ];



    var insertModal = {
        tittle: "Raport verisi girişi",
        inputs: inputs,
        buttons: [
            {
                html: <a className="btn btn-info font-weight-bolder font-size-sm">Aşı Oluştur</a>,
                clickEvent: () => {
                    return true;
                },
            },
            {
                text: "Vazgeç",
                cancel: true,
                class: "btn-secondary",
                clickEvent: () => {
                    return false;
                },
            },
            {
                text: "Oluştur",
                class: "btn-success",
                clickEvent: (data) => {
                    DataCenter().RoyalDog.InsertVaccine(data).then(r => {

                        console.log(r);
                        data["ek_vaccine_ID"] = r.data.lastID;
                        if (r.status) {
                            VaccineClick("inserted", data);
                        }
                    })
                    return false;
                },
            }

        ]
    }

    var updateModal = {
        tittle: "Raport verisi girişi",
        inputs: inputs,
        buttons: [
            {
                html: <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Communication/Write.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <rect x={0} y={0} width={24} height={24} />
                                <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            </g>
                        </svg>
                        {/*end::Svg Icon*/}
                    </span>
                </a>,
                clickEvent: () => {
                    return true;
                },
            },
            {
                text: "Güncelle",
                class: "btn-success",
                clickEvent: (data) => {
                    DataCenter().RoyalDog.UpdateVaccine(data).then(r => {
                        console.log(r);

                        if (r.status) {
                            data["ek_vaccine_ID"] = r.data.lastID;
                            data["ek_vaccine_name"] = r.data.updateName;

                            VaccineClick("updated", data);
                        }
                    })
                    return false;
                },
            },
            {
                text: "Sil",
                class: "btn-danger",
                clickEvent: (data) => {
                    DataCenter().RoyalDog.DeleteVaccine(data).then(r => {
                        console.log(r);

                        if (r.status) {

                            VaccineDelete(data);
                        }
                    })
                    return false;
                },
            },
            {
                text: "Vazgeç",
                cancel: true,
                class: "btn-secondary",
                clickEvent: () => {
                    return false;
                },
            }
        ]
    }



    var history = useHistory();

    useEffect(() => {
        DataCenter().RoyalDog.ListVaccine().then(r => {
            console.log(r);
            setList(r.data);
            setMainList(r.data);
        })
    }, [])


    function VaccineClick(caseData, data) {

        console.log(data);

        if (caseData == "inserted") {
            list.push(data);
            setList([...list])
        }
        else if (caseData == "updated") {

            for (var i = 0; i < list.length; i++) {
                if (list[i].ek_vaccine_ID == data.elementId) {

                    list[i] = data;
                    list[i]["ek_vaccine_ID"] = data.elementId;

                }
            }

            setList([...list]);

        }

    }

    function VaccineDelete(item) {

        console.log(item);

        var list2 = [...list];
        for (var i = 0; i < list2.length; i++) {
            if (list2[i].ek_vaccine_ID == item.elementId) {
                list2.splice(i, 1)
            }
        }
        setList(list2);

    }

    function ChangeSearchText(e) {
        var filteredList = mainList.filter(item => item.ek_vaccine_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setList(filteredList);

    }

    return <>
        <div className="mb-7">
            <div className="row align-items-center">
                <div className="col-md-10">
                    <div className="input-icon">
                        <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Aşı Adı" id="kt_datatable_search_query" />
                        <span>
                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                        </span>
                    </div>
                </div>
                <div className="col-md">
                    <AuthorityControl pageCode={75}>
                        {<ModalManager data={insertModal} />}
                    </AuthorityControl>
                </div>
            </div>
        </div>

        {/*end::Search Form*/}
        <AuthorityControl pageCode={74}>
            {
                list.length != 0 ? TableList(list) : <NullPage image={"vaccine.svg"} />
            }
        </AuthorityControl>
    </>



    function TableList(data) {


        let history = useHistory();

        function OpenSubBreed(ek_dog_breed_ID) {
            history.push("/dashboard/definition/dog-sub-breed/" + ek_dog_breed_ID);

        }

        const columns = [

            {
                title: <span className="text">Aşı no</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_vaccine_ID}</div>
            },
            {
                title: <span className="text">Aşı Adı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_vaccine_name}</div>
            },
            {
                title: <span className="text">İşlemler</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">

                    <AuthorityControl pageCode={76}>
                        {<ModalManager data={updateModal} selectedItem={data} elementId={data.ek_vaccine_ID} />}
                    </AuthorityControl>
                </div>
            },

        ]

        const handleRowClick = (event, rowData) => {
            const index = data.indexOf(rowData)

            localStorage.setItem("selectedItem", JSON.stringify(data[index]))

        }

        const Material = (
            <MaterialTable
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
                options={
                    {
                        filtering: false,
                        search: false,
                        toolbar: false,
                        pageSize: 5,
                        pageSizeOptions: [5, 10],
                    }

                } />
        )


        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                        {Material}
                    </table>
                </div>
            </div>
        )
    }
}
