import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import ModalCustomerInsert from "../../Modal/Customer/CustomerInsert"
import ModalDogInsert from "../../Modal/Customer/DogInsert"
import ModalPayDebt from "../../Modal/Customer/PayDebt"

import React, { useState, useEffect } from 'react'
import { useHistory, NavLink, useParams } from "react-router-dom";
import SubHeader from '../../Thema/sub-header'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import NullPage from '../../Ekmekk/Views/NullPage'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'


export default function ProfilMusteri() {

    const [index, setindex] = useState(0);

    const [customerData, setCustomerData] = useState([]);
    const [customerDog, setCustomerDog] = useState([]);
    const [lastProcess, setLastProcess] = useState([]);
    const [lastDebt, setLastDebt] = useState([]);

    var history = useHistory();
    // @ts-ignore
    const { id } = useParams();

    useEffect(() => {

        DataCenter().Customer.ShowCustomerProfile(id).then(r => {
            console.log(r.data);
            setCustomerData(r.data.customer[0]);

            // r.data.lasProcess.sort(GetSortOrder("creation_date")); //Pass the attribute to be sorted on    
            // TARİHE GÖRE SIRALA
            let sortedProcess = r.data.lasProcess.sort((b, a) => new Date(...a.creation_date.split(' ')[0].split('/').reverse()) - new Date(...b.creation_date.split(' ')[0].split('/').reverse()));

            setLastProcess(sortedProcess);


            // BORÇ OLMAYANLARIN ÇIKARTILMASI
            var debtData = [];
            debtData = r.data.debt;
            for (let i = 0; i < debtData.length; i++) {

                if (debtData[i].debt <= 0) {
                    debtData.splice(i, 1)
                }
            }

            if (debtData.length > 0) {
                let sortedDebt = debtData.sort((b, a) => new Date(...a.ek_selling_creation_date.split(' ')[0].split('/').reverse()) - new Date(...b.ek_selling_creation_date.split(' ')[0].split('/').reverse()));
                
                setLastDebt(sortedDebt);

            }
            else
                setLastDebt([]);

            console.log(debtData);
        });

        // @ts-ignore
        DataCenter().Customer.ListCustomerDog(id).then(r => {
            console.log(r.data);
            setCustomerDog(r.data);
        })

    }, [])

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    function DogInsertClick(data) {

        // data["debt"] = "0";
        // data["profit"] = "0";
        // data["last_process_count"] = "0";
        // data["ek_dog_breed_name"] = 
        data["educationCount"] = 0;
        data["activeEducationPercentage"] = 0;
        data["totalEducationPercentage"] = 0;
        customerDog.push(data);
        setCustomerDog([...customerDog])

    }

    function DebtPayInsertClick(data) {

        console.log(data);

        for (let i = 0; i < lastDebt.length; i++) {
            const element = lastDebt[i];

            if (lastDebt[i].ek_selling_ID == data.ek_selling_ID) {
                // console.log(data.paid_price);
                // console.log(lastDebt[i].ek_selling_paid_price);
                // console.log(lastDebt[i].ek_selling_paid_price - data.paid_price);
                lastDebt[i]["debt"] = lastDebt[i].debt - data.paid_price;
                lastDebt[i]["ek_selling_paid_price"] = lastDebt[i].ek_selling_paid_price + parseInt(data.paid_price);

                setLastDebt([...lastDebt]);
            }
        }

    }

    function CustomerInsertClick(caseData, data) {

        if (caseData == "updated") {

            if (data.ek_customer_image == null) {
                var tempPhoto = customerData.ek_customer_image;
            }
            else {
                var tempPhoto = data.ek_customer_image;
            }
            data["ek_customer_image"] = tempPhoto;

            var tempDebt = customerData.debt;
            data["debt"] = tempDebt;

            var tempProfit = customerData.profit;
            data["profit"] = tempProfit;

            var tempProcess_count = customerData.process_count;
            data["process_count"] = tempProcess_count;

            var tempTotal_order_price = customerData.total_order_price;
            data["total_order_price"] = tempTotal_order_price;
            setCustomerData(data);

        }
    }

    function CustomerDeleteClick(ID) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Customer.DeleteCustomer(ID).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        history.goBack();
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })

            }
        })
    }

    function CustomerDogItem(item) {

        // alert(JSON.stringify(item));
        function OpenDogProfile(index) {
            // <AuthorityControl pageCode={9}>

            history.push("/dashboard/customer/dog-profile/" + index);
            // </AuthorityControl >

        }

        return <>

            <tr>
                <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                        <div onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="symbol symbol-50 flex-shrink-0 mr-4">
                            <img src={item.ek_customer_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + item.ek_customer_dog_image} alt="" className="h-50" />

                            {/* <div className="symbol-label" style={{ backgroundImage: 'url("/assets/media/stock-600x400/img-26.jpg")' }} /> */}
                        </div>
                        <div>
                            <div onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{item.ek_customer_dog_name != null ? item.ek_customer_dog_name : ""}</div>
                            <span className="text-muted font-weight-bold d-block">{item.ek_dog_breed_name != null ? item.ek_dog_breed_name : ""} - {item.ek_dog_sub_breed_name != null ? item.ek_dog_sub_breed_name : ""}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <span onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.dog_age}</span>
                </td>
                <td>
                    <span onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.ek_customer_dog_gender}</span>
                </td>
                <td>
                    <span onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="text-dark-75 font-weight-bolder d-block font-size-lg">{item.educationCount}</span>
                </td>
                <td>
                    <span onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="font-weight-bold">Eğitim İlerleyişi</span>
                    <div onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="progress progress-xs mt-2 mb-2">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: item.activeEducationPercentage }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <span onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="font-weight-bolder text-dark">{item.activeEducationPercentage}%</span>
                </td>
                <td>
                    <span onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="font-weight-bold">Toplam İlerleyiş</span>
                    <div onClick={() => OpenDogProfile(item.ek_customer_dog_ID)} className="progress progress-xs mt-2 mb-2">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: item.totalEducationPercentage }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <span className="font-weight-bolder text-dark">{item.totalEducationPercentage}%</span>
                </td>
            </tr>

        </>

    }

    function LastProcessItemList(item) {
        return <>
            <tr>
                <td className="pl-0 py-6">
                    <label className="checkbox checkbox-lg checkbox-inline">
                        <input type="checkbox" defaultValue={1} />
                        <span />
                    </label>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{item.process_name}</span>
                </td>
                <td className="pl-0">
                    <a href="#" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg">{item.creation_date}</a>
                </td>
                <td>
                    <span className={item.type == 0 ? "label label-success label-dot mr-2" : item.type == 1 ? "label label-info label-dot mr-2" : item.type == 2 ? "label label-warning label-dot mr-2" : "label label-primary label-dot mr-2"} />
                    <span className={item.type == 0 ? "font-weight-bold text-success" : item.type == 1 ? "font-weight-bold text-info" : item.type == 2 ? "font-weight-bold text-warning" : "font-weight-bold text-primary"}>
                        {item.type == 0 ? "Eğitim" : item.type == 1 ? "Hizmet" : item.type == 2 ? "Ürün" : "Köpek"}
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{item.piece}</span>
                </td>
                <td>
                    {/* eğitim servis ürün köpek */}
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">₺{item.sale_price}</span>
                </td>
                <td>
                    <span className="text-success font-weight-bolder d-block font-size-lg">₺{item.piece * item.sale_price}</span>
                </td>
            </tr>
        </>
    }

    function LastDebtItemList(item) {

        if (item.debt > 0) {

            return <>
                <tr>
                    <td className="pl-0 py-6">
                        <label className="checkbox checkbox-lg checkbox-inline">
                            <input type="checkbox" defaultValue={1} />
                            <span />
                        </label>
                    </td>
                    <td className="pl-0">
                        <a href="#" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg">{item.ek_selling_creation_date}</a>
                    </td>
                    <td>
                        <span className="text-dark-75 font-weight-bold d-block font-size-lg">₺{item.ek_selling_cost_price}</span>
                    </td>

                    <td>
                        {/* eğitim servis ürün köpek */}
                        <span className="text-success font-weight-bold d-block font-size-lg">₺{item.ek_selling_paid_price}</span>
                    </td>
                    <td>
                        <span className="text-danger font-weight-bolder d-block font-size-lg">₺{item.debt}</span>
                    </td>
                    <td>
                        <span className={item.selling_type == 0 ? "label label-success label-dot mr-2" : item.selling_type == 1 ? "label label-info label-dot mr-2" : item.selling_type == 2 ? "label label-warning label-dot mr-2" : "label label-primary label-dot mr-2"} />
                        <span className={item.selling_type == 0 ? "font-weight-bold text-success" : item.selling_type == 1 ? "font-weight-bold text-info" : item.selling_type == 2 ? "font-weight-bold text-warning" : "font-weight-bold text-primary"}>
                            {item.selling_type == 0 ? "Eğitim Satışı" : item.selling_type == 1 ? "Hizmet Satışı" : item.selling_type == 2 ? "Ürün Satışı" : "Köpek Satışı"}
                        </span>
                    </td>
                    <td>
                        <ModalPayDebt selectedData={item} customerID={customerData.ek_customer_ID} insertClick={DebtPayInsertClick} />

                        {/* <button onClick={() => { }} className="btn btn-sm btn-light-success font-weight-bolder text-uppercase">Öde</button> */}
                    </td>
                </tr>
            </>


        }


    }



    return <>


        <AuthorityControl pageCode={6}>

            <div>
                <div className="card card-custom gutter-b">
                    <div className="card-body">
                        <div className="d-flex">
                            {/*begin: Pic*/}
                            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                <div className="symbol symbol-50 symbol-lg-120">
                                    {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                    <img src={customerData.length != 0 ? customerData.ek_customer_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + customerData.ek_customer_image : ""} alt="" className="h-50" />
                                </div>
                                <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                    <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                </div>
                            </div>
                            {/*end: Pic*/}
                            {/*begin: Info*/}
                            <div className="flex-grow-1">
                                {/*begin: Title*/}
                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                    <div className="mr-3">
                                        {/*begin::Name*/}
                                        <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{customerData.length != 0 ? customerData.ek_customer_fullname : ""}
                                            <i className="flaticon2-correct text-success icon-md ml-2" /></a>
                                        {/*end::Name*/}
                                        {/*begin::Contacts*/}
                                        <div className="d-flex flex-wrap my-2">
                                            <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <rect x={0} y={0} width={24} height={24} />
                                                            <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                            <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                        </g>
                                                    </svg>
                                                    {/*end::Svg Icon*/}
                                                </span>{customerData.length != 0 ? customerData.ek_customer_mail : ""}</a>
                                            <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                    {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <mask fill="white">
                                                                <use xlinkHref="#path-1" />
                                                            </mask>
                                                            <g />
                                                            <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                    {/*end::Svg Icon*/}
                                                </span>{customerData.length != 0 ? customerData.ek_customer_phone : ""}</a>
                                            <a href="#" className="text-muted text-hover-primary font-weight-bold">
                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <rect x={0} y={0} width={24} height={24} />
                                                            <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                    {/*end::Svg Icon*/}
                                                </span>{customerData.length != 0 ? customerData.ek_customer_address_district : ""}</a>
                                            <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 ml-1">
                                                    {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <mask fill="white">
                                                                <use xlinkHref="#path-1" />
                                                            </mask>
                                                            <g />
                                                            <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                    {/*end::Svg Icon*/}
                                                </span>{customerData.length != 0 ? customerData.ek_job : ""}</a>
                                        </div>
                                        {/*end::Contacts*/}
                                    </div>
                                    <div className="my-lg-0 my-1">

                                        <ModalDogInsert isUpdate={false} isSale={false} ek_customer_ID={id} insertClick={DogInsertClick} />

                                        <ModalCustomerInsert isUpdate={true} selectedData={customerData} insertClick={CustomerInsertClick} />

                                        <NavLink to={'/dashboard/sale/education-selling/' + customerData.ek_customer_ID}>
                                            <a className="btn btn-sm btn-warning font-weight-bolder text text-uppercase mr-3">Eğitim Sat</a>

                                        </NavLink>
                                        {/* <ModalPayDebt selectedData={customerData} /> */}
                                        {/* <AuthorityControl pageCode={5}> */}

                                        <button onClick={() => CustomerDeleteClick(customerData.ek_customer_ID)} className="btn btn-sm btn-danger font-weight-bolder text-uppercase">Kaldır</button>
                                        {/* </AuthorityControl> */}
                                    </div>
                                </div>
                                {/*end: Title*/}
                                {/*begin: Content*/}
                                <div className="d-flex align-items-center flex-wrap justify-content-between">
                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">{customerData.length != 0 ? customerData.ek_customer_address : ""}</div>
                                    <div className="d-flex flex-wrap align-items-center py-2">

                                        {/* <div className="flex-grow-1 flex-shrink-0 w-150px w-xl-300px mt-4 mt-sm-0">
                                        <span className="font-weight-bold">Progress</span>
                                        <div className="progress progress-xs mt-2 mb-2">
                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: '63%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                        </div>
                                        <span className="font-weight-bolder text-dark">78%</span>
                                    </div> */}
                                    </div>
                                </div>
                                {/*end: Content*/}
                            </div>
                            {/*end: Info*/}
                        </div>
                        <div className="separator separator-solid my-7" />
                        {/*begin: Items*/}
                        <div className="d-flex align-items-center flex-wrap">
                            {/*begin: Item*/}

                            <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1 ">
                                    <span className="mr-4">
                                        <span className="svg-icon svg-icon-light-dark svg-icon-3x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Shopping\Wallet2.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <rect fill="#000000" opacity="0.3" x={2} y={2} width={10} height={12} rx={2} />
                                                <path d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z" fill="#000000" />
                                            </g>
                                        </svg>{/*end::Svg Icon*/}</span>
                                    </span>
                                    <div className="d-flex flex-column text-dark-75">
                                        <span className="font-weight-bolder font-size-sm">Toplam Alışveriş</span>
                                        <span className="font-weight-bolder font-size-h5">
                                            <span className="text-dark-50 font-weight-bold">₺</span>{customerData.length != 0 ? customerData.total_order_price : ""}</span>
                                    </div>
                                </div>
                            </div>

                            {/*end: Item*/}
                            <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                {/*begin: Item*/}
                                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                    <span className="mr-4">
                                        <span className="svg-icon svg-icon-light-dark svg-icon-3x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Shopping\Wallet3.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <path d="M4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 Z" fill="#000000" opacity="0.3" />
                                                <path d="M18.5,11 L5.5,11 C4.67157288,11 4,11.6715729 4,12.5 L4,13 L8.58578644,13 C8.85100293,13 9.10535684,13.1053568 9.29289322,13.2928932 L10.2928932,14.2928932 C10.7456461,14.7456461 11.3597108,15 12,15 C12.6402892,15 13.2543539,14.7456461 13.7071068,14.2928932 L14.7071068,13.2928932 C14.8946432,13.1053568 15.1489971,13 15.4142136,13 L20,13 L20,12.5 C20,11.6715729 19.3284271,11 18.5,11 Z" fill="#000000" />
                                                <path d="M5.5,6 C4.67157288,6 4,6.67157288 4,7.5 L4,8 L20,8 L20,7.5 C20,6.67157288 19.3284271,6 18.5,6 L5.5,6 Z" fill="#000000" />
                                            </g>
                                        </svg>{/*end::Svg Icon*/}</span>

                                    </span>
                                    <div className="d-flex flex-column text-dark-75">
                                        <span className="font-weight-bolder font-size-sm">Kazanç</span>
                                        <span className="font-weight-bolder font-size-h5">
                                            <span className="text-dark-50 font-weight-bold">₺</span>{customerData.length != 0 ? customerData.profit : ""}</span>
                                    </div>
                                </div>
                                {/*end: Item*/}
                            </div>

                            <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                {/*begin: Item*/}
                                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                    <span className="mr-4">
                                        <span className="svg-icon svg-icon-light-dark svg-icon-3x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Clip.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <path d="M14,16 L12,16 L12,12.5 C12,11.6715729 11.3284271,11 10.5,11 C9.67157288,11 9,11.6715729 9,12.5 L9,17.5 C9,19.4329966 10.5670034,21 12.5,21 C14.4329966,21 16,19.4329966 16,17.5 L16,7.5 C16,5.56700338 14.4329966,4 12.5,4 L12,4 C10.3431458,4 9,5.34314575 9,7 L7,7 C7,4.23857625 9.23857625,2 12,2 L12.5,2 C15.5375661,2 18,4.46243388 18,7.5 L18,17.5 C18,20.5375661 15.5375661,23 12.5,23 C9.46243388,23 7,20.5375661 7,17.5 L7,12.5 C7,10.5670034 8.56700338,9 10.5,9 C12.4329966,9 14,10.5670034 14,12.5 L14,16 Z" fill="#000000" fillRule="nonzero" transform="translate(12.500000, 12.500000) rotate(-315.000000) translate(-12.500000, -12.500000) " />
                                            </g>
                                        </svg>{/*end::Svg Icon*/}</span>

                                    </span>
                                    <div className="d-flex flex-column text-dark-75">
                                        <span className="font-weight-bolder font-size-sm">Borç</span>
                                        <span className="font-weight-bolder font-size-h5">
                                            <span className="text-dark-50 font-weight-bold">₺</span>{customerData.length != 0 ? customerData.debt : ""}</span>
                                    </div>
                                </div>
                                {/*end: Item*/}
                            </div>

                            <div className="col-xl-3 col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                {/*begin: Item*/}
                                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                    <span className="mr-4">
                                        <span className="svg-icon svg-icon-light-dark svg-icon-3x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Clipboard.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                <rect x={0} y={0} width={24} height={24} />
                                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
                                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
                                                <rect fill="#000000" opacity="0.3" x={7} y={10} width={5} height={2} rx={1} />
                                                <rect fill="#000000" opacity="0.3" x={7} y={14} width={9} height={2} rx={1} />
                                            </g>
                                        </svg>{/*end::Svg Icon*/}</span>

                                    </span>
                                    <div className="d-flex flex-column text-dark-75">
                                        <span className="font-weight-bolder font-size-sm">İşlem Sayısı</span>
                                        <span className="font-weight-bolder font-size-h5">
                                            <span className="text-dark-50 font-weight-bold">₺</span>{customerData.length != 0 ? customerData.process_count : ""}</span>
                                    </div>
                                </div>
                                {/*end: Item*/}
                            </div>

                            {/*begin: Item*/}
                            {/* <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                            <span className="mr-4">
                                <span className="svg-icon svg-icon-light-dark svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M14.4862 18L12.7975 21.0566C12.5304 21.54 11.922 21.7153 11.4386 21.4483C11.2977 21.3704 11.1777 21.2597 11.0887 21.1255L9.01653 18H5C3.34315 18 2 16.6569 2 15V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H14.4862Z" fill="black" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z" fill="black" />
                                    </g>
                                </svg></span>

                            </span>
                            <div className="d-flex flex-column">
                                <span className="text-dark-75 font-weight-bolder font-size-sm">648 Comments</span>
                                <a href="#" className="text-primary font-weight-bolder">View</a>
                            </div>
                        </div> */}
                            {/*end: Item*/}
                            {/*begin: Item*/}
                            {/* <div className="d-flex align-items-center flex-lg-fill my-1">
                            <span className="mr-4">
                                <i className="flaticon-network icon-2x text-muted font-weight-bold" />
                            </span>
                            <div className="symbol-group symbol-hover">
                                <div className="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Mark Stone">
                                    <img alt="Pic" src="/assets/media/users/300_25.jpg" />
                                </div>
                                <div className="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Charlie Stone">
                                    <img alt="Pic" src="/assets/media/users/300_19.jpg" />
                                </div>
                                <div className="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Luca Doncic">
                                    <img alt="Pic" src="/assets/media/users/300_22.jpg" />
                                </div>
                                <div className="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Nick Mana">
                                    <img alt="Pic" src="/assets/media/users/300_23.jpg" />
                                </div>
                                <div className="symbol symbol-30 symbol-circle" data-toggle="tooltip" title="Teresa Fox">
                                    <img alt="Pic" src="/assets/media/users/300_18.jpg" />
                                </div>
                                <div className="symbol symbol-30 symbol-circle symbol-light">
                                    <span className="symbol-label font-weight-bold">5+</span>
                                </div>
                            </div>
                        </div> */}
                            {/*end: Item*/}
                        </div>
                        {/*begin: Items*/}


                        <div className="separator separator-solid my-7" />
                        {/*begin: Items*/}
                        <Accordion className="accordion accordion-solid accordion-panel accordion-svg-toggle">

                            <div className="card">

                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <div className="card-header" id="headingOne8">
                                        <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseOne8">
                                            <div className="card-label text-center">Köpeklerim<br /><span className="svg-icon svg-icon-primary svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-down.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                    <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 11.999999) rotate(-180.000000) translate(-12.000003, -11.999999) " />
                                                </g>
                                            </svg>{/*end::Svg Icon*/}</span></div>
                                        </div>
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="0">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-head-custom table-head-bg table-vertical-center table-borderless">
                                                {
                                                    customerDog == undefined ? "" : customerDog.length == 0 ? NullPage() : <thead>
                                                        <tr className="bg-gray-100 text-left">
                                                            <th style={{ minWidth: '250px' }} className="pl-7">
                                                                <span className="text-dark-75">İsim</span>
                                                            </th>
                                                            <th style={{ minWidth: '130px' }}>Yaş</th>
                                                            <th style={{ minWidth: '130px' }}>Cinsiyet</th>
                                                            <th style={{ minWidth: '120px' }}>Sertifika</th>
                                                            <th style={{ minWidth: '120px' }}>Aktif Eğitim</th>
                                                            <th style={{ minWidth: '110px' }}>Toplam Gelişim</th>
                                                            <th style={{ minWidth: '110px' }} />
                                                        </tr>
                                                    </thead>
                                                }

                                                <tbody>
                                                    {
                                                        customerDog.map(item => {
                                                            return CustomerDogItem(item)
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </Accordion.Collapse>
                            </div>

                        </Accordion>

                    </div>
                </div>
                {/*end::Card*/}

                {/*begin::Card*/}
                <div className="card card-custom">
                    {/*begin::Header*/}
                    <div className="card-header card-header-tabs-line align-items-center ">
                        {/* <h3 className="card-title align-items-center flex-column">
                        <span className="card-label font-weight-bolder text-dark">İşlem Geçmişi</span>
                    </h3> */}
                        <div className="card-toolbar">
                            <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-tabs-bold nav-tabs-line-3x" role="tablist">
                                <AuthorityControl pageCode={7}>
                                    <li className="nav-item mr-3">
                                        <a onClick={() => setindex(0)} className={index == 0 ? "nav-link active" : "nav-link"} data-toggle="tab">
                                            <span className="nav-icon mr-2">
                                                <span className="svg-icon mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <rect x={0} y={0} width={24} height={24} />
                                                            <path d="M4.875,20.75 C4.63541667,20.75 4.39583333,20.6541667 4.20416667,20.4625 L2.2875,18.5458333 C1.90416667,18.1625 1.90416667,17.5875 2.2875,17.2041667 C2.67083333,16.8208333 3.29375,16.8208333 3.62916667,17.2041667 L4.875,18.45 L8.0375,15.2875 C8.42083333,14.9041667 8.99583333,14.9041667 9.37916667,15.2875 C9.7625,15.6708333 9.7625,16.2458333 9.37916667,16.6291667 L5.54583333,20.4625 C5.35416667,20.6541667 5.11458333,20.75 4.875,20.75 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                            <path d="M2,11.8650466 L2,6 C2,4.34314575 3.34314575,3 5,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L12.9835977,18 C12.7263047,14.0909841 9.47412135,11 5.5,11 C4.23590829,11 3.04485894,11.3127315 2,11.8650466 Z M6,7 C5.44771525,7 5,7.44771525 5,8 C5,8.55228475 5.44771525,9 6,9 L15,9 C15.5522847,9 16,8.55228475 16,8 C16,7.44771525 15.5522847,7 15,7 L6,7 Z" fill="#000000" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span className="nav-text font-weight-bold">İşlem Geçmişi</span>
                                        </a>
                                    </li>
                                </AuthorityControl>
                                <AuthorityControl pageCode={8}>
                                    <li className="nav-item mr-3">
                                        <a onClick={() => setindex(1)} className={index == 1 ? "nav-link active" : "nav-link"} data-toggle="tab">
                                            <span className="nav-icon mr-2">
                                                <span className="svg-icon mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                            <rect x={0} y={0} width={24} height={24} />
                                                            <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fillRule="nonzero" />
                                                            <circle fill="#000000" opacity="0.3" cx={12} cy={10} r={6} />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span className="nav-text font-weight-bold">Borç Geçmişi</span>
                                        </a>
                                    </li>
                                </AuthorityControl>
                            </ul>
                        </div>

                    </div>

                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                        <div className="tab-content pt-5">

                            <AuthorityControl pageCode={7}>

                                {
                                    index == 0 ? <div className="tab-pane active" id="kt_apps_projects_view_tab_2" role="tabpanel">
                                        <form className="form">
                                            <div className="card-body py-0">
                                                {/*begin::Table*/}
                                                <div className="table-responsive">
                                                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_1">
                                                        {
                                                            lastProcess == undefined ? "" : lastProcess.length == 0 ? NullPage() : <thead>
                                                                <tr className="text-uppercase">
                                                                    <th className="pl-0" style={{ width: '40px' }}>
                                                                        <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                                                            <input type="checkbox" defaultValue={1} />
                                                                            <span />
                                                                        </label>
                                                                    </th>
                                                                    <th style={{ minWidth: '120px' }}>Ürün Adı</th>
                                                                    <th style={{ minWidth: '150px' }}>
                                                                        <span className="text-primary">Tarih</span>
                                                                        <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg*/}
                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                                    <rect fill="#000000" opacity="0.3" x={11} y={4} width={2} height={10} rx={1} />
                                                                                    <path d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999)" />
                                                                                </g>
                                                                            </svg>
                                                                            {/*end::Svg Icon*/}
                                                                        </span>
                                                                    </th>
                                                                    <th style={{ minWidth: '130px' }}>Türü</th>
                                                                    <th style={{ minWidth: '130px' }}>Adet</th>
                                                                    <th className="pl-0" style={{ minWidth: '100px' }}>Birim Fiyat</th>
                                                                    <th style={{ minWidth: '130px' }}>Ücret</th>
                                                                </tr>
                                                            </thead>
                                                        }

                                                        <tbody>
                                                            {
                                                                lastProcess == undefined ? "" : lastProcess.length == 0 ? "" : lastProcess.map(item => {
                                                                    return LastProcessItemList(item)
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/*end::Table*/}
                                            </div>
                                        </form>
                                    </div> : ''
                                }
                            </AuthorityControl>

                            <AuthorityControl pageCode={8}>

                                {
                                    index == 1 ? <div className="tab-pane active" id="kt_apps_projects_view_tab_2" role="tabpanel">
                                        <form className="form">
                                            <div className="card-body py-0">
                                                {/*begin::Table*/}
                                                <div className="table-responsive">
                                                    <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_1">
                                                        {
                                                            lastDebt == undefined ? "" : lastDebt.length == 0 || lastDebt == [] ? NullPage() : < thead >
                                                                <tr className="text-uppercase">
                                                                    <th className="pl-0" style={{ width: '40px' }}>
                                                                        <label className="checkbox checkbox-lg checkbox-inline mr-2">
                                                                            <input type="checkbox" defaultValue={1} />
                                                                            <span />
                                                                        </label>
                                                                    </th>
                                                                    <th style={{ minWidth: '150px' }}>
                                                                        <span className="text-primary">Tarih</span>
                                                                        <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                                            {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Down-2.svg*/}
                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                                    <rect fill="#000000" opacity="0.3" x={11} y={4} width={2} height={10} rx={1} />
                                                                                    <path d="M6.70710678,19.7071068 C6.31658249,20.0976311 5.68341751,20.0976311 5.29289322,19.7071068 C4.90236893,19.3165825 4.90236893,18.6834175 5.29289322,18.2928932 L11.2928932,12.2928932 C11.6714722,11.9143143 12.2810586,11.9010687 12.6757246,12.2628459 L18.6757246,17.7628459 C19.0828436,18.1360383 19.1103465,18.7686056 18.7371541,19.1757246 C18.3639617,19.5828436 17.7313944,19.6103465 17.3242754,19.2371541 L12.0300757,14.3841378 L6.70710678,19.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 15.999999) scale(1, -1) translate(-12.000003, -15.999999)" />
                                                                                </g>
                                                                            </svg>
                                                                            {/*end::Svg Icon*/}
                                                                        </span>
                                                                    </th>
                                                                    <th style={{ minWidth: '130px' }}>Fiyat</th>
                                                                    <th className="pl-0" style={{ minWidth: '100px' }}>Ödenen</th>
                                                                    <th style={{ minWidth: '130px' }}>Borç</th>
                                                                    <th style={{ minWidth: '130px' }}>Satış Türü</th>
                                                                    <th style={{ minWidth: '130px' }}>Öde</th>
                                                                </tr>
                                                            </thead>
                                                        }

                                                        <tbody>
                                                            {
                                                                lastDebt == undefined || lastDebt == {} ? "" : lastDebt.length == 0 ? "" : lastDebt.map(item => {
                                                                    return LastDebtItemList(item)
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/*end::Table*/}
                                            </div>
                                        </form>
                                    </div> : ''
                                }
                            </AuthorityControl>

                        </div>
                    </div>
                    {/*end::Body*/}
                </div>
            </div>

        </AuthorityControl>

    </>
}