import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import Ekmekk from "../../Ekmekk/Helper/ekmekk";

import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPlus, faTimes }  from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function ModalManager(props) {

    var inputName = useRef(null);

    const [show, setShow] = useState(false);
    const [imageArray, setImageArray] = useState([]);
    const [startDate, setStartDate] = useState(new Date());


    function ViewTittle() {
        return <h5 className="font-boldest">{props.selectedItem != undefined ? "Grup Güncelle" : "Grup Oluştur"}</h5>
    }

    return <>

        <span onClick={() => {
            var deneme = props.data.buttons[0].clickEvent();
            setShow(deneme);

        }}>
            {
                props.data.buttons[0].html
            }
        </span>

        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>
                        <ViewTittle />

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Validator>
                    {
                        props.data.inputs.map(item => {

                           if (item.type == "select") {
                                return <div className="form-group row">

                                    <label className="col-xl-3 col-lg-3 col-form-label">{item.label} : </label>
                                    <div className="col-lg-9 col-xl-9">
                                        <select name={item.name} defaultValue={props.selectedItem == undefined ? "" : props.selectedItem[item.name]}   className="form-control form-control-lg form-control-solid w-100" >
                                            {item.options.map(op =>{
                                                return <option value={op.id} >{op.label}</option>
                                            })}
                                        </select>
                                    </div>


                                </div>
                            }
                            else if (item.type == "date") {
                                return <div className="form-group row">

                                    <label className="col-xl-3 col-lg-3 col-form-label">{item.label} : </label>
                                    <div className="col-lg-9 col-xl-9">
                                        <DatePicker className="form-control form-control-lg form-control-solid w-100" selected={startDate} ref={inputName} name={item.name}  onChange={(date) => setStartDate(date)} />
                                    </div>

                                </div>
                            }
                            else {
                                return <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">{item.label} : </label>
                                    <div className="col-lg-9 col-xl-9">
                                        <input data-validation-rule={item.validation} ref={inputName} className="form-control form-control-lg form-control-solid" name={item.name} defaultValue={props.selectedItem == undefined ? "" : props.selectedItem[item.name]} placeholder={item.placeHolder == undefined ? item.label : item.placeHolder} type="text" />
                                    </div>
                                </div>
                            }

                        })
                    }




                </Validator>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    {
                        props.data.buttons.map((item, index) => {
                            if (index != 0) {
                                return <button type="button" onClick={() => {
                                    if(item.cancel)
                                    {
                                        setShow(false);
                                    }
                                    else
                                    {
                                        
                                        var deneme2 = new ValidationController();

                                        if (deneme2.Control() == 0) {
                                            Ekmekk().Alert.Sure(function (r) {
                                            
                                            if(r){
                                                 var jsonObject = deneme2.GetJSON();
                                           

                                            if(props.elementId){
                                                jsonObject.elementId = props.elementId
                                            }
                                            
                                            var deneme = item.clickEvent(jsonObject);
                                            setShow(false)
                                                }
                                            });
                                            
                                        }
                                    }
                                    



                                }} className={"btn font-weight-bold ml-2 " + item.class} id="kt_blockui_modal_default_btn" >
                                    {item.text}
                                </button>
                            }

                        })
                    }

                </div>

            </Modal.Footer>
        </Modal>
    </>
}