
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function CageController() {

    function InsertCage(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            if(dataBlock.ek_cage_image != null)
            data.append('ek_cage_image', dataBlock.ek_cage_image, dataBlock.ek_cage_image.name);
            data.append('ek_cage_name', dataBlock.ek_cage_name);
            data.append('ek_cage_size', dataBlock.ek_cage_size);
            data.append('ek_cage_price', dataBlock.ek_cage_price);

            axios.post(Ekmekk().URL + '/admin/cage', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateCage(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            if(dataBlock.ek_cage_image != null)
            data.append('ek_cage_image', dataBlock.ek_cage_image, dataBlock.ek_cage_image.name);
            data.append('ek_cage_name', dataBlock.ek_cage_name);
            data.append('ek_cage_size', dataBlock.ek_cage_size);
            data.append('ek_cage_price', dataBlock.ek_cage_price);
            data.append('ek_cage_ID', dataBlock.ek_cage_ID);

            axios.put(Ekmekk().URL + '/admin/cage', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListCage() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/cage', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {

                    console.log(response);

                    var responseList = [];
                    for (var i = 0; i < response.data.data.length; i++) {

                        var bashData = response.data.data[i];

                        if (bashData.cage_info != undefined && bashData.cage_info != "") {
                            bashData.cage_info = bashData.cage_info;
                        }
                        else {
                            bashData.cage_info = {};
                        }

                        responseList.push(bashData);
                    }

                    ok(response.data.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }


    function CageDetail(cageID) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/cage/detail/' + cageID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {

                    // var responseList = [];
                    // for (var i = 0; i < response.data.data.length; i++) {

                    //     var bashData = response.data.data[i];

                    //     if (bashData.cage_info != "") {
                    //         bashData.cage_info = JSON.parse(bashData.cage_info);
                    //     }
                    //     else {
                    //         bashData.cage_info = {};
                    //     }

                    //     responseList.push(bashData);
                    // }

                    // ok(responseList);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function DeleteCage(cageID) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/cage/' + cageID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    function InsertDogToCage(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.post(Ekmekk().URL + '/admin/cage_dog', {
                ek_cage_ID: dataBlock.ek_cage_ID,
                ek_dog_ID: dataBlock.ek_dog_ID,
                ek_entry_date: dataBlock.ek_entry_date,
                ek_exit_date: dataBlock.ek_exit_date,
                ek_dog_type: dataBlock.ek_dog_type,
                ek_process_ID: dataBlock.ek_process_ID,
                ek_process_type: dataBlock.ek_process_type,
                ek_cage_daily_price: dataBlock.ek_cage_daily_price,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });

    }

    function UpdateDogToCage(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/cage_dog', {
                ek_cage_dog_ID: dataBlock.ek_cage_dog_ID,
                ek_exit_date: dataBlock.ek_exit_date,
                is_exit: "1",
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });

    }

    function UpdateCageOnEducationSale(dogID, cageID){

        const api = Ekmekk().Profile.GetProfile()
        // /admin/cage_dog/cage  PUT Nesneler = > dogID, cageID,
        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/cage_dog/cage', {
                dogID: dogID,
                cageID: cageID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });

    }

    return {
        InsertCage, ListCage, DeleteCage, CageDetail, InsertDogToCage, UpdateCage, UpdateDogToCage, UpdateCageOnEducationSale
    }
}