import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function ModalCageInsert(props) {


    const [selectedImage, setSelectedImage] = useState(null);

    var inputCageName = useRef(null);
    var inputCageSize = useRef(null);
    var inputCagePrice = useRef(null);

    function CageInsertClick() {

        var dataBlock = {
            ek_cage_name: inputCageName.current.value,
            ek_cage_size: inputCageSize.current.value,
            ek_cage_image: selectedImage,

        }

        console.log(inputCagePrice);
        console.log(inputCagePrice.current);
        console.log(inputCagePrice.current.value);

        if (inputCagePrice == null || inputCagePrice.current == null || inputCagePrice == undefined || inputCagePrice.current == undefined) {
            dataBlock["ek_cage_price"] = 0;
        }
        else {
            dataBlock["ek_cage_price"] = inputCagePrice.current.value;
        }

        var controller = new ValidationController();

        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {

                DataCenter().Cage.InsertCage(dataBlock).then(r => {
                    // Ekmekk().Customer
                    // alert(JSON.stringify(r))

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_cage_image"] = r.data.imagePath;
                        dataBlock["ek_cage_ID"] = r.data.lastID;

                        props.insertClick(dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })

            }
            else {
                dataBlock["ek_cage_ID"] = props.selectedData.ek_cage_ID;

                DataCenter().Cage.UpdateCage(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_cage_image"] = r.data.ek_cage_image;

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }

    }

    const [show, setShow] = useState(false);

    function ClickEvent() {
        setShow(true)
    }

    function ViewTittle() {
        return <h5 className="font-boldest">{props.isUpdate == true ? "Kafes Güncelle" : "Kafes Oluştur"}</h5>
    }

    function GetData() {


        var items = document.getElementById("customerForm").querySelectorAll("input,textarea,select");

        // var userData = {}
        // items.forEach(item => {
        //     if (item.type == "file") {
        //         userData["pp"] = item.file;
        //     }
        //     else {
        //         userData[item.id] = item.value;
        //     }

        // })

        // alert(JSON.stringify(userData))

        //  DataCenter.Customer.Insert(userData);
    }

    return <>

        <BrowserView>
            {
                props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Güncelle</button> : <div onClick={ClickEvent} className="btn btn-primary btn-primary">Kafes Ekle</div>
            }
        </BrowserView>
        <MobileView>
            {
                props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Güncelle</button> : <div onClick={ClickEvent} className="btn btn-block btn-primary btn-primary">Kafes Ekle</div>
            }
        </MobileView>


        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>

                        <ViewTittle />


                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="customerForm">
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}
                    {/* FOTO SEÇİMİ */}
                    {
                        ImagePicker(function (r) {
                            setSelectedImage(r);

                        }, true, false, props.selectedData == undefined ? null : Ekmekk().URL + '/images/' + props.selectedData.ek_cage_image)
                    }
                    <br />
                    <Validator>
                        {/* KULLANICI İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kafes Adı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputCageName} className="form-control form-control-lg form-control-solid" name="customerName" defaultValue={props.selectedData != undefined ? props.selectedData.ek_cage_name : ""} placeholder="Kafes Adı" type="text" />
                            </div>
                        </div>
                        {/* TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kafes Boyutu: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputCageSize} className="form-control form-control-lg form-control-solid" name="name" placeholder="Kafes Boyutu" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_cage_size : ""} />
                            </div>
                        </div>
                        {/* TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kafes Fiyatı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="onlyNumber" ref={inputCagePrice} className="form-control form-control-lg form-control-solid" name="name" placeholder="Kafes Fiyatı" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_cage_price : ""} />
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={CageInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Oluştur"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}