
import ModalDogInsert from "../../Modal/RoyalDog/RoyalDogInsert"

import React, { useState, useEffect, useRef } from 'react'

import { useHistory, NavLink, useParams } from "react-router-dom";
import SubHeader from "../../Thema/sub-header";
import DataCenter from "../../Ekmekk/DataCenter/datacenter";
import Ekmekk from "../../Ekmekk/Helper/ekmekk";
import NullPage from "../../Ekmekk/Views/NullPage";
import Sticky from "react-sticky-el";
import ModalManager from '../../Ekmekk/Helper/ModalManager'
import { faPencilRuler, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import imageCompression from 'browser-image-compression'
import ImagePicker from '../../Ekmekk/Helper/ImagePicker'
import MaterialTable from 'material-table'
import AuthorityControl from "../../Ekmekk/Auth/AuthorityControl";
import { BrowserView, MobileView } from "react-device-detect";

var renderCount = 0

var inputs = [
    {
        label: "Aşı Tarihi",
        validation: "notNull",
        type: "date",
        name: "vaccine_date"
    },
    {
        label: "Aşı Durumu",
        name: "vaccionDateType",
        validation: "notNull",
        type: "select",
        options: [
            {
                label: "Yapılacak Aşı",
                id: "0",
            },
            {
                label: "Yapılan Aşı",
                id: "1",
            },
        ]

    },
    {
        label: "Köpek Tipi",
        name: "type",
        validation: "notNull",
        type: "select",
        options: [
            {
                label: "Şirket Köpeği",
                id: "0",
            },
            {
                label: "Müşteri Köpeği",
                id: "1",
            },
        ]

    },
];


export default function ProfilKopek() {

    var vaccineModal = {
        tittle: "Aşı oluştur",
        inputs: inputs,
        buttons: [
            {
                html: <a className="btn btn-sm btn-block btn-light-danger font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1">Aşı Oluştur</a>,
                clickEvent: () => {
                    return true;
                },
            },
            {
                text: "Oluştur",
                class: "btn-success",
                clickEvent: (data) => {

                    let dataa = {
                        type: data.type,
                        ek_vaccine_ID: data.ek_vaccine_ID,
                        ek_dog_ID: id
                    }
                    var a = data.vaccine_date.split('/')
                    var dateee = a[2] + "-" + a[0] + "-" + a[1]
                    data.vaccionDateType == 0 ? dataa.ek_will_vaccine_date = dateee : dataa.ek_was_vaccine_date = dateee

                    DataCenter().Customer.InsertCustomerDogVaccine(dataa).then(r => {

                        DataCenter().RoyalDog.ListRoyalDogProfile(id).then(r => {
                            console.log(r.data);
                            setDogData(r.data.dog);
                            setVaccineList(r.data.vaccine)

                        });

                    })
                    return false;
                },
            },
            {
                text: "Vazgeç",
                cancel: true,
                class: "btn-secondary",
                clickEvent: () => {
                    return false;
                },
            }
        ]
    }

    var updateModal = {
        tittle: "Raport verisi girişi",
        inputs: inputs,
        buttons: [
            {
                html: <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Communication/Write.svg*/}
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <rect x={0} y={0} width={24} height={24} />
                                <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                            </g>
                        </svg>
                        {/*end::Svg Icon*/}
                    </span>
                </a>,
                clickEvent: () => {
                    return true;
                },
            },
            {
                text: "Güncelle",
                class: "btn-success",
                clickEvent: (data) => {
                    let dataa = {
                        type: data.type,
                        ek_vaccine_ID: data.ek_vaccine_ID,
                        ek_dog_ID: id
                    }
                    var a = data.vaccine_date.split('/')
                    var dateee = a[2] + "-" + a[0] + "-" + a[1]
                    data.vaccionDataType == 0 ? dataa.ek_will_vaccine_date = dateee : dataa.ek_was_vaccine_date = dateee
                    dataa.ek_dog_vaccine_ID = data.elementId

                    DataCenter().Customer.UpdateCustomerDogVaccine(dataa).then(r => {
                        DataCenter().RoyalDog.ListRoyalDogProfile(id).then(r => {
                            console.log(r.data);
                            setDogData(r.data.dog);
                            setVaccineList(r.data.vaccine)

                        });
                        console.log(r);
                    })

                    return false;
                },
            },
            {
                text: "Sil",
                class: "btn-danger",
                clickEvent: (data) => {
                    DataCenter().Customer.DeleteCustomerDogVaccine(data.elementId).then(r => {

                        DataCenter().RoyalDog.ListRoyalDogProfile(id).then(r => {
                            console.log(r.data);
                            setDogData(r.data.dog);
                            setVaccineList(r.data.vaccine)

                        });
                        console.log(r);
                    })

                    return false;
                },
            },
            {
                text: "Vazgeç",
                cancel: true,
                class: "btn-secondary",
                clickEvent: () => {
                    return false;
                },
            }
        ]
    }

    const columns = [
        {
            title: <span className="text">Aşı Adı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_vaccine_name}</div>
        },
        {
            title: <span className="text">Durum</span>, render: data => <div className={data.ek_was_vaccine_date !== null ? "label label-light-success label-pill label-inline mr-2" : "label label-light-warning label-pill label-inline mr-2"}>{data.ek_was_vaccine_date !== null ? "Yapıldı" : "Yapılacak "}</div>
        },
        {
            title: <span className="text">Tarihi</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_was_vaccine_date !== null ? data.ek_was_vaccine_date : data.ek_will_vaccine_date}</div>
        },
        {
            title: <span className="text">İşlemler</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">

                {<ModalManager data={updateModal} selectedItem={data} elementId={data.ek_dog_vaccine_ID} />}
            </div>
        },

    ]

    const columns2 = [
        {
            title: <span className="text">Mama Adı</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_product_name}</div>
        },
        {
            title: <span className="text">Adet</span>, render: data => <div className={"text-dark-75 font-weight-bolder text-hover-primary font-size-lg"}>{data.ek_food_piece}</div>
        },
        {
            title: <span className="text">Tarihi</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_creation_date}</div>
        },
        {
            title: <span className="text">Alış</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_food_purchase_price}</div>
        },
        {
            title: <span className="text">Satış</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{data.ek_food_sale_price}</div>
        },
        // {
        //     title: <span className="text">İşlemler</span>, render: data => <div className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">

        //         {<ModalManager data={updateModal} selectedItem={data} elementId={data.ek_dog_vaccine_ID} />}
        //     </div>
        // },

    ]


    var addModalData = {

        label: "Aşı Seçimi",
        name: "ek_vaccine_ID",
        type: "select",
        options: []

    }


    var history = useHistory();
    // @ts-ignore
    const { id } = useParams();

    const inputLogo = useRef(null)

    const [selectedImageFile, setSelectedImageFile] = useState([])
    const [newLogo, setNewLogo] = useState({})
    const [dogData, setDogData] = useState([]);
    const [vaccineList, setVaccineList] = useState([])
    const [imageList, setImageList] = useState([]);
    const [foodList, setFoodList] = useState([])

    const Material = (
        <MaterialTable
            data={vaccineList}
            columns={columns}
            options={
                {
                    filtering: false,
                    search: false,
                    toolbar: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10],
                }

            } />
    )

    const Material2 = (
        <MaterialTable
            data={foodList}
            columns={columns2}
            options={
                {
                    filtering: false,
                    search: false,
                    toolbar: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10],
                }

            } />
    )


    useEffect(() => {

        DataCenter().RoyalDog.ListRoyalDogProfile(id).then(r => {
            console.log(r.data);
            setDogData(r.data.dog);
            setVaccineList(r.data.vaccine)
            setImageList(r.data.image);
            setFoodList(r.data.food);
        });

    }, [])



    useEffect(() => {
        DataCenter().RoyalDog.ListVaccine().then(r => {
            var d = r.data
            if (renderCount == 0) {
                d.forEach(element => {
                    let newData = {
                        id: element.ek_vaccine_ID,
                        label: element.ek_vaccine_name
                    }
                    addModalData.options.push(newData)
                })
                inputs.push(addModalData)
                renderCount++
            }

        })
    }, [])

    const PickImage = () => {

        inputLogo.current.click();
    }

    const FileSelected = (event) => {

        var f = inputLogo.current.files[0];

        var reader = new FileReader();
        reader.onload = function (e) {

            setNewLogo({ file: f, name: f.name, src: e.target.result })

            const img = new Image();

            img.crossOrigin = 'Anonymous';
            img.src = e.target.result;

        };
        reader.readAsDataURL(f)

    }

    const logoUpload = () => {
        Ekmekk().Alert.Sure(async function (r) {

            if (r) {

                var configComp = {
                    maxSizeMB: 0.2,
                    maxWidthOrHeight: 500,
                    useWebWorker: true
                }

                await imageCompression(newLogo.file, configComp)
                    .then(async function (compressedFile) {

                        var form = new FormData();
                        form.append('ek_royal_dog_ID', id)
                        form.append("ek_royal_dog_image", compressedFile, compressedFile.name)

                        DataCenter().RoyalDog.UpdateRoyalDogProfilPhoto(form).then(r => { 
                            
                            if (r.status) {
                                // FOTOĞRAF YÜKLENDİKTEN SONRA BUTONU KALDIRMAK İÇİN NEWLOGO BOŞALTILIR VE BİLGİLER TEKRAR YÜKLENİR APİ'DEN
                                setNewLogo({});
                                DataCenter().RoyalDog.ListRoyalDogProfile(id).then(r => {
                                    setDogData(r.data.dog);
                                });
                            }
                         })


                    })
                    .catch(function (error) {
                        console.log(error.message);
                    });


            }
        })

    }

    function DogInsertClick(caseData, data) {

        if (caseData == "updated") {

            if (data.ek_royal_dog_image == null || data.ek_royal_dog_image == undefined) {
                var tempPhoto = dogData.ek_royal_dog_image;
            }
            else {
                var tempPhoto = data.ek_royal_dog_image;
            }

            data["ek_royal_dog_image"] = tempPhoto;

            var tempPercentage = dogData.total_education_percentage;
            data["total_education_percentage"] = tempPercentage;

            setDogData(data);
        }

    }


    function CageDeleteClick(ID) {


        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Customer.DeleteCustomerDog(ID).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        // FURKAN KAFESLERİM SAYFASINA YÖNLENDİRELİM
                        history.goBack();
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })

            }
        })
    }


    function DeleteExistedImages(item) {

        console.log(item)
        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().RoyalDog.DeleteRoyalDogImages(item.ek_royal_dog_image_ID).then(r => {

                    if (r.status) {
                        var list2 = [...imageList];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_royal_dog_image_ID == item.ek_royal_dog_image_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setImageList(list2);
                    }
                })

            }
        })

    }

    const [photosArray, setPhotosArray] = useState([])

    const [options, setOptions] = useState({
        imageArray: [
        ],
        preview: true,
        multiple: true,
        compress: true,
        onRemoveImage: (removedImage, callback) => {

            for (var i = 0; i < options.imageArray.length; i++) {
                if (options.imageArray[i].id == removedImage.id) {
                    options.imageArray.splice(i, 1);
                    break;
                }
            }
            callback(removedImage)
        },
        onSelectedImage: (imageArray) => {

            setPhotosArray(imageArray)
        },
    });

    const updateDogPhotos = () => {

        InsertImages()

    }
    const InsertImages = () => {


        let data = new FormData();
        data.append('ek_royal_dog_ID', id);
        var sayac = 0;
        for (var i = 0; i < selectedImageFile.length; i++) {

            var options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            var image = selectedImageFile[i].file;

            imageCompression(image, options)
                .then(function (compressedFile) {

                    data.append('image', compressedFile, compressedFile.name);

                    sayac++;
                    if (sayac == selectedImageFile.length) {


                        DataCenter().RoyalDog.InsertRoyalDogImages(data).then(r => {
                            console.log(r);
                            if (r.status) {
                                Ekmekk().Alert.Success();
                            }
                            else {
                                Ekmekk().Alert.Fail();
                            }

                        })

                    }
                    return true; // write your own logic
                })


        }

    }

    const ItemAdd = (data, index) => {

        var mainArray = selectedImageFile;
        var reader = new FileReader();
        reader.onload = function (e) {

            mainArray.push({ file: data, src: e.target.result })

            setSelectedImageFile([...mainArray]);

        }

        reader.readAsDataURL(data);
    }


    const removePreviewImage = (item) => {


        var array = selectedImageFile;

        for (var i = 0; i < 10; i++) {
            if (array[i] == item) {
                array.splice(i, 1);

            }
        }
        setSelectedImageFile([...array]);
    }


    function BusinessGallery(params) {
        return <div>
            {
                ImagePicker(function (items) {

                    for (var i = 0; i < items.length; i++) {
                        ItemAdd(items[i], i);
                    }

                }, false, true)
            }
            <br />

            <div className="row">
                {
                    selectedImageFile.map(item =>
                        <div className="col-md-3 mb-8 mr-4">
                            <div class="img-wrap img-fluid" style={{ position: "relative" }}>

                                <div classname="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 mb-4 rounded" style={{ boxShadow: 'rgb(0 0 0 / 14%) 2px 2px 10px', borderRadius: 10 }}>

                                    <div classname="symbol-label rounded" style={{ borderRadius: 10, height: 200, backgroundSize: "cover", backgroundImage: 'url(' + Ekmekk().URL + '/images/' + item.src + ')' }} >



                                        <span style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "#00000066", borderRadius: 10 }}>

                                        </span>

                                        <span onClick={() => removePreviewImage(item)} style={{ position: "absolute", width: 50, height: 50, boxShadow: 'rgb(0 0 0 / 14%) 2px 2px 10px', backgroundColor: "white", left: "calc(50% - 25px)", top: "calc(50% - 25px)", color: "white", right: "50", zIndex: "100", borderRadius: 100, textAlign: "center" }}>
                                            <FontAwesomeIcon className="align-middle text-hover-danger" icon={faTimes} style={{ verticalAlign: "middle", color: "#3699FF", width: 50, height: 50, padding: 12 }} />

                                        </span>



                                    </div>
                                </div>

                            </div>

                        </div>
                    )
                }
                {
                    imageList.map(item =>
                        <div className="col-md-3 mb-8 mr-4">
                            <div class="img-wrap img-fluid" style={{ position: "relative" }}>

                                <div classname="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 mb-4 rounded" style={{ boxShadow: 'rgb(0 0 0 / 14%) 2px 2px 10px', borderRadius: 10 }}>

                                    <div classname="symbol-label rounded" style={{ borderRadius: 10, height: 200, backgroundSize: "cover", backgroundImage: 'url(' + Ekmekk().URL + '/images/' + item.ek_royal_dog_image + ')' }} >



                                        <span style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "#00000066", borderRadius: 10 }}>

                                        </span>

                                        <span onClick={() => DeleteExistedImages(item)} style={{ position: "absolute", width: 50, height: 50, boxShadow: 'rgb(0 0 0 / 14%) 2px 2px 10px', backgroundColor: "white", left: "calc(50% - 25px)", top: "calc(50% - 25px)", color: "white", right: "50", zIndex: "100", borderRadius: 100, textAlign: "center" }}>
                                            <FontAwesomeIcon className="align-middle text-hover-danger" icon={faTimes} style={{ verticalAlign: "middle", color: "#3699FF", width: 50, height: 50, padding: 12 }} />

                                        </span>



                                    </div>
                                </div>

                            </div>

                        </div>
                    )
                }
            </div>



        </div>
    }





    function CertificatesItemList(item) {
        return <>
            <div className="d-flex flex-wrap align-items-center mb-10">
                {/*begin::Symbol*/}
                <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                    <div className="symbol-label" style={{ backgroundImage: 'url("/assets/media/stock-600x400/img-17.jpg")' }} />
                </div>
                {/*end::Symbol*/}
                {/*begin::Title*/}
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                    <a className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">Koşu Eğitimi</a>
                    <span className="text-muted font-weight-bold font-size-sm my-1">Basic, basit &amp; kolay</span>
                    <span className="text-muted font-weight-bold font-size-sm">Eğitmen:
                        <span className="text-primary font-weight-bold">Ahmet Mehmet</span></span>
                </div>
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                    <a className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">Başlangıç - {item.ek_royal_dog_certificate_start_date}</a>
                    <a className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">Bitiş - {item.ek_royal_dog_certificate_end_date}</a>
                </div>
                {/*end::Title*/}
                {/*begin::Info*/}
                <div className="d-flex align-items-center py-lg-0 py-2">
                    <div className="d-flex flex-column text-right">
                        <span className="text-dark-75 font-weight-bolder font-size-h4">{item.ek_royal_dog_certificate_point}</span>
                        <span className="text-muted font-size-sm font-weight-bolder">puan</span>
                    </div>
                </div>
                {/*end::Info*/}
            </div>

        </>
    }

    function ProcessItemList(item) {
        return <>
            <NavLink to={"/dashboard/customer/dog-profile/" + id + "/education/2"}>
            </NavLink>
            <tr>
                <td className="pl-0 py-6">
                    <label className="checkbox checkbox-lg checkbox-inline">
                        <input type="checkbox" defaultValue={1} />
                        <span />
                    </label>
                </td>
                <td className="pl-0">
                    <NavLink to={"/dashboard/customer/dog-profile/" + id + "/education/" + item.selling_process_ID}>
                        <a className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{item.ek_selling_creation_date}</a>
                    </NavLink>
                </td>
                <td>
                    {
                        item.type == "Hizmet" ? <span className="label label-lg label-light-success label-inline">Gerçekleşti</span> : item.cage == "" || item.cage == undefined ? <span className="label label-lg label-light-warning label-inline">Bekliyor</span> : <span className="label label-lg label-light-success label-inline">Kafeste</span>
                    }
                </td>
                <td>
                    <span className={item.type == "Eğitim" ? "label label-success label-dot mr-2" : item.type == "Royal Dog" ? "label label-info label-dot mr-2" : item.type == "Konaklama" ? "label label-danger label-dot mr-2" : "label label-warning label-dot mr-2"} />
                    <span className={item.type == "Eğitim" ? "font-weight-bold text-success" : item.type == "Royal Dog" ? "font-weight-bold text-info" : item.type == "Konaklama" ? "font-weight-bold text-danger" : "font-weight-bold text-warning"}>{item.type}</span>
                </td>
                {/* <td>
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{item.cage}</span>
                </td> */}
                <td>
                    <span className="text-dark-75 font-weight-bold d-block font-size-lg">{item.process_name}</span>
                </td>
                {
                    item.type == "Eğitim" ? <td className="text-right">
                        <NavLink to={"/dashboard/customer/dog-profile/" + id + "/education/" + item.selling_process_ID}>
                            <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <rect x={0} y={0} width={24} height={24} />
                                            <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)" />
                                            <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                </span>
                            </a>
                        </NavLink>
                    </td> : ""
                }
            </tr>
        </>
    }

    return <>


        <AuthorityControl pageCode={40}>

            <div className="row">
                {/*begin::Aside*/}
                <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12" id="kt_profile_aside">
                    {/* <div className="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside"> */}
                    {/*begin::Profile Card*/}
                    <BrowserView>
                        <Sticky>
                            <div className="sticky card card-custom mr-4">
                                {/*begin::Body*/}
                                <div className="card-body pt-4">
                                    {/*begin::Toolbar*/}
                                    <div className="d-flex justify-content-end">
                                        <div className="dropdown dropdown-inline">
                                            <a className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="svg-icon svg-icon-light-dark svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Other2.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                        <rect x={0} y={0} width={24} height={24} />
                                                        <circle fill="#000000" cx={5} cy={12} r={2} />
                                                        <circle fill="#000000" cx={12} cy={12} r={2} />
                                                        <circle fill="#000000" cx={19} cy={12} r={2} />
                                                    </g>
                                                </svg>{/*end::Svg Icon*/}</span>

                                            </a>
                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                {/*begin::Navigation*/}

                                            </div>
                                        </div>
                                    </div>
                                    {/*end::Toolbar*/}
                                    {/*begin::User*/}
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                            <div onClick={() => PickImage()} className="symbol symbol-50 symbol-lg-120 ">
                                                <input type='file' name="logo" onChange={(event) => {
                                                    FileSelected(event);
                                                }} id='file' ref={inputLogo} style={{ display: 'none' }} />
                                                <span style={{ position: "absolute", width: 30, height: 30, boxShadow: 'rgb(0 0 0 / 14%) 2px 2px 10px', backgroundColor: "white", left: "calc(100% - 20px)", top: "calc(-10px)", color: "white", right: "50", zIndex: "50", borderRadius: 100, textAlign: "center" }}>
                                                    <FontAwesomeIcon className="align-middle" icon={faPencilRuler} style={{ verticalAlign: "middle", color: "#3699FF", width: 30, height: 30, padding: 8 }} />

                                                </span>
                                                <img alt="Pic" src={newLogo.src != undefined ? newLogo.src : dogData.ek_royal_dog_image ? "https://31.210.91.239:3602/images/" + dogData.ek_royal_dog_image : newLogo.src ? newLogo.src : "https://st4.depositphotos.com/18690434/21334/v/600/depositphotos_213341334-stock-illustration-big-photo-camera-vector-icon.jpg"} />


                                            </div>
                                            {/* <img src={dogData.ek_royal_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + dogData.ek_royal_dog_image} alt="" className="h-50" />*/}

                                            <i className="symbol-badge bg-success" />
                                        </div>
                                        <div>
                                            <a className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{dogData.ek_royal_dog_name}</a>
                                            <div className="text-muted">{dogData.ek_dog_breed_name} - {dogData.ek_dog_sub_breed_name}</div>
                                            <div className="mt-2">
                                                {newLogo.src != undefined ? <button onClick={() => logoUpload()} className="btn btn-sm btn-primary font-weight-bold  py-2 px-3 px-xxl-5 my-1">Profil Foto Ekle</button> : null}
                                                <AuthorityControl pageCode={40}>
                                                    <ModalDogInsert isUpdate={true} selectedData={dogData} insertClick={DogInsertClick} isSale={true} />
                                                </AuthorityControl >

                                                <AuthorityControl pageCode={42}>
                                                    <button onClick={() => CageDeleteClick(dogData.ek_royal_dog_ID)} className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1">Kaldır</button>
                                                </AuthorityControl >
                                            </div>
                                        </div>

                                    </div>
                                    <div className="py-9">
                                        <div className="d-flex align-items-center justify-content-between  mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Cinsiyet:</span>
                                            <a className="text-muted text-hover-primary">{dogData.ek_royal_dog_gender}</a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Doğum Tarihi:</span>
                                            <span className="text-muted">{dogData.ek_royal_dog_birhdate && dogData.ek_royal_dog_birhdate.substring(0, 10)}</span>
                                        </div>

                                        {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span className="font-weight-bolder font-size-h5 mr-2">Eğitmen Adı:</span>
                                    <span className="text-muted">Ahmet Eğitmen</span>
                                </div> */}

                                        <div className="separator separator-solid my-7" />

                                        {
                                            dogData.ek_royal_dog_purchase_price != "" && dogData.ek_royal_dog_purchase_price != undefined ? <div className="d-flex align-items-center justify-content-between mb-2">
                                                <span className="font-weight-bolder mr-2">Alış Fiyatı:</span>
                                                <span className="text-muted">₺{dogData.ek_royal_dog_purchase_price}</span>
                                            </div> : ""
                                        }
                                        {
                                            dogData.ek_royal_dog_sale_price != "" && dogData.ek_royal_dog_sale_price != undefined ? <div className="d-flex align-items-center justify-content-between mb-2">
                                                <span className="font-weight-bolder mr-2">Satış Fiyatı:</span>
                                                <span className="text-muted">₺{dogData.ek_royal_dog_sale_price}</span>
                                            </div> : ""
                                        }

                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder mr-2">Toplam Eğitim:</span>

                                            <span className="font-weight-bolder text-dark text-right">{dogData.total_education_percentage}%</span>
                                            <div className="w-xl-150px mt-sm-0">

                                                <div className="progress progress-xs mt-2 mb-2">
                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '' + dogData.total_education_percentage + '%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/*end::Contact*/}
                                    {/*begin::Nav*/}
                                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                        <div className="navi-item mb-2">
                                            <AuthorityControl pageCode={44}>

                                                {<ModalManager data={vaccineModal} />}

                                            </AuthorityControl >

                                            <AuthorityControl pageCode={43}>

                                                <NavLink to={"/dashboard/dog/royal-dog-food/" + id}>
                                                    <a className="btn btn-sm btn-block btn-light-primary font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1">Mama Ekle</a>
                                                </NavLink>
                                            </AuthorityControl >

                                        </div>
                                    </div>
                                    {/*end::Nav*/}
                                </div>
                                {/*end::Body*/}
                            </div>
                        </Sticky>
                    </BrowserView>

                    <MobileView>

                        <div className="sticky card card-custom mb-4">
                            {/*begin::Body*/}
                            <div className="card-body pt-4">
                                {/*begin::Toolbar*/}
                                <div className="d-flex justify-content-end">
                                    <div className="dropdown dropdown-inline">
                                        <a className="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="svg-icon svg-icon-light-dark svg-icon-2x">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\General\Other2.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <rect x={0} y={0} width={24} height={24} />
                                                    <circle fill="#000000" cx={5} cy={12} r={2} />
                                                    <circle fill="#000000" cx={12} cy={12} r={2} />
                                                    <circle fill="#000000" cx={19} cy={12} r={2} />
                                                </g>
                                            </svg>{/*end::Svg Icon*/}</span>

                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                            {/*begin::Navigation*/}

                                        </div>
                                    </div>
                                </div>
                                {/*end::Toolbar*/}
                                {/*begin::User*/}
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                        <div onClick={() => PickImage()} className="symbol symbol-50 symbol-lg-120 ">
                                            <input type='file' name="logo" onChange={(event) => {
                                                FileSelected(event);
                                            }} id='file' ref={inputLogo} style={{ display: 'none' }} />
                                            <span style={{ position: "absolute", width: 30, height: 30, boxShadow: 'rgb(0 0 0 / 14%) 2px 2px 10px', backgroundColor: "white", left: "calc(100% - 20px)", top: "calc(-10px)", color: "white", right: "50", zIndex: "50", borderRadius: 100, textAlign: "center" }}>
                                                <FontAwesomeIcon className="align-middle" icon={faPencilRuler} style={{ verticalAlign: "middle", color: "#3699FF", width: 30, height: 30, padding: 8 }} />

                                            </span>
                                            <img alt="Pic" src={newLogo.src != undefined ? newLogo.src : dogData.ek_royal_dog_image ? "https://31.210.91.239:3602/images/" + dogData.ek_royal_dog_image : newLogo.src ? newLogo.src : "https://st4.depositphotos.com/18690434/21334/v/600/depositphotos_213341334-stock-illustration-big-photo-camera-vector-icon.jpg"} />


                                        </div>
                                        {/* <img src={dogData.ek_royal_dog_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + dogData.ek_royal_dog_image} alt="" className="h-50" />*/}

                                        <i className="symbol-badge bg-success" />
                                    </div>
                                    <div>
                                        <a className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{dogData.ek_royal_dog_name}</a>
                                        <div className="text-muted">{dogData.ek_dog_breed_name} - {dogData.ek_dog_sub_breed_name}</div>
                                        <div className="mt-2">
                                            {newLogo.src != undefined ? <button onClick={() => logoUpload()} className="btn btn-sm btn-primary font-weight-bold  py-2 px-3 px-xxl-5 my-1">Profil Foto Ekle</button> : null}
                                            <AuthorityControl pageCode={40}>
                                                <ModalDogInsert isUpdate={true} selectedData={dogData} insertClick={DogInsertClick} isSale={true} />
                                            </AuthorityControl >

                                            <AuthorityControl pageCode={42}>
                                                <button onClick={() => CageDeleteClick(dogData.ek_royal_dog_ID)} className="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1">Kaldır</button>
                                            </AuthorityControl >
                                        </div>
                                    </div>

                                </div>
                                <div className="py-9">
                                    <div className="d-flex align-items-center justify-content-between  mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Cinsiyet:</span>
                                        <a className="text-muted text-hover-primary">{dogData.ek_royal_dog_gender}</a>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Doğum Tarihi:</span>
                                        <span className="text-muted">{dogData.ek_royal_dog_birhdate && dogData.ek_royal_dog_birhdate.substring(0, 10)}</span>
                                    </div>

                                    {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                    <span className="font-weight-bolder font-size-h5 mr-2">Eğitmen Adı:</span>
                                    <span className="text-muted">Ahmet Eğitmen</span>
                                </div> */}

                                    <div className="separator separator-solid my-7" />

                                    {
                                        dogData.ek_royal_dog_purchase_price != "" && dogData.ek_royal_dog_purchase_price != undefined ? <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder mr-2">Alış Fiyatı:</span>
                                            <span className="text-muted">₺{dogData.ek_royal_dog_purchase_price}</span>
                                        </div> : ""
                                    }
                                    {
                                        dogData.ek_royal_dog_sale_price != "" && dogData.ek_royal_dog_sale_price != undefined ? <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder mr-2">Satış Fiyatı:</span>
                                            <span className="text-muted">₺{dogData.ek_royal_dog_sale_price}</span>
                                        </div> : ""
                                    }

                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="font-weight-bolder mr-2">Toplam Eğitim:</span>

                                        <span className="font-weight-bolder text-dark text-right">{dogData.total_education_percentage}%</span>
                                        <div className="w-xl-150px mt-sm-0">

                                            <div className="progress progress-xs mt-2 mb-2">
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '' + dogData.total_education_percentage + '%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/*end::Contact*/}
                                {/*begin::Nav*/}
                                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                    <div className="navi-item mb-2">
                                        <AuthorityControl pageCode={44}>

                                            {<ModalManager data={vaccineModal} />}

                                        </AuthorityControl >

                                        <AuthorityControl pageCode={43}>

                                            <NavLink to={"/dashboard/dog/royal-dog-food/" + id}>
                                                <a className="btn btn-sm btn-block btn-light-primary font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1">Mama Ekle</a>
                                            </NavLink>
                                        </AuthorityControl >

                                    </div>
                                </div>
                                {/*end::Nav*/}
                            </div>
                            {/*end::Body*/}
                        </div>

                    </MobileView>
                    {/*end::Profile Card*/}
                </div>
                {/*end::Aside*/}
                {/*begin::Content*/}
                <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    {/* <div className="flex-row-fluid ml-lg-8"> */}
                    <AuthorityControl pageCode={45}>

                        {/*begin::List Widget 14*/}
                        <div className="card card-custom card-stretch gutter-b">
                            {/*begin::Header*/}
                            <div className="card-header border-0">
                                <h3 className="card-title font-weight-bolder text-dark">Fotoğraflar</h3>
                            </div>
                            <button onClick={() => updateDogPhotos()} style={{ position: 'absolute', top: '10px', right: '10px' }} type="button" className="btn btn-primary font-weight-bold" id="kt_blockui_modal_default_btn" >
                                {"Kaydet"}
                            </button>
                            {/*end::Header*/}
                            {/*begin::Body*/}
                            <div className="card-body pt-2">
                                {
                                    BusinessGallery()
                                }
                            </div>
                            {/*end::Body*/}
                        </div>
                        {/*end::List Widget 14*/}
                    </AuthorityControl >

                    <div className="col-lg-12">
                        {/*begin::List Widget 14*/}
                        <div className="card card-custom card-stretch gutter-b">
                            {/*begin::Header*/}
                            <div className="card-header border-0">
                                <h3 className="card-title font-weight-bolder text-dark">Aşı Listesi</h3>
                            </div>
                            {/*end::Header*/}
                            {/*begin::Body*/}
                            <div className="card-body pt-2">
                                <div>
                                    <div className="table-responsive">
                                        <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                                            {Material}
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/*end::Body*/}
                        </div>
                        {/*end::List Widget 14*/}
                    </div>


                    <div className="col-lg-12">
                        {/*begin::List Widget 14*/}
                        <div className="card card-custom card-stretch gutter-b">
                            {/*begin::Header*/}
                            <div className="card-header border-0">
                                <h3 className="card-title font-weight-bolder text-dark">Mama Listesi</h3>
                            </div>
                            {/*end::Header*/}
                            {/*begin::Body*/}
                            <div className="card-body pt-2">
                                <div>
                                    <div className="table-responsive">
                                        <table className="table table-head-custom table-vertical-center" id="kt_advance_table_widget_2">
                                            {Material2}
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/*end::Body*/}
                        </div>
                        {/*end::List Widget 14*/}
                    </div>

                </div>
                {/*end::Content*/}
            </div>
        </AuthorityControl>

    </>
}