
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function RoyalDogController() {

    function InsertRoyalDog(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            let data = new FormData();
            if(dataBlock.ek_royal_dog_image != null)
            data.append('ek_royal_dog_image', dataBlock.ek_royal_dog_image, dataBlock.ek_royal_dog_image.name);
            data.append('ek_royal_dog_name', dataBlock.ek_royal_dog_name);
            data.append('ek_royal_dog_gender', dataBlock.ek_royal_dog_gender);
            data.append('ek_royal_dog_birhdate', dataBlock.ek_royal_dog_birhdate);
            data.append('ek_royal_dog_sub_breed_ID', dataBlock.ek_royal_dog_sub_breed_ID);
            data.append('ek_royal_dog_purchase_price', dataBlock.ek_royal_dog_purchase_price);
            data.append('ek_royal_dog_sale_price', dataBlock.ek_royal_dog_sale_price);

            axios.post(Ekmekk().URL + '/admin/royal_dog', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateRoyalDog(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            let data = new FormData();
            console.log(dataBlock);

            if(dataBlock.ek_royal_dog_image != null)
            data.append('ek_royal_dog_image', dataBlock.ek_royal_dog_image, dataBlock.ek_royal_dog_image.name);
            data.append('ek_royal_dog_name', dataBlock.ek_royal_dog_name);
            data.append('ek_royal_dog_gender', dataBlock.ek_royal_dog_gender);
            data.append('ek_royal_dog_birhdate', dataBlock.ek_royal_dog_birhdate);
            data.append('ek_royal_dog_sub_breed_ID', dataBlock.ek_royal_dog_sub_breed_ID);
            data.append('ek_royal_dog_purchase_price', dataBlock.ek_royal_dog_purchase_price);
            data.append('ek_royal_dog_sale_price', dataBlock.ek_royal_dog_sale_price);
            data.append('ek_royal_dog_ID', dataBlock.ek_royal_dog_ID);

            axios.put(Ekmekk().URL + '/admin/royal_dog', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateRoyalDogProfilPhoto(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.put(Ekmekk().URL + '/admin/royal_dog', dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${dataBlock._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListRoyalDog() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/royal_dog', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function ListRoyalDogProfile(id) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/royal_dog/profile/'+id, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function DeleteRoyalDog(royalDogID){
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/royal_dog/' +  royalDogID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        })
    }

    //VACCİNEEEEEEEEEEEEEEEEE

    function InsertVaccine(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.post(Ekmekk().URL + '/admin/vaccine', dataBlock, {
                headers: {
                    'Authorization': `Basic ${api}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateVaccine(dataBlock) {
       
        const api = Ekmekk().Profile.GetProfile()
        let data = {
            ek_vaccine_name : dataBlock.ek_vaccine_name,
            ek_vaccine_ID : dataBlock.elementId
        }

        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/vaccine', data, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });

    }
    function DeleteVaccine(dataBlock){

        const api = Ekmekk().Profile.GetProfile()


        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/vaccine/' +  dataBlock.elementId, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        })
    }

    function ListVaccine(){

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/vaccine/', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);

                if (error.response.status == 401) {
                    Ekmekk().Alert.GoToLogin((e) => {
                        if (e) {
                            window.location.href = '/login';
                        }
                        else {
                            window.location.href = '/login';
                        }
                    })
          
                }
            });
        })
    }

    function InsertRoyalDogImages(block) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.post(Ekmekk().URL + '/admin/royal_dog/images', block, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${block._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function DeleteRoyalDogImages(imageID){

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.delete(Ekmekk().URL + '/admin/royal_dog/image/' + imageID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },

            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });

    }

    function ListRoyalDogSelled() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/royal_dog/sold/dog', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    return {
       DeleteVaccine,InsertRoyalDogImages, ListRoyalDogProfile,ListVaccine,UpdateRoyalDogProfilPhoto ,InsertRoyalDog, ListRoyalDog, DeleteRoyalDog, UpdateRoyalDog, InsertVaccine, UpdateVaccine, ListRoyalDogSelled, DeleteRoyalDogImages
    }
}