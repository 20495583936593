import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import DatePicker from 'react-datepicker'
import ImagePicker from '../../Ekmekk/Helper/ImagePicker'
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';


export default function ModalCaseInsert(props) {

    var inputTittle = useRef(null);
    var inputType = useRef(null);
    var inputAmount = useRef(null);
    var inputDescription = useRef(null);

    const [calendarDate, setCalendarDate] = useState(new Date());

    function IncomeInsertClick() {

        // Ekmekk().Alert.Block();

        var birthDate = calendarDate, date = birthDate.getFullYear() + "/" + (birthDate.getMonth() + 1) + "/" + birthDate.getDate();

        var dataBlock = {
            title: inputTittle.current.value,
            price: inputAmount.current.value,
            description: inputDescription.current.value,
            type: JSON.parse(inputType.current.value),
            creationDate: date,
        }


        var controller = new ValidationController();
        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {
                DataCenter().Case.InsertIncomeExpense(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.UnblockUI();
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_process_ID"] = r.data.processID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.UnblockUI();
                        Ekmekk().Alert.Fail();
                    }
                })
            }
            else {
                dataBlock["ek_income_expense_ID"] = props.selectedData.ek_process_ID;

                DataCenter().Case.UpdateIncomeExpense(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        // dataBlock["ek_product_image"] = r.data.ek_product_image;

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

            console.log(dataBlock);

        }
    }

    const [show, setShow] = useState(false);


    return <>

        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={() => setShow(true)} icon={faPencilRuler} style={{ fontSize: 2 }} >
            </FontAwesomeIcon> : props.isUpdate == true ? <button onClick={() => setShow(true)}
                className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle
                </button> : <button onClick={() => setShow(true)} className="btn btn-light-info btn-sm font-weight-bolder font-size-sm py-3 px-6">Gelir/Gider Ekle</button>
        }

        <Modal
            show={show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">

                    <div>
                        <h5 className="font-boldest">{props.isUpdate == true ? "Gelir | Gider Güncelle" : "Gelir | Gider Ekle"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>

                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Gelir/Gider Başlığı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputTittle} className="form-control form-control-lg form-control-solid" name="dog_name" placeholder="Başlık Adı" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.process_name : ""} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Tutar: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputAmount} className="form-control form-control-lg form-control-solid" name="dog_name" placeholder="Tutar" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_cash_box_process_price : ""} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Tür: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputType} className="form-control form-control-lg form-control-solid" name="dog_name" placeholder="Kategori Adı" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_category_name : ""} >
                                    <option value="1" selected={props.selectedData != undefined && props.selectedData.type == 1}> Gelir</option>
                                    <option value="0" selected={props.selectedData != undefined && props.selectedData.type == 2}> Gider</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Tarih: </label>
                            <div className="col-lg-9 col-xl-9">


                                <DatePicker

                                    selected={calendarDate}
                                    // @ts-ignore
                                    onChange={date => setCalendarDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control form-control-lg form-control-solid w-100"
                                />
                            </div>
                        </div>
                        {/* MÜŞTERİ AÇIKLAMASI */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Açıklama:</label>
                            <div className="col-lg-9 col-xl-9">
                                <textarea ref={inputDescription} className="form-control" name="textarea" rows={3} placeholder="Lütfen açıklama giriniz." defaultValue={props.selectedData != undefined ? props.selectedData.description : ""} />
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false) }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={IncomeInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Ekle"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}