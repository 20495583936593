import { faMoneyBill, faMoneyBillAlt, faMoneyBillWaveAlt, faSortDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import { NavLink, useHistory } from 'react-router-dom'
import SubHeader from "../../Thema/sub-header";
import ModalCaseInsert from "../../Modal/Case/CaseModal";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import DataCenter from "../../Ekmekk/DataCenter/datacenter";
import Ekmekk from "../../Ekmekk/Helper/ekmekk";
import AuthorityControl from "../../Ekmekk/Auth/AuthorityControl";

export default function Case() {
    // const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    //     <button className="w-100 btn-primary" style={{ width: "100%" }} onClick={onClick} ref={ref}>
    //         " "
    //     </button>
    // ));

    const startRef = useRef();

    const [list, setList] = useState([]);
    const [processList, setProcessList] = useState([]);

    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    // const closeRef = useRef(null);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const closeRef = useRef(null);

    var history = useHistory();


    useEffect(() => {

        FetchData();

    }, []);


    function FetchData() {

        // BUGÜNLE 30 GÜN ÖNCESİNİN DATALARINI GETİRİR
        var birthDate = new Date(), secondDate = birthDate.getFullYear() + "-" + (birthDate.getMonth() + 1) + "-" + birthDate.getDate() + " " + birthDate.getHours() + ":" + birthDate.getMinutes() + ":" + birthDate.getSeconds();

        var d = new Date();
        d.setDate(birthDate.getDate() - 30);
        var birthDate2 = d, firstDate = birthDate2.getFullYear() + "-" + (birthDate2.getMonth() + 1) + "-" + birthDate2.getDate() + " " + birthDate2.getHours() + ":" + birthDate2.getMinutes() + ":" + birthDate2.getSeconds();

        setDateRange([birthDate, birthDate2]);

        console.log(firstDate);
        console.log(secondDate);

        DataCenter().Case.ListIncomeExpense(firstDate, secondDate).then(r => {

            if (r.status) {

                setList(r.data);
                console.log(r.data.processList);
                setProcessList(r.data.processList);
            }

        });

    }

    function FetchData2(start, end) {

        var birthDate = start, firstDate = birthDate.getFullYear() + "-" + (birthDate.getMonth() + 1) + "-" + birthDate.getDate() + " " + birthDate.getHours() + ":" + birthDate.getMinutes() + ":" + birthDate.getSeconds();
        var birthDate2 = end, secondDate = birthDate2.getFullYear() + "-" + (birthDate2.getMonth() + 1) + "-" + birthDate2.getDate() + " " + birthDate2.getHours() + ":" + birthDate2.getMinutes() + ":" + birthDate2.getSeconds();

        console.log(firstDate);
        console.log(secondDate);

        DataCenter().Case.ListIncomeExpense(firstDate, secondDate).then(r => {

            if (r.status) {

                setList(r.data);
                console.log(r.data.processList);
                setProcessList(r.data.processList);
            }

        });

    }

    function ProfitInsertClick(caseData, data) {

        if (caseData == "inserted") {

            if (data.type == 2)
                var newType = 0;

            var today = new Date(), date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();;

            var myList = [];

            // myList["ek_cash_box_ID"] = data.description;
            myList["ek_process_ID"] = data.ek_process_ID;
            myList["process_name"] = data.title;
            myList["description"] = data.description;
            myList["ek_cash_box_process_price"] = data.price;
            myList["ek_cash_box_creation_date"] = date;
            myList["type"] = newType;

            // INSERT(data,0)
            processList.push(myList);
            setProcessList([...processList]);
        }
        else if (caseData == "updated") {

            for (var i = 0; i < processList.length; i++) {
                if (processList[i].ek_process_ID == data.ek_income_expense_ID) {

                    processList[i]["ek_cash_box_process_price"] = data.price;
                    processList[i]["ek_process_ID"] = data.ek_income_expense_ID;
                    processList[i]["process_name"] = data.title;
                    processList[i]["description"] = data.description;
                    processList[i]["ek_cash_box_process_price"] = data.price;
                    processList[i]["ek_cash_box_creation_date"] = data.creationDate;

                    if (data.type == 0)
                        processList[i]["type"] = 2;
                    else
                        processList[i]["type"] = 1;

                    setProcessList([...processList]);

                }
            }

        }

    }

    function IncomeExpenseDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Case.DeleteIncomeExpense(item.ek_process_ID).then(r => {
                    console.log(item.ek_process_ID);
                    if (r.status) {
                        var list2 = [...processList];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_process_ID == item.ek_process_ID) {
                                console.log(list2[i].ek_process_ID);
                                list2.splice(i, 1)
                            }
                        }
                        setProcessList(list2);
                    }
                })

            }
        })

    }


    function ShowDetail(item) {

        // item.type 0 veya 3 ise detaya gönder - 1:gelir 2:gider
        if (item.type != 4 || item.type != 5) {
            history.push("/dashboard/company/case/" + item.ek_cash_box_ID);
        }

    }



    function ProfitItemList(item) {
        return <>
            <tr>
                <td onClick={() => { ShowDetail(item) }} className="w-40px align-middle pb-6 pl-0 pr-2">

                    {
                        item.type == 1 || item.type == 3 || item.type == 8 || item.type == 2 || item.type == 4 ? <div className="symbol symbol-40 symbol-light-success">
                            <span className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-success">
                                    <FontAwesomeIcon icon={faMoneyBillWaveAlt} />
                                </span>
                            </span>
                        </div> : item.type == 0 ? <div className="symbol symbol-40 symbol-light-success">
                            <span className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-success">
                                    <FontAwesomeIcon icon={faMoneyBillWaveAlt} />
                                </span>
                            </span>
                        </div> : <div className="symbol symbol-40 symbol-light-danger align-middle">
                            <span className="symbol-label">
                                <span className="svg-icon svg-icon-lg svg-icon-danger">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                </span>
                            </span>
                        </div>
                    }

                    {/*end::Symbol*/}
                </td>
                <td onClick={() => { ShowDetail(item) }} className="font-size-lg font-weight-bolder text-dark-75 align-middle w-150px pb-6">{item.ek_cash_box_ID}</td>
                <td onClick={() => { ShowDetail(item) }} className="font-size-lg font-weight-bolder text-dark-75 align-middle w-150px pb-6">{item.process_name}</td>
                <td onClick={() => { ShowDetail(item) }} className="font-weight-bold text-muted text-right align-middle pb-6">{item.description}</td>
                <td onClick={() => { ShowDetail(item) }} className="font-weight-bold text-muted text-right align-middle pb-6">{item.ek_cash_box_creation_date}</td>
                <td onClick={() => { ShowDetail(item) }} className="font-weight-bolder font-size-lg text-dark-75 text-right align-middle pb-6">{item.ek_cash_box_process_price} ₺</td>
                <td className="font-weight-bolder font-size-lg text-dark-75 text-right align-middle pb-6">
                    {
                        item.type == 4 || item.type == 5 ? <div className="btn-group">
                            <AuthorityControl pageCode={36}>
                                <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">

                                        <ModalCaseInsert isUpdateTable={true} isUpdate={true} selectedData={item} insertClick={ProfitInsertClick} />

                                    </span>
                                </div>
                                <div className="btn btn-icon btn-light btn-hover-primary btn-sm">
                                    <span className="svg-icon svg-icon-sm svg-icon-primary">

                                        <FontAwesomeIcon onClick={() => IncomeExpenseDeleteClick(item)} icon={faTrash} />

                                    </span>
                                </div>
                            </AuthorityControl>
                        </div> : ""
                    }

                </td>

            </tr>
        </>
    }



    return <div>


        <AuthorityControl pageCode={35}>

            <div className="row">

                <div className="col-md-4 p-4">
                    <div className="card card-custom bg-light-success card-stretch ">

                        <div className="card-body">
                            <span className="svg-icon svg-icon-2x svg-icon-success">
                                {/*begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg*/}
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                    viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16}
                                            rx="1.5" />
                                        <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                                        <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                                        <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                            </span>
                            <span
                                className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block">{list.monthy_income}₺</span>
                            <span className="font-weight-bold text-muted font-size-sm">Bu ay kazanılan kazanç</span>
                        </div>

                    </div>
                </div>

                <div className="col-md-4 p-4">
                    <div className="card card-custom bg-light-danger card-stretch ">

                        <div className="card-body">
                            <span className="svg-icon svg-icon-2x svg-icon-danger">
                                {/*begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg*/}
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                    viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16}
                                            rx="1.5" />
                                        <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                                        <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                                        <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                            </span>
                            <span
                                className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block">{list.monthly_expense}₺</span>
                            <span className="font-weight-bold text-muted font-size-sm">Bu ay harcanan tutar</span>
                        </div>

                    </div>
                </div>

                <div className="col-md-4 p-4">
                    <div className="card card-custom bg-light-secondary card-stretch ">

                        <div className="card-body">
                            <span className="svg-icon svg-icon-2x svg-icon-secondary">
                                {/*begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg*/}
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                    viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <rect fill="#000000" opacity="0.3" x={13} y={4} width={3} height={16}
                                            rx="1.5" />
                                        <rect fill="#000000" x={8} y={9} width={3} height={11} rx="1.5" />
                                        <rect fill="#000000" x={18} y={11} width={3} height={9} rx="1.5" />
                                        <rect fill="#000000" x={3} y={13} width={3} height={7} rx="1.5" />
                                    </g>
                                </svg>
                                {/*end::Svg Icon*/}
                            </span>
                            <span
                                className="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block">{list.monthly_net_profit}₺</span>
                            <span className="font-weight-bold text-muted font-size-sm">Bu ay net </span>
                        </div>

                    </div>
                </div>

                <div className="col-md-12 mt-8">
                    {/* <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                        if (update[1] != null) {
                            startRef.current.setOpen(false);
                            FetchData2(update[0], update[1]);
                        }
                    }}
                    isClearable={true}
                /> */}
                    {/* <DatePicker

                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                        if (update[1] != null) {
                            startRef.current.setOpen(false);
                            FetchData2(update[0], update[1]);
                        }

                    }}
                    ref={startRef}
                    withPortal
                    shouldCloseOnSelect={false}
                /> */}



                    <div className="card card-custom card-stretch gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-6 mb-2">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">Harcamalar</span>
                                <span className="text-muted font-weight-bold font-size-sm">{processList.length} Harcama bulundu</span>
                            </h3>

                            <div className="card-toolbar">
                                {/* <Picker onSelected={(start, end) => {
                                console.log(start);
                                console.log(end);
                            }} />
                            <div className="btn btn-primary mt-0 mr-2" onClick={() => {
                                closeRef.current.setOpen(true);
                            }}>
                                {
                                    (startDate == null ? firstDate : startDate.getDate() + "-" + startDate.getMonth() + "-" + startDate.getFullYear()) + " / " + (endDate == null ? secondDate : endDate.getDate() + "-" + endDate.getMonth() + "-" + endDate.getFullYear())
                                }
                            </div> */}

                                <div style={{ float: "left" }}>
                                    <DatePicker

                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        ref={closeRef}
                                        customInput={<div style={{ float: "left" }}></div>}

                                        shouldCloseOnSelect={false}
                                        onChange={(update) => {
                                            setDateRange(update);
                                            // if (update[1] != null) {
                                            //     closeRef.current.setOpen(false);
                                            //     props.onSelected(update[0], update[1])
                                            //     FetchData2(update[0], update[1]);
                                            // }

                                            if (update[1] != null) {
                                                closeRef.current.setOpen(false);
                                                // props.onSelected(update[0], update[1])
                                                FetchData2(update[0], update[1]);


                                                // setFirstDate(JSON.stringify(CorrectDate(update[0])));
                                                // setSecondDate(JSON.stringify(CorrectDate(update[1])));

                                            }
                                            setDateRange(update);
                                        }}
                                        withPortal
                                    />
                                </div>


                                <div className="btn btn-primary mt-0 mr-2" onClick={() => {
                                    closeRef.current.setOpen(true);
                                }}>
                                    {/* (startDate != undefined ? firstDate : "..-..-.. / " + (secondDate != undefined ? secondDate : "..-..-..")) */}

                                    {
                                        (startDate == null ? "..-..-.." : startDate.getDate() + "-" + startDate.getMonth() + "-" + startDate.getFullYear()) + " / " + (endDate == null ? "..-..-.." : endDate.getDate() + "-" + endDate.getMonth() + "-" + endDate.getFullYear())
                                    }
                                </div>

                                <AuthorityControl pageCode={36}>

                                    <ModalCaseInsert insertClick={ProfitInsertClick} />
                                </AuthorityControl >

                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body pt-2">
                            {/*begin::Table*/}
                            <div className="table-responsive">
                                <table className="table table-borderless mb-0">
                                    <tbody>
                                        {

                                            processList.map(item => {
                                                return ProfitItemList(item);
                                            })
                                        }


                                    </tbody>
                                </table>
                            </div>
                            {/*end::Table*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                    {/*end::List Widget 19*/}

                </div>
            </div>
        </AuthorityControl>

    </div>


    function CorrectDate(date) {

        var birthDate = date, firstDate = birthDate.getDate() + "-" + (birthDate.getMonth() + 1) + "-" + birthDate.getFullYear();

        console.log(firstDate);

        return firstDate;

    }

    // const [firstDate, setFirstDate] = useState();
    // const [secondDate, setSecondDate] = useState();
    var firstDate, secondDate;
    function Picker(props) {
        const [dateRange, setDateRange] = useState([null, null]);
        const [startDate, endDate] = dateRange;
        const closeRef = useRef(null);

        // const [firstDate, setFirstDate] = useState();
        // const [secondDate, setSecondDate] = useState();
        // var firstDate, secondDate;
        return (
            <>
                <div style={{ float: "left" }}>
                    <DatePicker

                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        ref={closeRef}
                        customInput={<div style={{ float: "left" }}></div>}

                        shouldCloseOnSelect={false}
                        onChange={(update) => {
                            setDateRange(update);
                            // if (update[1] != null) {
                            //     closeRef.current.setOpen(false);
                            //     props.onSelected(update[0], update[1])
                            //     FetchData2(update[0], update[1]);
                            // }

                            if (update[1] != null) {
                                closeRef.current.setOpen(false);
                                props.onSelected(update[0], update[1])
                                FetchData2(update[0], update[1]);


                                // setFirstDate(JSON.stringify(CorrectDate(update[0])));
                                // setSecondDate(JSON.stringify(CorrectDate(update[1])));

                                firstDate = CorrectDate(update[0]);
                                secondDate = CorrectDate(update[1]);

                                console.log(firstDate);
                                console.log(secondDate);

                            }
                            setDateRange(update);
                        }}
                        withPortal
                    />
                </div>


                <div className="btn btn-primary mt-0 mr-2" onClick={() => {
                    closeRef.current.setOpen(true);
                }}>
                    {
                        (startDate == null ? firstDate : startDate.getDate() + "-" + startDate.getMonth() + "-" + startDate.getFullYear()) + " / " + (endDate == null ? secondDate : endDate.getDate() + "-" + endDate.getMonth() + "-" + endDate.getFullYear())
                    }
                </div>
            </>


        );
    }

}