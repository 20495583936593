
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Validator, { ValidationController } from "../../Ekmekk/Helper/Validator"
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function ModalStockInsert(props) {

    // const [selectedImage, setSelectedImage] = useState(null);

    // var inputStockName = useRef(null);
    // var inputStockBrandID = useRef(null);
    // var inputStockCategoryID = useRef(null);

    var inputStockPurchasePrice = useRef(null);
    var inputStockSalePrice = useRef(null);
    var inputStockStok = useRef(null);

    const { id } = useParams();

    function StockInsertClick() {

        var dataBlock = {
            // ek_product_image: selectedImage,
            // ek_product_name: inputStockName.current.value,
            // ek_product_brandID: inputStockBrandID.current.value,
            // ek_product_category_ID: inputStockCategoryID.current.value,
            // ek_product_category_name: null,

            ek_product_ID: props.productID,
            ek_product_stock: inputStockStok.current.value,
            // ek_product_purchase_price: inputStockPurchasePrice.current.value,
            // ek_product_sale_price: inputStockSalePrice.current.value,
        }


        var controller = new ValidationController();
        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {

                dataBlock["ek_product_purchase_price"] = inputStockPurchasePrice.current.value;
                dataBlock["ek_product_sale_price"] = inputStockSalePrice.current.value;

                DataCenter().Stock.InsertStock(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        // dataBlock["ek_product_image"] = r.data.imagePath;
                        // dataBlock["ek_product_ID"] = r.data.lastID;
                        dataBlock["ek_product_stock_ID"] = r.data.ek_product_stock_ID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }
            else {

                dataBlock["ek_product_stock_ID"] = props.selectedData.ek_product_stock_ID;
                dataBlock["ek_product_purchase_price"] = props.selectedData.ek_product_purchase_price;
                dataBlock["ek_product_sale_price"] = props.selectedData.ek_product_sale_price;

                console.log(dataBlock);
                DataCenter().Stock.UpdateStock(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        // dataBlock["ek_product_image"] = r.data.ek_product_image;

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }


    }


    const [show, setShow] = useState(false);

    function ClickEvent() {
        setShow(true)
    }

    function ViewTittle() {
        return <h5 className="font-boldest">{props.isUpdate == true ? "Stok Güncelle" : "Stok Oluştur"}</h5>
    }


    return <>

        <BrowserView>
            {
                props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Stok Oluştur</button>
                    : ""
            }
        </BrowserView>
        <MobileView>
            {
                props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary btn-block font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                    Stok Oluştur</button>
                    : ""
            }
        </MobileView>



        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={ClickEvent} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>

                        <ViewTittle />


                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="customerForm">


                    <Validator>

                        {
                            props.isUpdate != true ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Alış Fiyat: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <input data-validation-rule="notNull|onlyNumber" ref={inputStockPurchasePrice} className="form-control form-control-lg form-control-solid" name="phone" placeholder="Fiyat" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_purchase_price : ""} />
                                </div>
                            </div> : ""
                        }

                        {
                            props.isUpdate != true ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyat: </label>
                                <div className="col-lg-9 col-xl-9">
                                    <input data-validation-rule="notNull|onlyNumber" ref={inputStockSalePrice} className="form-control form-control-lg form-control-solid" name="phone" placeholder="Fiyat" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_sale_price : ""} />
                                </div>
                            </div> : ""
                        }

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Stok:</label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputStockStok} className="form-control form-control-lg form-control-solid" type="number" placeholder="Stok" name="example-email-input" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_stock : ""} />
                            </div>
                        </div>

                    </Validator>

                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={StockInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Oluştur"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}