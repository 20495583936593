import axios from "axios"
import Ekmekk from "../Helper/ekmekk";

export default function Case() {

    function ListIncomeExpense(startDate, endDate) {

        return new Promise((ok, fail) => {
            const api = Ekmekk().Profile.GetProfile()

            axios.post(Ekmekk().URL + '/admin/cash_box', {
                startDate: startDate,
                endDate: endDate,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });
        })
    }

    function InsertIncomeExpense(dataBlock) {
        
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.post(Ekmekk().URL + '/admin/income_expense', {
                title: dataBlock.title,
                description: dataBlock.description,
                price: dataBlock.price,
                type: dataBlock.type,
                creationDate: dataBlock.creationDate
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            }).catch(function (error) {
                console.log(error);
            });
        })
    }

    function UpdateIncomeExpense(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/income_expense', {
                ek_income_expense_ID: dataBlock.ek_income_expense_ID,
                ek_income_expense_creation_date: dataBlock.creationDate,
                price: dataBlock.price,
                type: dataBlock.type,
                description: dataBlock.description,
                title: dataBlock.title,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function DeleteIncomeExpense(incomeExpenseID) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/income_expense/' + incomeExpenseID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    
    function ShowIncomeExpenseDetail(ek_cash_box_ID) {
        
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {

            axios.get(Ekmekk().URL + '/admin/cash_box/' + ek_cash_box_ID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            }).catch(function (error) {
                console.log(error);

                if (error.response.status == 401) {
                    Ekmekk().Alert.GoToLogin((e) => {
                        if (e) {
                            window.location.href = '/login';
                        }
                        else {
                            window.location.href = '/login';
                        }
                    })
          
                }
            });
        })
    }

    return {
        ListIncomeExpense, InsertIncomeExpense, UpdateIncomeExpense, DeleteIncomeExpense, ShowIncomeExpenseDetail,
    }
}