import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'

import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler, faRuler } from '@fortawesome/free-solid-svg-icons';

export default function ModalAdminInsert(props) {

    const [selectedImage, setSelectedImage] = useState(null);
    const [permissionGroupList, setPermissionGroupList] = useState([]);
    const [phone, setphone] = useState("");

    var inputName = useRef(null);
    var inputSurname = useRef(null);
    var inputMail = useRef(null);
    var inputPassword = useRef(null);
    var inputSelect = useRef(null);


    function CustomerInsertClick() {

        var dataBlock = {
            ek_admin_name: inputName.current.value,
            ek_admin_lastname: inputSurname.current.value,
            ek_admin_mail: inputMail.current.value,
            ek_admin_ID: 0,
            ek_admin_authority_ID: inputSelect.current.value,
            ek_admin_authority: inputSelect.current[inputSelect.current.selectedIndex].text,

        }
        

        var controller = new ValidationController();

        if (controller.Control() == 0) {
            if(props.selectedItem != undefined)
            {
                DataCenter().Auth.UpdateUser(props.selectedItem.ek_admin_ID, inputMail.current.value, inputName.current.value, inputSurname.current.value,inputPassword.current.value,inputSelect.current.value).then((r)=>{
                    dataBlock["ek_admin_ID"] =props.selectedItem.ek_admin_ID
                    props.OnHandler("updated",dataBlock);
                    setShow(false);
                });
            }
            else
            {
                DataCenter().Auth.InsertUser(inputMail.current.value, inputName.current.value, inputSurname.current.value,inputPassword.current.value,inputSelect.current.value).then((r)=>{
                    dataBlock["ek_admin_ID"] = r.id;
                    props.OnHandler("inserted",dataBlock);
                    setShow(false);
                });
            }
           
        }


    }

    const ilceler = ["Adalar", "Arnavut", "Bağcılar", "Bahçelievler", "Bakırköy", "Başakşehir", "Bayrampaşa", "Beşiktaş", "Beykoz", "Beylikdüzü", "Beyoğlu", "Büyükçekmece",
        "Çatalca", "Çekmeköy", "Esenler", "Esenyurt", "Eyüp", "Fatih", "Gaziosmanpaşa", "Güngören", "Kadıköy", "Kağıthane", "Kartal", "Küçükçekmece", "Maltepe",
        "Pendik", "Sancaktepe", "Sarıyer", "Silivri", "Sultanbeyli", "Sultangazi", "Şile", "Şişli", "Tuzla", "Ümraniye", "Üsküdar", "Zeytinburnu"
    ];

    function IlceItem(item) {
        return <option value={item} >{item}</option>
    }


    const [show, setShow] = useState(false);

    function ClickEvent() {
     
        DataCenter().Auth.ListGroup().then(r=>{
         
            setPermissionGroupList(r);
        })
       
        setShow(true)
    }

    function ViewTittle() {
        return <h5 className="font-boldest">{ props.selectedItem != undefined  ? "Kullanıcı Güncelle" : "Kullanıcı Oluştur"}</h5>
    }

    return <>

        {
            props.selectedItem == undefined ? <button onClick={ClickEvent} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                <span className="svg-icon svg-icon-md">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect x={0} y={0} width={24} height={24} />
                            <circle fill="#000000" cx={9} cy={15} r={6} />
                            <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
                        </g>
                    </svg> */}
                    {/*end::Svg Icon*/}

                </span>Kullanıcı Oluştur</button>
                : <div  onClick={ClickEvent}  className="btn btn-icon btn-light btn-hover-primary btn-sm">
                    <span className="svg-icon svg-icon-sm svg-icon-primary">

                        <FontAwesomeIcon icon={faPencilRuler}></FontAwesomeIcon>
                    </span>
                </div>
        }

        {
            props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>

                        <ViewTittle />


                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="customerForm">
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}

                    <Validator>
                        {/* KULLANICI İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">İsim : </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputName} className="form-control form-control-lg form-control-solid" name="customerName" defaultValue={props.selectedItem != undefined ? props.selectedItem.ek_admin_name : ""} placeholder="İsim" type="text" />
                            </div>
                        </div>
                        {/* TELEFON NO */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Soy İsim: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputSurname} className="form-control form-control-lg form-control-solid" name="phone" placeholder="Soyisim" defaultValue={props.selectedItem != undefined ? props.selectedItem.ek_admin_lastname : ""}  type="tel" />
                            </div>
                        </div>
                        {/* E-MAIL */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kullanıcı adı :</label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputMail} className="form-control form-control-lg form-control-solid" type="email" placeholder="Kullanıcı adı" name="example-email-input" defaultValue={props.selectedItem != undefined ? props.selectedItem.ek_admin_mail : "" } />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Şifre :</label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule={props.selectedItem ==undefined ? "notNull" : ""} ref={inputPassword} className="form-control form-control-lg form-control-solid" type="password" placeholder="Şifre" name="example-email-input"  />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Yetki :</label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputSelect} className="form-control form-control-lg form-control-solid">
                                <option value="-1">Lütfen seçim yapınız</option>
                                {
                                    permissionGroupList.map(item=>{
                                        return <option value={item.ek_admin_authority_ID} selected={props.selectedItem == undefined ? false : item.ek_admin_authority_ID == props.selectedItem.ek_admin_authority_ID}> {item.ek_admin_authority}</option>
                                    })
                                }
                                   
                                </select>
                            </div>
                        </div>

                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={CustomerInsertClick}>
                        {props.selectedItem != undefined ? "Güncelle" : "Oluştur"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}