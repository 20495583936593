
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function CategoryController() {

    function InsertCategory(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();
            if(dataBlock.ek_product_image != null)
            data.append('ek_product_category_image', dataBlock.ek_product_category_image, dataBlock.ek_product_category_image.name);
            data.append('ek_product_category_name', dataBlock.ek_product_category_name);

            axios.post(Ekmekk().URL + '/admin/product/category', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateCategory(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            let data = new FormData();

            if(dataBlock.ek_product_category_image != null)
            data.append('ek_product_category_image', dataBlock.ek_product_category_image, dataBlock.ek_product_category_image.name);
            data.append('ek_product_category_name', dataBlock.ek_product_category_name);
            data.append('ek_product_category_ID', dataBlock.ek_product_category_ID);

            axios.put(Ekmekk().URL + '/admin/product/category', data, {
                headers: {
                    'Authorization': `Basic ${api}`,
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    // @ts-ignore
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then(function (response) {
                    console.log(response.data);
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListCategory() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/product/category', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function DeleteCategory(categoryID) {
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/product/category/' + categoryID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    return {
        InsertCategory, ListCategory, DeleteCategory, UpdateCategory
    }
}