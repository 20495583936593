import React, { useEffect, useState } from "react"
import { NavLink, Route, Switch } from "react-router-dom"
import Ekmekk from "../Ekmekk/Helper/ekmekk"


export default function SubHeader(props) {

    const [headers, setHeaders] = useState([]);
    const [oldPath, setOldPath] = useState("");


    useEffect(() => {

        setHeaders(Ekmekk().GetHeaders())


    }, [])
    return <>
        <div className="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
            <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                {/*begin::Details*/}
                <div className="d-flex align-items-center flex-wrap mr-2">
                    {/*begin::Title*/}
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">

                        <Switch>

                            {
                                headers.map(MainItem => {
                                    return <Route path={MainItem.router}>
                                        {
                                            MainItem.itemName
                                        }
                                    </Route>
                                })
                            }
                        </Switch>
                    </h5>
                    {/*end::Title*/}
                    {/*begin::Separator*/}
                    <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                    {/*end::Separator*/}
                    {/*begin::Search Form*/}
                    
                    {/*end::Search Form*/}
                </div>
                {/*end::Details*/}
                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center">
                    {/*begin::Button*/}
                    <Switch>

                        {
                            headers.map(MainItem => {
                                return <Route path={MainItem.router}>
                                    {
                                        MainItem.subPage.map(item => {
                                            return <NavLink to={item.router} activeClassName="btn btn-primary font-weight-bold ml-2" className="btn btn-info font-weight-bold ml-2">
                                                <div className="menu-link menu-toggle">
                                                    <span className="menu-text">{item.itemName}</span>
                                                    <i className="menu-arrow" />
                                                </div>
                                            </NavLink>
                                        })
                                    }
                                </Route>
                            })
                        }
                    </Switch>
                    {/*end::Dropdown*/}
                </div>
                {/*end::Toolbar*/}
            </div>
        </div>

    </>
    return <>

        <div id="kt_header" className="header header-fixed">
            {/*begin::Header Wrapper*/}
            <div className="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center">
                {/*begin::Container*/}
                <div className="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap">
                    {/*begin::Menu Wrapper*/}


                    <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">


                        <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                            <h2 className="card-label">{props.tittle}</h2>

                            {/*end::Nav*/}
                        </div>
                        {/*end::Menu*/}
                    </div>
                    <div className="d-flex align-items-center py-3 py-lg-2">
                        <h3 className="card-label"></h3>

                        <div className="pl-4">

                            <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">

                                <ul className="menu-nav">
                                    <Switch>

                                        {
                                            headers.map(MainItem => {
                                                return <Route path={MainItem.router}>
                                                    {
                                                        MainItem.subPage.map(item => {
                                                            return <NavLink to={item.router} activeClassName="menu-item menu-item-submenu menu-item-rel menu-item-here" className="menu-item menu-item-submenu menu-item-rel">
                                                                <a href="javascript:;" className="menu-link menu-toggle">
                                                                    <span className="menu-text">{item.itemName}</span>
                                                                    <i className="menu-arrow" />
                                                                </a>
                                                            </NavLink>
                                                        })
                                                    }
                                                </Route>
                                            })
                                        }
                                    </Switch>



                                </ul>
                                {/*end::Nav*/}
                            </div>

                        </div>




                    </div>

                </div>
                {/*end::Container*/}
            </div>

            {/*end::Header Wrapper*/}
        </div>
        <div className="separator separator-dashed my-5"></div>
    </>
}