import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import DatePicker from "react-datepicker"


export default function ModalSaleComplete(props) {

    var odenecekTutar = useRef(null);
    var odenenTutar = useRef(null);

    const [toplamTutar, setToplamTutar] = useState(0);
    const [kalanTutar, setKalanTutar] = useState(0)


    function SaleCompleteClick() {

        console.log(switchButton);
        console.log(toplamTutar);

        var InsertedSaleList = []

        var validator = new ValidationController();
        console.log(odenenTutar.current.value)
        console.log(toplamTutar)
        console.log(parseInt(odenenTutar.current.value) <= toplamTutar )
        console.log(odenenTutar.current.value >= 0)
        console.log(validator.Control() == 0)

        if (validator.Control() == 0 && parseInt(odenenTutar.current.value) <= toplamTutar && odenenTutar.current.value >= 0) {
            // Ekmekk().Alert.Block();

            if (props.saleList.length > 0) {
                for (let i = 0; i < props.saleList.length; i++) {
                    var education = {};
                    var service = {};
                    var product = {};
                    var royaldog = {};

                    if (props.saleList[i].type == 0) {
                        education["ek_education_price"] = props.saleList[i].ek_education_price;
                        education["ek_education_ID"] = props.saleList[i].ek_education_ID;
                        education["ek_customer_dog_ID"] = props.saleList[i].ek_customer_dog_ID;
                        education["piece"] = props.saleList[i].piece;
                        education["type"] = props.saleList[i].type;
                        InsertedSaleList.push(education);
                    }
                    else if (props.saleList[i].type == 1) {

                        service["ek_service_purchase_price"] = props.saleList[i].ek_service_purchase_price;
                        service["ek_service_sale_price"] = props.saleList[i].ek_service_sale_price;
                        service["ek_service_ID"] = props.saleList[i].ek_service_ID;
                        service["ek_customer_dog_ID"] = props.saleList[i].ek_customer_dog_ID;
                        service["piece"] = props.saleList[i].piece;
                        service["type"] = props.saleList[i].type;
                        InsertedSaleList.push(service);

                    }
                    else if (props.saleList[i].type == 2) {
                        product["ek_product_sale_price"] = props.saleList[i].ek_product_sale_price;
                        product["ek_product_purchase_price"] = props.saleList[i].ek_product_purchase_price;
                        product["ek_product_ID"] = props.saleList[i].ek_product_ID;
                        product["ek_product_stock_ID"] = props.saleList[i].ek_product_stock_ID;
                        product["piece"] = props.saleList[i].piece;
                        product["type"] = props.saleList[i].type;
                        InsertedSaleList.push(product);

                    }
                    else if (props.saleList[i].type == 3) {

                        royaldog["ek_royal_dog_sale_price"] = props.saleList[i].ek_royal_dog_sale_price;
                        royaldog["ek_royal_dog_purchase_price"] = props.saleList[i].ek_royal_dog_purchase_price;
                        royaldog["ek_royal_dog_ID"] = props.saleList[i].ek_royal_dog_ID;
                        royaldog["piece"] = props.saleList[i].piece;
                        royaldog["type"] = props.saleList[i].type;
                        InsertedSaleList.push(royaldog);

                    }

                }

                var dataBlock = {
                    ek_selling_cost_price: toplamTutar,
                    ek_selling_paid_price: odenenTutar.current.value,
                    data: JSON.stringify(InsertedSaleList),
                    // customerID: JSON.parse(props.customer).ek_customer_ID,
                }

                // EĞER MÜŞTERİ SEÇİLDİYSE ID EKLENİYOR POST'A
                if (props.customer != null || props.customer != undefined) {
                    dataBlock["customerID"] = JSON.parse(props.customer).ek_customer_ID;
                }


                // GEÇMİŞ SATIŞSA
                if (switchButton == true) {

                    // alert("geçmiş");

                    var birthDate = calendarDate, date = birthDate.getFullYear() + '-' + (birthDate.getMonth() + 1) + '-' + birthDate.getDate() + ' 12:00:00';
                    dataBlock["creationDate"] = date;

                    DataCenter().Sale.InsertOldSale(dataBlock).then(r => {

                        if (r.status) {

                            // EĞİTİM SATIŞINDA KÖPEĞİ YENİ KAFESE GÜNCELLEMEK İSTEDİYSE
                            // if (props.isInCage != undefined && props.isInCage == 1 && props.updateCage != undefined && props.updateCage == 1) {

                            //     DataCenter().Cage.UpdateCageOnEducationSale(parseInt(props.dogID), props.cageID).then(r => {

                            //         if (r.status) {
                            //             // alert("yeni kafeste");
                            //         }
                            //         else {
                            //             // alert("bişeyler ters gitti " + r.status);
                            //         }

                            //     })

                            // }

                            Ekmekk().Alert.UnblockUI();
                            Ekmekk().Alert.Success();
                            setShow(false);
                            dataBlock["ek_education_selling"] = r.data.ek_education_selling;

                            if (props.insertClick != undefined || props.insertClick != null)
                                props.insertClick(dataBlock);
                        }
                        else {
                            Ekmekk().Alert.UnblockUI();
                            Ekmekk().Alert.Fail();
                        }
                    })

                }
                else {
                    alert("anlık satış");

                    DataCenter().Sale.InsertSale(dataBlock).then(r => {

                        if (r.status) {

                            // EĞİTİM SATIŞINDA KÖPEĞİ YENİ KAFESE GÜNCELLEMEK İSTEDİYSE
                            if (props.isInCage != undefined && props.isInCage == 1 && props.updateCage != undefined && props.updateCage == 1 && switchButton == false) {

                                DataCenter().Cage.UpdateCageOnEducationSale(parseInt(props.dogID), props.cageID).then(r => {

                                    if (r.status) {
                                        // alert("yeni kafeste");
                                    }
                                    else {
                                        // alert("bişeyler ters gitti " + r.status);
                                    }

                                })

                            }

                            Ekmekk().Alert.UnblockUI();
                            Ekmekk().Alert.Success();
                            setShow(false);
                            dataBlock["ek_education_selling"] = r.data.ek_education_selling;

                            if (props.insertClick != undefined || props.insertClick != null)
                                props.insertClick(dataBlock);
                        }
                        else {
                            Ekmekk().Alert.UnblockUI();
                            Ekmekk().Alert.Fail();
                        }
                    })
                }



            }
            else {
                Ekmekk().Alert.UnblockUI();

                Ekmekk().Alert.Fail();
            }

        }
        else {
            Ekmekk().Alert.Fail();
            console.log(odenenTutar.current.value);
            console.log(toplamTutar);
        }


        console.log(dataBlock);


    }

    function SumItems() {
        // setToplamTutar(props.sumItems(toplamTutar));

        var toplam = 0;
        for (var i = 0; i < props.saleList.length; i++) {
            toplam = (toplam + (props.saleList[i].sale_product_price * props.saleList[i].piece))
        }
        setToplamTutar(toplam);
        //alert(totalPrice);

    }

    const [calendarDate, setCalendarDate] = useState(new Date());
    const [switchButton, setSwitchButton] = useState(false)

    const [show, setShow] = useState(false);


    useEffect(() => {

        if (props.isShow && props.salePrice != undefined) {
            console.log(props.salePrice);
            console.log(props);
            setToplamTutar(props.salePrice);
        }


    }, [props.isShow])
    return <>

        {
            props.isVisible == false ? "" : <button type="button" onClick={() => {
                SumItems()
                setShow(true)
            }} className="btn btn-success btn-sm font-weight-bold ml-2" data-toggle="modal" data-target="#exampleModalCenter">
                Tamamla
            </button>
        }



        <Modal
            show={props.isShow == undefined ? show : props.isShow}
            onHide={() => { setShow(false); if (props.handler != undefined) { props.handler("close"); setSwitchButton(false) } }}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Ödemeyi Tamamla
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Geçmiş Satış: </label>
                            <div className="col-lg-9 col-xl-9">
                                <span className="switch">
                                    <label>

                                        <input value={switchButton} onChange={(e) => {

                                            if (switchButton == false) {
                                                setSwitchButton(true);
                                            }
                                            else {
                                                setSwitchButton(false);
                                            }

                                        }} type="checkbox" name="email_notification_1" />


                                        <span />
                                    </label>
                                </span>

                            </div>
                        </div>
                        {
                            switchButton && <>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">Satış Tarihi: </label>

                                    <div className="col-lg-9 col-xl-9">

                                        <DatePicker
                                            selected={calendarDate}
                                            // @ts-ignore
                                            onChange={date => setCalendarDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className="form-control form-control-lg form-control-solid w-100"
                                        />

                                    </div>

                                </div>
                            </>
                        }

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Ödenecek Tutar: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input className="form-control form-control-lg" id="campain_name2" disabled="disabled" placeholder={toplamTutar} type="number" defaultValue />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Ödenen Tutar: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={odenenTutar} onChange={(e) => { setKalanTutar(toplamTutar - e.target.value) }} className="form-control form-control-lg" id="campain_name2" placeholder="0" type="number" defaultValue />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kalan Tutar: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input value={kalanTutar} className="form-control form-control-lg" disabled="disabled" id="campain_name2" placeholder={kalanTutar} type="number" defaultValue />
                            </div>
                        </div>
                    </Validator>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal" onClick={() => {
                        setShow(false); if (props.handler != undefined) { props.handler("close"); setSwitchButton(false) }
                    }}>
                        Kapat</button>
                    <button type="button" className="btn btn-success font-weight-bold" onClick={SaleCompleteClick}>Tamamla</button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}