
import axios from 'axios'

import Ekmekk from "../Helper/ekmekk";

export default function EducatorController() {

    function InsertEducator(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.post(Ekmekk().URL + '/admin/educator', {
                ek_educator_fullname: dataBlock.ek_educator_fullname,
                ek_educator_phone: dataBlock.ek_educator_phone,
                ek_educator_other_phone: dataBlock.ek_educator_other_phone,
                ek_educator_mail: dataBlock.ek_educator_mail,
                ek_educator_blood_group: dataBlock.ek_educator_blood_group,
                ek_educator_address: dataBlock.ek_educator_address,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function UpdateEducator(dataBlock) {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.put(Ekmekk().URL + '/admin/educator', {
                ek_educator_fullname: dataBlock.ek_educator_fullname,
                ek_educator_phone: dataBlock.ek_educator_phone,
                ek_educator_other_phone: dataBlock.ek_educator_other_phone,
                ek_educator_mail: dataBlock.ek_educator_mail,
                ek_educator_blood_group: dataBlock.ek_educator_blood_group,
                ek_educator_address: dataBlock.ek_educator_address,
                ek_educator_ID: dataBlock.ek_educator_ID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    console.log(response.data)
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });

        });
    }

    function ListEducator() {

        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.get(Ekmekk().URL + '/admin/educator', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
                .then(function (response) {
                    ok(response.data);
                })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });

        });
    }

    function DeleteEducator(educatorID){
        const api = Ekmekk().Profile.GetProfile()

        return new Promise((ok, fail) => {
            axios.delete(Ekmekk().URL + '/admin/educator/' +  educatorID, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            })
            .then(function (response) {
                ok(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        })
    }

    return {
        InsertEducator, ListEducator, DeleteEducator, UpdateEducator
    }
}