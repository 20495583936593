import { useEffect, useState } from "react"
import Ekmekk from "../Helper/ekmekk"

export default function AuthorityControl(props)
{
    const [isVisiable,setVisiable] = useState(false);
    useEffect(()=>{
     
        
        var dizi = Ekmekk().Profile.GetPermission().split(',');
        dizi.map(item=>{
                if(item == props.pageCode)
                {
                    setVisiable(true);
                }
        });
    },[]);
    return <div>
        {isVisiable ? props.children : ""}
    </div>
}