
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import Ekmekk from '../../Ekmekk/Helper/ekmekk'

import ModalCageInsert from "../../Modal/Cage/CageInsert"
import ModalCageFill from "../../Modal/Cage/CageFill"
import SubHeader from '../../Thema/sub-header'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import NullPage from '../../Ekmekk/Views/NullPage'
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function Kafeslerim() {

    var inputFillSearch = useRef(null)
    var inputFillReasonSearch = useRef(null)


    const [cageList, setCageList] = useState([]);
    const [mainCageList, setMainCageList] = useState([]);

    useEffect(() => {
        DataCenter().Cage.ListCage().then(r => {
            console.log(r);
            console.log(r[0].cage_info[0]);
            setCageList(r)
            setMainCageList(r);
        })
    }, [])

    function CageInsertClick(data) {

        var myDizi = [];
        myDizi["exit_date"] = "-";
        myDizi["full_reason"] = "-";
        myDizi["entry_date"] = "-";

        // data["cage_info"] = {
        //     "exit_date": "-",
        //     "full_reason": "-",
        //     "entry_date": "-",
        // }
        data["cage_info"] = {}
        data["isCageFull"] = 0;
        data["isCageUsing"] = 0;
        data["dog_count"] = 0;

        console.log(data);

        // alert(JSON.stringify(data));
        cageList.push(data);
        setCageList([...cageList])

    }


    function CageDeleteClick(item) {

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Cage.DeleteCage(item.ek_cage_ID).then(r => {
                    if (r.status) {
                        var list2 = [...cageList];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_cage_ID == item.ek_cage_ID) {
                                list2.splice(i, 1)
                            }
                        }
                        setCageList(list2);
                    }
                })
            }
        })

    }


    function CageItem(item) {
        return item.dog_count < 2 ? <>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                {/*begin::Card*/}
                <div className="card card-custom gutter-b card-stretch">

                    {/*begin::Body*/}
                    <div className="card-body pt-4">
                        {/*begin::Toolbar*/}
                        {/*end::Toolbar*/}
                        <div className="pt-4 text-center">
                            <div className="symbol symbol-100 symbol-2by3 flex-shrink-0">
                                <div className="d-flex flex-column">
                                    <div className="symbol-label mb-3" style={{ backgroundImage: 'url("' + Ekmekk().URL + '/images/' + item.ek_cage_image + '")' }} />
                                </div>
                            </div>
                        </div>
                        {/*begin::User*/}
                        <div className="d-flex align-items-center mb-7">
                            {/*begin::Pic*/}
                            <div className="flex-shrink-0 mr-4">
                            </div>
                            {/*end::Pic*/}
                            {/*begin::Title*/}
                            <div className="d-flex flex-column">
                                <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.ek_cage_name}</a>
                                <span className="text-muted font-weight-bold">{item.ek_cage_size}</span>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::User*/}

                        {/*JSON.stringify(item.cage_info.dog_ID)*/}
                        <div className="">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Giriş Tarihi:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{item.cage_info[0] != undefined ? (item.cage_info[0].entry_date != null ? item.cage_info[0].entry_date : "-") : "-"}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Çıkış Tarihi:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{item.cage_info[0] != undefined ? (item.cage_info[0].exit_date != null ? item.cage_info[0].exit_date : "-") : " - "}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Doluluk Sebebi:</span>
                                <span className="text-dark-50 text-hover-primary">{item.cage_info[0] != undefined ? (item.cage_info[0].full_reason != null ? item.cage_info[0].full_reason : "-") : " - "}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Müşteri:</span>
                                <span className="text-dark-50 font-weight-bold">{item.cage_info[0] != undefined ? (item.cage_info[0].customer_name != null ? item.cage_info[0].customer_name : "-") : " - "}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Köpek:</span>
                                <span className="text-dark-50 font-weight-bold">{item.cage_info[0] != undefined ? (item.cage_info[0].dog_name != null ? item.cage_info[0].dog_name : "-") : " - "}</span>
                            </div>
                        </div>
                        {/*end::Info*/}


                    </div>
                    {/*end::Body*/}
                    <AuthorityControl pageCode={29}>
                        <div className="card-footer">

                            {
                                item.isCageFull == 0 ? <NavLink to={'/dashboard/cages/cage-detail/' + item.ek_cage_ID} className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 text-uppercase">Kafes Detay</NavLink> : <NavLink to={'/dashboard/cages/cage-detail/' + item.ek_cage_ID} className="btn btn-block btn-sm btn-light-danger font-weight-bolder py-4 text-uppercase">Kafes Detay</NavLink>
                            }
                            <div className="row text-center">
                                {/* <div className="col-md-6">
                            <a href="#" className="btn btn-block btn-sm btn-light-primary font-weight-bolder py-4 text-uppercase mr-2 mb-2" data-backdrop="static" data-toggle="modal" data-target="#exampleModalCenter2"  >detay</a>

                        </div> */}
                                <div className="col-md-6">


                                    {/* {
                                item.isCageFull == 0 ? <ModalCageFill isSale={false} /> : <a href="#" className="btn btn-block btn-sm btn-light-danger font-weight-bolder py-4 text-uppercase" data-backdrop="static" data-toggle="modal" data-target="#exampleModalCenter2"  >boşalt</a>

                            } */}

                                </div>

                            </div>
                        </div>
                    </AuthorityControl >
                </div>
                {/*end:: Card*/}
            </div>

        </> :
            <>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                    {/*begin::Card*/}
                    <div className="card card-custom gutter-b card-stretch">

                        {/*begin::Body*/}
                        <div className="card-body pt-4">
                            {/*begin::Toolbar*/}
                            {/*end::Toolbar*/}
                            <div className="pt-4 text-center">
                                <div className="symbol symbol-100 symbol-2by3 flex-shrink-0">
                                    <div className="d-flex flex-column">
                                        <div className="symbol-label mb-3" style={{ backgroundImage: 'url("' + Ekmekk().URL + '/images/' + item.ek_cage_image + '")' }} />
                                    </div>
                                </div>
                            </div>
                            {/*begin::User*/}
                            <div className="d-flex align-items-center mb-7">
                                {/*begin::Pic*/}
                                <div className="flex-shrink-0 mr-4">
                                </div>
                                {/*end::Pic*/}
                                {/*begin::Title*/}
                                <div className="d-flex flex-column">
                                    <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.ek_cage_name}</a>
                                    <span className="text-muted font-weight-bold">{item.ek_cage_size}</span>
                                </div>
                                {/*end::Title*/}
                            </div>
                            {/*end::User*/}

                            {/*JSON.stringify(item.cage_info.dog_ID)*/}
                            <div className="">
                                {/* <div className="d-flex justify-content-between align-items-center">
                        <span className="text-dark-75 font-weight-bolder mr-2">Köpek Kapasitesi:</span>
                        <span className="text-dark-50 font-weight-bold">{item.ek_cage_size && item.ek_cage_size}</span>
                    </div> */}
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-dark-75 font-weight-bolder mr-2">Köpek Sayısı:</span>
                                    <span className="text-dark-50 font-weight-bold">{item.dog_count && item.dog_count}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-dark-75 font-weight-bolder mr-2">Köpek:</span>
                                    {item.cage_info && item.cage_info.length != 0 &&
                                        item.cage_info.map(element => {
                                            return <span className="text-dark-50 font-weight-bold">{element.dog_name}</span>
                                        })
                                    }

                                </div>
                            </div>
                            {/*end::Info*/}


                        </div>
                        {/*end::Body*/}
                        <AuthorityControl pageCode={29}>
                            <div className="card-footer">
                                {
                                    item.isCageFull == 0 ? <NavLink to={'/dashboard/cages/cage-detail/' + item.ek_cage_ID} className="btn btn-block btn-sm btn-light-success font-weight-bolder py-4 text-uppercase">Kafes Detay</NavLink> : <NavLink to={'/dashboard/cages/cage-detail/' + item.ek_cage_ID} className="btn btn-block btn-sm btn-light-danger font-weight-bolder py-4 text-uppercase">Kafes Detay</NavLink>
                                }
                                <div className="row text-center">
                                    {/* <div className="col-md-6">
                        <a href="#" className="btn btn-block btn-sm btn-light-primary font-weight-bolder py-4 text-uppercase mr-2 mb-2" data-backdrop="static" data-toggle="modal" data-target="#exampleModalCenter2"  >detay</a>

                    </div> */}
                                    <div className="col-md-6">


                                        {/* {
                            item.isCageFull == 0 ? <ModalCageFill isSale={false} /> : <a href="#" className="btn btn-block btn-sm btn-light-danger font-weight-bolder py-4 text-uppercase" data-backdrop="static" data-toggle="modal" data-target="#exampleModalCenter2"  >boşalt</a>

                        } */}

                                    </div>

                                </div>
                            </div>
                        </AuthorityControl>
                    </div>
                    {/*end:: Card*/}
                </div>

            </>
    }

    function ChangeSearchText(e) {
        var filteredList = mainCageList.filter(item => item.ek_cage_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setCageList(filteredList);

    }

    function ChangeCageSizeText(e) {
        var filteredList = mainCageList.filter(item => item.ek_cage_size.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
        setCageList(filteredList);

    }

    function ChangeFillText(e) {

        if (inputFillSearch.current.value == -1) {
            setCageList(mainCageList);
        }
        else {
            var filteredList = mainCageList.filter(item => item.isCageFull.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()));
            setCageList(filteredList);
        }

    }

    function ChangeFillReasonText(e) {

        if (inputFillReasonSearch.current.value == -1) {
            setCageList(mainCageList);
        }
        else {
            var filteredList = mainCageList.filter(item => item.cage_info.length > 0 && item.cage_info[0].full_reason != undefined ? item.cage_info[0].full_reason.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) : false);
            setCageList(filteredList);
        }

    }

    return <>


        <BrowserView>
            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-md-2">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Kafes Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-2">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeCageSizeText} className="form-control" placeholder="Kafes Büyüklüğü" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-2">
                        <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">Doluluk:</label>
                            <select ref={inputFillSearch} onChange={ChangeFillText} className="form-control" id="kt_datatable_search_type">
                                <option value={-1}>Hepsi</option>
                                <option value={0}>Boş</option>
                                <option value={1}>Dolu</option>

                            </select>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-2">
                        <div className="d-flex align-items-center">
                            <label className=" mb-0 d-none d-md-block">Doluluk Sebebi:</label>
                            <select ref={inputFillReasonSearch} onChange={ChangeFillReasonText} className="form-control" id="kt_datatable_search_type">
                                <option value={-1}>Hepsi</option>
                                <option value={"Eğitim"}>Eğitim</option>
                                <option value={"Royal Dog"}>Royal Dog</option>
                                <option value={"Konaklama"}>Konaklama</option>

                            </select>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-1">
                        <AuthorityControl pageCode={28}>
                            <ModalCageInsert isSale={false} insertClick={CageInsertClick} />
                        </AuthorityControl>
                    </div>
                </div>
            </div>
        </BrowserView>

        <MobileView>
            <div className="mb-7">
                <div className="row align-items-center">
                    <div className="col-md-12 mb-2">
                        <AuthorityControl pageCode={28}>
                            <ModalCageInsert isSale={false} insertClick={CageInsertClick} />
                        </AuthorityControl>
                    </div>
                    <div className="col-md-2 mb-2">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeSearchText} className="form-control" placeholder="Kafes Adı" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-2 mb-2">
                        <div className="input-icon">
                            <input type="text" onChange={ChangeCageSizeText} className="form-control" placeholder="Kafes Büyüklüğü" id="kt_datatable_search_query" />
                            <span>
                                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                            </span>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-3 mb-2">
                        <div className="d-flex align-items-center">
                            <label className="mr-3 mb-0 d-none d-md-block">Doluluk:</label>
                            <select ref={inputFillSearch} onChange={ChangeFillText} className="form-control" id="kt_datatable_search_type">
                                <option value={-1}>Hepsi</option>
                                <option value={0}>Boş</option>
                                <option value={1}>Dolu</option>

                            </select>
                        </div>
                    </div>
                    <div className="col-md"></div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-center">
                            <label className=" mb-0 d-none d-md-block">Doluluk Sebebi:</label>
                            <select ref={inputFillReasonSearch} onChange={ChangeFillReasonText} className="form-control" id="kt_datatable_search_type">
                                <option value={-1}>Hepsi</option>
                                <option value={"Eğitim"}>Eğitim</option>
                                <option value={"Royal Dog"}>Royal Dog</option>
                                <option value={"Konaklama"}>Konaklama</option>

                            </select>
                        </div>
                    </div>
                    <div className="col-md"></div>

                </div>
            </div>

        </MobileView>


        <AuthorityControl pageCode={27}>
            <div className={cageList.length == 0 ? "row d-flex justify-content-center" : "row"}>



                {
                    cageList == undefined ? "" : cageList.length == 0 ? <NullPage image={"certification.svg"} /> : cageList.map(item => {
                        return CageItem(item)
                    })
                }

            </div>
        </AuthorityControl>

    </>
}


<div className="card card-custom mb-4">
    <div className="card-body py-7">
        {/*begin::Pagination*/}
        <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex flex-wrap mr-3">
                <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                    <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-double-left.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fillRule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
                            <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
                        </g>
                    </svg>{/*end::Svg Icon*/}</span>

                </a>
                <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                    <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-left.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-12.000003, -11.999999) " />
                        </g>
                    </svg>{/*end::Svg Icon*/}</span>

                </a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">23</a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">24</a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">25</a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">26</a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">27</a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">28</a>
                <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                    <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-right.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) " />
                        </g>
                    </svg>{/*end::Svg Icon*/}</span>

                </a>
                <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                    <span className="svg-icon svg-icon-primary svg-icon-lg">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo7\dist/../src/media/svg/icons\Navigation\Angle-double-right.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fillRule="nonzero" />
                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
                        </g>
                    </svg>{/*end::Svg Icon*/}</span>
                </a>
            </div>
            <div className="d-flex align-items-center">
                <select className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary" style={{ width: '75px' }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                <span className="text-muted">Displaying 10 of 230 records</span>
            </div>
        </div>
        {/*end:: Pagination*/}
    </div>
</div>
