import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';


export default function ModalEducationLessonInsert(props) {


    var inputEducationLessonName = useRef(null);
    var inputEducationLessonContent = useRef(null);

    function EducationLessonInsertClick() {

        var lessonData = []

        var lessonAltData = {};
        lessonAltData["ek_education_lesson_name"] = inputEducationLessonName.current.value;
        lessonAltData["ek_education_lesson_content"] = inputEducationLessonContent.current.value;

        lessonData.push(lessonAltData);


        var dataBlock = {
            ek_education_lesson_name: inputEducationLessonName.current.value,
            ek_education_lesson_content: inputEducationLessonContent.current.value,
        }

        if(props.selectedData != undefined){
            dataBlock["ek_education_lesson_ID"] = props.selectedData.ek_education_lesson_ID;
        }
        var controller = new ValidationController();
        if (controller.Control() == 0) {

            if(props.selectedData == undefined){
                DataCenter().Education.InsertEducationLesson(lessonData, props.ek_education_ID).then(r => {

                    // alert(JSON.stringify(r))
        
                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }
            else{

                DataCenter().Education.UpdateEducationLesson(dataBlock).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

            
        }
        
    }


    const [show, setShow] = useState(false);


    return <>

        {
            props.isSale == false ? <button type="button" onClick={() => setShow(true)} className="btn w-50 btn-light-primary font-weight-bolder text-uppercase text-center mt-0" data-toggle="modal" data-target="#exampleModalCenter">
                Ders Ekle
        </button>
                : <button type="button" onClick={() => setShow(true)} className="btn btn-light-info font-weight-bolder text-uppercase text-center mt-0" data-toggle="modal" data-target="#exampleModalCenter">
                    Güncelle
        </button>
        }

        <Modal
            show={show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">

                    <div>
                        <h5 className="font-boldest">{props.isUpdate == true ? "Dersi Güncelle" : "Ders Ekle"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}
                    {/* KÖPEK İSMİ */}
                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Ders Adı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputEducationLessonName} className="form-control form-control-lg form-control-solid" name="dog_name" placeholder="Ders Adı" type="text" defaultValue={props.selectedData != undefined ? props.selectedData.ek_education_lesson_name : ""} />
                            </div>
                        </div>
                        {/* KÖPEK CİNSİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Ders Açıklaması:</label>
                            <div className="col-lg-9 col-xl-9">
                                <textarea data-validation-rule="notNull" ref={inputEducationLessonContent} className="form-control" name="textarea" rows={3} placeholder="Lütfen Açıklama Giriniz." defaultValue={props.selectedData != undefined ? props.selectedData.ek_education_lesson_content : ""} />
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false) }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={EducationLessonInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Ekle"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}