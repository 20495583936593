import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import SubHeader from "../../Thema/sub-header";
import DataCenter from "../../Ekmekk/DataCenter/datacenter";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import NullPage from "../../Ekmekk/Views/NullPage";
import { List } from "@material-ui/icons";
import Ekmekk from "../../Ekmekk/Helper/ekmekk";
export default function DogEducationList() {

    const { id, educationID } = useParams();
    const [calendarDate, setCalendarDate] = useState(new Date());

    const [lessonData, setLessonData] = useState([])

    useEffect(() => {

        DataCenter().Plain.DogEducationLessonList(educationID, id).then(r => {

            if (r.status) {

                var bashList = [];

                // EĞER DERS PLANDA OLUŞTURULDUYSA LİSTEYE EKLİYOR
                for (var i = 0; i < r.data.length; i++) {

                    if (r.data[i].ek_dog_lesson_plan_ID != "") {

                        // TAKVİMLERİN SEÇİLİ GELMESİ İÇİN TARİHLERİ SAAT FORMATINA ÇEVİRİYORUZ
                        var date = new Date(r.data[i].ek_dog_lesson_start_date);

                        if (!isNaN(date.getTime())) {
                            r.data[i].["theStartDate"] = date;
                        }
                        else {
                            r.data[i].["theStartDate"] = new Date();
                        }

                        var date2 = new Date(r.data[i].ek_dog_lesson_end_date);

                        if (!isNaN(date2.getTime())) {
                            r.data[i].["theEndDate"] = date2;
                        }
                        else {
                            r.data[i].["theEndDate"] = new Date();
                        }

                        bashList.push(r.data[i]);

                    }
                }
                setLessonData(bashList);

            }
        })

    }, [])

    function SaveLesson() {


        // Ekmekk().Alert.Block();

        for (let i = 0; i < lessonData.length; i++) {
            // lessonData[i].isComplete = JSON.stringify(lessonData[i].isComplete);
            console.log(lessonData[i]);

            DataCenter().Plain.UpdateDogEducationLessonPlan(lessonData[i]).then(r => {

                if (r.status) {
                    // Ekmekk().Alert.UnblockUI();
                    Ekmekk().Alert.Success();
                }
                else {
                    // Ekmekk().Alert.UnblockUI();
                    Ekmekk().Alert.Fail();
                }

            })

        }



    }

    function LessonItemList(item, i) {

        return <>
            <tr className="">
                <td className="w-40px align-middle pb-6 pl-0 pr-2 ">
                    {/*begin::Symbol*/}
                    <div className={item.isComplete == 0 ? "symbol symbol-40 symbol-light-dark" : item.isComplete == 1 ? "symbol symbol-40 symbol-light-success" : "symbol symbol-40 symbol-light-danger"}>
                        <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-success font-weight-bolder font-size-lg ">{i + 1}</span>
                        </span>
                    </div>
                    {/*end::Symbol*/}
                </td>
                <td className="font-size-lg font-weight-bolder text-dark-75 align-middle w-150px pb-6 ">{item.ek_education_lesson_name}</td>
                <td className="font-weight-bold text-muted text-right align-middle pb-6">{item.ek_educator_fullname}</td>
                <td className="font-weight-bold text-muted text-right align-middle pb-6 col-md-3">
                    <DatePicker

                        selected={item.theStartDate}
                        // @ts-ignore
                        onChange={date => {
                            item.theStartDate = date;
                            var myStartDate = new Date(date), startTime = myStartDate.getFullYear() + '-' + (myStartDate.getMonth() + 1) + '-' + myStartDate.getDate() + ' ' + myStartDate.getHours() + ':' + myStartDate.getMinutes() + ':' + myStartDate.getSeconds();
                            item.ek_dog_lesson_start_date = startTime;

                            setLessonData([...lessonData]);
                        }}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeInput
                        dropdownMode="select"
                        className="form-control form-control-lg form-control-solid w-100"
                    />
                </td>
                <td className="font-weight-bold text-muted text-right align-middle pb-6 col-md-3">
                    <DatePicker

                        selected={item.theEndDate}
                        // @ts-ignore
                        onChange={date => {
                            item.theEndDate = date;
                            var theEndDate = new Date(date), endTime = theEndDate.getFullYear() + '-' + (theEndDate.getMonth() + 1) + '-' + theEndDate.getDate() + ' ' + theEndDate.getHours() + ':' + theEndDate.getMinutes() + ':' + theEndDate.getSeconds();
                            item.ek_dog_lesson_end_date = endTime;

                            setLessonData([...lessonData]);
                        }}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        showTimeInput
                        dropdownMode="select"
                        className="form-control form-control-lg form-control-solid w-100"
                    />
                </td>
                <td className="font-weight-bolder font-size-xs text-dark-75 text-right align-middle pb-6">

                    {/* DERSİ GEÇTİ */}
                    <div onClick={() => { item.isComplete = 1; setLessonData([...lessonData]); }} className="symbol symbol-40 symbol-light-success mr-2">
                        <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-success font-size-sm ">

                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                        </span>

                    </div>
                </td>
                <td className="font-weight-bolder font-size-lg text-dark-75 text-right align-middle pb-6">

                    {/* DERSİ GEÇTİ */}
                    <div onClick={() => { item.isComplete = 0; setLessonData([...lessonData]); }} className="symbol symbol-40 symbol-light-dark">
                        <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-dark font-size-sm ">

                                <FontAwesomeIcon icon={faCircleNotch} />
                            </span>
                        </span>

                    </div>

                </td>
                <td className="font-weight-bolder font-size-lg text-dark-75 text-right align-middle pb-6">


                    {/* DERSTEN KALDI */}
                    <div onClick={() => { item.isComplete = -1; setLessonData([...lessonData]); }} className="symbol symbol-40 symbol-light-danger ml-2">
                        <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-danger font-size-sm ">

                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </span>

                    </div>
                </td>
            </tr>

        </>


    }

    return <div>

        <div className="card card-custom card-stretch gutter-b">
            {/*begin::Header*/}
            <div className="card-header border-0 pt-6 mb-2">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bold font-size-h4 text-dark-75 mb-3">İleri Itaat Eğitimi</span>
                    <span className="text-muted font-weight-bold font-size-sm">{lessonData && lessonData.length} ders bulundu</span>
                </h3>
                <button onClick={() => { SaveLesson() }} className="btn w-30 btn-light-primary font-weight-bolder text-uppercase text-center mt-0">Planı Kaydet</button>
            </div>

            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-2">
                {/*begin::Table*/}
                <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                        <tbody>
                            {/*begin::Item*/}
                            {
                                lessonData == undefined ? "" : lessonData.length == 0 ? <NullPage /> : lessonData.map((item, i) => {
                                    return LessonItemList(item, i)
                                })
                            }


                        </tbody>
                    </table>
                </div>
                {/*end::Table*/}
            </div>
            {/*end::Body*/}
        </div>

    </div>
}