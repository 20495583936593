import ModalEducationInsert from "../../Modal/Education/EducationInsert"
import ModalEducationLessonInsert from "../../Modal/Education/EducationLessonInsert"

import { useHistory, NavLink, useParams } from "react-router-dom";
import React from "react";
import SubHeader from "../../Thema/sub-header";
import Ekmekk from "../../Ekmekk/Helper/ekmekk";
import { useEffect, useState } from "react";
import DataCenter from "../../Ekmekk/DataCenter/datacenter";
import AuthorityControl from "../../Ekmekk/Auth/AuthorityControl";
import Sticky from "react-sticky-el";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function EgitimDetay() {

    const [educationData, setEducationData] = useState([]);
    const [lessonData, setLessonData] = useState([])

    var history = useHistory();
    // @ts-ignore
    const { id } = useParams();

    useEffect(() => {
        DataCenter().Education.ShowEducationDetail(id).then(r => {
            console.log(r.data);
            setEducationData(r.data.education);
            setLessonData(r.data.lessons);

        });

    }, [])

    function EducationInsertClick(caseData, data) {
        if (caseData == "updated") {

            setEducationData(data);

        }

    }

    function EducationLessonInsertClick(caseData, data) {

        if (caseData == "inserted") {

            lessonData.push(data);
            setLessonData([...lessonData])

        }
        else if (caseData == "updated") {

            for (var i = 0; i < lessonData.length; i++) {
                if (lessonData[i].ek_education_lesson_ID == data.ek_education_lesson_ID) {

                    lessonData[i] = data;

                }
            }

            setLessonData([...lessonData]);
        }

    }

    function EducationLessonDeleteClick(item) {

        console.log(item);

        Ekmekk().Alert.Delete((e) => {
            if (e) {
                DataCenter().Education.DeleteEducationLesson(item).then(r => {

                    if (r.status) {
                        var list2 = [...lessonData];
                        for (var i = 0; i < list2.length; i++) {
                            if (list2[i].ek_education_lesson_ID == item) {
                                list2.splice(i, 1)
                            }
                        }
                        setLessonData(list2);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })

            }
        })
    }

    function EducationLessonItem(item) {

        return <>
            <div className="card card-custom gutter-b">
                <div className="card-body">
                    <div className="d-flex">
                        {/*begin::Pic*/}
                        <div className="flex-shrink-0 mr-7">
                            <div className="symbol symbol-50 symbol-lg-120">
                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                {/* <img src={JSON.parse(selectedCustomer).ek_customer_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}

                            </div>
                        </div>
                        {/*end::Pic*/}
                        {/*begin: Info*/}
                        <div className="flex-grow-1">
                            {/*begin::Title*/}
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                {/*begin::User*/}
                                <div className="mr-3">
                                    <div className="d-flex align-items-center mr-3">
                                        {/*begin::Name*/}
                                        <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{item.ek_education_lesson_name}</a>
                                        {/*end::Name*/}
                                    </div>
                                    {/*begin::Contacts*/}
                                    {/*end::Contacts*/}
                                </div>
                                {/*begin::User*/}
                                {/*begin::Actions*/}
                                <div className="mb-10 btn-group">

                                    <AuthorityControl pageCode={92}>

                                        <ModalEducationLessonInsert isSale={true} isUpdate={true} selectedData={item} insertClick={EducationLessonInsertClick} />

                                    </AuthorityControl >

                                    {/* <ModalCustomerSelect selectedCustomer={r => {
                                            setSelectedCustomer(r);
                                            setModalSelectIsShow(false);


                                        }} isShow={modalSelectIsShow} handler={InsertModalHandler} isChange={1} />

                                        <ModalCustomerInsert isShow={modalInsertIsShow} HandlerClose={() => { setModalInsertIsShow(false); setModalSelectIsShow(true) }} isSale={true} /> */}

                                    <AuthorityControl pageCode={93}>

                                        <button onClick={() => EducationLessonDeleteClick(item.ek_education_lesson_ID)} className="btn btn-danger font-weight-bolder text-uppercase mr-2">Sil</button>
                                    </AuthorityControl >
                                </div>
                                {/*end::Actions*/}
                            </div>
                            {/*end::Title*/}
                            {/*begin::Content*/}
                            <div className="d-flex align-items-center flex-wrap justify-content-between">
                                {/*begin::Description*/}
                                <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">{item.ek_education_lesson_content}</div>
                                {/*end::Description*/}
                                {/*begin::Progress*/}
                                {/*end::Progress*/}
                            </div>
                            {/*end::Content*/}
                        </div>
                        {/*end::Info*/}
                    </div>
                </div>
            </div>
        </>

    }


    return <>

        <AuthorityControl pageCode={90}>

            <div className="row">
                {/*begin::Aside*/}
                <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12" id="kt_profile_aside">
                    {/*begin::Profile Card*/}
                    <BrowserView>

                        <Sticky>
                            <div className="sticky card card-custom mr-4">
                                {/*begin::Body*/}

                                <div className="card-body pt-4">
                                    {/*begin::Toolbar*/}
                                    <div className="d-flex justify-content-end">

                                    </div>
                                    {/*end::Toolbar*/}
                                    {/*begin::Contact*/}
                                    <div className="py-9">

                                        <div className="d-flex align-items-center justify-content-between  mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Eğitim Adı:</span>
                                            <a href="#" className="text-muted text-hover-primary">{educationData.ek_education_name}</a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Fiyat:</span>
                                            <span className="text-muted">₺{educationData.ek_education_price}</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span className="font-weight-bolder font-size-h5 mr-2">Ders Sayısı:</span>
                                            <span className="text-muted">{lessonData.length}</span>
                                        </div>

                                    </div>
                                    {/*end::Contact*/}
                                    {/*begin::Nav*/}
                                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                        <AuthorityControl pageCode={91}>
                                            <div className="btn-group text-center navi-item mb-2">

                                                <ModalEducationInsert isUpdate={true} selectedData={educationData} insertClick={EducationInsertClick} isSale={true} />

                                                {/* <ModalEducationInsert isSale={true} isUpdate={true} /> */}
                                                <ModalEducationLessonInsert insertClick={EducationLessonInsertClick} isSale={false} ek_education_ID={educationData.ek_education_ID} />


                                            </div>
                                        </AuthorityControl>
                                    </div>
                                    {/*end::Nav*/}
                                </div>
                                {/*end::Body*/}
                            </div>
                        </Sticky>
                    </BrowserView>

                    <MobileView>

                        <div className="sticky card card-custom mb-4">
                            {/*begin::Body*/}

                            <div className="card-body pt-4">
                                {/*begin::Toolbar*/}
                                <div className="d-flex justify-content-end">

                                </div>
                                {/*end::Toolbar*/}
                                {/*begin::Contact*/}
                                <div className="py-9">

                                    <div className="d-flex align-items-center justify-content-between  mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Eğitim Adı:</span>
                                        <a href="#" className="text-muted text-hover-primary">{educationData.ek_education_name}</a>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Fiyat:</span>
                                        <span className="text-muted">₺{educationData.ek_education_price}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="font-weight-bolder font-size-h5 mr-2">Ders Sayısı:</span>
                                        <span className="text-muted">{lessonData.length}</span>
                                    </div>

                                </div>
                                {/*end::Contact*/}
                                {/*begin::Nav*/}
                                <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                    <AuthorityControl pageCode={91}>
                                        <div className="btn-group text-center navi-item mb-2">

                                            <ModalEducationInsert isUpdate={true} selectedData={educationData} insertClick={EducationInsertClick} isSale={true} />

                                            {/* <ModalEducationInsert isSale={true} isUpdate={true} /> */}
                                            <ModalEducationLessonInsert insertClick={EducationLessonInsertClick} isSale={false} ek_education_ID={educationData.ek_education_ID} />


                                        </div>
                                    </AuthorityControl>
                                </div>
                                {/*end::Nav*/}
                            </div>
                            {/*end::Body*/}
                        </div>

                    </MobileView>
                    {/*end::Profile Card*/}
                </div>
                {/*end::Aside*/}
                {/*begin::Content*/}
                <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-xs-12">


                    {
                        lessonData.map(item => {
                            return EducationLessonItem(item)
                        })
                    }

                </div>
                {/*end::Content*/}

            </div>

        </AuthorityControl >

    </>
}