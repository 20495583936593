import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import DatePicker from "react-datepicker"


export default function ModalRoyalDogInsertToCage(props) {


    const [processType, setProcessType] = useState(-1);

    const [isFillButton, setİsFillButton] = useState(-1);

    var inputProcessType = useRef(null);
    var inputDogID = useRef(null);

    function DogInsertToCageClick() {
        var today = calendarDate, date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

        console.log(date);

        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID
        var validator = new ValidationController();

        if (validator.Control() == 0) {

            if (isFillButton == 1) {

                var dataBlock = {
                    ek_cage_ID: props.cageID,
                    // ek_dog_ID: inputDogID.current,
                    ek_entry_date: date,
                    // ek_exit_date: 2,
                    ek_dog_type: props.dogType,
                    ek_process_ID: -1,                                   // NE ATTIĞIN FARK ETMEZ AMA AT
                    ek_process_type: -1,

                }

                // EĞER HERHANGİ BİR KÖPEK SEÇİLMEMİŞSE
                if (dataBlock.ek_dog_type == -1) {
                    Ekmekk().Alert.Fail();
                    console.log(dataBlock);
                }
                else {

                    // ŞİRKET KÖPEĞİYSE
                    dataBlock["ek_dog_ID"] = JSON.parse(props.royalDog).ek_royal_dog_ID;
                    dataBlock["ek_cage_daily_price"] = 0;

                    // KONAKLAMA SATIŞI YAPILDIYSA
                    // if (dataBlock.ek_process_type == 0) {
                    //     dataBlock["ek_cage_daily_price"] = inputSalePrice.current.value;
                    // }
                    // else {
                    //     dataBlock["ek_cage_daily_price"] = 0;
                    // }

                    // KAFESİ DOLDUR
                    DataCenter().Cage.InsertDogToCage(dataBlock).then(r => {

                        if (r.status) {
                            Ekmekk().Alert.Success();
                            setShow(false);
                            dataBlock["ek_cage_dog_ID"] = r.data[0].ek_cage_dog_ID;
                            dataBlock["dog_name"] = r.data[0].dog_name;
                            dataBlock["ek_customer_fullname"] = r.data[0].ek_customer_fullname;
                            dataBlock["type"] = r.data[0].type;

                            props.insertClick("inserted", dataBlock);
                        }
                        else {
                            Ekmekk().Alert.Fail();
                        }
                    })


                    console.log(dataBlock);


                }
            }
            else {

                // KAFES DOLU BOŞALT

                var dataBlock2 = {
                    ek_cage_dog_ID: parseInt(inputDogID.current.value),
                    ek_exit_date: date,

                }

                console.log(dataBlock2);

                // KAFES GÜNCELLEME (BOŞALTMA)
                DataCenter().Cage.UpdateDogToCage(dataBlock2).then(r => {

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);

                        props.insertClick("updated", dataBlock2);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })


            }

        }

    }

    function RoyalDogItemList(item) {
        // console.log(item.activeCage);
        // if (item.activeCage == "")
        return <option value={item.ek_cage_dog_ID}>{item.ek_customer_dog_ID} - {item.ek_customer_dog_name}</option>
    }


    // ------------- TARİH --------------

    const [calendarDate, setCalendarDate] = useState(new Date());
    // const [birthDate, setBirthDate] = useState(null);



    const [show, setShow] = useState(false);


    function CheckData() {

        if (props.isCageFull == 0 && props.royalDog == null && props.royalDog == null) {
            Ekmekk().Alert.FillAll();
            // console.log(props.isCageFull);
            // console.log(props.customerDogs);
            // console.log(props.royalDog);
        }
        else {

            // EĞER KONAKLAMAYI BOŞALTIYORSAK
            if (props.isCageFull == 1 && props.lastProcess[0].process_type_ID == 0) {

                var dataBlock2 = {
                    ek_cage_daily_price: props.lastProcess[0].ek_cage_daily_price,
                    ek_entry_date: props.lastProcess[0].ek_entry_date,
                    ek_customer_ID: props.lastProcess[0].ek_customer_ID,

                }


                var firstHalf = dataBlock2.ek_entry_date.split(" ");
                console.log(firstHalf);
                var dates = firstHalf[0].split("/");
                console.log(dates);

                var theDate = dates[2] + "-" + dates[1] + "-" + dates[0] + " " + firstHalf[1];
                console.log(theDate);

                dataBlock2["ek_entry_date"] = theDate;

                DataCenter().Sale.CalculatePrice(dataBlock2).then(r => {
                    if (r.status) {

                        // setCost(r.data.cost);
                        // setPreDebt(r.data.debt);
                        // setTotalCost(r.data.total_cost);

                    }

                })

            }


            setShow(true);


        }

    }

    return <>

        {/* KAFESTE ROYALDOG YOKSA DOLDUR BUTONU, VARSA HER İKİ BUTON */}

        {
            props.isCageFull == 1 ? <button type="button" onClick={() => {
                // CheckData();
                setİsFillButton(1);
                setShow(true);
                console.log(props);
            }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
                Royaldog Ekle
            </button> : ""
        }

        {
            props.isCageFull == 0 ? <button type="button" onClick={() => {
                // CheckData();
                setİsFillButton(1);
                setShow(true);
                console.log(props);
            }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
                Royaldog Ekle
            </button> : <button type="button" onClick={() => {
                // CheckData();
                setİsFillButton(0);
                setShow(true);
                console.log(props);
            }} className="btn btn-sm btn-block btn-light-danger font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
                Royaldog Çıkart
            </button>
        }



        <Modal
            show={show}
            onHide={() => { setShow(false); setİsFillButton(-1); }}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Seçimi Tamamla
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    {/* <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Adet: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={inputAmount} className="form-control form-control-lg" id="campain_name2" placeholder="1" type="number" defaultValue="1" />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyatı: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={salePrice} className="form-control form-control-lg" id="campain_name2" placeholder={props.productPrice} type="number" defaultValue={props.productPrice} />
                        </div>
                    </div> */}

                    <Validator>
                        {
                            props.royalDogs != null && isFillButton == 0 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Köpek Seçimi: </label>

                                <div className="col-lg-9 col-xl-9 col-md-9 col-sm-12">
                                    <select ref={inputDogID} className="form-control selectpicker">
                                        {
                                            props.royalDogs != null ? props.royalDogs.map(item => {
                                                return RoyalDogItemList(item)
                                            }) : ""
                                        }
                                    </select>
                                    {/* <button type="button" className="mt-2 btn btn-primary font-weight-bold">Köpek Ekle</button> */}
                                </div>
                            </div> : ""
                        }

                        {
                            isFillButton == 1 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Giriş Tarihi: </label>

                                <div className="col-lg-9 col-xl-9">

                                    <DatePicker
                                        selected={calendarDate}
                                        // @ts-ignore
                                        onChange={date => setCalendarDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput

                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="form-control form-control-lg form-control-solid w-100"
                                    />

                                </div>

                            </div> : <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Çıkış Tarihi: </label>

                                <div className="col-lg-9 col-xl-9">

                                    <DatePicker
                                        selected={calendarDate}
                                        // @ts-ignore
                                        onChange={date => setCalendarDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        showTimeInput

                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="form-control form-control-lg form-control-solid w-100"
                                    />

                                </div>

                            </div>
                        }

                    </Validator>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal" onClick={() => {
                        setShow(false);
                        setİsFillButton(-1);
                    }}>
                        Close</button>
                    <button type="button" className="btn btn-primary font-weight-bold" onClick={DogInsertToCageClick}>Tamamla</button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}