import axios from "axios"
import Ekmekk from "../Helper/ekmekk";

export default function Case() {

    function List() {
        return new Promise((ok, fail) => {
            const api = Ekmekk().Profile.GetProfile()
            axios.get(Ekmekk().URL + '/admin/customer_dog_lesson_plan/global/plan', {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });
        })
    }

    // KÖPEK PROFİLİNDEKİ ALINAN EĞİTİMİN ALT DERSLERİ

    function DogEducationLessonList(educationID, dogID) {
        return new Promise((ok, fail) => {
            const api = Ekmekk().Profile.GetProfile()

            axios.post(Ekmekk().URL + '/admin/customer/dog/profile/lesson', {
                ek_education_selling_ID: educationID,
                ek_customer_dog_ID: dogID,
            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);

                    if (error.response.status == 401) {
                        Ekmekk().Alert.GoToLogin((e) => {
                            if (e) {
                                window.location.href = '/login';
                            }
                            else {
                                window.location.href = '/login';
                            }
                        })
              
                    }
                });
        })
    }


    // DERS PLANI OLUŞTURMA YERİNDE

    function InsertDogEducationLessonPlan(dataBlock) {
        const api = Ekmekk().Profile.GetProfile()
        return new Promise((ok, fail) => {

            axios.post(Ekmekk().URL + '/admin/customer_dog_lesson_plan', {
                ek_education_lesson_ID: dataBlock.ek_education_lesson_ID,
                ek_educator_ID: dataBlock.ek_educator_ID,
                ek_customer_dog_ID: dataBlock.ek_customer_dog_ID,
                ek_dog_lesson_start_date: dataBlock.ek_dog_lesson_start_date,
                ek_dog_lesson_end_date: dataBlock.ek_dog_lesson_end_date,

            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    // HEM DERS PLANLAMA YERİNDE HEM DE KÖPEĞİN EĞİTİMİ TAMAMLAYIP TAMAMLANMADIĞI YERDE GÜNCELLENİR

    function UpdateDogEducationLessonPlan(dataBlock) {
        const api = Ekmekk().Profile.GetProfile()
        return new Promise((ok, fail) => {

            axios.put(Ekmekk().URL + '/admin/customer_dog_lesson_plan', {
                ek_dog_lesson_plan_ID: dataBlock.ek_dog_lesson_plan_ID,
                ek_dog_lesson_lesson_ID: dataBlock.ek_dog_lesson_lesson_ID,
                ek_dog_customer_dog_ID: dataBlock.ek_dog_customer_dog_ID,
                ek_educator_ID: dataBlock.ek_educator_ID,
                ek_dog_lesson_start_date: dataBlock.ek_dog_lesson_start_date,
                ek_dog_lesson_end_date: dataBlock.ek_dog_lesson_end_date,
                isComplete: JSON.stringify(dataBlock.isComplete),

            }, {
                headers: {
                    'Authorization': `Basic ${api}`
                },
            }).then(function (response) {
                console.log(response.data);
                ok(response.data);
            })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    return {
        List, InsertDogEducationLessonPlan, UpdateDogEducationLessonPlan, DogEducationLessonList
    }
}