import React from 'react'

import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import 'react-datepicker/dist/react-datepicker.css'

import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import Validator, { ValidationController } from "../../Ekmekk/Helper/Validator"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function RoyalDogInsert(props) {

    const [selectedImage, setSelectedImage] = useState(null)

    var inputRoyalDogName = useRef(null);
    var inputRoyalDogGender = useRef(null);
    var inputRoyalDogSubBreed = useRef(null);
    var inputRoyalDogBreed = useRef(null);
    var inputRoyalPurchasePrice = useRef(null);
    var inputRoyalSalePrice = useRef(null);


    function RoyalDogInsertClick() {

        var birthDate = calendarDate, date = birthDate.getFullYear() + '/' + (birthDate.getMonth() + 1) + '/' + birthDate.getDate();

        var dataBlock = {
            ek_royal_dog_name: inputRoyalDogName.current.value,
            ek_royal_dog_gender: inputRoyalDogGender.current.value,
            ek_royal_dog_birhdate: date,
            ek_royal_dog_breed_ID: inputRoyalDogBreed.current.value,
            ek_royal_dog_sub_breed_ID: inputRoyalDogSubBreed.current.value,
            ek_royal_dog_purchase_price: inputRoyalPurchasePrice.current.value,
            ek_royal_dog_sale_price: inputRoyalSalePrice.current.value,

            ek_royal_dog_image: selectedImage,

            ek_dog_breed_name: null,
            ek_dog_sub_breed_name: null,
        }

        var validator = new ValidationController();

        if (validator.Control() == 0) {

            if (props.selectedData == undefined) {

                DataCenter().RoyalDog.InsertRoyalDog(dataBlock).then(r => {


                    for (let i = 0; i < breedList.length; i++) {

                        if (breedList[i].ek_dog_breed_ID == dataBlock.ek_royal_dog_breed_ID) {
                            dataBlock.ek_dog_breed_name = breedList[i].ek_dog_breed_name;
                        }
                    }

                    for (let i = 0; i < subBreedList.length; i++) {

                        if (subBreedList[i].ek_dog_sub_breed_ID == dataBlock.ek_royal_dog_sub_breed_ID) {
                            dataBlock.ek_dog_sub_breed_name = subBreedList[i].ek_dog_sub_breed_name;
                        }
                    }

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_royal_dog_image"] = r.data.image_path;
                        dataBlock["ek_royal_dog_ID"] = r.data.ek_royal_dog_ID;
                        dataBlock["dog_age"] = r.data.age[0].age;

                        props.insertClick("inserted", dataBlock);
                    }

                })
            }
            else {
                dataBlock["ek_royal_dog_ID"] = props.selectedData.ek_royal_dog_ID;

                console.log(dataBlock);
                DataCenter().RoyalDog.UpdateRoyalDog(dataBlock).then(r => {

                    for (let i = 0; i < breedList.length; i++) {

                        if (breedList[i].ek_dog_breed_ID == dataBlock.ek_royal_dog_breed_ID) {
                            dataBlock.ek_dog_breed_name = breedList[i].ek_dog_breed_name;
                        }
                    }

                    for (let i = 0; i < subBreedList.length; i++) {

                        if (subBreedList[i].ek_dog_sub_breed_ID == dataBlock.ek_royal_dog_sub_breed_ID) {
                            dataBlock.ek_dog_sub_breed_name = subBreedList[i].ek_dog_sub_breed_name;
                        }
                    }


                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_royal_dog_image"] = r.data.image;
                        dataBlock["dog_age"] = r.data.age[0].age;

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }


    }

    // ----------- CİNSİYET ------------

    const genders = ["Erkek", "Dişi"];

    function GenderItem(item) {
        return <option value={item} >{item}</option>
    }

    // ------------- CİNS --------------

    const [breedList, setBreedList] = useState([])
    useEffect(() => {
        DataCenter().DogBreed.ListDogBreed().then(r => {
            setBreedList(r.data)
        })

        // GÜNCELLEMEDE SUBBREED ID'YE GÖRE SUBBREED LİSTESİ GÖSTERME
        if (props.selectedData == undefined) {
            DataCenter().SubBreed.ListDogSubBreed().then(r => {
                setSubBreedList(r.data)
            })
        }
        else {
            var subBreedID = props.selectedData.ek_dog_breed_ID;
            DataCenter().SubBreed.ListDogSubBreed(subBreedID).then(r => {
                setSubBreedList(r.data)
            })
        }

    }, [])

    function BreedItem(item) {
        return <option value={item.ek_dog_breed_ID} selected={props.selectedData != undefined && props.selectedData.ek_dog_breed_name == item.ek_dog_breed_name}>{item.ek_dog_breed_name}</option>
    }


    // ------------- ALT CİNS --------------

    const [subBreedList, setSubBreedList] = useState([])

    function ChangeSubBreedList(subBreedID) {


        DataCenter().SubBreed.ListDogSubBreed(subBreedID).then(r => {
            setSubBreedList(r.data)
        })
    }

    function SubBreedItem(item) {
        return <option value={item.ek_dog_sub_breed_ID} selected={props.selectedData != undefined && props.selectedData.ek_dog_sub_breed_name == item.ek_dog_sub_breed_name} >{item.ek_dog_sub_breed_name}</option>
    }


    // ------------- TARİH --------------

    const [calendarDate, setCalendarDate] = useState(new Date());
    // const [birthDate, setBirthDate] = useState(null);


    //---------------------------------------

    const [show, setShow] = useState(false);

    function ClickEvent() {

        // GÜNCELLEMEYSE DOĞUM TARİHİNİ TAKVİMDE SEÇİLİ GETİRİYOR
        if (props.selectedData != null) {

            console.log(props.selectedData.ek_royal_dog_birhdate);
            var date = new Date(props.selectedData.ek_royal_dog_birhdate);
            console.log(date);

            // let strDay = props.selectedData.ek_royal_dog_birhdate;
            // var res = strDay.split(".");

            // var temp = res[0];
            // res[0] = res[1];
            // res[1] = temp;

            // var date = new Date(res);

            setCalendarDate(date);
        }


        setShow(true);

    }

    return <>

        <BrowserView>
        {
            props.isSale == false ? <button onClick={() => setShow(true)} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                Köpek Ekle</button>
                : ""
        }
        </BrowserView>
        <MobileView>
        {
            props.isSale == false ? <button onClick={() => setShow(true)} className="btn btn-primary btn-block font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                Köpek Ekle</button>
                : ""
        }
        </MobileView>


        

        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={ClickEvent} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={show}
            onHide={() => { setShow(false) }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">

                    <div>
                        <h5 className="font-boldest">{props.isUpdate == true ? "Köpek Güncelle" : "Köpek Ekle"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {/* KÖTÜ FOTO YÜKLEME
                      <div class="form-group row">
                          <div class="col-lg-12 col-md-9 col-sm-12">
                              <div class="dropzone dropzone-default" id="kt_dropzone_1">
                                  <div class="dropzone-msg dz-message needsclick">
                                      <h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      */}

                    {/* FOTO SEÇİMİ */}
                    {/*
                        ImagePicker(function (r) {
                            setSelectedImage(r);
                        }, true, false, props.selectedData == undefined ? null : Ekmekk().URL + '/images/' + props.selectedData.ek_royal_dog_image)
                    */ }

                    <Validator>
                        {/* KULLANICI İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">İsim: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputRoyalDogName} className="form-control form-control-lg form-control-solid" name="customerName" defaultValue={props.selectedData != undefined ? props.selectedData.ek_royal_dog_name : ""} placeholder="İsim" type="text" />
                            </div>
                        </div>
                        {/* KÖPEK İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Köpek Cinsiyeti: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputRoyalDogGender} className="form-control form-control-lg form-control-solid" id="select_banner_type" defaultValue={props.selectedData != undefined ? props.selectedData.ek_royal_dog_gender : ""} >
                                    <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                    {
                                        genders.map(item => {
                                            return GenderItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* KÖPEK CİNSİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Doğum Tarihi: </label>

                            <div className="col-lg-9 col-xl-9">

                                <DatePicker
                                    selected={calendarDate}
                                    // @ts-ignore
                                    onChange={date => setCalendarDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control form-control-lg form-control-solid w-100"
                                />

                            </div>

                        </div>
                        {/* SÜRE */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Cinsi: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputRoyalDogBreed} onChange={(e) => ChangeSubBreedList(e.target.value)} className="form-control form-control-lg form-control-solid" id="select_banner_type" >
                                    <option value="-1">Lütfen Bir Seçim Yapınız</option>
                                    {
                                        breedList.map(item => {
                                            return BreedItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* SÜRE */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Alt Cinsi: </label>
                            <div className="col-lg-9 col-xl-9">
                                <select ref={inputRoyalDogSubBreed} className="form-control form-control-lg form-control-solid" id="select_banner_type" >
                                    {
                                        subBreedList.map(item => {
                                            return SubBreedItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* SÜRE */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Alış Fiyatı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputRoyalPurchasePrice} className="form-control form-control-lg form-control-solid" name="dog_breed" placeholder="Alış Fiyatı" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_royal_dog_purchase_price : ""} />
                            </div>
                        </div>
                        {/* SÜRE */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyatı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputRoyalSalePrice} className="form-control form-control-lg form-control-solid" name="dog_breed" placeholder="Satış Fiyatı" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_royal_dog_sale_price : ""} />
                            </div>
                        </div>
                    </Validator>
                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false) }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={RoyalDogInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Ekle"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}