import Modal from 'react-bootstrap/Modal'
import React, { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import Validator, { ValidationController } from '../../Ekmekk/Helper/Validator';
import DatePicker from "react-datepicker"


export default function ModalHotelSale(props) {


    // const [processType, setProcessType] = useState(-1);

    // const [cost, setCost] = useState(-1);
    // const [preDebt, setPreDebt] = useState(-1);
    // const [totalCost, setTotalCost] = useState(-1);


    // const [kalanTutar, setKalanTutar] = useState(0)

    // var inputAmount = useRef(null);
    // var inputProcessType = useRef(null);
    // var inputDogID = useRef(null);
    // var inputSalePrice = useRef(null);
    // var odenenTutar = useRef(null);

    const [isExit, setIsExit] = useState(-1);
    let exitValue = false;

    function DogInsertToCageClick() {
        var today = calendarDate, date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        var today2 = exitDate, myExitDate = today2.getFullYear() + '-' + (today2.getMonth() + 1) + '-' + today2.getDate() + ' ' + today2.getHours() + ':' + today2.getMinutes() + ':' + today2.getSeconds();

        console.log(date);
        console.log(myExitDate);

        if (isExit != 1) {
            myExitDate = null;
        }

        // ek_entry_date, ek_cage_daily_price, ek_selling_paid_price, customerID, ek_customer_dog_ID
        var validator = new ValidationController();

        if (validator.Control() == 0) {

            var dataBlock = {
                ek_cage_ID: props.cageID,
                // ek_dog_ID: inputDogID.current,
                ek_entry_date: date,
                ek_exit_date: myExitDate,
                ek_dog_type: 1,                                     // MÜŞTERİ KÖPEĞİ
                ek_process_ID: 1,                                   // NE ATTIĞIN FARK ETMEZ AMA AT
                // ek_process_type: inputProcessType.current.value,

            }

            dataBlock["ek_dog_ID"] = props.dogID;
            dataBlock["ek_process_type"] = "0";
            dataBlock["ek_cage_daily_price"] = props.salePrice;


            console.log(dataBlock);

            // KAFESİ DOLDUR
            DataCenter().Cage.InsertDogToCage(dataBlock).then(r => {

                if (r.status) {
                    Ekmekk().Alert.Success();

                    props.insertClick(dataBlock);
                }
                else {
                    Ekmekk().Alert.Fail();
                }
            })


            console.log(dataBlock);

        }

    }

    // ------------- TARİH --------------

    const [calendarDate, setCalendarDate] = useState(new Date());
    const [exitDate, setExitDate] = useState(new Date());
    // const [birthDate, setBirthDate] = useState(null);



    const [show, setShow] = useState(false);

    useEffect(() => {

        if (props.isShow && props.salePrice != undefined) {
            // console.log(props.salePrice);
            console.log(props);
        }


    }, [props.isShow])


    return <>

        {
            // props.isCageFull == 0 ? <button type="button" onClick={() => {
            //     console.log(props);
            // }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
            //     Doldur
            // </button> : <button type="button" onClick={() => {
            //     setShow(true);
            //     console.log(props);
            // }} className="btn btn-sm btn-block btn-light-danger font-weight-bold font-size-lg mr-2 py-4 px-3 px-xxl-5 my-1 text-uppercase" data-toggle="modal" data-target="#exampleModalCenter">
            //     Boşalt
            // </button>
        }

        <Modal
            show={props.isShow == undefined ? show : props.isShow}
            onHide={() => { setShow(false); if (props.handler != undefined) props.handler("close") }}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Seçimi Tamamla
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                    {/* <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Adet: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={inputAmount} className="form-control form-control-lg" id="campain_name2" placeholder="1" type="number" defaultValue="1" />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyatı: </label>
                        <div className="col-lg-9 col-xl-9">
                            <input ref={salePrice} className="form-control form-control-lg" id="campain_name2" placeholder={props.productPrice} type="number" defaultValue={props.productPrice} />
                        </div>
                    </div> */}

                    <Validator>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Giriş Tarihi: </label>

                            <div className="col-lg-9 col-xl-9">

                                <DatePicker
                                    selected={calendarDate}
                                    // @ts-ignore
                                    onChange={date => setCalendarDate(date)}
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    showTimeInput

                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control form-control-lg form-control-solid w-100"
                                />

                            </div>

                        </div>

                        <div className="form-group row align-items-center">
                            <label className="col-xl-3 col-lg-3 col-form-label">Çıkış Tarihi Seç: </label>
                            <div className="col-lg-9 col-xl-6">
                                <span className="switch">
                                    <label>

                                        <input value={exitValue} onChange={() => {

                                            if (isExit != 1) {
                                                setIsExit(1);
                                                // exitValue = true;
                                            }
                                            else {
                                                setIsExit(0);
                                                // exitValue = false;
                                            }

                                        }} type="checkbox" name="email_notification_1" />

                                        <span />
                                    </label>
                                    {/* <label>

                                        <input value={item.sms} onChange={() => {

                                            if (item.sms == 0) {
                                                notificationList[i]["sms"] = 1;
                                                setNewMail(1);
                                            }
                                            else {
                                                notificationList[i]["sms"] = 0;
                                                setNewMail(0);
                                            }

                                        }} defaultChecked={item.sms == 0 ? "" : "checked"} type="checkbox" name="email_notification_1" />

                                        <span />
                                    </label> */}
                                </span>
                            </div>
                        </div>


                        {
                            props.isCageFull == 0 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Giriş Tarihi: </label>

                                <div className="col-lg-9 col-xl-9">

                                    <DatePicker
                                        selected={calendarDate}
                                        // @ts-ignore
                                        onChange={date => setCalendarDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput

                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="form-control form-control-lg form-control-solid w-100"
                                    />

                                </div>

                            </div> : isExit == 1 ? <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">Çıkış Tarihi: </label>

                                <div className="col-lg-9 col-xl-9">

                                    <DatePicker
                                        selected={exitDate}
                                        // @ts-ignore
                                        onChange={date => setExitDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput

                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className="form-control form-control-lg form-control-solid w-100"
                                    />

                                </div>

                            </div> : ""
                        }

                    </Validator>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button type="button" className="btn btn-light-primary font-weight-bold mr-4" data-dismiss="modal" onClick={() => {
                        setShow(false); if (props.handler != undefined) props.handler("close")
                    }}>
                        Close</button>
                    <button type="button" className="btn btn-primary font-weight-bold" onClick={DogInsertToCageClick}>Tamamla</button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}