import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import { ViewState } from '@devexpress/dx-react-scheduler';
import classNames from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Scheduler,
    WeekView,
    MonthView,
    Appointments,
    DateNavigator,
    Toolbar,
    TodayButton,
    AppointmentTooltip,
    ViewSwitcher,
    AppointmentForm,
    AllDayPanel,
    EditRecurrenceMenu,
    DayView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useEffect } from 'react';
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import { useState } from 'react';
import { useRef } from 'react';
import ModalCustomerSelect from '../../Modal/Customer/CustomerSelect';
import ModalEducatorSelect from '../../Modal/Education/EducatorSelect';
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { useParams } from 'react-router-dom';
import SubHeader from '../../Thema/sub-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalEducatorInsert from '../../Modal/Education/EducatorInsert';
import NullPage from '../../Ekmekk/Views/NullPage';
import ModalSaleComplete from '../../Modal/Sale/SaleComplete';
import ModalRoyalDogSelect from '../../Modal/RoyalDog/RoyalDogSelect';
import Sticky from 'react-sticky-el';
import AuthorityControl from '../../Ekmekk/Auth/AuthorityControl';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


export default function EgitimSatis() {


    var royalDogID = useParams();

    var selectionData = useRef(null);
    var dogRef = useRef(null);
    var stockRef = useRef(null);
    var amount = useRef(null);
    var salePrice = useRef(null);

    const [mySalePrice, setMySalePrice] = useState(-1);
    const [saleList, setSaleList] = useState([]);

    const [selectionCustomer, setSelectedCustomer] = useState({});  // SEÇİLEN KÖPEK

    const [stockList, setStockList] = useState([]);
    const [selectedStock, setSelectedStock] = useState(-1);

    const [dogList, setDogList] = useState([]);
    const [selectedDog, setSelectedDog] = useState(-1);
    const [dogPrice, setDogPrice] = useState(-1);

    // const [educationList, setEducationList] = useState([]);
    // const [mainEducationList, setMainEducationList] = useState([]);
    // const [selectedEducation, setSelectedEducation] = useState(-1);

    const [cageList, setCageList] = useState([]);                   // ÜRÜN LİSTESİ
    const [selectedCage, setSelectedCage] = useState([]);           // SEÇİLEN ÜRÜN BİLGİSİ
    const [selectedProduct, setSelectedProduct] = useState(-1);     // SEÇİLEN ÜRÜN İD

    const [isModal, setModal] = useState(false);
    const [isSaleModal, setSaleModal] = useState(false);
    const [isEducatorModal, setEducatorModal] = useState(false);

    useEffect(() => {

        console.log(royalDogID.royalDogID);

        DataCenter().Sale.ListSale().then(r => {
            // console.log(r);
            // setEducationList(r.product);
            setCageList(r.product);

            console.log(r.product);
        })

        var today = new Date(), date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

        // EĞER KÖPEK PROFİLİNDEN GELİNDİYSE MÜŞTERİ VE KÖPEĞİ SEÇİLİ YAPMA
        if (royalDogID.royalDogID != undefined) {
            // Ekmekk().Alert.Block();
            DataCenter().RoyalDog.ListRoyalDogProfile(royalDogID.royalDogID).then(r => {

                console.log(r.data);
                console.log(r.data.dog);
                setSelectedCustomer(r.data.dog);

                // setSelectedCustomer(r.data.customer[0]);        // GELEN MÜŞTERİ ID İLE MÜŞTERİ BİLGİLERİ TOPLANIP SEÇİLİ MÜŞTERİYE BASILIR


            });
        }
    }, [])

    function CompleteSale() {

        if (amount.current.value == null || amount.current.value == "" || selectedProduct == -1 || selectedStock == -1) {
            Ekmekk().Alert.FillAll();
        }
        else {
            console.log(amount.current.value)
            console.log(selectionCustomer.ek_royal_dog_ID)
            console.log(selectedCage)
            console.log(JSON.parse(selectedCage.stok_info))
            console.log(selectedProduct)
            console.log(selectedStock)
            console.log(amount.current.value);

            var salePrice, purchasePrice;

            for (let i = 0; i < JSON.parse(selectedCage.stok_info).length; i++) {

                if (JSON.parse(selectedCage.stok_info)[i].ek_product_stock_ID == selectedStock) {
                    console.log(JSON.parse(selectedCage.stok_info)[i])
                    salePrice = JSON.parse(selectedCage.stok_info)[i].ek_product_sale_price;
                    purchasePrice = JSON.parse(selectedCage.stok_info)[i].ek_product_purchase_price;
                }
            }

            var dataBlock = {
                ek_royal_dog_ID: selectionCustomer.ek_royal_dog_ID,
                ek_product_ID: selectedProduct,
                ek_product_stock_ID: parseInt(selectedStock),
                ek_food_piece: parseInt(amount.current.value),
                ek_food_sale_price: salePrice,
                ek_food_purchase_price: purchasePrice,
            }

            console.log(dataBlock)

            Ekmekk().Alert.Sure((e) => {
                if (e) {
                    DataCenter().Product.AddFoodToRoyalDog(dataBlock).then(r => {
                        
                        if (r.status) {
                            Ekmekk().Alert.Success();
                        }
                        else {
                            Ekmekk().Alert.Fail();
                        }
                    })

                }
                else {
                    Ekmekk().Alert.Fail();
                }
            })

        }

    }

    function Handler(data) {
        if (data == "close") {
            setModal(false);
            setSaleModal(false);
            setEducatorModal(false);
        }
    }

    function selectedCustomer(r) {
        setModal(false);
        setSelectedCustomer(JSON.parse(r));
        console.log(JSON.parse(r))
        /* DataCenter().Customer.ListCustomerDog(JSON.parse(r)["ek_customer_ID"]).then(r => {
             setDogList(r.data)
         });*/

    }


    function SelectCustomer() {
        return <div>

            <div style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center">
                    <img src="/my-assets/royaldog.svg" className="max-h-400px" />

                </div>
                <div className="font-weight-bold font-size-lg mt-2">Herşeyden önce bir köpek seçmelisin</div>
                <div className="text-muted mt-2">Mama eklemek için öncelikli olarak bir köpek seçmelisin</div>
                <div className="btn btn-primary mt-6" onClick={() => { setModal(true); }}>Köpek seç</div>
            </div>


        </div>
    }

    function SelectAnotherData() {
        return <div>

            <div style={{ textAlign: "center" }}>
                <div className="d-flex justify-content-center">
                    <img src="/my-assets/royaldog.svg" className="max-h-400px" />

                </div>
                <div className="font-weight-bold font-size-lg mt-2">Harika! Kalan ufak bilgileri doldur ve organize etmeye başla</div>
                {/* <div className="text-muted mt-2">Bir mama eklemek için öncelikli olarak bir müşteri seçmelisin</div> */}

            </div>

        </div>
    }

    function CageItem(item) {
        return <>
            <div onClick={() => {
                setSelectedCage(item);

                if (JSON.parse(item.stok_info) != null)
                    setStockList(JSON.parse(item.stok_info));

                setSelectedProduct(item.ek_product_ID);


            }} id="item_advertisementID" className="col-xl-4 col-lg-6 col-md-6 col-sm-6">

                <div className="card card-custom gutter-b card-stretch">

                    <div className="card-body pt-4">

                        <div className="pt-4 text-center">
                            <div className="symbol symbol-150 symbol-2by3 flex-shrink-0">
                                <img src={item.ek_product_image == "" ? "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg" : Ekmekk().URL + '/images/' + item.ek_product_image} alt="" className="h-50" />

                            </div>
                        </div>
                        {/*begin::User*/}
                        <div className="d-flex align-items-center mb-7">
                            {/*begin::Pic*/}
                            <div className="flex-shrink-0 mr-4">
                            </div>
                            {/*end::Pic*/}
                            {/*begin::Title*/}
                            <div className="d-flex flex-column">
                                <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.ek_product_name}</a>
                                <span className="text-muted font-weight-bold">{item.ek_product_brand_name}</span>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Info*/}
                        <div className="mb-7">
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Fiyat:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">₺{JSON.parse(item.stok_info).length > 0 ? JSON.parse(item.stok_info)[0].ek_product_sale_price : "---"}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-cente my-1">
                                <span className="text-dark-75 font-weight-bolder mr-2">Stok:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{JSON.parse(item.stok_info).length > 0 ? JSON.parse(item.stok_info)[0].ek_product_stock : "---"}</a>
                            </div> */}
                            <div className="d-flex justify-content-between align-items-cente my-1">
                                <span className="text-dark-75 font-weight-bolder mr-2">Kategori:</span>
                                <a href="#" className="text-dark-50 text-hover-primary">{item.ek_product_category_name}</a>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-dark-75 font-weight-bolder mr-2">Türü:</span>
                                <span className="text-dark-50 font-weight-bold">{item.type == 2 ? "Ürün" : ""}</span>
                            </div>
                        </div>
                        {/*end::Info*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end:: Card*/}
            </div>

        </>
    }

    function Organizer() {
        return <>

            <div className={cageList.length == 0 ? "row d-flex justify-content-center" : "row"}>

                {
                    cageList == undefined ? "" : cageList.length == 0 ? <NullPage image={"certification.svg"} /> : cageList.map(item => {
                        return CageItem(item)
                    })
                }

            </div>

        </>
    }

    function TimeLine() {
        return <>
            <BrowserView>

                <Sticky topOffset={30}>
                    <div className="timeline timeline-5">
                        <div className="timeline-items">
                            {/*begin::Item*/}
                            <div className="timeline-item">
                                {/*begin::Icon*/}
                                <div className="timeline-media bg-light-success">
                                    <span className="svg-icon-success svg-icon-md">
                                        1
                                    </span>
                                </div>
                                {/*end::Icon*/}
                                {/*begin::Info*/}
                                <div className="timeline-desc timeline-desc-light-success">
                                    <span className="font-weight-bolder text-success">Köpek Bilgisi</span>
                                    <p className="font-weight-normal text-dark-50 pb-2">
                                        <div className="d-flex mt-6" onClick={() => {
                                            setModal(true);
                                            // setList([]);
                                            setSelectedProduct(-1);
                                            setSelectedCage([]);
                                            setSelectedStock(-1);
                                            setStockList([]);
                                            // setSelectedDog(-1);
                                            // setDogPrice(-1);

                                            // setLessonList([]);
                                            // setSelectedLesson([]);
                                            // setSelectedEducator({});

                                        }}>
                                            {/*begin: Pic*/}
                                            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                                <div className="symbol shadow symbol-40 symbol-lg-50">
                                                    {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                    <img src={(selectionCustomer.ek_royal_dog_image != "" && selectionCustomer.ek_royal_dog_image != undefined) ? Ekmekk().GetImageURL(selectionCustomer.ek_royal_dog_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                                </div>
                                                <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                    <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                                </div>
                                            </div>
                                            {/*end: Pic*/}
                                            {/*begin: Info*/}
                                            <div className="flex-grow-1">
                                                {/*begin: Title*/}
                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                    <div className="mr-3">
                                                        {/*begin::Name*/}
                                                        <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">

                                                            {
                                                                (selectionCustomer.ek_royal_dog_name != "" && selectionCustomer.ek_royal_dog_name != undefined) ? selectionCustomer.ek_royal_dog_ID + " - " + selectionCustomer.ek_royal_dog_name : "-----"
                                                            }
                                                            <i className="flaticon2-correct text-success icon-md ml-2" /></a>

                                                        {selectionCustomer.ek_royal_dog_gender}
                                                    </div>
                                                </div>
                                                {/*end: Title*/}
                                                {/*begin: Content*/}
                                                <div className="d-flex align-items-center flex-wrap justify-content-between">

                                                </div>
                                                {/*end: Content*/}
                                            </div>
                                            {/*end: Info*/}
                                        </div>
                                        <div className="separator separator-solid my-3" />

                                        <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Cinsiyet :</span>  {(selectionCustomer.ek_royal_dog_gender != "" && selectionCustomer.ek_royal_dog_gender != undefined) ? selectionCustomer.ek_royal_dog_gender : "-----"}</div>
                                        <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Cins :</span> {(selectionCustomer.ek_dog_breed_name != "" && selectionCustomer.ek_dog_breed_name != undefined) ? selectionCustomer.ek_dog_breed_name : "-----"}</div>
                                        <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Alt Cins :</span> {(selectionCustomer.ek_dog_sub_breed_name != "" && selectionCustomer.ek_dog_sub_breed_name != undefined) ? selectionCustomer.ek_dog_sub_breed_name : "-----"}</div>

                                    </p>
                                </div>
                                {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="timeline-item">
                                {/*begin::Icon*/}
                                <div className="timeline-media bg-light-success">
                                    <span className="svg-icon-success svg-icon-md">
                                        2
                                    </span>
                                </div>
                                {/*end::Icon*/}
                                {/*begin::Info*/}
                                <div className="timeline-desc timeline-desc-light-success">
                                    <span className="font-weight-bolder text-success">Ürün Bilgisi</span>
                                    <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                        <div className="d-flex mt-6" onClick={() => {
                                            // setModal(true);
                                            // setList([]);
                                            // setSelectedProduct(-1);
                                            // setSelectedEducation(-1);
                                            // setSelectedDog(-1);
                                            // setLessonList([]);
                                            // setSelectedLesson([]);
                                            // setSelectedEducator({});

                                        }}>
                                            {/*begin: Pic*/}
                                            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                                <div className="symbol shadow symbol-40 symbol-lg-50">
                                                    {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                    <img src={(selectedCage.ek_product_image != "" && selectedCage.ek_product_image != undefined) ? Ekmekk().GetImageURL(selectedCage.ek_product_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                                </div>
                                                <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                    <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                                </div>
                                            </div>
                                            {/*end: Pic*/}
                                            {/*begin: Info*/}
                                            <div className="flex-grow-1">
                                                {/*begin: Title*/}
                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                    <div className="mr-3">
                                                        {/*begin::Name*/}
                                                        <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">

                                                            {
                                                                (selectedCage.ek_product_name != "" && selectedCage.ek_product_name != undefined) ? selectedCage.ek_product_name : "-----"
                                                            }

                                                            <i className="flaticon2-correct text-success icon-md ml-2" />
                                                        </a>

                                                        <span className="text-muted font-weight-bold">
                                                            {
                                                                (selectedCage.ek_product_brand_name != "" && selectedCage.ek_product_brand_name != undefined) ? selectedCage.ek_product_brand_name : "-----"
                                                            }
                                                        </span>


                                                    </div>
                                                </div>
                                                {/*end: Title*/}
                                                {/*begin: Content*/}
                                                <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                    <span className="text-muted font-weight-bold">
                                                        {
                                                            (selectedCage.ek_product_category_name != "" && selectedCage.ek_product_category_name != undefined) ? selectedCage.ek_product_category_name : "-----"
                                                        }
                                                    </span>

                                                </div>
                                                {/*end: Content*/}
                                            </div>
                                            {/*end: Info*/}
                                        </div>

                                    </p>
                                </div>
                                {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="timeline-item">
                                {/*begin::Icon*/}
                                <div className="timeline-media bg-light-success">
                                    <span className="svg-icon-success svg-icon-md">
                                        3
                                    </span>
                                </div>
                                {/*end::Icon*/}
                                {/*begin::Info*/}
                                <div className="timeline-desc timeline-desc-light-success">
                                    <span className="font-weight-bolder text-success">Stok Bilgisi</span>
                                    <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                        <select onChange={(e) => {

                                            setSelectedStock(e.target.value);
                                            // setSelectedDog(e.target.value)

                                            // for (let i = 0; i < dogList.length; i++) {

                                            //     if (dogList[i].ek_royal_dog_ID == e.target.value)
                                            //         // e.target.value = educationList[i].ek_education_price;
                                            //         setDogPrice(dogList[i].ek_royal_dog_sale_price);
                                            // }

                                        }} value={selectedStock} ref={stockRef} class="form-control form-control-lg form-control-solid" id="select_banner_type">
                                            <option value="-1">Lütfen bir seçim yapınız</option>
                                            {
                                                stockList.map(item => {
                                                    return <option value={item.ek_product_stock_ID}>Alış - {item.ek_product_purchase_price} | Satış - {item.ek_product_sale_price} | Stok - {item.ek_product_stock}</option>
                                                })
                                            }


                                        </select>
                                    </p>
                                </div>
                                {/*end::Info*/}
                            </div>



                            <div className="timeline-item">
                                {/*begin::Icon*/}
                                <div className="timeline-media bg-light-success">
                                    <span className="svg-icon-success svg-icon-md">
                                        4
                                    </span>
                                </div>
                                {/*end::Icon*/}
                                {/*begin::Info*/}
                                <div className="timeline-desc timeline-desc-light-success">
                                    <span className="font-weight-bolder text-success">Adet Bilgisi</span>
                                    <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                        <div className="col-lg-9 col-xl-9">
                                            <input ref={amount} className="form-control form-control-lg" id="campain_name2" placeholder={"Stok Adeti"} type="number" />
                                        </div>
                                    </p>
                                </div>
                                {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="timeline-item">
                                {/*begin::Icon*/}
                                <div className="timeline-media bg-light-success">
                                    <span className="svg-icon-success svg-icon-md">
                                        5
                                    </span>
                                </div>
                                {/*end::Icon*/}
                                {/*begin::Info*/}
                                <div className="timeline-desc timeline-desc-light-success">

                                    <button onClick={() => { CompleteSale() }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg py-4 px-3">İşlemi Tamamla</button>

                                </div>
                                {/*end::Info*/}
                            </div>
                            {/*end::Item*/}
                        </div>
                    </div>
                </Sticky>

            </BrowserView>
            <MobileView>

                <div className="timeline timeline-5">
                    <div className="timeline-items">
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    1
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Köpek Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        setModal(true);
                                        // setList([]);
                                        setSelectedProduct(-1);
                                        setSelectedCage([]);
                                        setSelectedStock(-1);
                                        setStockList([]);
                                        // setSelectedDog(-1);
                                        // setDogPrice(-1);

                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});

                                    }}>
                                        {/*begin: Pic*/}
                                        <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                            <div className="symbol shadow symbol-40 symbol-lg-50">
                                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                <img src={(selectionCustomer.ek_royal_dog_image != "" && selectionCustomer.ek_royal_dog_image != undefined) ? Ekmekk().GetImageURL(selectionCustomer.ek_royal_dog_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                            </div>
                                            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                            </div>
                                        </div>
                                        {/*end: Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin: Title*/}
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">

                                                        {
                                                            (selectionCustomer.ek_royal_dog_name != "" && selectionCustomer.ek_royal_dog_name != undefined) ? selectionCustomer.ek_royal_dog_ID + " - " + selectionCustomer.ek_royal_dog_name : "-----"
                                                        }
                                                        <i className="flaticon2-correct text-success icon-md ml-2" /></a>

                                                    {selectionCustomer.ek_royal_dog_gender}
                                                </div>
                                            </div>
                                            {/*end: Title*/}
                                            {/*begin: Content*/}
                                            <div className="d-flex align-items-center flex-wrap justify-content-between">

                                            </div>
                                            {/*end: Content*/}
                                        </div>
                                        {/*end: Info*/}
                                    </div>
                                    <div className="separator separator-solid my-3" />

                                    <div className="flex-grow-1 text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Cinsiyet :</span>  {(selectionCustomer.ek_royal_dog_gender != "" && selectionCustomer.ek_royal_dog_gender != undefined) ? selectionCustomer.ek_royal_dog_gender : "-----"}</div>
                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Cins :</span> {(selectionCustomer.ek_dog_breed_name != "" && selectionCustomer.ek_dog_breed_name != undefined) ? selectionCustomer.ek_dog_breed_name : "-----"}</div>
                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5"><span className="font-weight-bolder text-dark">Alt Cins :</span> {(selectionCustomer.ek_dog_sub_breed_name != "" && selectionCustomer.ek_dog_sub_breed_name != undefined) ? selectionCustomer.ek_dog_sub_breed_name : "-----"}</div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    2
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Ürün Seçimi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        // setModal(true);
                                        // setList([]);
                                        // setSelectedEducation(-1);
                                        // setSelectedDog(-1);
                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});
                                        scroll.scrollToBottom()

                                    }}>

                                        {
                                            Organizer()
                                        }

                                    </div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    3
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Ürün Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="d-flex mt-6" onClick={() => {
                                        // setModal(true);
                                        // setList([]);
                                        // setSelectedProduct(-1);
                                        // setSelectedEducation(-1);
                                        // setSelectedDog(-1);
                                        // setLessonList([]);
                                        // setSelectedLesson([]);
                                        // setSelectedEducator({});

                                    }}>
                                        {/*begin: Pic*/}
                                        <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                                            <div className="symbol shadow symbol-40 symbol-lg-50">
                                                {/* <img src={Ekmekk().URL + '/images/' + JSON.parse(selectedCustomer).ek_customer_image} alt="" className="h-50" /> */}
                                                <img src={(selectedCage.ek_product_image != "" && selectedCage.ek_product_image != undefined) ? Ekmekk().GetImageURL(selectedCage.ek_product_image) : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"} alt="" className="h-50" />
                                            </div>
                                            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                                                <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
                                            </div>
                                        </div>
                                        {/*end: Pic*/}
                                        {/*begin: Info*/}
                                        <div className="flex-grow-1">
                                            {/*begin: Title*/}
                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                <div className="mr-3">
                                                    {/*begin::Name*/}
                                                    <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">

                                                        {
                                                            (selectedCage.ek_product_name != "" && selectedCage.ek_product_name != undefined) ? selectedCage.ek_product_name : "-----"
                                                        }

                                                        <i className="flaticon2-correct text-success icon-md ml-2" />
                                                    </a>

                                                    <span className="text-muted font-weight-bold">
                                                        {
                                                            (selectedCage.ek_product_brand_name != "" && selectedCage.ek_product_brand_name != undefined) ? selectedCage.ek_product_brand_name : "-----"
                                                        }
                                                    </span>


                                                </div>
                                            </div>
                                            {/*end: Title*/}
                                            {/*begin: Content*/}
                                            <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                <span className="text-muted font-weight-bold">
                                                    {
                                                        (selectedCage.ek_product_category_name != "" && selectedCage.ek_product_category_name != undefined) ? selectedCage.ek_product_category_name : "-----"
                                                    }
                                                </span>

                                            </div>
                                            {/*end: Content*/}
                                        </div>
                                        {/*end: Info*/}
                                    </div>

                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    4
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Stok Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <select onChange={(e) => {

                                        setSelectedStock(e.target.value);
                                        // setSelectedDog(e.target.value)

                                        // for (let i = 0; i < dogList.length; i++) {

                                        //     if (dogList[i].ek_royal_dog_ID == e.target.value)
                                        //         // e.target.value = educationList[i].ek_education_price;
                                        //         setDogPrice(dogList[i].ek_royal_dog_sale_price);
                                        // }

                                    }} value={selectedStock} ref={stockRef} class="form-control form-control-lg form-control-solid" id="select_banner_type">
                                        <option value="-1">Lütfen bir seçim yapınız</option>
                                        {
                                            stockList.map(item => {
                                                return <option value={item.ek_product_stock_ID}>Alış - {item.ek_product_purchase_price} | Satış - {item.ek_product_sale_price} | Stok - {item.ek_product_stock}</option>
                                            })
                                        }


                                    </select>
                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>



                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    5
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">
                                <span className="font-weight-bolder text-success">Adet Bilgisi</span>
                                <p className="font-weight-normal text-dark-50 pt-1 pb-2">
                                    <div className="col-lg-9 col-xl-9">
                                        <input ref={amount} className="form-control form-control-lg" id="campain_name2" placeholder={"Stok Adeti"} type="number" />
                                    </div>
                                </p>
                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <div className="timeline-item">
                            {/*begin::Icon*/}
                            <div className="timeline-media bg-light-success">
                                <span className="svg-icon-success svg-icon-md">
                                    6
                                </span>
                            </div>
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="timeline-desc timeline-desc-light-success">

                                <button onClick={() => { CompleteSale() }} className="btn btn-sm btn-block btn-light-success font-weight-bold font-size-lg py-4 px-3">İşlemi Tamamla</button>

                            </div>
                            {/*end::Info*/}
                        </div>
                        {/*end::Item*/}
                    </div>
                </div>

            </MobileView>
        </>
    }

    return <div>


        <AuthorityControl pageCode={43}>

            {/* <ModalCustomerSelect selectedCustomer={selectedCustomer} handler={Handler} isVisible={false} isShow={isModal}></ModalCustomerSelect> */}
            <ModalRoyalDogSelect selectedRoyalDog={selectedCustomer} handler={Handler} isVisible={false} isShow={isModal} ></ModalRoyalDogSelect>

            {/* <ModalSaleComplete insertClick={FillCage} saleList={saleList} customer={JSON.stringify(selectionCustomer)} salePrice={mySalePrice} handler={Handler} isVisible={false} isShow={isSaleModal} /> */}

            {/* <ModalEducatorSelect selectedEducator={r => {
            setSelectedEducator(JSON.parse(r));
            console.log(JSON.parse(r));
            setEducatorModal(false);

        }} isVisible={false} handler={Handler} isShow={isEducatorModal} /> */}

            {
                selectionCustomer.ek_royal_dog_name == null ? SelectCustomer() : <div className="row">

                    <div className="col-xl-3 col-lg-4 col-md-12">
                        <TimeLine />
                    </div>
                    <div className="col-md"></div>
                    <div className="col-xl-8 col-lg-7">
                        <BrowserView>
                            {
                                Organizer()
                            }
                        </BrowserView>

                    </div>
                </div>

            }
        </AuthorityControl>

    </div>

}
