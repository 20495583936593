
import React from 'react'

export default function Validator({ children }) {



    return <div id="ekmekkValidator">
        {children}

    </div>
}


export class ValidationController {

    Control() {
        var texAreaElements = document.getElementById("ekmekkValidator").querySelectorAll("textarea");
        var inputElements = document.getElementById("ekmekkValidator").querySelectorAll("input");
        var selectElement = document.getElementById("ekmekkValidator").querySelectorAll("select");

        var count = 0;
        count += this.Loop(texAreaElements, "textarea");
        count += this.Loop(inputElements, "input");

        for (var i = 0; i < selectElement.length; i++) {

            var oldLabel = selectElement[i] != undefined && selectElement[i] != null ? selectElement[i].parentNode.querySelectorAll(".ekmekkValidator-label") : [];

            for (var k = 0; k < oldLabel.length; k++) {
                oldLabel[k].parentNode.removeChild(oldLabel[k]);
            }

            if (selectElement[i].value == -1) {
                var iDiv = document.createElement('div');
                iDiv.id = 'block';
                iDiv.className = 'text-danger ekmekkValidator-label';
                iDiv.innerHTML = "Lütfen bir seçim yapınız";
                selectElement[i].parentNode.appendChild(iDiv);
                count++;
            }
        }

        return count;



    }

    ValidateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    ValidatePhone(inputPhone) {
        const phone = require('phone');


        if (!inputPhone.includes("+90"))
            inputPhone = "+90" + inputPhone;


        if (phone(inputPhone).length == 0) {
            return false;
        }
        else {
            return true;
        }
        // ; // return ['+85265698900', 'HKG']
        // phone('+1(817) 569-8900', ''); // return ['+18175698900', 'USA']
        // phone('(817) 569-8900', 'USA'); // return ['+18175698900', 'USA']
        // phone('(817) 569-8900', 'HKG'); // return []
        // phone('+1(817) 569-8900', 'HKG'); // return [], as it is not a valid HKG mobile phone number
        // phone('6123-6123', 'HKG'); // return ['+85261236123', 'HKG']

        // const re = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
        // return phone(inputPhone);
    }

    Loop(elements, type) {
        var count = 0;
        for (var i = 0; i < elements.length; i++) {
            var inputElement = elements[i];

            if (inputElement.dataset.validationRule != undefined) {
                var rules = inputElement.dataset.validationRule.split('|');

                var oldLabel = inputElement != undefined && inputElement != null ? inputElement.parentNode.querySelectorAll(".ekmekkValidator-label") : [];

                for (var k = 0; k < oldLabel.length; k++) {
                    oldLabel[k].parentNode.removeChild(oldLabel[k]);
                }

                if (type == "textarea")
                    inputElement.innerHTML = inputElement.value;

                for (var k = 0; k < rules.length; k++) {
                    var rule = rules[k];
                    if (rule == "notNull") {
                        if (inputElement.value.length == 0) {
                            //inputElement.parentNode.innerHTML += this.Label();
                            var iDiv = document.createElement('div');
                            iDiv.id = 'block';
                            iDiv.className = 'text-danger ekmekkValidator-label';
                            iDiv.innerHTML = "Lütfen bu alanı doldurun";
                            inputElement.parentNode.appendChild(iDiv);
                            count++;
                            break;
                        }
                    }
                    else if (rule == "onlyNumber") {

                        if (isNaN(inputElement.value)) {
                            var iDiv = document.createElement('div');
                            iDiv.id = 'block';
                            iDiv.className = 'text-danger ekmekkValidator-label';
                            iDiv.innerHTML = "Lütfen bu alana yanlızca rakam giriniz";
                            inputElement.parentNode.appendChild(iDiv);
                            count++;

                        }

                    }
                    else if (rule == "onlyMail") {

                        if (inputElement.value.length != 0 && !this.ValidateEmail(inputElement.value)) {
                            var iDiv = document.createElement('div');
                            iDiv.id = 'block';
                            iDiv.className = 'text-danger ekmekkValidator-label';
                            iDiv.innerHTML = "Lütfen bu alana mail@adres.com şeklinde giriniz";
                            inputElement.parentNode.appendChild(iDiv);
                            count++;

                        }

                    }
                    else if (rule == "onlyPhone") {

                        if (!this.ValidatePhone(inputElement.value)) {
                            var iDiv = document.createElement('div');
                            iDiv.id = 'block';
                            iDiv.className = 'text-danger ekmekkValidator-label';
                            iDiv.innerHTML = "Lütfen geçerli telefon numarası giriniz";
                            inputElement.parentNode.appendChild(iDiv);
                            count++;

                        }

                    }
                    else if (rule == "minLength") {

                        var lengthRule = rule.split("(")[0];
                        var lengthData = rule.split("(")[1].split(")")[0];

                        if (lengthRule == "minLength" && inputElement.value.length < lengthData) {
                            var iDiv = document.createElement('div');
                            iDiv.id = 'block';
                            iDiv.className = 'text-danger ekmekkValidator-label';
                            iDiv.innerHTML = "Bu alana en az " + lengthData + " karakter girmelisiniz";
                            inputElement.parentNode.appendChild(iDiv);
                            count++;
                            break;
                        }

                    }

                }

            }




        }
        return count;
    }

    GetJSON() {
        var texAreaElements = document.getElementById("ekmekkValidator").querySelectorAll("textarea");
        var inputElements = document.getElementById("ekmekkValidator").querySelectorAll("input");
        var selectElement = document.getElementById("ekmekkValidator").querySelectorAll("select");

        var datas = {};


        for (var i = 0; i < inputElements.length; i++) {
            if (inputElements[i].type == "file") {
                datas[inputElements[i].name] = inputElements[i].files;
            }
            else if (inputElements[i].type == "checkbox") {
                datas[inputElements[i].name] = inputElements[i].checked ? 1 : 0;
            }
            else {
                datas[inputElements[i].name] = inputElements[i].value;
            }

        }
        for (var i = 0; i < selectElement.length; i++) {

            datas[selectElement[i].name] = selectElement[i].value;


        }
        return datas;
    }

    Label(label) {
        return "<p style={{alignItems: 'center',display: 'center'}} class='text-danger ekmekkValidator-label'>Bu alan boş bırakılamaz</p>"
    }
    Label2(length) {
        return "<p class='text-danger ekmekkValidator-label'>" + length + " karakter uzun girin</p>"
    }
}