
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useRef } from 'react'
import DataCenter from '../../Ekmekk/DataCenter/datacenter';
import ImagePicker from '../../Ekmekk/Helper/ImagePicker';
import Validator, { ValidationController } from "../../Ekmekk/Helper/Validator"
import Ekmekk from '../../Ekmekk/Helper/ekmekk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

export default function ModalProductInsert(props) {

    const [selectedImage, setSelectedImage] = useState(null);

    var inputProductName = useRef(null);
    var inputProductBrandID = useRef(null);
    var inputProductCategoryID = useRef(null);

    // var inputProductPurchasePrice = useRef(null);
    // var inputProductSalePrice = useRef(null);
    // var inputProductStok = useRef(null);

    function ProductInsertClick() {

        var dataBlock = {
            ek_product_image: selectedImage,
            ek_product_name: inputProductName.current.value,
            ek_product_brandID: inputProductBrandID.current.value,
            ek_product_category_ID: inputProductCategoryID.current.value,
            ek_product_category_name: null,

            // ek_product_stok: inputProductStok.current.value,
            // ek_product_purchase_price: inputProductPurchasePrice.current.value,
            // ek_product_sale_price: inputProductSalePrice.current.value,
        }


        var controller = new ValidationController();
        if (controller.Control() == 0) {

            if (props.selectedData == undefined) {
                DataCenter().Product.InsertProduct(dataBlock).then(r => {

                    for (let i = 0; i < categoryList.length; i++) {

                        if (categoryList[i].ek_product_category_ID == dataBlock.ek_product_category_ID) {
                            dataBlock.ek_product_category_name = categoryList[i].ek_product_category_name;
                        }

                    }

                    for (let i = 0; i < brandList.length; i++) {

                        if (brandList[i].ek_product_brandID == dataBlock.ek_product_brandID) {
                            dataBlock.ek_product_brand_name = brandList[i].ek_product_brand_name;
                        }

                    }

                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_product_image"] = r.data.imagePath;
                        dataBlock["ek_product_ID"] = r.data.lastID;

                        props.insertClick("inserted", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }
            else {
                dataBlock["ek_product_ID"] = props.selectedData.ek_product_ID;

                DataCenter().Product.UpdateProduct(dataBlock).then(r => {

                    for (let i = 0; i < categoryList.length; i++) {

                        if (categoryList[i].ek_product_category_ID == dataBlock.ek_product_category_ID) {
                            dataBlock.ek_product_category_name = categoryList[i].ek_product_category_name;
                        }

                    }

                    for (let i = 0; i < brandList.length; i++) {

                        if (brandList[i].ek_product_brandID == dataBlock.ek_product_brandID) {
                            dataBlock.ek_product_brand_name = brandList[i].ek_product_brand_name;
                        }

                    }


                    if (r.status) {
                        Ekmekk().Alert.Success();
                        setShow(false);
                        dataBlock["ek_product_image"] = r.data.ek_product_image;

                        props.insertClick("updated", dataBlock);
                    }
                    else {
                        Ekmekk().Alert.Fail();
                    }
                })
            }

        }


    }

    // ------- MARKA SEÇİM LİSTESİ -----------
    const [brandList, setBrandList] = useState([])
    useEffect(() => {
        DataCenter().Brand.ListBrand().then(r => {
            setBrandList(r.data)
        })
    }, [])

    function BrandItem(item) {
        return <option value={item.ek_product_brandID} >{item.ek_product_brand_name}</option>
    }

    // ------- KATEGORİ SEÇİM LİSTESİ -----------
    const [categoryList, setCategoryList] = useState([])
    useEffect(() => {
        DataCenter().Category.ListCategory().then(r => {
            setCategoryList(r.data)
        })
    }, [])

    function CategoryItem(item) {
        return <option value={item.ek_product_category_ID} selected={props.selectedData != undefined && props.selectedData.ek_product_category_name == item.ek_product_category_name}>{item.ek_product_category_name}</option>
    }



    const [show, setShow] = useState(false);

    function ClickEvent() {
        setShow(true)
    }

    function ViewTittle() {
        return <h5 className="font-boldest">{props.isUpdate == true ? "Ürün Güncelle" : "Ürün Oluştur"}</h5>
    }


    return <>

        <BrowserView>
        {
            props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                Ürün Oluştur</button>
                : ""
        }
        </BrowserView>
        <MobileView>
        {
            props.isSale == false ? <button onClick={ClickEvent} className="btn btn-primary btn-block font-weight-bolder" data-backdrop="static" data-toggle="modal" data-target="#master_banner_modal_insert">
                Ürün Oluştur</button>
                : ""
        }
        </MobileView>


        {
            props.isUpdateTable == true ? <FontAwesomeIcon onClick={ClickEvent} icon={faPencilRuler} style={{ fontSize: 2 }} ></FontAwesomeIcon> : props.isUpdate == true ? <button onClick={ClickEvent} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-3">Güncelle</button> : ""
        }

        <Modal
            show={props.isShow != undefined ? props.isShow : show}
            onHide={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <div>

                        <ViewTittle />


                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="customerForm">

                    {
                        ImagePicker(function (r) {
                            setSelectedImage(r);

                        }, true, false, props.selectedData == undefined ? null : Ekmekk().URL + '/images/' + props.selectedData.ek_product_image)
                    }
                    <Validator>
                        <br />
                        {/* KULLANICI İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Ürün Adı: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull" ref={inputProductName} className="form-control form-control-lg form-control-solid" name="customerName" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_name : ""} placeholder="Ürün Adı" type="text" />
                            </div>
                        </div>
                        {/* KULLANICI İSMİ */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Marka:</label>
                            <div className="col-lg-9 col-xl-9">
                                <select id="categoryID" ref={inputProductBrandID} className="form-control form-control-lg form-control-solid" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_brandID : ""} >
                                    <option value="-1"> Lütfen bir seçim yapınız</option>
                                    {
                                        brandList.map(item => {
                                            return BrandItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* KÖPEK SAYISI */}
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Kategori:</label>
                            <div className="col-lg-9 col-xl-9">
                                <select id="categoryID" ref={inputProductCategoryID} className="form-control form-control-lg form-control-solid" >
                                    <option value="-1"> Lütfen bir seçim yapınız</option>
                                    {
                                        categoryList.map(item => {
                                            return CategoryItem(item)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Alış Fiyat: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputProductPurchasePrice} className="form-control form-control-lg form-control-solid" name="phone" placeholder="Fiyat" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_purchase_price : ""} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Satış Fiyat: </label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputProductSalePrice} className="form-control form-control-lg form-control-solid" name="phone" placeholder="Fiyat" type="number" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_sale_price : ""} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">Stok:</label>
                            <div className="col-lg-9 col-xl-9">
                                <input data-validation-rule="notNull|onlyNumber" ref={inputProductStok} className="form-control form-control-lg form-control-solid" type="number" placeholder="Stok" name="example-email-input" defaultValue={props.selectedData != undefined ? props.selectedData.ek_product_stok : ""} />
                            </div>
                        </div> */}

                    </Validator>

                </form>

            </Modal.Body>

            <Modal.Footer className="modal-footer">
                <div>
                    <button onClick={() => { setShow(false); if (props.HandlerClose != undefined) props.HandlerClose() }} type="button" className="btn btn-secondary mr-4" data-dismiss="modal">Vazgeç</button>
                    <button type="button" className="btn btn-success font-weight-bold" id="kt_blockui_modal_default_btn" onClick={ProductInsertClick}>
                        {props.isUpdate == true ? "Güncelle" : "Oluştur"}
                    </button>
                </div>

            </Modal.Footer>
        </Modal>
    </>
}